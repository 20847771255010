import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Markdown from '../Markdown';
import { IOpportunity } from 'src/components/cards/Opportunity/types';
import { IEvent } from 'src/components/cards/Event/types';
import Icon from 'src/components/icons/Icon/index.web';

interface IProps {
  cancel: () => void;
  confirm: () => void;
  text: string | undefined;
  entity: IEvent | IOpportunity;
  isLikesSection?: boolean;
}

export const ApplyModal = ({ cancel, confirm, text, entity, isLikesSection }: IProps) => {
  const { t } = useTranslation();
  const mockedText = 'Thank you for applying! You will be redirected to the companies page.';
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.close} onClick={cancel}>
          <Icon size={20} name={'close'} color={'grey'} />
        </div>
        <div className={styles.emoji}>🎉</div>
        <div className={styles.text1}>
          <Markdown isMobile={false} markdown={text ? text : mockedText} isApplication />
        </div>
        <div className={styles.buttons}>
          {!entity.is_liked && !isLikesSection ? (
            <>
              <button className={styles.cancelButton} onClick={cancel}>
                {t('Cancel')}
              </button>
              <ButtonMain containerStyle={styles.applyButton} size={'lg'} type={'primary_1'} text={t('Confirm')} onPress={confirm} />
            </>
          ) : (
            <ButtonMain
              containerStyle={styles.applyButton}
              size={'lg'}
              type={'primary_1'}
              text={t('Continue')}
              onPress={
                entity.application?.redirectUrl
                  ? () => {
                      window.open(entity.application?.redirectUrl);
                      cancel();
                    }
                  : cancel
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
