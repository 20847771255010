import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        flexFlow: 'row wrap',
        alignItems: isMobile ? 'center' : 'flex-start',
        width: '100%',
        height: 'auto',
        padding: 0,
        marginTop: isMobile ? 20 : 10,
        overflow: 'auto',
      };
    },
    list: {},
    item: ({ isMobile }: IProps) => ({
      marginBottom: 15,
    }),
    noResults: ({ isMobile }: IProps) => ({
      width: '100%',
      justifyContent: 'center',
      marginTop: 50,
      marginLeft: isMobile ? 0 : 15,
      color: theme.$primaryColor1,
    }),
    info: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    results: {
      display: 'flex',
      alignItems: 'center',
    },
    order: {
      display: 'flex',
      alignItems: 'center',
    },
    orderSelect: {
      border: 'none',
      background: 'transparent',
    },
    filtersLabel: {
      margin: 5,
    },
    filtersValue: {},
    filtersValueActive: {},
  };
});
