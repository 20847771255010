import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

interface IColorScheme {
  default: string;
  alpha: string;
}
interface IComponentBackgroundColor {
  primary_1: IColorScheme;
  secondary_1: IColorScheme;
  primary_2: IColorScheme;
  secondary_2: IColorScheme;
  default_: IColorScheme;
}

export const useStyles = createUseStyles((theme: ITheme) => {
  const componentBackgroundColor: IComponentBackgroundColor = {
    primary_1: {
      default: theme.$primaryColor1,
      alpha: theme.$primaryColor1a05,
    },
    primary_2: {
      default: theme.$primaryColor2,
      alpha: theme.$primaryColor2a05,
    },
    secondary_1: {
      default: theme.$secondaryColor1,
      alpha: theme.$secondaryColor1a05,
    },
    secondary_2: {
      default: theme.$secondaryColor2,
      alpha: theme.$secondaryColor2a05,
    },
    default_: {
      default: theme.$defaultColor1,
      alpha: theme.$defaultColor1a05,
    },
  };
  const componentSize = {
    xl: '5.4rem',
    lg: '4.4rem',
    sm: '3.4rem',
    xs: '2.6rem',
  };

  const componentWidth = {
    xl: '100%',
    lg: '100%',
    sm: 'auto',
    xs: 'auto',
  };

  const componentMaxWidth = {
    xl: '18rem',
    lg: '18rem',
    sm: 'none',
    xs: 'none',
  };

  const ifOutlineComponent = ({ outline, type, text }: IProps) => {
    if (outline) {
      return {
        backgroundColor: 'white',
        borderSize: 1,
        borderStyle: 'solid',
        borderColor: componentBackgroundColor[type].default,
        '&:hover': {
          backgroundColor: componentBackgroundColor[type].alpha,
        },
      };
    } else {
      return null;
    }
  };

  const ifOutlineText = ({ outline, type }: IProps) => {
    if (outline) {
      return {
        color: componentBackgroundColor[type].default,
      };
    } else {
      return null;
    }
  };

  return {
    component: (p: IProps) => ({
      width: componentWidth[p.size],
      maxWidth: componentMaxWidth[p.size],
      height: componentSize[p.size],
      backgroundColor: componentBackgroundColor[p.type].default,
      borderColor: componentBackgroundColor[p.type].default,
      borderRadius: '1rem',
      ...ifOutlineComponent(p),
      '&:hover': {
        opacity: 0.9,
      },
    }),
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      margin: '0 1rem',
    },
    text: (p: IProps) => ({
      color: 'white',
      margin: '0 1rem',
      ...ifOutlineText(p),
    }),
  };
});
