import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/inputs/Select/index.web';
import { IOptionItem } from 'src/requests/models';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { useQuery } from '@apollo/client';
import { EVENTS_DATA_AVAILABLES } from 'src/requests/queries';
import { Context as EventSearchContext } from 'src/contexts/SearchContext/EventSearchContext';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import DatePicker from 'src/components/inputs/DatePicker/index.web';
import CompanyFilter from 'src/web/components/CompanyFilter';

const View = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, onClose } = props;
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies') },
    { id: '2', name: 'events', label: t('Events') },
    { id: '3', name: 'opportunities', label: t('Opportunities') },
  ];

  const filterQ = useQuery(EVENTS_DATA_AVAILABLES, {
    variables: {
      lang,
    },
  });

  const { typeList } = filterQ.data
    ? filterQ.data
    : {
        typeList: [],
      };

  const { setFilters, entity, setEntityMob }: any = useContext(SearchContext);
  const { companyId, types = [], startDate, endDate, setData, setSearchStart, setClear }: any = useContext(EventSearchContext);

  const [ready, setReady] = useState(!filterQ.loading && filterQ.data);
  const [filterModal, setFilterModal] = useState(false);

  const onSelectType = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ types: types.concat([item]) });
    } else if (action === 'rem') {
      setData({ types: types.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onStartDateChange = (value: any) => {
    //@ts-ignore
    setData({ startDate: value });
  };

  const onEndDateChange = (value: any) => {
    //@ts-ignore
    setData({ endDate: value });
  };

  const onCompanyIdClick = () => {
    setData({ companyId: undefined });
    setSearchStart();
  };

  const onSubmit = () => {
    setSearchStart();
    onClose();
    setFilters('entity', entity);
  };

  const onClear = () => {
    setClear();
    setSearchStart();
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.headerTitle} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Search')}
        </Title>
      </div>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div className={styles.body}>
          {companyId ? <CompanyFilter containerStyle={styles.onFilterByCompany} companyId={companyId} onClick={onCompanyIdClick} /> : null}
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Searching for')}
          </Title>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={setEntityMob}
            noInput
          />
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Type')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {typeList.map((industryItem: IOptionItem) => {
              const active = !!types.find((item: IOptionItem) => item.id === industryItem.id);
              return (
                <ButtonToggle
                  key={industryItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={industryItem.name}
                  onPress={() => onSelectType(industryItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Date')}
          </Title>
          <Title size={'h3'} containerStyle={`${styles.bodyItem} ${styles.dateStartTitle}`}>
            {t('From')}
          </Title>
          <DatePicker isMobile={true} value={startDate} containerStyle={styles.dateInputStyle} onChange={onStartDateChange} type={'date'} showTimeInput />
          <Title size={'h3'} containerStyle={`${styles.bodyItem} ${styles.dateEndTitle}`}>
            {t('To')}
          </Title>
          <DatePicker isMobile={true} value={endDate} containerStyle={styles.dateInputStyle} onChange={onEndDateChange} type={'date'} showTimeInput />
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default View;
