import React, { useEffect } from 'react';
import SettingsPage from '../containers/SettingsPage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { initGA } from '../../utilities';

const Container = () => (
  <Page>
    {({ isMobile }: IPassProps) => {
      return <SettingsPage isMobile={isMobile} />;
    }}
  </Page>
);

export default Container;
