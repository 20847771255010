import React, { FC, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTheme } from 'react-jss';
import Header from 'src/web/components/Header';
import ProfileView from '../../components/Profile/View';
import ProfileEdit from '../../components/Profile/Edit';
import ProfileEditLanguages from '../../components/Profile/Edit/Languages';
import ProfileEditActivities from '../../components/Profile/Edit/Activities';
import ProfileEditExperience from '../../components/Profile/Edit/Experience';
import ProfileEditEducation from '../../components/Profile/Edit/Education';
import Modal from '../../components/Modal';

import styles from './styles.module.scss';
import { IProps } from './types';
import { PROFILE_DATA_NEEDED } from 'src/requests/queries';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';

interface Pages {
  main: string;
  profileEdit: string;
  profileEducation: string;
  profileExperience: string;
  profileLanguages: string;
  profileActivities: string;
}

const pages: Pages = {
  main: '',
  profileEdit: 'Edit Profile',
  profileEducation: 'Education',
  profileExperience: 'Experience',
  profileLanguages: 'Languages',
  profileActivities: 'Activities',
};
const Profile: FC<IProps> = (props) => {
  const match = useRouteMatch();
  // @ts-ignore
  const theme: ITheme = useTheme();

  const { isMobile } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const [currentPage, setCurrentPage] = useState<keyof Pages>('main');
  const [modalLang, setModalLang] = useState(false);
  const [modalEducation, setModalEducation] = useState(false);
  const [modalActivities, setModalActivities] = useState(false);
  const [modalExperience, setModalExperience] = useState(false);
  const [modalProfileEdit, setModalProfileEdit] = useState(false);
  const [scrollTo, setScrollTo] = useState<any>(undefined);

  const { data, loading, error } = useQuery(PROFILE_DATA_NEEDED, { variables: { lang } });

  const setPage = (page: keyof Pages) => {
    setCurrentPage(page);
  };

  const onEditLangSet = (flag: boolean) => {
    setModalLang(flag);
  };

  const onEditEducationSet = (flag: boolean) => {
    setModalEducation(flag);
  };

  const onEditActivitiesSet = (flag: boolean) => {
    setModalActivities(flag);
  };

  const onEditExperienceSet = (flag: boolean) => {
    setModalExperience(flag);
  };

  const onEditProfileSet = (flag: boolean, scroll?: string) => {
    setModalProfileEdit(flag);
    setScrollTo(scroll ? scroll : undefined);
  };

  return !loading ? (
    <>
      <Helmet>
        <title>{'Profile'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <Modal isModalOpened={modalLang} onClose={() => onEditLangSet(false)}>
          <ProfileEditLanguages isMobile={isMobile} list={data.languages} setPage={setPage} onClose={() => onEditLangSet(false)} />
        </Modal>
        <Modal isModalOpened={modalEducation} onClose={() => onEditEducationSet(false)}>
          <ProfileEditEducation isMobile={isMobile} achievementList={data.achievements} setPage={setPage} onClose={() => onEditEducationSet(false)} />
        </Modal>
        <Modal isModalOpened={modalActivities} onClose={() => onEditActivitiesSet(false)}>
          <ProfileEditActivities isMobile={isMobile} list={data.activities} setPage={setPage} onClose={() => onEditActivitiesSet(false)} />
        </Modal>
        <Modal isModalOpened={modalExperience} onClose={() => onEditExperienceSet(false)}>
          <ProfileEditExperience
            isMobile={isMobile}
            internationalExperienceList={data.internationalExperiences}
            workExperienceList={data.workExperiences}
            setPage={setPage}
            onClose={() => onEditExperienceSet(false)}
          />
        </Modal>
        <Modal isModalOpened={modalProfileEdit} onClose={() => onEditProfileSet(false)}>
          <ProfileEdit
            isMobile={isMobile}
            countries={data.countries}
            sexes={data.sexes}
            educationLevels={data.educationLevels}
            setPage={setPage}
            onClose={() => onEditProfileSet(false)}
            scrollTo={scrollTo}
          />
        </Modal>
        <div className={styles.contain}>
          <ProfileView
            isMobile={isMobile}
            setPage={setPage}
            onEditLang={onEditLangSet}
            onEditEducation={onEditEducationSet}
            onEditActivities={onEditActivitiesSet}
            onEditExperience={onEditExperienceSet}
            onEditProfile={onEditProfileSet}
          />
        </div>
      </ErrorBoundaries>
    </>
  ) : null;
};

export default Profile;
