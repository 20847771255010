import React from 'react';
import { useTranslation } from 'react-i18next';

const Error = ({ error, errorInfo }: { error?: any; errorInfo?: any }) => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <h2 style={{ fontSize: 28 }}>{`${t('Oops. Something went wrong.')} 😅`}</h2>
    </div>
  );
};

export default Error;
