import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {},
  icon: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 10px',
  },
}));
