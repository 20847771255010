import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: 76,
      background: 'white',
      borderBottom: isMobile ? `1px solid ${theme.$borderColor}` : '',
      cursor: 'pointer',
      transition: '0.3s all ease',
      padding: isMobile ? '0' : '0 15px',
      borderRadius: 5,

      '&:hover': {
        background: theme.$defaultColor1,
      },
    }),
    componentActive: () => ({
      background: '#F9FBFC',
    }),
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    item2: ({ isMobile }: IProps) => ({
      maxWidth: 'calc(100% - 80px)',
    }),
    title: {
      display: 'block',
      marginTop: 0,
      marginBottom: 5,
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    text: {
      height: 16,
      display: '-webkit-box',
      overflow: 'hidden',
      lineHeight: '19px',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,

      '& *': {
        fontFamily: 'Inter, sans-serif',
        fontSize: 12,
        fontWeight: 400,
        margin: 0,
        lineHeight: '18px',
      },
    },
    textNew: {
      '& *': {
        fontWeight: 500,
      },
    },
    logo: ({ data }: IProps) => ({
      width: 46,
      height: 46,
      borderRadius: '50%',
      backgroundImage: `url(${data && data.logo && data.logo.replace(/ /g, '%20')})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }),
    withoutLogo: {
      width: 46,
      height: 46,
      borderRadius: '50%',
      background: theme.$secondaryColor2,
    },
    isNewContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      flexGrow: 1,
    },
    isNew: {
      width: 8,
      height: 8,
      background: theme.$primaryColor1,
      borderRadius: '50%',
    },
    lastMsgTime: {
      fontSize: 12,
      textAlign: 'right',
    },
  };
});
