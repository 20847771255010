import React, { useContext, useEffect, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const Message = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, data, containerStyle } = props;

  const { i18n } = useTranslation();

  moment.locale(i18n.language);

  return (
    <div className={`${styles.component} ${containerStyle}`}>
      <div className={styles.msg}>
        <ReactMarkdown source={data.text} escapeHtml={false} />
      </div>
      <div className={styles.date}>{moment(data.message_created).locale(i18n.language).fromNow()}</div>
    </div>
  );
};

export default Message;
