import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
}

const Devider: FC<IProps> = (props) => {
  const theme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
  };

  return <div className={cn.component} />;
};

export default Devider;
