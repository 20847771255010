import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

interface IStyles {
  props: IProps;
  theme: ITheme;
}
interface IColorScheme {
  default: string;
  alpha: string;
  text: string;
}
interface IComponentBackgroundColor {
  primary: IColorScheme;
  secondary: IColorScheme;
  default: IColorScheme;
}

type DefaultTheme = object | null;

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => {
  const componentSize = {
    xl: '5.4rem',
    lg: '4.4rem',
  };

  const radiusSize = {
    xl: 10,
    lg: 8,
  };

  const componentActive = (active: boolean) =>
    active
      ? {
          //backgroundColor: theme.$primaryColor1
        }
      : null;

  return {
    component: (p: IProps) => {
      const { size, active } = p;

      return {
        width: '100%',
        maxWidth: componentSize[size],
        height: componentSize[size],
        backgroundColor: theme.$primaryColor1a05,
        border: 'none',
        borderRadius: radiusSize[size],
        '&:hover': {
          opacity: 0.9,
        },
        ...componentActive(active),
      };
    },
    icon: {
      ['@media only screen and (max-width: 413px)']: {
        transform: 'scale(0.6)',
      },
      ['@media only screen and (min-width: 414px) and (max-width: 1023px) ']: {
        transform: 'scale(0.7)',
      },
      ['@media only screen and (min-width: 1024px) and (max-width: 1263px)']: {
        transform: 'scale(0.8)',
      },
      ['@media only screen and (min-width: 1264px) and (max-width: 1439px)']: {
        transform: 'scale(1)',
      },
      ['@media only screen and (min-width: 1440px)']: {
        transform: 'scale(1)',
      },
    },
  };
});
