import React, { FC, useMemo, useState } from 'react';
import moment from 'moment';
import Picker from 'react-datepicker';
import Icon from 'src/components/icons/Icon/index.web';
import { IProps } from './types';
import './datePicker.scss';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';

interface ComponentStyles {
  component?: string;
  iconCalendar?: string;
  calendar?: string;
}

const CustomInput = ({ value, onClick, onChange, isMobile, type }: { value?: any; onClick?: any; onChange?: any; isMobile: boolean; type: string }) => (
  <input
    value={value}
    placeholder={type === 'years' ? 'yyyy' : type === 'months' ? 'MM/yyyy' : 'dd/MM/yyyy'}
    type={'phone'}
    onClick={onClick}
    onChange={onChange}
    readOnly={isMobile}
  />
);

const DatePicker: FC<IProps> = (props) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [showCalendar, setShowCalendar] = useState(false);
  const [usedValue, setUsedValue] = useState<Date | null>(null);
  const [offset, setOffset] = useState(0);
  const {
    value,
    type = 'date',
    minDate,
    maxDate,
    isMobile,
    onPress = () => undefined,
    onBlur = () => undefined,
    onChange,
    containerStyle,
    inputStyle,
    showTimeInput,
    openToDate,
  } = props;
  //const usedValue = value ? moment(value).add(-moment(value).utcOffset(), 'minutes').toDate() : null;

  React.useEffect(() => {
    if (!value) {
      setUsedValue(null);
    } else if (Number(value) >= 0) {
      setUsedValue(moment(Number(value)).toDate());
    } else {
      setUsedValue(moment(value).toDate());
    }
  }, [value]);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    calendar: `${styles.calendar}`.trim(),
    iconCalendar: `${styles.icon} ${styles.iconCalendar}`.trim(),
  };

  const onCalendarOpen = () => {
    setShowCalendar(true);
  };

  const onCalendarClose = () => {
    setShowCalendar(false);
  };

  const onDateChange = (date: Date) => {
    onChange(date ? moment(date).toDate() : undefined);
  };

  const pickerConfig = useMemo(() => {
    if (type === 'years') {
      return {
        showYearPicker: true,
        dateFormat: 'yyyy',
      };
    } else if (type === 'months') {
      return {
        showMonthYearPicker: true,
        dateFormat: 'MM yyyy',
      };
    } else {
      return {
        showYearDropdown: true,
        showMonthDropdown: true,
        useShortMonthInDropdown: true,
      };
    }
  }, [type]);

  return (
    <div className={cn.component}>
      <Picker
        //@ts-ignore
        selected={usedValue}
        dateFormat={'dd/MM/yyyy'}
        locale={language}
        onChange={onDateChange}
        dropdownMode={'select'}
        maxDate={maxDate}
        minDate={minDate}
        openToDate={usedValue || openToDate}
        customInput={<CustomInput isMobile={isMobile} type={type} />}
        showTimeInput={showTimeInput}
        calendarClassName={cn.calendar}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        strictParsing
        {...pickerConfig}
      />
      <Icon name={'calendar'} color={showCalendar ? theme.$primaryColor1 : theme.$secondaryColor2} size={20} />
    </div>
  );
};

export default DatePicker;
