import React, { FC, useContext } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './style';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from 'src/constant/themes';
import { useQuery } from '@apollo/client';
import { COMPANY } from 'src/requests/queries';
import { useTranslation } from 'react-i18next';

interface ComponentStyles {
  component?: string;
  componentText?: string;
}

const CompanyFilter: FC<IProps> = (props) => {
  const { companyId, containerStyle, onClick } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const { data = { company: { name: '' } }, loading } = useQuery(COMPANY, {
    variables: { lang, id: companyId.toString() },
  });

  return loading ? null : (
    <div className={`${styles.component} ${containerStyle ? containerStyle : ''}`} onClick={onClick}>
      <div className={styles.componentText}>{data.company.name}</div>
      <Icon name={'close'} size={8} color={theme.$primaryColor1} />
    </div>
  );
};

export default CompanyFilter;
