import React, { FC, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles';
import { useDropzone } from 'react-dropzone';
import Loader from 'react-loader-spinner';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from '../../../constant/themes';
import { useTranslation } from 'react-i18next';
import Text from 'src/components/texts/Text/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';

interface ComponentStyles {
  component?: string;
  mainText?: string;
  secondaryText?: string;
  uploadBtn?: string;
  file?: string;
}

const fileFormat = ['application/pdf'];
const imageSize = 5;

const ProfileCV: FC<IProps> = (props) => {
  const { containerStyle, profile_cv, setCV, removeCV, loading } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });

  const [showError, setShowError] = useState(false);
  const [filePath, setFilePath] = useState(profile_cv);

  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: fileFormat,
    maxSize: 4000000,
    onDropRejected: (e) => {
      setShowError(true);
      console.error(e);
    },
  });

  const onCancelFile = () => {
    setFilePath('');
    removeCV();
  };

  useEffect(() => {
    setFilePath(profile_cv);
  }, [profile_cv]);

  useEffect(() => {
    if (acceptedFiles.length) {
      setCV(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    mainText: `${styles.mainText}`,
    secondaryText: `${styles.secondaryText}`,
    uploadBtn: `${styles.uploadBtn}`,
    file: `${styles.file}`,
  };

  const displayedFilePath = filePath ? filePath.split('/').reverse()[0] : null;

  return (
    <div className={cn.component}>
      <div style={{ position: 'relative' }} {...getRootProps({ className: 'dropzone' })}>
        <Text containerStyle={cn.mainText} type={'button'}>
          {t('Curriculum Vitae upload')}
        </Text>
        <Text containerStyle={cn.secondaryText} type={'secondary'}>
          {t('Please attach a CV')}
        </Text>
        <input {...getInputProps()} />
        {displayedFilePath && filePath ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
            <div className={cn.file}>
              <a className={styles.path} href={filePath} target={'_blank'} title={filePath}>{`${filePath.slice(54)}`}</a>
              <div className={styles.close} onClick={onCancelFile}>
                <Icon name={'close'} color={'white'} size={7} />
              </div>
            </div>
          </div>
        ) : (
          <ButtonMain type={'primary_1'} containerStyle={cn.uploadBtn} size={'lg'} text={t('Upload')} iconName={'upload'} onPress={open} outline />
        )}
        {loading ? (
          <div className={styles.loader}>
            <Loader type="Oval" color={theme.$primaryColor1} height={50} width={50} />
          </div>
        ) : (
          ''
        )}
        {showError ? (
          <div className={styles.avatarError} onDoubleClick={() => setShowError(false)}>
            <div className={styles.closeError} onClick={() => setShowError(false)} />
            <p>{`${t('Acceptable formats')}:  ${fileFormat[0]}`}</p>
            <br />
            <p>{t('The file you have uploaded exceeds the limit of size MB', { size: imageSize })}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileCV;
