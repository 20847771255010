import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { Props } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: ({ type }: Props) => {
    if (type === 'primary') {
      return {
        fontFamily: 'Inter, sans-serif',
        fontWeight: '300',
        fontSize: '1.3rem',
        lineHeight: '1.8rem',
        color: theme.$primaryColor2,
        opacity: 1,
      };
    } else if (type === 'secondary') {
      return {
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '1.2rem',
        color: theme.$primaryColor2,
        opacity: 0.75,
        lineHeight: '1.6rem',
      };
    } else if (type === 'button') {
      return {
        fontFamily: 'AvenirNextCyr, sans-serif',
        fontWeight: 500,
        fontSize: '1.5rem',
        color: theme.$primaryColor2,
        opacity: 1,
        lineHeight: '2rem',
      };
    } else {
      return {
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '1.2rem',
        lineHeight: '1.6rem',
        color: '#656771',
        opacity: 1,
      };
    }
  },
}));
