import React, { useContext } from 'react';
import moment from 'moment';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Title from 'src/components/titles/Title/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from 'src/constant/themes';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { formatInBrusselsTimeZone } from 'src/utilities/dateTimeFormatter';

interface ComponentStyles {
  component?: string;
  header?: string;
  body?: string;
  footer?: string;
}

const Item = (props: IProps) => {
  const theme: ITheme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, data, toggleLike, toggleBookmark, view } = props;
  const { setApplyModalShown, setEntityForApply } = useContext(SearchContext);

  const { id, name, company_name, main_description, types = [], address, start_date, is_liked, is_bookmarked = view === 'bookmarks' } = data;

  const onViewEvent = () => {
    history.push('/event/' + id);
  };
  const showApplyModal = () => {
    setApplyModalShown(true);
    setEntityForApply(data);
  };

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    header: `${styles.block} ${styles.header}`,
    body: `${styles.block} ${styles.body}`,
    footer: `${styles.block} ${styles.footer}`,
  };

  return (
    <div className={cn.component}>
      <div className={cn.header} onClick={onViewEvent}>
        {
          <div style={{ display: 'flex' }}>
            <div>
              <div className={styles.logo} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Title size={'h3'} containerStyle={styles.companyName}>
                {company_name}
              </Title>
              <div className={styles.types}>{types && types[0] && types[0].name}</div>
            </div>
          </div>
        }
        <Title size={'h2'} containerStyle={styles.eventName}>
          {name}
        </Title>
        <div className={styles.eventInfo}>
          <div className={styles.eventInfoImg} />
          <div className={styles.eventShortDate}>
            <div className={styles.eventShortDateDay}>{moment(start_date).format('DD')}</div>
            <div className={styles.eventShortDateMonth}>{moment(start_date).format('MMM')}</div>
          </div>
          <Title size={'h3'} containerStyle={styles.eventInfoItem}>
            <Icon size={20} color={'white'} name={'location'} />
            {address}
          </Title>
          <Title size={'h3'} containerStyle={styles.eventInfoItem}>
            <Icon size={20} color={'white'} name={'calendar'} />
            {formatInBrusselsTimeZone(start_date)}
          </Title>
        </div>
      </div>
      <div className={cn.body} onClick={onViewEvent}>
        {main_description}
      </div>
      <div className={cn.footer}>
        {is_liked ? (
          <ButtonMain
            outline
            size={'lg'}
            type={'primary_1'}
            text={t('Interested')}
            containerStyle={styles.setInterest}
            onPress={() => {
              showApplyModal();
            }}
          />
        ) : (
          <ButtonMain
            size={'lg'}
            type={'primary_1'}
            text={t('Show interest event')}
            containerStyle={styles.setInterest}
            onPress={() => {
              // if (view === 'explorer' && data.application_flow !== 'REDIRECT') {
              //   dispatch(addSubscription({ id: '' + id, name, type: 'e' }));
              // }
              // toggleLike(data, true);
              showApplyModal();
            }}
          />
        )}
        {!view ||
          (view === 'explorer' && !is_liked && (
            <ButtonIconToggle
              size={'lg'}
              activeIconName={'bookmark-fill'}
              inactiveIconName={'bookmark'}
              containerStyle={styles.btnMain}
              iconSize={20}
              iconColor={'#F65532'}
              active={is_bookmarked}
              onPress={() => toggleBookmark(id, !is_bookmarked)}
            />
          ))}
      </div>
    </div>
  );
};

export default Item;
