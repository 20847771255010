import React, { createContext, useEffect, useState } from 'react';
import { GLOBAL_SEARCH } from '../../../requests/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsReady } from '../../../state/modules/session';
import { Platform } from 'react-native';
import {
  UPDATE_COMPANY_BOOKMARK,
  UPDATE_COMPANY_LIKE,
  UPDATE_EVENT_BOOKMARK,
  UPDATE_EVENT_LIKE,
  UPDATE_OPPORTUNITY_BOOKMARK,
  UPDATE_OPPORTUNITY_LIKE,
} from '../../../requests/mutations';
import { IEvent } from '../../../components/cards/Event/types';
import { IOpportunity } from '../../../components/cards/Opportunity/types';

export interface IGlobalFilter {
  searchValue: string;
  limit: number;
  page: number;
}

interface IProps {
  children: any;
  filters: IGlobalFilter;
  setFilters: any;
  setEntityByName: any;
  applyModalShown: boolean;
  setApplyModalShown: (v: boolean) => void;
  entityForApply: {} | IEvent | IOpportunity;
  setEntityForApply: (v: {} | IEvent | IOpportunity) => void;
}

interface ExplorerContextState {
  setSearch: () => void;
  result: any;
  setSearchNext: () => void;
  setSearchStart: () => void;
  loading: boolean;
  setClear: () => void;
  setData: (data: any) => void;
  searchValue: string;
  setLike: (id: string | number, is_liked: boolean, type: 'event' | 'opportunity' | 'company', update?: any) => Promise<void>;
  setBookmark: (id: string | number, is_bookmarked: boolean, type: 'event' | 'opportunity' | 'company', update?: any) => Promise<void>;
}

const customLikeUpdate = (cache: any, { data }: { data: any }, type: 'company' | 'event' | 'opportunity') => {
  const typeName = type[0].toUpperCase() + type.slice(1);

  const { id, is_liked, name } = type === 'company' ? data.updateCompanyLike : type === 'event' ? data.updateEventLike : data.updateOpportunityLike;
  const item: any = {
    __typename: typeName,
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });
};

const customBookmarkUpdate = (cache: any, { data }: { data: any }, type) => {
  const typeName = type[0].toUpperCase() + type.slice(1);
  const { id, is_bookmarked, name } =
    type === 'company' ? data.updateCompanyBookmark : type === 'event' ? data.updateEventBookmark : data.updateOpportunityBookmark;
  const item: any = {
    __typename: typeName,
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });
};

const Context = createContext({} as ExplorerContextState);
Context.displayName = '_GLOBAL_SEARCH_';

const Provider = (props: IProps) => {
  const { children, filters, setFilters, setEntityByName } = props;
  const { searchValue, limit, page } = filters;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const isReady = useSelector((state) => selectIsReady(state));
  const [enableFilters, setEnableFilters] = useState(false);

  const [queryFilters, setQueryFilters] = useState({
    searchValue,
    limit,
    page,
  });
  const [enableSave, setEnableSave] = useState(false);

  useEffect(() => {
    if (enableFilters) {
      setQueryFilters({
        searchValue,
        limit,
        page,
      });
      setEnableFilters(false);
      setEnableSave(true);
    }
  }, [enableFilters, searchValue, limit, page]);

  const [
    getEverything,
    {
      loading,
      data = {
        globalSearch: {
          result: [],
          page: 1,
          is_last: true,
          limit: 10,
        },
      },
      fetchMore,
      called,
    },
  ] = useLazyQuery(GLOBAL_SEARCH, {
    variables: {
      lang,
      filters: {
        name: queryFilters.searchValue ? queryFilters.searchValue : '',
      },
      limit: queryFilters.limit,
      page: queryFilters.page,
    },
    fetchPolicy: enableSave ? 'cache-and-network' : 'cache-only',
  });

  const [eventLike, { loading: eventLikeLoading }] = useMutation(UPDATE_EVENT_LIKE);
  const [eventBookmark, { loading: eventBookmarkLoading }] = useMutation(UPDATE_EVENT_BOOKMARK);
  const [companyLike, { loading: companyLikeLoading }] = useMutation(UPDATE_COMPANY_LIKE);
  const [companyBookmark, { loading: companyBookmarkLoading }] = useMutation(UPDATE_COMPANY_BOOKMARK);
  const [opportunityLike, { loading: opportunityLikeLoading }] = useMutation(UPDATE_OPPORTUNITY_LIKE);
  const [opportunityBookmark, { loading: opportunityBookmarkLoading }] = useMutation(UPDATE_OPPORTUNITY_BOOKMARK);

  const setLike = async (id: string | number, is_liked: boolean, type: 'event' | 'opportunity' | 'company', update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customLikeUpdate(cache, result, type);
        if (update) {
          update(cache, result);
        }
      };
      if (type === 'company') {
        await companyLike({ variables: { like: { id: id + '', is_liked } }, update: mergeUpdates });
      } else if (type === 'opportunity') {
        await opportunityLike({ variables: { like: { id: id + '', is_liked } }, update: mergeUpdates });
      } else if (type === 'event') {
        await eventLike({ variables: { like: { id: id + '', is_liked } }, update: mergeUpdates });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const setBookmark = async (id: string | number, is_bookmarked: boolean, type: 'event' | 'opportunity' | 'company', update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customBookmarkUpdate(cache, result, type);
        if (update) {
          update(cache, result);
        }
      };
      if (type === 'company') {
        await companyBookmark({ variables: { bookmark: { id: id + '', is_bookmarked } }, update: mergeUpdates });
      } else if (type === 'opportunity') {
        await opportunityBookmark({ variables: { bookmark: { id: id + '', is_bookmarked } }, update: mergeUpdates });
      } else if (type === 'event') {
        await eventBookmark({ variables: { bookmark: { id: id + '', is_bookmarked } }, update: mergeUpdates });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (enableSave && !loading && data) {
      setEnableSave(false);
    }
  }, [enableSave, loading, data]);

  const setClear = () => {
    setFilters(undefined, {
      searchValue: '',
      page: 1,
    });
  };

  const setSearch = () => {
    getEverything();
    setEnableFilters(true);
  };
  const setData = (data) => {
    setFilters(undefined, data);
  };

  const setSearchStart = () => {
    setFilters('page', 1);
    setEntityByName('everything');
    setSearch();
  };

  const setSearchNext = async () => {
    if (!loading && data.globalSearch && !data.globalSearch.is_last) {
      // if (page === 1 && data.events.result.length !== data.events.total) setSearch();
      setFilters('page', page + 1);
      if (called && data.globalSearch.result.length) {
        //@ts-ignore
        await fetchMore({
          variables: {
            lang,
            limit: 10,
            page: page + 1,
          },
          //@ts-ignore
          updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
            return Object.assign({}, prev, {
              globalSearch: {
                ...fetchMoreResult.globalSearch,
                result: [...prev.globalSearch.result, ...fetchMoreResult?.globalSearch?.result],
              },
            });
          },
        });
      }
    }
  };

  useEffect(() => {
    if ((Platform.OS === 'web' && isReady) || Platform.OS !== 'web') {
      setSearch();
    }
  }, [isReady]);
  return (
    <Context.Provider
      value={{
        searchValue,
        setSearch,
        setSearchStart,
        result: data.globalSearch ? data.globalSearch.result : [],
        setSearchNext,
        loading,
        setClear,
        setLike,
        setData,
        setBookmark,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
