import { createUseStyles } from 'react-jss';
import { IProps } from './types';

export const useStyles = createUseStyles({
    container: ({ isMobile }: IProps) => {
      return {
        width: isMobile ? '100%' : 320,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'white',
        height: isMobile ? 'calc(100vh - 54px)' : 'auto',
        borderRadius: isMobile ? 0 : 10,
        padding: isMobile ? '15px 30px 70px' : '15px 30px',
        boxShadow: isMobile ? 'none' : 'rgba(145, 165, 182, 0.24) 0px -1px 8px 2px',
      };
    },
    navs: ({ theme }: any) => ({
      borderBottom: `1px solid ${theme.$borderColor}`,
      flexGrow: 1,
    }),
    nav: ({ theme }: any) => ({
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      color: ['#222222', '!important'],
      padding: '14px 0',
      borderBottom: `1px solid ${theme.$borderColor}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    }),
    navLogin: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      color: ['#222222', '!important'],
      padding: '14px 0',
    },
    navText: {
      marginLeft: 10,
    },
    footer: {
      '& $nav': {
        borderBottom: 'none',
      },
    },
});
