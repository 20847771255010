import React, { FC, useRef, useState, useEffect } from 'react';
import Text from 'src/components/texts/Text/index.web';
import ArrowToggle from 'src/components/toggles/ArrowToggle/index.web';
import { useTheme } from 'react-jss';
import { IProps } from './types.web';
import { IOptionItem } from 'src/requests/models';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';
import { useTranslation } from 'react-i18next';

interface ComponentStyles {
  component?: string;
  content?: string;
  field?: string;
  value?: string;
  options?: string;
  option?: string;
  arrow?: string;
}

export interface Option {
  option: IOptionItem;
  containerStyle?: string;

  onPress(e: any): void;
}

function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

const CustomOption = ({ option, containerStyle, onPress }: Option) => {
  const { t } = useTranslation();

  const onChoose = (e: MouseEvent) => {
    e.stopPropagation();
    onPress(option);
  };

  return (
    <Text containerStyle={containerStyle} onPress={onChoose}>
      {option.label ? option.label : option.name}
    </Text>
  );
};

const Select: FC<IProps> = (props) => {
  const {
    activeBy = 'id',
    value = { id: '', name: '', is_primary: false },
    placeholder = 'Select',
    list,
    containerStyle,
    contentStyle,
    optionStyle,
    optionsStyle,
    inputStyle,
    menuIsOpen,
    onChoose,
    onChange,
    onScroll,
    onActive,
    disabled = false,
    noInput,
    inputValidation,
    Option = CustomOption,
  } = props;
  const [isOptionsOpened, setIsOptionsOpened] = useState(menuIsOpen !== undefined ? menuIsOpen : false);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const ref = useRef(null);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    content: `${styles.content} ${isOptionsOpened ? styles.contentWithOptions : ''} ${contentStyle}`.trim(),
    field: `${styles.field}`.trim(),
    value: `${styles.value} ${isOptionsOpened ? styles.valueOpened : ''} ${inputStyle}`.trim(),
    options: `${styles.options} ${optionsStyle}`.trim(),
    option: `${styles.option} ${optionStyle}`,
    arrow: `${styles.arrow}`.trim(),
  };
  const selectedOption = value ? list.find((o: IOptionItem, i) => o[activeBy] === value[activeBy]) : null;
  const activeItems: string[] = selectedOption ? [selectedOption[activeBy]] : [''];
  const displayedValue = selectedOption ? selectedOption.name : '';

  const onComponentClick = () => {
    if (!disabled) {
      setIsOptionsOpened(menuIsOpen !== undefined ? menuIsOpen : !isOptionsOpened);
    }
  };
  const onOptionChoose = (value: IOptionItem) => {
    const option = list.find((o: IOptionItem) => o[activeBy] === value[activeBy]);
    onChoose(option);
    onComponentClick();
  };
  const onInputChange = (e: any) => {
    if (isOptionsOpened && (!inputValidation || inputValidation(e.target.value))) {
      setInputValue(e.target.value);
      onChange && onChange(e.target.value);
    }
  };
  const onInputBlur = () => {
    //  setInputValue(value.name);
  };

  const onInputClick = () => {
    if (!isOptionsOpened && !disabled) {
      setIsOptionsOpened(true);
    }
  };

  useOnClickOutside(ref, () => {
    setIsOptionsOpened(false);
  });

  useEffect(() => {
    if (!isOptionsOpened) {
      setInputValue(undefined);
    }
    if (onActive) {
      onActive(isOptionsOpened);
    }
  }, [isOptionsOpened]);

  return (
    <div className={cn.component} ref={ref} onClick={onComponentClick}>
      <div
        className={cn.content}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div
          className={cn.field}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {noInput ? (
            <Option option={selectedOption} containerStyle={cn.option} onPress={onInputClick} />
          ) : (
            <input
              className={cn.value}
              type="text"
              disabled={disabled}
              placeholder={placeholder}
              value={inputValue !== undefined ? inputValue : displayedValue}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onClick={onInputClick}
            />
          )}
          <ArrowToggle active={isOptionsOpened} onPress={onComponentClick} containerStyle={cn.arrow} />
        </div>
        {/*@ts-ignore*/}
        <div className={cn.options} onScroll={onScroll}>
          {list
            .filter((o: IOptionItem) => o.name.toLowerCase().includes(inputValue !== undefined ? inputValue.toLowerCase() : ''))
            .map((o: IOptionItem) => (
              <Option
                key={o.id}
                option={o}
                onPress={onOptionChoose}
                containerStyle={`${cn.option} ${activeItems.includes(o[activeBy]) ? styles.optionActive : ''}`}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Select;
