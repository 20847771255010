import React, { FC, useEffect, useRef, useState } from 'react';
import Text from '../../../components/texts/Text/index.web';
import Icon from '../../../components/icons/Icon/index.web';
import { Props } from './types.web';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  icon?: string;
}

const TextIcon: FC<Props> = (props) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });
  const { children, containerStyle, iconStyle, iconSize = 20, iconName } = props;
  const refIcon = useRef(null);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    icon: `${styles.icon} ${iconStyle}`.trim(),
  };

  return (
    <Text type={'secondary'} containerStyle={cn.component} style={{ paddingLeft: `${iconSize + 5}px` }}>
      {iconName ? (
        <span style={{ display: 'block' }} className={cn.icon} ref={refIcon}>
          <Icon name={iconName} size={iconSize} color={theme.$secondaryColor1} />
        </span>
      ) : null}
      <span style={{ display: 'block' }} className={styles.text}>
        {children}
      </span>
    </Text>
  );
};

export default TextIcon;
