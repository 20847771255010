import React, { useContext, useEffect } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import Title from 'src/components/titles/Title/index.web';
import ButtonCombine from 'src/components/buttons/ButtonCombine/index.web';
import { useHistory } from 'react-router';
import { Context as MessagesContext } from 'src/contexts/MessagesContext/index.web';

const WhatsNew = (props: IProps) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { isMobile, containerStyle } = props;

  const { inboxCount }: any = useContext(MessagesContext);

  const history = useHistory();

  const { t } = useTranslation();
  return (
    <div className={`${styles.component} ${containerStyle}`}>
      <Title size={'h2'} containerStyle={styles.title}>
        {t("What's new")}
      </Title>
      <ButtonCombine
        type={'primary'}
        iconName={'mail'}
        iconSize={17.5}
        iconColor={'#F65532'}
        text={inboxCount.toString()}
        containerStyle={styles.button}
        onPress={() => history.push('/messages')}
      />
    </div>
  );
};

export default WhatsNew;
