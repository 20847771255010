import { TUIState, EUIActionTypes, TUIAction } from './ui.types';
import { IOptionItem } from '../../../requests/models';

const resolutions = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
};

const initialState: TUIState = {
  resolutions,
  chosenTheme: 'main',
  modalPrivacy: false,
  modalTerms: false,
  subscriptions: [],
};

export const uiReducer = (state: TUIState = initialState, action: TUIAction): TUIState => {
  switch (action.type) {
    case EUIActionTypes.SET_DEVICE_RES: {
      const { key, value } = action.value;

      return {
        ...state,
        resolutions: {
          ...resolutions,
          [key]: value,
        },
      };
    }
    case EUIActionTypes.SET_MODAL: {
      const { key = '', value = undefined } = action.value;

      switch (key) {
        case 'privacy': {
          return {
            ...state,
            modalTerms: false,
            modalPrivacy: value !== undefined ? value : !state.modalTerms,
          };
        }
        case 'terms': {
          return {
            ...state,
            modalPrivacy: false,
            modalTerms: value !== undefined ? value : !state.modalTerms,
          };
        }
        default: {
          return {
            ...state,
            modalPrivacy: false,
            modalTerms: false,
          };
        }
      }
    }
    case EUIActionTypes.SET_SUBSCRIPTION: {
      return {
        ...state,
        subscriptions: [...state.subscriptions, ...[action.value]],
      };
    }
    case EUIActionTypes.REMOVE_SUBSCRIPTION: {
      return {
        ...state,
        subscriptions: state.subscriptions.filter((s: IOptionItem) => s.id !== action.value),
      };
    }

    default:
      return state;
  }
};
