import React from 'react';
import Title from 'src/components/titles/Title/index.web';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

interface IProps {
  userName?: string;
  ready?: boolean;
}

const CustomTitle = (props: IProps) => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { user } = useAuth0();
  const styles = useStyles({ ...props, ...theme });

  const { userName = user ? (user.given_name ? user.given_name : user.name ? user.name.split(' ')[0] : t('User')) : t('User'), ready = false } = props;

  return ready ? (
    <Title size={'h1'} containerStyle={styles.title}>
      🚀 {t('Ready to go')}!
    </Title>
  ) : (
    <Title size={'h1'} containerStyle={styles.title}>
      👋 {t('Welcome')}, {userName}!
    </Title>
  );
};

export default CustomTitle;
