import React, { FC } from 'react';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from '../../../constant/themes';

import { IProps } from './types.web';
import { useTheme } from 'react-jss';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  box?: string;
  check?: string;
}

const CheckBox: FC<IProps> = (props) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { active, onPress, activeIcon, inactiveIcon, containerStyle, boxStyle, checkStyle } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    box: `${styles.box} ${boxStyle}`.trim(),
    check: `${styles.check} ${checkStyle}`.trim(),
  };

  const renderContent = (): React.ReactChild => {
    const checkElement = () => (activeIcon ? activeIcon : <Icon name={'checkmark'} color={'white'} size={16} />);
    const uncheckElement = () => (inactiveIcon ? inactiveIcon : null);

    return (
      <>
        <div className={cn.box} onClick={onPress}>
          {active ? checkElement() : uncheckElement()}
        </div>
      </>
    );
  };

  return <div className={cn.component}>{renderContent()}</div>;
};

export default CheckBox;
