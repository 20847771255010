import { combineReducers, Reducer, AnyAction } from 'redux';

import { sessionReducer, ESessionActionTypes } from './session';
import { uiReducer } from './ui';
import { onboardingReducer } from './onboarding';
import { userReducer, EUIActionTypes } from './user';
import { errorReducer } from './error';

const combinedReducer = combineReducers({
  session: sessionReducer,
  ui: uiReducer,
  onboarding: onboardingReducer,
  user: userReducer,
  error: errorReducer,
});

// Clear app state when signout action fires
export const rootReducer: Reducer = (state, action: AnyAction) => {
  if (action.type === ESessionActionTypes.SIGN_OUT || action.type === EUIActionTypes.SET_LOGOUT) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export type State = ReturnType<typeof rootReducer>;
