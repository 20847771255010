import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: (p: IProps) => ({
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 15,
      padding: p.isMobile ? '15px 0' : '30px 0',
    }),
    tabTitle: (p: IProps) => ({
      padding: p.isMobile ? '0 15px' : '0 30px',
      marginTop: 0,
    }),
    itemTitle: {
      marginTop: '15px',
    },
    componentItem: (p: IProps) => ({
      padding: p.isMobile ? '15px' : '15px 30px',
    }),
    componentItemDevider: (p: IProps) => ({
      padding: p.isMobile ? '10px 15px 5px' : '10px 30px 5px',
    }),
    select: {
      maxWidth: '100%',
    },
    interests: (p) => ({
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: 'auto',
      alignContent: 'flex-start',
    }),
    interest: {
      margin: '10px 10px 0 0',
    },
    footer: {},
    saveBtn: {
      maxWidth: 'none ',
    },
    fieldLabel: {
      color: 'rgba(40,40,40,0.75)',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 400,
      margin: '0.5rem 0',
      width: '370px',
    },
  };
});
