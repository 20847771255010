import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { settingDeviceRes } from '../../state/modules/ui/ui.actions';

interface Props {
  children: any;
}
const WithWidth: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  window.addEventListener('resize', () => {
    dispatch(settingDeviceRes(window.innerWidth));
  });

  useEffect(() => {
    dispatch(settingDeviceRes(window.innerWidth));
  }, []);

  return <>{children}</>;
};

export default WithWidth;
