import React, { useEffect } from 'react';
import CompanyPage from '../containers/CompanyPage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';

const Container = () => {
  return (
    <Page>
      {({ isMobile }: IPassProps) => {
        return <CompanyPage isMobile={isMobile} />;
      }}
    </Page>
  );
};

export default Container;
