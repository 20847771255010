import { createUseStyles } from 'react-jss';
import { IProps } from './types';

export const useStyles = createUseStyles({
  container: ({ isMobile, view, theme }: any) => {
    return {
      display: 'flex',
      flexDirection: (view === 'likes' || view === 'bookmarks') && !isMobile ? 'row' : 'column',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'center',
      maxWidth: view ? '100%' : isMobile ? 345 : 383,
      width: '100%',
      //height: isMobile ? 204 : 233,
      padding: isMobile ? 15 : 30,
      backgroundColor: 'white',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      boxShadow: `0px 2px 0px ${theme.$borderColor}`,
    };
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%',
  },
  header: {
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  body: ({ view, isMobile }: IProps) => ({
    maxWidth: (view === 'likes' || view === 'bookmarks') && !isMobile ? '60%' : '100%',
    lineHeight: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    cursor: 'pointer',
  }),
  footer: ({ view, isMobile }: IProps) => ({
    maxWidth: (view === 'likes' || view === 'bookmarks') && !isMobile ? '40%' : '100%',
    paddingLeft: (view === 'likes' || view === 'bookmarks') && !isMobile ? 30 : 0,
    paddingTop: 10,
    alignItems: 'flex-end',
  }),
  title: {
    lineHeight: '21px',
    wordBreak: 'break-word',
    margin: 0,
  },
  logo: ({ data }: IProps) => ({
    width: 30,
    height: 30,
    borderRadius: 3,
    marginRight: 15,
    flexShrink: 0,
    backgroundImage: `url(${data && data.logo ? data.logo.replace(/ /g, '%20') : ''})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
  info: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  btnMain: {
    marginLeft: 15,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  bookmark: {
    marginLeft: 15,
  },
  setInterest: {
    flexGrow: 1,
    maxWidth: 'none',
  },
  labels: {
    display: 'flex',
  },
  label: {
    padding: '0 5px',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
  },
  headerItemTitle: {
    display: 'flex',
    alignItems: 'start',
    padding: '5px 0',
  },
  location: {
    width: 'auto',
    // maxWidth: 140,
    padding: '0 5px',
    justifyContent: ['flex-start', '!important'],
  },
  company: ({ fixed }: IProps) => ({
    // maxWidth: fixed ? 'auto' : 200,
    maxWidth: 'auto',
    padding: '0 5px',
  }),
  createdUpdated: ({ fixed }: IProps) => ({
    maxWidth: fixed ? 'auto' : 90,
    padding: '0 5px',
  }),
  opportunityType: ({ view, isMobile }: IProps) => ({
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(232, 239, 244, 0.5)',
    padding: '3px 12px',
    borderRadius: 3,
    marginLeft: view && !isMobile ? 15 : 0,
  }),
});
