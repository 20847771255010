import { EErrorActionTypes, ErrorState, TErrorActionType } from './error.types';

const initialState: ErrorState = {
  messages: [],
};

let id = 0;

export const errorReducer = (state: ErrorState = initialState, action: TErrorActionType): any => {
  switch (action.type) {
    case EErrorActionTypes.SET_MESSAGE: {
      id++;
      return {
        ...state,
        messages: [...state.messages, ...[{ id, ...action.value }]],
      };
    }
    case EErrorActionTypes.CLEAR_MESSAGE: {
      return {
        ...state,
        messages: state.messages.filter((m) => m.id !== action.value),
      };
    }
    case EErrorActionTypes.CLEAR_ALL_MESSAGES: {
      return {
        ...state,
        messages: [],
      };
    }

    default:
      return state;
  }
};
