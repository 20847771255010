import React from 'react';
import { Route } from 'react-router-dom';
import WithAuth from '../../../containers/WithAuth';
import WithReferrer from '../../../containers/WithReferrer';

const PrivateRoute = ({ children, path, allowed, exact, ...args }: { children: any; path: string; exact?: boolean; allowed?: boolean }) => {
  return (
    <Route {...args}>
      <WithReferrer>
        <WithAuth path={path} allowed={allowed}>
          {children}
        </WithAuth>
      </WithReferrer>
    </Route>
  );
};

export default PrivateRoute;
