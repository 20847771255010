import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
    },
    label: {
      fontSize: 14,
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 16,
      cursor: 'pointer',
    },
    input: {
      marginRight: 10,
      width: 25,
      height: 25,
      borderRadius: '50%',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      flexShrink: 0,

      '&:checked': {
        filter: 'grayscale(1)',
      },
    },
  };
});
