import React, { FC, useRef, useState, useEffect } from 'react';
import { useTheme } from 'react-jss';
import Icon from 'src/components/icons/Icon/index.web';
import { IProps } from './types.web';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';
import { validateEmail } from '../../../utilities/validation';

interface ComponentStyles {
  component?: string;
  iconClose?: string;
  input?: string;
}

const EmailInput: FC<IProps> = (props) => {
  const { value, placeholder = 'example@mail.com', containerStyle, inputStyle, onBlur, onHover, onPress = () => {}, onChange = () => {} } = props;
  const theme: ITheme = useTheme();
  const inputRef = useRef(null);
  const [active, setActive] = useState(false);
  const [valid, setValid] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle} ${active ? styles.componentActive : ''} ${
      value && !valid && !active ? styles.componentInvalid : ''
    }`.trim(),
    iconClose: `${styles.icon} ${styles.iconClose}`.trim(),
    input: `${styles.input} ${inputStyle ? inputStyle : ''}`.trim(),
  };

  const setFocusOnInput = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (inputRef.current) {
      // @ts-ignore */
      inputRef.current.focus();
      setActive(true);
    }
  };

  const onInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setActive(true);
    onPress(event);
  };

  const onClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onChange('');
    setInputValue('');
  };

  const onInputBlur = (e: any) => {
    setActive(false);
    onChange(inputValue);
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    setValid(validateEmail(inputValue));
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={cn.component} onClick={setFocusOnInput}>
      <input
        ref={inputRef}
        className={cn.input}
        value={inputValue}
        placeholder={placeholder}
        type="text"
        onBlur={onInputBlur}
        onClick={onInputClick}
        onMouseOver={onHover}
        onChange={onInputChange}
      />
      {value && !active ? (
        <div className={cn.iconClose} onClick={onClear}>
          <Icon name={'close'} color={theme.$secondaryColor2} size={16} />
        </div>
      ) : null}
    </div>
  );
};

export default EmailInput;
