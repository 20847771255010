import React from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';

import { IProps } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  content?: string;
}

const Button = React.forwardRef((props: IProps, ref: any) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { children, onPress = () => {}, containerStyle, contentStyle, disabled } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle} ${disabled ? styles.componentDisabled : ''}`.trim(),
    content: `${styles.content} ${contentStyle}`.trim(),
  };

  const onPressHandler = (e: any) => {
    if (disabled) {
    } else {
      onPress(e);
    }
  };

  const renderContent = (): React.ReactChild => {
    const content = children ? children : '';

    return (
      <>
        <div className={cn.content}>{content}</div>
      </>
    );
  };

  return (
    <button className={cn.component} onClick={onPressHandler} ref={ref}>
      {renderContent()}
    </button>
  );
});

export default Button;
