import React, { memo, useContext, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Input from 'src/components/inputs/TextInput/index.web';
import Text from 'src/components/texts/Text/index.web';
import Title from 'src/components/titles/Title/index.web';
import { IOptionItem } from 'src/requests/models';
import Icon from 'src/components/icons/Icon/index.web';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import { useTranslation } from 'react-i18next';

const Experience = memo((props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { setPage, workExperienceList, internationalExperienceList, onClose } = props;

  const {
    workExperiences: defaultWorkExperiences,
    internationalExperiences: defaultInternationalExperiences,
    otherInternationalExperiences: defaultOtherInternationalExperiences,
    otherWorkExperiences: defaulOtherWorkExperiences,
    setData,
  }: any = useContext(ProfileContext);
  const [workExperiences, setWorkExperiences] = useState(defaultWorkExperiences);
  const [internationalExperiences, setInternationalExperiences] = useState(defaultInternationalExperiences);
  const [otherInternationalExperiences, setOtherInternationalExperiences] = useState(defaultOtherInternationalExperiences);
  const [otherWorkExperiences, setOtherWorkExperiences] = useState(defaulOtherWorkExperiences);

  const onSelectExperience = (type: 'i' | 'w', item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      if (type === 'i') {
        setInternationalExperiences(internationalExperiences.concat([item]));
      } else if (type === 'w') {
        setWorkExperiences(workExperiences.concat([item]));
      }
    } else if (action === 'rem') {
      if (type === 'i') {
        setInternationalExperiences(internationalExperiences.filter((i: IOptionItem) => i.name !== item.name).slice());
      } else if (type === 'w') {
        setWorkExperiences(workExperiences.filter((i: IOptionItem) => i.name !== item.name).slice());
      }
    }
  };

  const onCancelPress = () => {
    onClose();
  };

  const onSavePress = async () => {
    try {
      setData({ workExperiences, internationalExperiences, otherWorkExperiences, otherInternationalExperiences });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPage('profileEdit');
  }, []);

  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Experience')}
        </Title>
      </div>
      <div className={styles.container}>
        <Title size={'h2'}>{t('International experience')}</Title>
        <div className={styles.experiences}>
          {internationalExperienceList.map((experienceItem: IOptionItem) => {
            const active = !!internationalExperiences.find((value: IOptionItem) => value.id === experienceItem.id);
            return (
              <ButtonToggle
                key={experienceItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.experience}
                text={experienceItem.name}
                onPress={() => onSelectExperience('i', experienceItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        <div className={styles.textAreaTexts}>
          <Text containerStyle={styles.fieldLabel}>{t('Describe your experience')}</Text>
          <Text type={'secondary'} containerStyle={styles.textAreaMax}>
            500 {t('characters max')}
          </Text>
        </div>
        <Input
          value={otherInternationalExperiences}
          containerStyle={styles.textArea}
          inputStyle={styles.textAreaInput}
          type={'textarea'}
          onChange={setOtherInternationalExperiences}
        />
        <div className={styles.divider} />
        <Title size={'h2'}>Work experience</Title>
        <div className={styles.experiences}>
          {workExperienceList.map((experienceItem: IOptionItem) => {
            const active = !!workExperiences.find((value: IOptionItem) => value.id === experienceItem.id);
            return (
              <ButtonToggle
                key={experienceItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.experience}
                text={experienceItem.name}
                onPress={() => onSelectExperience('w', experienceItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        <div className={styles.textAreaTexts}>
          <Text containerStyle={styles.fieldLabel}>{t('Describe your experience')}</Text>
          <Text type={'secondary'} containerStyle={styles.textAreaMax}>
            500 {t('characters max')}
          </Text>
        </div>
        <Input
          value={otherWorkExperiences}
          containerStyle={styles.textArea}
          inputStyle={styles.textAreaInput}
          type={'textarea'}
          onChange={setOtherWorkExperiences}
        />
      </div>
      <div className={styles.footer}>
        <ButtonMain type={'primary_2'} size={'xl'} text={t('Cancel')} containerStyle={styles.footerButtonBack} outline onPress={onCancelPress} />
        <ButtonMain type={'primary_1'} size={'xl'} text={t('Save')} containerStyle={styles.footerButtonNext} onPress={onSavePress} />
      </div>
    </div>
  );
});

export default Experience;
