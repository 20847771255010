import React, { FC, useRef, useState } from 'react';
import { useTheme } from 'react-jss';
import Icon from '../../../components/icons/Icon/index.web';
import { IProps } from './types.web';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  iconSearch?: string;
  iconClose?: string;
  input?: string;
}

const SearchInput: FC<IProps> = (props) => {
  const {
    onPressClear = () => {},
    value,
    placeholder,
    containerStyle,
    inputStyle,
    onBlur,
    onHover,
    onPress = () => {},
    onChange = () => {},
    onKeyPress,
  } = props;
  const theme: ITheme = useTheme();
  const inputRef = useRef(null);
  const [active, setActive] = useState(false);
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle} ${active ? styles.componentActive : ''}`.trim(),
    iconSearch: `${styles.icon} ${styles.iconSearch}`.trim(),
    iconClose: `${styles.icon} ${styles.iconClose}`.trim(),
    input: `${styles.input} ${inputStyle ? inputStyle : ''}`.trim(),
  };

  const setFocusOnInput = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (inputRef.current) {
      // @ts-ignore */
      inputRef.current.focus();
      setActive(true);
    }
  };

  const onInputChange = (e: any) => {
    onChange(e.target.value);
  };

  const onSearchIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // setActive(true);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setActive(true);
    onPress(event);
  };

  const onClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onChange('');
    onPressClear();
  };

  const onInputBlur = (e: any) => {
    setActive(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className={cn.component} onClick={setFocusOnInput}>
      <div className={cn.iconSearch} onClick={onSearchIconClick}>
        <Icon name={'search'} color={active ? theme.$primaryColor2 : theme.$secondaryColor2} size={20} />
      </div>
      <input
        ref={inputRef}
        className={cn.input}
        value={value}
        placeholder={placeholder}
        type="text"
        onBlur={onInputBlur}
        onClick={onInputClick}
        onMouseOver={onHover}
        onChange={onInputChange}
        onKeyPress={onKeyPress}
      />
      {value && !active ? (
        <div className={cn.iconClose} onClick={onClear}>
          <Icon name={'close'} color={theme.$secondaryColor2} size={16} />
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
