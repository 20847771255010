import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { Props } from './types';

interface IStyles {
  props: Props;
  theme: ITheme;
}
interface IColorScheme {
  default: string;
  alpha: string;
  text: string;
  outline: string;
}
interface IComponentBackgroundColor {
  primary: IColorScheme;
  default: IColorScheme;
}

type DefaultTheme = object | undefined;

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = (p: Props, t: DefaultTheme) =>
  createUseStyles((theme: ITheme) => {
    const { type } = p;

    const componentBackgroundColor: IComponentBackgroundColor = {
      primary: {
        default: theme.$primaryColor1a05,
        alpha: theme.$primaryColor1a10,
        text: theme.$primaryColor1,
        outline: theme.$primaryColor1,
      },
      default: {
        default: 'transparent',
        alpha: 'transparent',
        text: theme.$textColor,
        outline: theme.$textColor,
      },
    };

    return {
      component: {
        width: '100%',
        maxWidth: '12.4rem',
        height: '3.6rem',
        border: '1px solid transparent',
        borderColor: componentBackgroundColor[type].outline,
        backgroundColor: componentBackgroundColor[type].default,
        borderRadius: '1.8rem',
        '&:hover': {
          opacity: 0.9,
          backgroundColor: componentBackgroundColor[type].alpha,
        },
        '&:hover $text': {
          fontWeight: 600,
        },
      },
      content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      text: {
        transition: '0.25s all ease',
        color: componentBackgroundColor[type].text,
        fontFamily: 'AvenirNextCyr, sans-serif',
        fontSize: '1.3rem',
        fontWeight: 400,
        lineHeight: '1.3rem',
        marginLeft: '1rem',
      },
      icon: {
        display: 'flex',

        // [`@media only screen and (max-width: 413px)`]: {
        //   transform: 'scale(0.6)'
        // },
        // [`@media only screen and (min-width: 414px) and (max-width: 1023px) `]: {
        //   transform: 'scale(0.7)'
        // },
        // [`@media only screen and (min-width: 1024px) and (max-width: 1263px)`]: {
        //   transform: 'scale(0.8)'
        // },
        // [`@media only screen and (min-width: 1264px) and (max-width: 1439px)`]: {
        //   transform: 'scale(1)'
        // },
        // [`@media only screen and (min-width: 1440px)`]: {
        //   transform: 'scale(1)'
        // }
      },
    };
  })(t);
