import { createUseStyles } from 'react-jss';
import { FooterHeight } from 'src/constant/variables';

export const useStyles = createUseStyles(() => {
  return {
    footer: () => ({
      position: 'fixed',
      width: '100%',
      bottom: 0,
      left: 0,
      height: FooterHeight,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
      boxShadow: 'inset 0px -1px 0px rgba(145, 165, 182, 0.24)',
      zIndex: 99,
    }),
    footerButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '510px',
      padding: '0 15px',
    },
    footerButtonBack: {
      width: '25%',
      maxWidth: 'none',
      marginRight: 15,
    },
    footerButtonNext: {
      width: 'calc(75% - 15px)',
      maxWidth: 'none',
    },
    footerButtonOnly: {
      width: '100%',
      maxWidth: 'none',
    },
  };
});
