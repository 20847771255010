import React, { FC, useEffect } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import { IOptionItem } from 'src/requests/models';
import Divider from 'src/components/dividers/Divider/index.web';
import SkipPortal from '../SkipPortal';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { OnboardingData } from 'src/requests/models';
import { useSelector } from 'react-redux';
import { selectAchievements, selectAverageGrade } from 'src/state/modules/onboarding';
import { useTranslation } from 'react-i18next';

interface IProps {
  isMobile: boolean;
  children?: any;
  gradeList: IOptionItem[];
  achievementList: IOptionItem[];
  ref: any;
  next: any;
  onNext(next: string): void;
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding4: FC<IProps> = React.forwardRef((props, ref: any) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { gradeList, achievementList, next, onNext, setData, setPage, setIsNextDisabled } = props;
  const { t } = useTranslation();
  const averageGrade: IOptionItem | undefined = useSelector((state) => selectAverageGrade(state));
  const achievements: IOptionItem[] = useSelector((state) => selectAchievements(state));

  const onSelectGrades = (averageGrades: IOptionItem) => {
    setData({ averageGrades });
  };

  const onSelectAchievement = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ achievements: achievements.concat([item]) });
    } else if (action === 'rem') {
      setData({ achievements: achievements.filter((i: IOptionItem) => i.name !== item.name).slice() });
    }
  };

  const onSkipPress = async () => {
    //@ts-ignore
    await setData({ averageGrade: undefined, achievements: undefined });
    await onNext(next);
  };

  useEffect(() => {
    setPage('4');
    setIsNextDisabled(false);
  }, []);

  return (
    <>
      <SkipPortal current={ref.current} onSkipPress={onSkipPress} />
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>{`${t('How smart are you')}`}</Title>
        <Text
          type={'default'}
          text={`${t('Companies would like to know your average grades. Please indicate them here and attach a proof of your grades to your profile later.')}`}
        />
        <Text containerStyle={styles.label}>{t('Average grades')}</Text>
        <div className={styles.grades}>
          {gradeList.map((gradeItem: IOptionItem) => (
            <ButtonToggle
              key={gradeItem.name}
              size={'lg'}
              active={averageGrade ? averageGrade.name === gradeItem.name : false}
              containerStyle={styles.grade}
              text={gradeItem.name}
              onPress={() => onSelectGrades(gradeItem)}
            />
          ))}
        </div>
        <Text containerStyle={styles.label}>{t('Achievements')}</Text>
        <div className={styles.achievements}>
          {achievementList.map((achievementItem: IOptionItem) => {
            const active = !!achievements.find((a: IOptionItem) => a.name === achievementItem.name);
            return (
              <ButtonToggle
                key={achievementItem.name}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.achievement}
                text={achievementItem.name}
                onPress={() => onSelectAchievement(achievementItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
      </div>
    </>
  );
});

export default Onboarding4;
