import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import Icon from 'src/components/icons/Icon/index.web';
import { Props } from './types.web';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  icon?: string;
  input?: string;
}

const SearchInput: FC<Props> = (props) => {
  const { value, type = 'text', maxLength = 500, placeholder, containerStyle, inputStyle, onBlur, onHover, onPress, onChange } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    icon: `${styles.icon}`.trim(),
    input: `${styles.input} ${inputStyle ? inputStyle : ''}`.trim(),
  };

  return (
    <div className={cn.component}>
      <div className={cn.icon}>
        <Icon name={'search'} color={'black'} size={20} />
      </div>
      {type === 'text' ? (
        <input
          className={cn.input}
          value={value}
          placeholder={placeholder}
          type="text"
          onBlur={onBlur}
          onClick={onPress}
          onMouseOver={onHover}
          onChange={onChange}
        />
      ) : null}
      {type === 'textarea' ? (
        <textarea
          className={cn.input}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onBlur}
          onClick={onPress}
          onMouseOver={onHover}
          onChange={onChange}
        />
      ) : null}
    </div>
  );
};

export default SearchInput;
