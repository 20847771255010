import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import Text from 'src/components/texts/Text/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import Button from '../Button/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';
// import {ITheme} from "../../../constant/themes";

interface ComponentStyles {
  component?: string;
  content?: string;
  text?: string;
}

const ButtonMain: FC<IProps> = (props) => {
  /* @ts-ignore */
  const theme: any = useTheme();
  const {
    disabled,
    children,
    type,
    iconName,
    iconSize = 25,
    outline,
    iconColor = outline ? theme[`$${type.split('_')[0]}Color${type.split('_')[1]}`] : 'white',
    text,
    containerStyle,
    contentStyle,
    textStyle,
    onHover,
    onPress,
    onBlur,
  } = props;

  const styles = useStyles({ ...props, theme, outline });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    content: `${styles.content} ${contentStyle}`.trim(),
    text: `${styles.text} ${textStyle ? textStyle : ''}`.trim(),
  };

  const renderIcon = (): React.ReactChild | null => {
    return iconName ? <Icon size={iconSize} name={iconName} color={iconColor} /> : null;
  };

  const renderText = (): React.ReactChild | null => {
    return (
      <Text containerStyle={cn.text} type={'button'} inline>
        {text}
      </Text>
    );
  };

  return (
    <Button disabled={disabled} onPress={onPress} onHover={onHover} onBlur={onBlur} containerStyle={cn.component} contentStyle={cn.content}>
      {children ? (
        children
      ) : (
        <>
          {iconName ? renderIcon() : null}
          {text ? renderText() : null}
        </>
      )}
    </Button>
  );
};

export default ButtonMain;
