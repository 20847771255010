import React, { useEffect, memo, useCallback, useState } from 'react';
import { IProps, IViewOption } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import Select, { Option } from 'src/components/inputs/Select/index.web';
import Text from 'src/components/texts/Text/index.web';
import { useTranslation } from 'react-i18next';
import Icon from 'src/components/icons/Icon/index.web';

interface IOption extends Option {
  option: IViewOption;
}

const ViewSelect = memo((props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const { value, onChoose, disabled } = props;
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const styles = useStyles({ ...props, ...theme, language });

  const [active, setActive] = useState(false);

  const list: IViewOption[] = [
    { id: 'likes', name: t('Interested'), icon: 'thumbs-up' },
    { id: 'bookmarks', name: t('Bookmarked'), icon: 'bookmark' },
    { id: 'dislikes', name: t('Ignored'), icon: 'thumbs-down' },
  ];

  const onChooseHandler = (item: IViewOption) => onChoose(item.id);

  const renderOption = useCallback((props: IOption) => {
    const { option, onPress, containerStyle } = props;

    const onChoose = (e: MouseEvent) => {
      e.stopPropagation();
      onPress(option);
    };

    return (
      <div className={`${containerStyle} ${styles.option}`}>
        <div style={{ flexShrink: 0 }}>
          <Icon size={16} color={theme.$primaryColor1} name={option.icon} />
        </div>
        <Text containerStyle={styles.optionText} type={'default'} onPress={onChoose}>
          {option.name}
        </Text>
      </div>
    );
  }, []);

  return (
    <Select
      value={list.find((item: IViewOption) => item.id === value)}
      list={list}
      noInput
      disabled={disabled}
      onChoose={onChooseHandler}
      Option={renderOption}
      containerStyle={styles.component}
      contentStyle={active ? styles.activeComponentContent : styles.componentContent}
      optionsStyle={styles.options}
      onActive={setActive}
    />
  );
});

export default ViewSelect;
