import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: !isMobile ? 'row' : 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: '100%',
        width: '100%',
        //height: isMobile ? 204 : 233,
        padding: isMobile ? 15 : 30,
        backgroundColor: 'white',
        border: `1px solid ${theme.$borderColor}`,
        borderRadius: 10,
      };
    },
    head: ({ isMobile }: IProps) => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: isMobile ? 'calc(100% - 30px)' : '100%',
      margin: isMobile ? '15px 15px 0' : '0 0 15px',
    }),
    headTitle: ({ isMobile }: IProps) => ({
      fontSize: isMobile ? 18 : 24,
      margin: 0,
      lineHeight: '30px',
    }),
    block: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
    },
    header: {
      paddingBottom: 10,
    },
    body: ({ isMobile }: IProps) => ({
      maxWidth: '100%',
      overflow: 'hidden',
      lineHeight: '19px',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    }),
    footer: ({ isMobile }: IProps) => ({
      maxWidth: !isMobile ? '40%' : '100%',
      paddingLeft: !isMobile ? 30 : 0,
      paddingTop: 10,
      alignItems: 'flex-end',
    }),
    title: {
      marginTop: 2,
      marginBottom: 8,
      lineHeight: '21px',
    },
    logo: ({ data }: IProps) => ({
      width: 46,
      height: 46,
      borderRadius: 3,
      marginRight: 15,
      flexShrink: 0,
      backgroundImage: `url(${data && data.logo ? data.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    info: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    bookmark: {
      marginLeft: 15,
    },
    setInterest: {
      flexGrow: 1,
      maxWidth: 'none',
    },
    labels: ({ isMobile }: IProps) => ({
      display: 'flex',
    }),
    label: ({ isMobile }: IProps) => ({
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      padding: isMobile ? 0 : '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    }),
    labelLocation: {
      maxWidth: 200,
      marginRight: 15,
    },
    labelSection: {
      maxWidth: 280,
    },
    labelIcon: {},
    details: {},
    detailsImg: ({ data }: IProps) => ({
      width: '100%',
      height: 214,
      backgroundImage: `url(${data && data.background_photo ? data.background_photo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }),
    detailsList: {
      padding: 0,
    },
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    setIgnore: {},
    setIgnoreText: {
      color: '#222222',
    },
  };
});
