import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IErrorsProps, IErrorProps } from './types';

//@ts-ignore
export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    list: (p: IErrorsProps) => ({
      width: p.isMobile ? '100vw' : '375px',
      height: p.isMobile ? '100vh' : 'auto',
      maxHeight: '100%',
      padding: '10px',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    }),
    item: (p: IErrorProps) => ({
      position: 'relative',
      width: '100%',
      height: 150,
      display: 'flex',
      flexDirection: 'column',
      padding: 30,
      background: 'white',
      borderRadius: 15,
      border: '1px solid rgb(232, 239, 244)',
    }),
    itemType: {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 10,
    },
    itemMessage: {
      border: 'none',
      resize: 'none',
      background: 'transparent',
    },
    close: {
      position: 'absolute',
      display: 'flex',
      top: 15,
      right: 15,
      width: 20,
      height: 20,
      cursor: 'pointer',

      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        height: 25,
        width: 3,
        background: theme.$primaryColor1,
      },
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        height: 25,
        width: 3,
        background: theme.$primaryColor1,
      },
    },
  };
});
