import { TSessionAction, ESessionActionTypes } from './session.types';
import { Storage } from '../../../utilities/Storage';

export const signIn = (): TSessionAction => ({
  type: ESessionActionTypes.SIGN_IN,
});

export const signOut = (): TSessionAction => ({
  type: ESessionActionTypes.SIGN_OUT,
});

export const setTokenAction = (value: string | undefined): TSessionAction => ({
  type: ESessionActionTypes.TOKEN,
  value,
});

export const setTries = (value?: number): TSessionAction => ({
  type: ESessionActionTypes.TRIES,
  value,
});

//@ts-ignore
export const setToken = (token: string | undefined, mobile: boolean) => async (dispatch) => {
  if (!mobile) {
    await dispatch(setTokenAction(token));
  } else {
    const storage = new Storage(mobile);
    await storage.setTokens(token === undefined ? '' : token);
  }
};

export const setSessionReady = (value: boolean): TSessionAction => ({
  type: ESessionActionTypes.READY,
  value,
});
