import React from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../../../constant/themes';
import Icon from 'src/components/icons/Icon/index.web';
import Title from 'src/components/titles/Title/index.web';
import Label from 'src/components/labels/Label/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { useTranslation } from 'react-i18next';
import TextBlock from 'src/web/components/TextBlock/index.web';

interface ComponentStyles {
  component?: string;
}

const Languages = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const { containerStyle, list, onEdit, otherContentText = '' } = props;
  const notEmptyList = list && list.length ? true : false;
  const styles = useStyles({ ...props, notEmptyList, ...theme });
  const { t } = useTranslation();

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
  };

  return (
    <div className={cn.component}>
      <div className={`${styles.title} ${notEmptyList ? '' : styles.emptyTitle}`}>
        <Icon size={notEmptyList ? 18 : 22} name={'chat'} color={theme.$primaryColor1} />
        <Title size={'h2'} containerStyle={`${styles.titleText} ${notEmptyList ? '' : styles.emptyTitleText}`}>
          {t('Languages')}
        </Title>
        {notEmptyList ? (
          <ButtonMain
            type={'primary_1'}
            size={'xl'}
            containerStyle={styles.editLink}
            textStyle={styles.editLinkText}
            onPress={() => onEdit(true)}
            text={t('Edit')}
          />
        ) : null}
      </div>
      <div className={styles.list}>
        {notEmptyList ? (
          list.map((item) => <Label key={item.id} item={item} containerStyle={styles.item} />)
        ) : (
          <>
            <Text type={'secondary'} containerStyle={styles.text} style={{ width: '100%' }}>
              {t('Add languages proficiency. Please include intermediate level or above')}
            </Text>
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              containerStyle={styles.addLink}
              textStyle={styles.addLinkText}
              onPress={() => onEdit(true)}
              text={`+ ${t('Add languages')}`}
            />
          </>
        )}
      </div>
      {otherContentText ? <TextBlock text={otherContentText} /> : null}
    </div>
  );
};

export default Languages;
