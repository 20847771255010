import React, { useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';

interface IProps {
  isMobile?: boolean;
  filterRef?: any;
  setSearchNext: any;
  result: any;
}

export const useLoadOnScroll = ({ setSearchNext, result }: IProps) => {
  const ref = useRef(window);
  let flag = false;

  const onScroll = useCallback(
    (e: any) => {
      e.stopPropagation();
      const c = document.documentElement;
      if (!flag && c.scrollTop + window.innerHeight >= c.scrollHeight - 60) {
        flag = true;
        setTimeout(() => {
          flag = false;
        }, 500);
        setSearchNext();
      }
    },
    [result],
  );

  useEffect(() => {
    const targetRef = ref?.current;

    if (targetRef) {
      targetRef.addEventListener('scroll', onScroll);

      return () => {
        targetRef.removeEventListener('scroll', onScroll);
      };
    }
  }, [onScroll, ref]);
};
