import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import Select from 'src/components/inputs/Select/index.web';
import { IProps, EExploreFeedTypes } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { Context as ExploreContext } from 'src/contexts/ExploreContext';
import { useTranslation } from 'react-i18next';
import Side from './Side';
import { Context as ProfileContext } from '../../contexts/ProfileContexts/index.web';
import { Context as MessageContext } from 'src/contexts/MessagesContext/index.web';
import List from './List';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import moment from 'moment';
import Title from 'src/components/titles/Title/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Text from 'src/components/texts/Text/index.web';
import Modal from '../Modal/Modal';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import { PROFILE_DATA_NEEDED, UNREAD_MEMOS } from 'src/requests/queries';
import { useQuery } from '@apollo/client';
import Icon from 'src/components/icons/Icon/index.web';
import TabList from 'src/components/tabs/TabList/index.web';
import { IOptionItem } from 'src/requests/models';
import { ApplyModal } from '../ApplyModal';
import { IOpportunity } from 'src/components/cards/Opportunity/types';
import { addSubscription } from 'src/state/modules/ui';
import { useDispatch } from 'react-redux';
import { MemosModal } from '../MemosModal';

const Explore = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, activeTab, onChangeTab, result } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    toggleCompanyLike,
    toggleOpportunityLike,
    toggleEventLike,
    toggleCompanyBookmark,
    toggleOpportunityBookmark,
    toggleEventBookmark,
    toggleCompanyDislike,
  }: any = useContext(ExploreContext);
  const { setSearch: fetchMsgs }: any = useContext(MessageContext);

  let { data: dataM } = useQuery(UNREAD_MEMOS, {
    variables: {
      lang: 'en',
    },
  });

  const [isFirst, setIsFirst] = useState(false);
  const [unreadMemos, setUnreadMemos] = useState([{ message: '' }]);
  const [memoNumber, setMemoNumber] = useState(0);
  const [memoToShow, setMemoToShow] = useState('');

  const handleEOLike = ({ id, application, type, name }: any, is_liked: boolean) => {
    if (application.redirectUrl) {
      window.open(application.redirectUrl);
    } else {
      dispatch(addSubscription({ id: '' + id, name, type: type === 'opportunity' ? 'o' : 'e' }));
    }

    if (type === 'opportunity') {
      toggleOpportunityLike(id, is_liked, type[0]);
    } else if (type === 'event') {
      toggleEventLike(id, is_liked, type[0]);
    }
  };

  const handleCLike = ({ id, type }: any, is_liked: boolean) => toggleCompanyLike(id, is_liked, type[0]);

  const [position, setPosition] = useState<any>(null);

  const sideRef = useRef(null);

  const positionInit = () => {
    if (sideRef) {
      //@ts-ignore
      setPosition({ x: sideRef.current.offsetLeft, y: sideRef.current.offsetTop });
    }
  };

  useEffect(() => {
    if (!dataM) {
      setIsFirst(true);
    }
  }, []);

  useEffect(() => {
    if (isFirst) {
      setUnreadMemos(dataM?.unreadMemos);
    }
  }, [dataM?.unreadMemos[0]?.message]);

  useEffect(() => {
    if (unreadMemos) {
      setMemoToShow(unreadMemos[memoNumber]?.message);
    }
  }, [unreadMemos]);

  useEffect(() => {
    fetchMsgs();
    positionInit();
    window.addEventListener('resize', positionInit);
    return () => {
      window.removeEventListener('resize', positionInit);
    };
  }, []);
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const { data } = useQuery(PROFILE_DATA_NEEDED, { variables: { lang } });
  const { setApplyModalShown, setEntityForApply, entityForApply, applyModalShown } = useContext(SearchContext);

  const { profileData, dataReady, avatarData, setData }: any = useContext(ProfileContext);

  const [modalUpdatePersona, setModalUpdatePersona] = useState(false);
  const [modalConfirmPersona, setModalConfirmPersona] = useState(false);
  const [selectedPersona, setPersona] = useState(profileData?.education_level);
  const [modalAddLang, setModalAddLang] = useState(false);
  const [selectedLang, setLang]: any[] = useState([]);

  const languageList = data?.languages || [];
  const listOfNonPrimaryLangs = languageList.filter((l) => !l.is_primary);
  const listPrimaryLangs = languageList.filter((l) => l.is_primary);
  const [selectedOther, setSelectedOther] = useState<IOptionItem[]>([]);

  const onModalClose = useCallback(() => {
    setModalUpdatePersona(false);
  }, []);

  useEffect(() => {
    if (profileData?.persona_update_time === null || moment().diff(moment(profileData?.persona_update_time), 'month') >= 6) {
      setTimeout(() => {
        setModalUpdatePersona(true);
      }, 500);
    } else if (!profileData.languages?.length) {
      setTimeout(() => {
        setModalAddLang(true);
      }, 500);
    }
  }, [profileData?.persona_update_time, profileData?.languages]);

  const onSave = useCallback(() => {
    setData({ educationLevel: selectedPersona });
    setModalConfirmPersona(true);
    setModalUpdatePersona(false);

    setTimeout(() => {
      setModalConfirmPersona(false);
    }, 2000);
  }, [selectedPersona, setData]);

  const onSelectLanguage = (item: IOptionItem, action: 'add' | 'rem') => {
    if (item.is_primary) {
      if (action === 'add') {
        setLang(selectedLang.concat([item]));
      } else if (action === 'rem') {
        setLang(selectedLang.filter((i: IOptionItem) => i.id !== item.id).slice());
      }
    } else {
      const newSelectedOther = selectedOther.slice();
      const selectedIndex = newSelectedOther.findIndex((i: IOptionItem) => i.id === item.id);

      if (selectedIndex >= 0) {
        if (selectedOther[selectedIndex].id === item.id) {
          newSelectedOther.splice(selectedIndex, 1);
        } else {
          newSelectedOther[selectedIndex] = item;
        }
      } else {
        newSelectedOther.push(item);
      }
      const primaryLangs = selectedLang.filter((l: IOptionItem) => l.is_primary);
      setLang([...primaryLangs, ...newSelectedOther]);
      setSelectedOther(newSelectedOther);
    }
  };

  const onChangeOther = (selectedIndex: number, other: IOptionItem) => {
    const newSelectedOther = selectedOther.slice();
    if (selectedOther[selectedIndex]) {
      if (selectedOther[selectedIndex].id === other.id) {
        newSelectedOther.splice(selectedIndex, 1);
      } else {
        newSelectedOther[selectedIndex] = other;
      }
    } else {
      newSelectedOther.push(other);
    }
    const primaryLangs = selectedLang.filter((l) => l.is_primary);
    setLang([...primaryLangs, ...newSelectedOther]);
    setSelectedOther(newSelectedOther);
  };

  const onLangSave = () => {
    setData({ languages: selectedLang });
    setModalAddLang(false);
  };

  const tabList = useMemo(
    () => [
      { key: EExploreFeedTypes.GENERAL, value: t('General') },
      { key: EExploreFeedTypes.COMPANY, value: t('Companies_explore') },
      { key: EExploreFeedTypes.EVENT, value: t('Events_explore') },
      { key: EExploreFeedTypes.OPPORTUNITY, value: t('Opportunities_explore') },
    ],
    [t],
  );

  return (
    <div className={styles.component}>
      <div className={styles.side} ref={sideRef}>
        <Side ready={dataReady} avatarData={avatarData} profileData={profileData} position={position} />
      </div>
      <div className={styles.main}>
        <TabList isMobile={isMobile} active={activeTab} list={tabList} containerStyle={styles.tabs} tabStyle={styles.tab} onChange={onChangeTab} />
        <List
          isGlobal={false}
          isMobile={isMobile}
          toggleCompanyLike={handleCLike}
          toggleOpportunityLike={handleEOLike}
          toggleEventLike={handleEOLike}
          toggleCompanyBookmark={toggleCompanyBookmark}
          toggleOpportunityBookmark={toggleOpportunityBookmark}
          toggleEventBookmark={toggleEventBookmark}
          toggleCompanyDislike={toggleCompanyDislike}
          result={result}
        />
      </div>
      {applyModalShown && (
        <ApplyModal
          entity={entityForApply as IOpportunity}
          cancel={() => {
            setApplyModalShown(false);
            setEntityForApply({});
          }}
          confirm={() => {
            handleEOLike(entityForApply, true);
            setApplyModalShown(false);
            setEntityForApply({});
          }}
          text={(entityForApply as IOpportunity)?.application?.message ? (entityForApply as IOpportunity)?.application?.message : ''}
        />
      )}
      {memoToShow && (
        <MemosModal
          close={() => {
            setMemoToShow('');
            setMemoNumber(0);
            dataM = { unreadMemos: [] };
          }}
          next={() => {
            setMemoToShow(unreadMemos[memoNumber + 1]?.message);
            setMemoNumber(memoNumber + 1);
          }}
          text={memoToShow}
          isLast={memoNumber + 1 >= unreadMemos?.length}
          allMemosCount={unreadMemos.length}
          current={memoNumber + 1}
        />
      )}
      <Modal
        isModalOpened={modalUpdatePersona || modalConfirmPersona || modalAddLang}
        wrapperStyles={styles.modal}
        contentStyles={styles.modalContent}
        onClose={onModalClose}>
        {!modalConfirmPersona && modalUpdatePersona && (
          <div className={styles.deactivateContent}>
            <div className={styles.modalHeader}>
              <Title size={'h1'} containerStyle={styles.modalHeaderTitle}>
                {t('Confirm your persona')}
              </Title>
            </div>
            <div className={styles.modalBody}>
              <Title containerStyle={styles.bodyTitle} size={'h2'}>{`${t('It has been a while since you have updated your persona.')}`}</Title>
              <Text containerStyle={styles.bodyText}>{t('Please select the persona below which fits your profile best.')}</Text>
              <div className={styles.list}>
                {data?.educationLevels.map((item) => {
                  return (
                    <ButtonToggle
                      key={item.id}
                      containerStyle={styles.listItem}
                      size={'md'}
                      text={item.name}
                      active={item.id === selectedPersona?.id}
                      onPress={() => setPersona(item)}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain size={'lg'} type={'primary_1'} containerStyle={styles.modalFooterBtn} text={t('Save')} onPress={onSave} />
            </div>
          </div>
        )}
        {modalConfirmPersona && (
          <div className={styles.deactivateContent}>
            <div className={styles.modalHeader}>
              <Title size={'h1'} containerStyle={styles.modalHeaderTitle}>
                {t('Confirm your persona')}
              </Title>
            </div>
            <div className={styles.confirmBody}>
              <div className={styles.bodyIcon}>
                <Icon size={24} name={'thumbs-up'} color={theme.$primaryColor1} />
              </div>
              <Title containerStyle={styles.bodyTitle} size={'h2'}>{`${t('Thanks for confirming your persona')}`}</Title>
              <Text containerStyle={styles.confirmBodyText}>{t('You can always update your persona via your profile')}</Text>
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain
                size={'lg'}
                type={'primary_2'}
                outline
                containerStyle={styles.modalFooterBtn}
                text={t('Close')}
                onPress={() => {
                  setModalConfirmPersona(false);
                }}
              />
              <Text containerStyle={styles.confirmText}>{t('Confirmation will auto disappear in 2 seconds')}</Text>
            </div>
          </div>
        )}
        {modalAddLang && (
          <div className={styles.deactivateContent}>
            <div className={styles.modalHeader}>
              <Title size={'h1'} containerStyle={styles.modalHeaderTitle}>
                {t('Please add your language knowledge')}
              </Title>
            </div>
            <div className={styles.modalBody}>
              <Title containerStyle={styles.languageTitle} size={'h2'}>{`${t(
                'Please select the languages of which you have at least working proficiency (at least 1)',
              )}`}</Title>
              <Text containerStyle={styles.languageText}>
                {t(
                  'In the future, the Explore algorithm will take your language knowledge into account. As you did not configure any language knowledge, please do it now before continuing to the use the app.',
                )}
              </Text>
              <div>
                {[...listPrimaryLangs, ...selectedOther].map((languageItem: IOptionItem) => {
                  const active = !!selectedLang.find((a: IOptionItem) => a.id === languageItem.id);
                  return (
                    <ButtonToggle
                      key={languageItem.id}
                      size={'lg'}
                      icon
                      active={active}
                      containerStyle={styles.language}
                      text={languageItem.name}
                      onPress={() => onSelectLanguage(languageItem, active ? 'rem' : 'add')}
                    />
                  );
                })}
              </div>
              {selectedOther.length < 3 ? (
                <Select
                  value={undefined}
                  containerStyle={styles.langInput}
                  placeholder={t('Other')}
                  list={listOfNonPrimaryLangs.filter((lang: IOptionItem) => !selectedOther.find((sLang: IOptionItem) => lang.id === sLang.id))}
                  onChoose={(item: IOptionItem) => onChangeOther(selectedOther.length, item)}
                />
              ) : null}
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain
                disabled={!selectedLang.length}
                size={'lg'}
                type={'primary_1'}
                containerStyle={styles.modalFooterBtn}
                text={t('Save')}
                onPress={onLangSave}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Explore;
