import React from 'react';
import ErrorBoundary from '../ErrorBoundaries';
import { useTranslation } from 'react-i18next';

const Error = ({}: { children: any }) => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <h2 style={{ fontSize: 28 }}>{`${t('Something went wrong. We are working to make things right.')} 😀`}</h2>
    </div>
  );
};

export default ({ children }: { children: any }) => <ErrorBoundary Fallback={Error}>{children}</ErrorBoundary>;
