import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Title from 'src/components/titles/Title/index.web';
import moment from 'moment';
import Text from 'src/components/texts/Text/index.web';

const Item = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, active, data, containerStyle, onPress } = props;

  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);

  const { id, name, logo, message_created, text, is_accepted } = data;

  const textToDisplay = useMemo(() => {
    const nextLineIndex = text.indexOf('\n');
    if (nextLineIndex <= 60 && nextLineIndex >= 0) {
      return text.slice(0, nextLineIndex);
    } else {
      return text.slice(0, 60);
    }
  }, [text]);

  return (
    <div className={`${styles.component} ${active && styles.componentActive} ${containerStyle}`} onClick={onPress}>
      <div style={{ display: 'flex', width: 'calc(100% - 80px)' }}>
        <div className={styles.item} style={{ marginRight: 10 }}>
          {logo ? <div className={styles.logo} /> : <div className={styles.withoutLogo} />}
        </div>
        <div className={`${styles.item} ${styles.item2}`} style={{ flexGrow: 1 }}>
          <Title size={'h2'} containerStyle={styles.title}>
            {name}
          </Title>
          <Text containerStyle={styles.text} type={'primary'} text={textToDisplay} />
        </div>
      </div>
      <div className={styles.item} style={{ width: 80, height: '100%', padding: isMobile ? 15 : '15px 0', flexShrink: 0 }}>
        <div className={styles.isNewContainer}>{is_accepted === null && <div className={styles.isNew} />}</div>
        <div className={styles.lastMsgTime}>{moment(message_created).fromNow()}</div>
      </div>
    </div>
  );
};

export default Item;
