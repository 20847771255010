import React, { useContext } from 'react';
import { Context as MessagesContext } from '../../../contexts/MessagesContext/index.web';
import { ITheme } from '../../../constant/themes';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';

const MessageCounter = ({ className }: { className?: string }) => {
  const { inboxCount }: any = useContext(MessagesContext);
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });

  return inboxCount ? <div className={`${className} ${styles.inboxCount}`}>{inboxCount}</div> : null;
};

export default MessageCounter;
