import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDataOperation } from 'src/state/modules/onboarding';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import { MyThunkDispatch } from 'src/state/modules/types';
import { Redirect, Route, Switch } from 'react-router';
import { Storage } from 'src/utilities/Storage';

interface IProps {
  children: any;
  path: any;
}

const storage = new Storage(false);

const WithOnboarding = ({ children }: IProps) => {
  const dispatch: MyThunkDispatch = useDispatch();
  const [dataReady, setDataReady] = useState(false);
  const { profileData }: any = useContext(ProfileContext);

  useEffect(() => {
    if (profileData.id) {
      storage.getUser().then((userId) => {
        if (profileData.id === userId) {
          dispatch(getDataOperation(false)).then(() => {
            setDataReady(true);
          });
        } else {
          setDataReady(true);
          storage.setUser(profileData.id);
        }
      });
    }
  }, [profileData, dispatch]);

  return dataReady && profileData.id ? (
    profileData.is_profile_created && !profileData.isOnboarding ? (
      <>{children}</>
    ) : (
      <>
        <Switch>
          <Route path={'/onboarding'}>{children}</Route>
          <Redirect to={'/onboarding'} />
        </Switch>
      </>
    )
  ) : null;
};

export default WithOnboarding;
