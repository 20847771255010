import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TYPED_FEED } from '../../../requests/queries';
import { UPDATE_COMPANY_DISLIKE } from '../../../requests/mutations';

enum EExploreFeedTypes {
  COMPANY = 'company',
  EVENT = 'event',
  OPPORTUNITY = 'opportunity',
}

interface IProps {
  children: any;
}

interface ExplorerContextState {
  setSearch: () => void;
  setSearchNext: () => void;
  getFeedByType: (type: EExploreFeedTypes) => void;
  result: any;
  loading: boolean;
  toggleDislike: (id: string) => void;
}

const Context = createContext({} as ExplorerContextState);
Context.displayName = '_TYPED_EXPLORE_';

const Provider = (props: IProps) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const [getTypedFeed, { data, loading, refetch, fetchMore }] = useLazyQuery(TYPED_FEED);

  const getFeedByType = (type: EExploreFeedTypes) =>
    getTypedFeed({
      variables: {
        lang,
        page: 1,
        limit: 10,
        type,
      },
    });

  const cacheClear = (cache: any, id: number, data: any): void => {
    const itemRef = cache.identify(data.typedFeed.result.find((item: any) => item.id === id));
    cache.modify({
      id: cache.identify(data.typedFeed),
      fields: {
        result: (cache: any): any => {
          return cache.filter((c: any) => c.__ref !== itemRef);
        },
      },
    });
  };

  const [companyDislikeExplore] = useMutation(UPDATE_COMPANY_DISLIKE, {
    update(cache, { data: { updateCompanyDislike } }) {
      cacheClear(cache, updateCompanyDislike.id, data);
    },
  });

  const toggleDislikeExplore = async (id: string) => {
    try {
      await companyDislikeExplore({ variables: { dislike: { id: id + '', is_disliked: true } } });
    } catch (e) {
      console.error(e);
    }
  };

  const setSearchNext = async () => {
    if (!loading && data.typedFeed && !data.typedFeed.is_last) {
      //@ts-ignore
      await fetchMore({
        variables: {
          lang,
          limit: 10,
          page: data.typedFeed.page + 1,
        },
        //@ts-ignore
        updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
          if (!fetchMoreResult || !fetchMoreResult.typedFeed) {
            return prev;
          }
          return Object.assign({}, prev, {
            typedFeed: {
              ...fetchMoreResult.typedFeed,
              result: [...prev.typedFeed.result, ...fetchMoreResult.typedFeed.result],
            },
          });
        },
      });
    }
  };

  const setSearch = () => {
    refetch && refetch();
  };

  return (
    <Context.Provider
      value={{
        setSearch,
        setSearchNext,
        getFeedByType,
        result: data?.typedFeed ? data?.typedFeed?.result : [],
        loading,
        toggleDislike: toggleDislikeExplore,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
