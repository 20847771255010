import { IThemes } from 'src/constant/themes';
import { IOptionItem } from '../../../requests/models';

export type TUIState = {
  resolutions: {
    readonly xs: boolean; // < 375
    readonly sm: boolean; // 375 - 1023
    readonly md: boolean; // 1024 - 1365
    readonly lg: boolean; // >= 1366
  };
  chosenTheme: keyof IThemes;
  modalPrivacy: boolean;
  modalTerms: boolean;
  subscriptions: IOptionItem[];
};

export enum EUIActionTypes {
  SET_DEVICE_RES = '@ui/SET_DEVICE_RES',
  SET_MODAL = '@ui/SET_MODAL',
  SET_SUBSCRIPTION = '@ui/SET_SUBSCRIPTION',
  REMOVE_SUBSCRIPTION = '@ui/REMOVE_SUBSCRIPTION',
}

export type TSetDeviceResAction = {
  type: EUIActionTypes.SET_DEVICE_RES;
  value: { key: string; value: boolean };
};

export type TSetSubscription = {
  type: EUIActionTypes.SET_SUBSCRIPTION;
  value: IOptionItem;
};

export type TRemoveSubscription = {
  type: EUIActionTypes.REMOVE_SUBSCRIPTION;
  value: string;
};

export type TSetModal = {
  type: EUIActionTypes.SET_MODAL;
  value: { key?: string | undefined; value?: boolean | undefined };
};

export type TUIAction = TSetDeviceResAction | TSetModal | TSetSubscription | TRemoveSubscription;
