const langMap = new Map([
  ['English', 'EN'],
  ['Nederlands', 'NL'],
  ['Français', 'FR'],
  ['Deutsch', 'DE'],
  ['Afrikaans', 'AF'],
  ['简体中文', 'ZH'],
  ['Dansk', 'DA'],
  ['Español', 'ES'],
  ['Frysk', 'FY'],
  ['Italiano', 'IT'],
  ['Norsk', 'NO'],
  ['Português', 'PT'],
  ['Slovenski', 'SK'],
  ['Svenska', 'SV'],
  ['Kiswahili', 'SW'],
  ['Türkçe', 'TR'],
  ['русский', 'RU'],
]);

export const createLabel = (languages: string[]) => {
  if (languages.length >= 1) {
    return languages.reduce((res, item, index) => {
      if (index === 0) {
        return langMap.get(item) || item;
      }

      return `${res} + ${langMap.get(item) || item}`;
    }, '');
  }

  return languages[0];
};
