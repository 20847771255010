import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '15px',
      alignItems: 'flex-start',
      color: theme.$primaryColor2,
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      backgroundColor: theme.$defaultColor1,
      border: `1px solid ${theme.$defaultColor1}`,
      borderRadius: 10,
      margin: '10px 5px',
      width: '100%',
    },
    textContainer: {
      margin: '0 15px',
    },
  };
});
