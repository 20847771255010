import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: (p: IProps) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      padding: p.isMobile ? '15px 15px 15px 40px' : '30px',
      backgroundColor: 'white',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: '10px',
    }),
    title: (p: IProps) => ({
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: p.isMobile ? 0 : 25,

      '& svg': {
        marginLeft: -22,
      },
    }),
    emptyTitle: () => ({
      flexDirection: 'column',
      padding: 0,

      '& svg': {
        marginLeft: 0,
      },
    }),
    editLink: (p: IProps) => ({
      position: 'absolute',
      height: 'auto',
      top: p.isMobile ? 15 : 30,
      right: p.isMobile ? 15 : 30,
      borderColor: 'transparent',
      width: 'auto',
      background: 'transparent',
    }),
    editLinkText: {
      color: theme.$primaryColor1,
    },
    titleText: (p: IProps) => ({
      margin: '0 0 0 10px',
      width: 'calc(100% - 25px)',
      paddingRight: !p.notEmptyList ? 0 : 50,
      textAlign: !p.notEmptyList ? 'center' : 'left',
      display: 'block',
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    emptyTitleText: (p: IProps) => ({
      margin: '8px 0 0 0',
    }),
    list: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '5px 0 0',
    },
    item: {
      margin: 5,
    },
    text: {
      textAlign: 'center',
      padding: '10px',
    },
    addLink: (p: IProps) => ({
      width: '100%',
      maxWidth: 'none',
      height: 'auto',
      top: p.isMobile ? 15 : 30,
      right: p.isMobile ? 15 : 30,
      borderColor: 'transparent',
      background: 'transparent',
    }),
    addLinkText: {
      color: theme.$primaryColor1,
    },
  };
});
