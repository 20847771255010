import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      maxWidth: 705,
      margin: '60px auto',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '6px 6px 0px 0px',
    },
    companyBackground: ({ banner_img }: IProps) => ({
      backgroundImage: `url(${banner_img ? banner_img.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: 350,
      borderRadius: '6px 6px 0px 0px',
      zIndex: 1,
    }),
    main: {
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      background: 'white',
      padding: '20px 30px 30px',
      zIndex: 2,
      borderRadius: '0 0 6px 6px',
    },
    mainItem: {
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    mainItemTop: {
      marginBottom: 30,
    },
    mainItemInfo: {
      padding: '10px 0',
    },
    mainHeader: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      marginBottom: 20,
    },
    mainInfoLogo: ({ logo }: IProps) => ({
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 90,
      height: 90,
      border: '2px solid #FFFFFF',
      borderRadius: 5,
      flexShrink: 0,
      backgroundColor: 'white',
      //@ts-ignore
      backgroundImage: `url(${logo ? logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    sideLogo: {
      width: 'calc(100% - 90px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingLeft: 105,
    },
    labels: {
      display: 'flex',
    },
    wrapper: {
      position: 'relative',
      '&:hover .tooltip': {
        display: 'inline-block',
      },
    },
    tooltip: {
      bottom: '100%',
      left: '100%',
      display: 'none',
      width: 'max-content',
      padding: '10px 20px',
      position: 'absolute',
      fontSize: '1rem',
      background: 'white',
      boxShadow: 'rgb(48 70 89 / 8%) 0px 5px 10px',
      borderRadius: '5px',
    },
    label: {
      justifyContent: 'flex-start',
      overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'pre',
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    labelType: {
      margin: 0,
    },
    labelIcon: {},
    labelLocation: {
      // maxWidth: 200,
    },
    labelSection: {
      // maxWidth: 200,
    },
    labelUser: {
      // maxWidth: 200,
    },
    labelSite: {
      // maxWidth: 200,
    },
    linkText: {
      color: theme.$primaryColor2,
      fontSize: 12,
      opacity: 0.75,
    },
    labelOther: {
      color: '#282828',
      fontWeight: 500,
      maxWidth: 120,
      opacity: 1,
      '& svg *': {
        fill: theme.$primaryColor1,
      },
    },
    labelList: {
      width: 'auto',
    },
    buttons: {
      display: 'flex',
    },
    setInterest: {
      width: 180,
    },
    opportunityInfo: {
      marginRight: 20,
      width: 'calc(100% - 235px)',
    },
    opportunityOther: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      padding: '10px 0 20px',
      margin: '18px 0',
    },
    opportunityOtherLabels: {
      display: 'flex',
      width: '70%',
      minWidth: 280,
      justifyContent: 'space-around',
    },
    overallValueFull: {
      overflow: 'auto',
    },
    profileLink: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    opportunityInfoItem: {
      display: 'flex',
      alignItems: 'center',
    },
    companyTitle: {
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      fontSize: 21,
      lineHeight: '19px',
      marginBottom: 5,
      whiteSpace: 'pre',
    },
    title: {
      margin: 0,
      lineHeight: '21px',

      '& svg': {
        cursor: 'pointer',
        marginRight: 10,
      },
    },
    opportunityTitle: {
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',

      marginBottom: 10,
    },
    labelTime: {},
    mainInfoTitle: {},
    mainInfoSite: {
      color: theme.$primaryColor1,
      fontSize: 12,
      lineHeight: '16px',
    },
    mainInfoLabels: {},
    linksContainer: {
      padding: '15px 0',
    },
    links: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 0 0 0',
      marginTop: 0,
    },
    linksIcon: {
      position: 'absolute',
      display: 'flex',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    link: {
      fontSize: 12,
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 5,
    },
    tag: {
      padding: '5px 12px',
      background: 'rgba(232, 239, 244, 0.5)',
      borderRadius: '3px',
      fontSize: 12,
      lineHeight: '16px',
      color: '#656771',
      margin: '0 10px',

      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    overall: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      transition: '0.5s all ease',
      overflowY: 'hidden',
      wordBreak: 'break-all',
    },
    overallValue: {
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      resize: 'none',
      overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // display: '-webkit-box',
      // '-webkit-line-clamp': 3,
      // '-webkit-box-orient': 'vertical'
    },
    overallTitle: {
      width: '100%',
      display: 'none',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 0,
    },
    overallBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0 0',
      marginBottom: 10,
    },
    perks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      boxSizing: 'border-box',
      borderRadius: 10,
      marginBottom: 15,
    },
    btnToggle: {},
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    perkItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '10px 20px 15px',
    },
    perkItemBtn: {
      width: 54,
      height: 54,
      borderRadius: 27,
      margin: 0,
      border: 'none',
    },
    perkItemValue: {
      fontSize: 24,
    },
    perkItemName: {},
    demoToggleTitle: {
      maxWidth: '37rem',
      width: '100%',
      cursor: 'pointer',
    },
    toggleText: {
      borderTop: 'none',
      padding: 0,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    toggleTextItem: {},
    viewCompany: {
      maxWidth: 'none',
    },
    bio: {
      margin: '10px 0',
      lineHeight: '21px',
    },
    opportunityMarkdown: {
      borderRadius: 6,
      marginTop: 30,
      padding: '30px',
      background: 'white',
      '& img': {
        maxWidth: '100%',
      },
    },
    aboutOpportunity: {},
    shareButton: {
      background: 'transparent',
      backgroundColor: 'transparent !important',
      border: '1px solid #F65532',
      width: '63px !important',
      height: '53px !important',
      marginLeft: '15px',
    },
  };
});
