import React, { useContext, useEffect, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import LabelList from 'src/components/labels/LabelList/index.web';
import { addSubscription } from '../../../../state/modules/ui';
import { useDispatch } from 'react-redux';

interface ComponentStyles {
  component?: string;
  header?: string;
  body?: string;
  footer?: string;
}

const View = (props: IProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { isMobile, containerStyle, data, toggleLike, toggleBookmark, toggleDislike, view } = props;

  const {
    name,
    cities,
    city,
    sectors = [],
    company_size = { name: '0' },
    bio,
    id,
    is_liked = view === 'likes',
    is_bookmarked = view === 'bookmarks',
    is_disliked = view === 'dislikes',
  } = data;

  const dispatch = useDispatch();

  const refBody = useRef(null);

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    header: `${styles.block} ${styles.header}`,
    body: `${styles.block} ${styles.body}`,
    footer: `${styles.block} ${styles.footer}`,
  };

  const onViewCompany = () => {
    if (view === 'onboarding') return;
    history.push('/company/' + id);
  };

  return (
    <div className={cn.component}>
      <div className={cn.header} onClick={onViewCompany}>
        <div className={styles.logo} />
        <div className={styles.info}>
          <Title size={'h2'} containerStyle={styles.title}>
            {name}
          </Title>
          <TextIcon iconName={'location'} iconSize={14} containerStyle={styles.label} iconStyle={styles.labelIcon}>
            {cities && cities.length ? cities.join(', ') : city || ''}
          </TextIcon>
          {sectors.length ? (
            <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={styles.label} iconStyle={styles.labelIcon}>
              {sectors[0].name}
            </TextIcon>
          ) : null}
        </div>
      </div>
      <div className={cn.body} ref={refBody} onClick={onViewCompany}>
        {view === 'explorer' && (
          <div className={styles.details}>
            <div className={styles.detailsImg} />
            <LabelList list={sectors} visibleCount={3} containerStyle={styles.detailsList} />
          </div>
        )}
        {bio}
      </div>
      <div className={cn.footer}>
        {view === 'explorer'
          ? !is_liked && (
              <ButtonMain
                size={'lg'}
                type={'default_'}
                text={t('Ignore')}
                textStyle={styles.setIgnoreText}
                containerStyle={`${styles.btnMain} ${styles.setIgnore}`}
                onPress={() => toggleDislike(id, !is_disliked)}
              />
            )
          : null}
        {(!view || (view !== 'bookmarks' && view !== 'dislikes')) && !is_disliked ? (
          <>
            {is_liked ? (
              <ButtonMain
                outline
                size={'lg'}
                type={'primary_1'}
                text={t('Subscribed')}
                containerStyle={`${styles.btnMain} ${styles.setInterest}`}
                onPress={() => toggleLike(data, false)}
              />
            ) : (
              <ButtonMain
                size={'lg'}
                type={'primary_1'}
                text={t('Follow company')}
                containerStyle={`${styles.btnMain} ${styles.setInterest}`}
                onPress={() => {
                  dispatch(addSubscription({ id: '' + id, name, type: 'c' }));
                  toggleLike(data, true);
                }}
              />
            )}
          </>
        ) : null}
        {(!view || (view === 'explorer' && !is_liked)) && !is_disliked ? (
          <ButtonIconToggle
            size={'lg'}
            containerStyle={styles.btnMain}
            activeIconName={'bookmark-fill'}
            inactiveIconName={'bookmark'}
            iconSize={20}
            iconColor={'#F65532'}
            active={is_bookmarked}
            onPress={() => toggleBookmark(id, !is_bookmarked)}
          />
        ) : null}
        {(!view || (view === 'explorer' && !is_liked)) && is_disliked ? (
          <ButtonMain
            outline
            size={'lg'}
            type={'primary_1'}
            text={t('Ignored')}
            containerStyle={`${styles.btnMain} ${styles.setInterest}`}
            onPress={() => toggleDislike(id, false)}
          />
        ) : null}
        {view === 'bookmarks' ? (
          <ButtonMain
            outline
            size={'lg'}
            type={'primary_1'}
            text={t('Remove bookmark')}
            containerStyle={`${styles.btnMain} ${styles.setInterest}`}
            onPress={() => toggleBookmark(id, false)}
          />
        ) : null}
        {view === 'dislikes' ? (
          <ButtonMain
            outline
            size={'lg'}
            type={'primary_1'}
            text={t('Ignored')}
            containerStyle={`${styles.btnMain} ${styles.setInterest}`}
            onPress={() => toggleDislike(id, !is_disliked)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default View;
