import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      maxWidth: 'none',
      padding: '0 1rem',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'start',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '0.3rem 1.2rem',
      height: '2.2rem',
      alignItems: 'center',
      color: '#656771',
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      backgroundColor: 'rgba(232, 239, 244, 0.5)',
      border: `1px solid ${theme.$defaultColor1}`,
      borderRadius: 3,
      margin: '0.5rem',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    labelShow: {
      cursor: 'pointer',
      backgroundColor: 'white',
    },
  };
});
