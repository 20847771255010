import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LIKES, BOOKMARKS, DISLIKES } from '../../requests/queries';
import { Context as EventSearchContext } from '../SearchContext/EventSearchContext';
import { Context as OpportunitySearchContext } from '../SearchContext/OpportunitySearchContext';
import { Context as CompaniesSearchContext } from '../SearchContext/CompaniesSearchContext';
import { useTranslation } from 'react-i18next';
import { selectIsReady } from '../../state/modules/session';
import { useSelector } from 'react-redux';
import { Platform } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { Context as ProfileContext } from '../../web/contexts/ProfileContexts/index.web';

interface IProps {
  children: any;
}

const orderDirectionList = [
  { key: 'asc', labelWeb: '↓', labelMob: '↓' },
  { key: 'desc', labelWeb: '↑', labelMob: '↑' },
];

const entityType = {
  c: '1',
  o: '2',
  e: '3',
};

const keyMap = {
  orderKey: {
    likes: 'like_created',
    bookmarks: 'bookmark_created',
    dislikes: 'dislike_created',
  },
  orderLabelWeb: {
    likes: 'Date liked',
    bookmarks: 'Date bookmarked',
    dislikes: 'Date ignored',
  },
};

const fetchMore = async (view: string, foo: any, variables: object) => {
  return await foo({
    variables,
    //@ts-ignore
    updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
      if (!fetchMoreResult || !fetchMoreResult[view] || !fetchMoreResult[view].result.length) {
        return prev;
      }

      return Object.assign({}, prev, {
        [view]: {
          ...fetchMoreResult[view],
          total: prev[view].total,
          result: [...prev[view].result, ...fetchMoreResult[view].result],
        },
      });
    },
  });
};

const Context = createContext({});
Context.displayName = '_LIKES_';

const Provider = (props: IProps) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const isReady = useSelector((state) => selectIsReady(state));
  const [view, setView] = useState<'likes' | 'bookmarks' | 'dislikes'>('likes');
  const [tab, setTab] = useState<string>('0');
  const [searchValue, setSearchValue] = useState<string>('');
  const [order, setOrder] = useState('1');
  const [orderDirection, setOrderDirection] = useState('desc');
  const { setLike: companyLike, setBookmark: companyBookmark, setDislike: companyDislike } = useContext(CompaniesSearchContext);
  const { setLike: opportunityLike, setBookmark: opportunityBookmark } = useContext(OpportunitySearchContext);
  const { setLike: eventLike, setBookmark: eventBookmark } = useContext(EventSearchContext);
  const { profileData } = useContext(ProfileContext);
  const { t } = useTranslation();

  const orderList = useCallback(
    (view: 'likes' | 'bookmarks' | 'dislikes') => [
      { id: '0', key: 'name', labelWeb: 'Name', labelMob: 'Name' },
      {
        id: '1',
        key: keyMap.orderKey[view],
        labelWeb: keyMap.orderLabelWeb[view],
        labelMob: 'Date',
      },
    ],
    [view],
  );

  const tabList = useMemo(
    () =>
      view !== 'dislikes'
        ? [
            { key: '0', value: t('All') },
            { key: '1', value: t('Companies') },
            { key: '2', value: t('Opportunities') },
            { key: '3', value: t('Events') },
          ]
        : [{ key: '0', value: t('All') }],
    [t, view],
  );

  const orderItemL = orderList('likes').find((o) => o.id === order);
  const orderItemB = orderList('bookmarks').find((o) => o.id === order);
  const orderItemD = orderList('dislikes').find((o) => o.id === order);

  const [
    getLikes,
    {
      loading: loadingLikes,
      data: dataLikes = {
        likes: {
          result: [],
          page: 1,
          total: 0,
          limit: 10,
        },
      },
      refetch: refetchLikes,
      fetchMore: fetchMoreLikes,
      called: likesCalled,
    },
  ] = useLazyQuery(LIKES, {
    variables: {
      lang,
      type: tab === '0' ? undefined : tab,
      order: orderItemL ? orderItemL.key : undefined,
      orderDirection,
      search: searchValue,
      limit: 10,
      page: 1,
    },
    fetchPolicy: view !== 'likes' ? 'standby' : 'cache-first',
  });

  const [
    getBookmarks,
    {
      loading: loadingBookmarks,
      data: dataBookmarks = {
        bookmarks: {
          result: [],
          page: 1,
          total: 0,
          limit: 10,
        },
      },
      refetch: refetchBookmarks,
      fetchMore: fetchMoreBookmarks,
      called: bookmarksCalled,
    },
  ] = useLazyQuery(BOOKMARKS, {
    variables: {
      lang,
      type: tab === '0' ? undefined : tab,
      order: orderItemB ? orderItemB.key : undefined,
      orderDirection,
      search: searchValue,
      limit: 10,
      page: 1,
    },
    fetchPolicy: view !== 'bookmarks' ? 'standby' : 'cache-first',
  });

  const [
    getDislikes,
    {
      loading: loadingDislikes,
      data: dataDislikes = {
        dislikes: {
          result: [],
          page: 1,
          total: 0,
          limit: 10,
        },
      },
      refetch: refetchDislikes,
      fetchMore: fetchMoreDislikes,
      called: dislikesCalled,
    },
  ] = useLazyQuery(DISLIKES, {
    variables: {
      lang,
      type: tab === '0' ? undefined : tab,
      order: orderItemD ? orderItemD.key : undefined,
      orderDirection,
      search: searchValue,
      limit: 10,
      page: 1,
    },
    fetchPolicy: view !== 'dislikes' ? 'standby' : 'cache-first',
  });

  const queryData = useMemo(
    () => ({
      likes: dataLikes.likes,
      bookmarks: dataBookmarks.bookmarks,
      dislikes: dataDislikes.dislikes,
    }),
    [dataBookmarks, dataLikes, getDislikes],
  );

  const setSearch = async () => {
    if (view === 'likes') {
      if (likesCalled && refetchLikes) {
        await refetchLikes();
      } else {
        getLikes();
      }
    } else if (view === 'bookmarks') {
      if (bookmarksCalled && refetchBookmarks) {
        await refetchBookmarks();
      } else {
        getBookmarks();
      }
    } else if (view === 'dislikes') {
      if (dislikesCalled && refetchDislikes) {
        await refetchDislikes();
      } else {
        getDislikes();
      }
    }
  };

  const setSearchNext = async () => {
    try {
      if (view === 'likes') {
        //@ts-ignore
        const data = dataLikes[view];
        if (!loadingLikes && data && data.page < Math.ceil(data.total / data.limit)) {
          await fetchMore(view, fetchMoreLikes, {
            lang,
            type: tab === '0' ? undefined : tab,
            order: orderItemL ? orderItemL.key : undefined,
            orderDirection,
            search: searchValue,
            limit: 10,
            page: data.page + 1,
          });
        }
      } else if (view === 'bookmarks') {
        //@ts-ignore
        const data = dataBookmarks[view];
        if (!loadingBookmarks && data && data.page < Math.ceil(data.total / data.limit)) {
          await fetchMore(view, fetchMoreBookmarks, {
            lang,
            type: tab === '0' ? undefined : tab,
            order: orderItemB ? orderItemB.key : undefined,
            orderDirection,
            search: searchValue,
            limit: 10,
            page: data.page + 1,
          });
        }
      } else if (view === 'dislikes') {
        //@ts-ignore
        const data = dataDislikes[view];
        if (!loadingDislikes && data && data.page < Math.ceil(data.total / data.limit)) {
          await fetchMore(view, fetchMoreDislikes, {
            lang,
            type: tab === '0' ? undefined : tab,
            order: orderItemD ? orderItemD.key : undefined,
            orderDirection,
            search: searchValue,
            limit: 10,
            page: data.page + 1,
          });
        }
      }
    } catch (e) {
      console.error('Error while setSearchNext Likes');
    }
  };

  const toggleLike = async (id: string, is_liked: boolean, set: 'c' | 'e' | 'o') => {
    try {
      const additionalUpdate = (cache: any, { data }: { data: any }) => {
        const like = {
          __typename: 'Like',
          id,
          like_type: entityType[set],
        };

        // Because we use complex sorting and filtering using client cache modification is too expansive

        if (set && is_liked) {
          const eventNames = {
            c: 'company_liked',
            o: 'opportunity_applied',
            e: 'event_applied',
          };
          // @ts-ignore
          window.dataLayer.push({
            event: eventNames[set],
            eventProps: {
              account_id: profileData.id,
            },
          });
        }

        if (!is_liked && dataLikes.likes.result.find((item: any) => item.id == id)) {
          const res = cache.modify({
            id: cache.identify(dataLikes.likes),
            fields: {
              result: (cached: any) => cached.filter((item: any) => item.__ref !== cache.identify(like)),
              total: (cached: any) => cached - 1,
            },
          });
        } else if (is_liked && !dataLikes.likes.result.find((item: any) => item.id == id)) {
          if (refetchLikes) {
            refetchLikes();
          }
        }
      };
      // await eventLike(id, is_liked, additionalUpdate);  TODO: WHY THIS WAS NEEDED???
      if (set === 'c') {
        await companyLike(id, is_liked, additionalUpdate);
      } else if (set === 'e') {
        await eventLike(id, is_liked, additionalUpdate);
      } else if (set === 'o') {
        await opportunityLike(id, is_liked, additionalUpdate);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleBookmark = async (id: string, is_bookmarked: boolean, set: 'c' | 'e' | 'o') => {
    try {
      const additionalUpdate = (cache: any, { data }: { data: any }) => {
        const bookmark = {
          __typename: 'Bookmark',
          id,
          bookmark_type: entityType[set],
        };

        // Because we use complex sorting and filtering using client cache modification is too expansive

        if (!is_bookmarked && dataBookmarks.bookmarks.result.find((item: any) => item.id == id)) {
          cache.modify({
            id: cache.identify(dataBookmarks.bookmarks),
            fields: {
              result: (cached: any) => cached.filter((item: any) => item.__ref !== cache.identify(bookmark)),
              total: (cached: any) => cached - 1,
            },
          });
        }
      };

      if (set === 'c') {
        await companyBookmark(id, is_bookmarked, additionalUpdate);
      } else if (set === 'e') {
        await eventBookmark(id, is_bookmarked, additionalUpdate);
      } else if (set === 'o') {
        await opportunityBookmark(id, is_bookmarked, additionalUpdate);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleDislike = async (id: string, is_disliked: boolean, set: 'c' | 'e' | 'o', update?: any) => {
    try {
      const additionalUpdate = (cache: any, result: { data: any }) => {
        const dislike = {
          __typename: 'Dislike',
          id,
          dislike_type: entityType[set],
        };

        if (!is_disliked && dataDislikes.dislikes.result.find((item: any) => item.id == id)) {
          console.log(cache.identify(dislike));
          cache.modify({
            id: cache.identify(dataDislikes.dislikes),
            fields: {
              result: (cached: any) => cached.filter((item: any) => item.__ref !== cache.identify(dislike)),
              total: (cached: any) => cached - 1,
            },
          });
        } else if (is_disliked && !dataDislikes.dislikes.result.find((item: any) => item.id == id)) {
          cache.modify({
            id: cache.identify(dataDislikes.dislikes),
            fields: {
              result: (cached: any) => [...cached, dislike],
              total: (cached: any) => cached + 1,
            },
          });
        }
        if (update) {
          update(cache, result);
        }
      };

      if (set === 'c') {
        await companyDislike(id, is_disliked, additionalUpdate);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setViewOperation = (value: 'likes' | 'bookmarks' | 'dislikes') => {
    setView(value);
    setTab('0');
  };

  useEffect(() => {
    if ((Platform.OS === 'web' && isReady) || Platform.OS !== 'web') {
      setSearch();
    }
  }, [isReady, tab, searchValue, order, orderDirection, view]);

  return (
    <Context.Provider
      value={{
        view,
        setView: setViewOperation,
        tab,
        setTab,
        searchValue,
        setSearchValue,
        order,
        setOrder,
        orderDirection,
        setOrderDirection,
        orderList: orderList(view),
        orderDirectionList,
        tabList,
        //@ts-ignore
        total: queryData[view] ? queryData[view].total : 0,
        //@ts-ignore
        result: queryData[view] ? queryData[view].result : [],
        toggleLike,
        toggleDislike,
        toggleBookmark,
        setSearch,
        setSearchNext,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
