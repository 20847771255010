import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TUIAction, EUIActionTypes } from './ui.types';
import { State } from '../index';
import { IOptionItem } from '../../../requests/models';

export const setDeviceRes = (payload: { key: string; value: boolean }): TUIAction => ({
  type: EUIActionTypes.SET_DEVICE_RES,
  value: payload,
});

export const setModal = (payload: { key?: string; value?: boolean }): TUIAction => ({
  type: EUIActionTypes.SET_MODAL,
  value: payload,
});

export const addSubscription = (payload: IOptionItem): TUIAction => ({
  type: EUIActionTypes.SET_SUBSCRIPTION,
  value: payload,
});

export const removeSubscription = (payload: string): TUIAction => ({
  type: EUIActionTypes.REMOVE_SUBSCRIPTION,
  value: payload,
});

export const settingDeviceRes = (width: number): ThunkAction<void, State, unknown, Action<string>> => async (dispatch) => {
  const res = { key: '', value: true };

  if (width < 375) {
    res.key = 'xs';
  } else if (width >= 375 && width < 1024) {
    res.key = 'sm';
  } else if (width >= 1024 && width < 1279) {
    res.key = 'md';
  } else if (width >= 1280) {
    res.key = 'lg';
  }
  dispatch(setDeviceRes(res));
};
