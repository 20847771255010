import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    btn: {
      padding: '0 10px',
      backgroundColor: 'transparent',
      border: 'none',
    },
    btnContent: {
      justifyContent: 'space-between',
      color: theme.$primaryColor1,
      '& svg': {
        marginLeft: 10,
      },
    },
  };
});
