import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { useStyles } from './styles.web';

import { Props } from './types';

interface ComponentStyles {
  component?: string;
  toggle?: string;
  toggleActive?: string;
}

const Toggle: FC<Props> = (props) => {
  const { containerStyle, active, onPress } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...theme, ...props });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    toggle: `${styles.toggle} ${active ? styles.toggleActive : ''}`.trim(),
  };

  return (
    <div className={cn.component} onClick={onPress}>
      <div className={cn.toggle} />
    </div>
  );
};

export default Toggle;
