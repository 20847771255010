import React, { FC, useContext, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import Header from 'src/web/components/Header';
import OpportunityDesktop from 'src/web/components/Opportunity/Page/Desktop';
import OpportunityMobile from 'src/web/components/Opportunity/Page/Mobile';
import { IProps } from './types';
import { ITheme } from 'src/constant/themes';
import { useLazyQuery } from '@apollo/client';
import styles from './styles.module.scss';
import { OPPORTUNITY } from 'src/requests/queries';
import { useParams } from 'react-router';
import { COMPANY } from 'src/requests/queries';
import { useTranslation } from 'react-i18next';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';
import { Context as LikesContext } from 'src/contexts/LikesContext/index.web';
import { ApplyModal } from 'src/web/components/ApplyModal';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { IOpportunity } from 'src/components/cards/Opportunity/types';
import { useDispatch } from 'react-redux';
import { addSubscription } from 'src/state/modules/ui';

const OpportunityPage: FC<IProps> = (props) => {
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isMobile } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const [
    getOpportunity,
    {
      data = {
        opportunity: {
          name: '',
          company_id: '',
          logo: '',
          company_name: '',
          banner_img: '',
          promo_video: '',
          main_description: '',
          description: '',
          cities: [],
          is_liked: false,
          is_bookmarked: false,
          languages: [],
          experiences: [],
          degrees: [],
          types: [],
          company_size: { id: '', name: '', value: '' },
          sectors: [],
          created: '',
          updated: '',
          deleted: '',
          application_flow: 'MESSAGE',
          redirection_link: undefined,
          application: { redirectUrl: '', message: '' },
        },
      },
      loading: opportunityLoading,
      called: opportunityCalled,
    },
  ] = useLazyQuery(OPPORTUNITY, { variables: { lang, id } });

  const {
    name,
    company_id,
    company_name,
    banner_img,
    promo_video,
    main_description,
    description,
    cities,
    logo,
    languages,
    is_liked,
    is_bookmarked,
    experiences,
    degrees,
    types,
    company_size,
    sectors,
    created,
    updated,
    deleted,
    application_flow,
    redirection_link,
    application,
  } = data.opportunity;

  const [
    getCompany,
    {
      data: companyData = {
        company: {
          id: '',
          website: '',
          logo: '',
          bio: '',
          additional_info: '',
        },
      },
      loading: companyLoading,
      called: companyCalled,
    },
  ] = useLazyQuery(COMPANY, { variables: { lang, id: '' + company_id } });
  const { setApplyModalShown, setEntityForApply, applyModalShown, entityForApply } = useContext(SearchContext);

  const { toggleLike, toggleBookmark }: any = useContext(LikesContext);

  const setOpportunityLike = (undo = false) => {
    if (!application.redirectUrl) dispatch(addSubscription({ id: '' + id, name, type: 'o' }));
    if (application.redirectUrl) {
      window.open(application.redirectUrl);
    }
    toggleLike(id, true, 'o');
  };

  const setOpportunityBookmark = () => {
    toggleBookmark(id, !is_bookmarked, 'o');
  };

  useEffect(() => {
    if (opportunityCalled && !opportunityLoading && !companyCalled) {
      getCompany();
    }
  }, [opportunityCalled, companyCalled, opportunityLoading]);

  useEffect(() => {
    getOpportunity();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Opportunity - ${name}`}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          {opportunityLoading && companyLoading ? (
            <>Loading...</>
          ) : isMobile ? (
            <OpportunityMobile
              id={id}
              name={name}
              logo={logo}
              is_liked={is_liked}
              is_bookmarked={is_bookmarked}
              company_name={company_name}
              banner_img={banner_img}
              company_id={company_id}
              promo_video={promo_video}
              main_description={main_description}
              description={description}
              cities={cities || []}
              languages={languages}
              experiences={experiences}
              degrees={degrees}
              types={types}
              company_size={company_size}
              sectors={sectors}
              created={created}
              updated={updated}
              deleted={deleted}
              setOpportunityLike={setOpportunityLike}
              setOpportunityBookmark={setOpportunityBookmark}
              companyData={companyData.company}
              application_flow={application_flow}
              application={application}
            />
          ) : (
            <OpportunityDesktop
              id={id}
              name={name}
              logo={logo}
              is_liked={is_liked}
              is_bookmarked={is_bookmarked}
              company_name={company_name}
              banner_img={banner_img}
              promo_video={promo_video}
              main_description={main_description}
              description={description}
              cities={cities || []}
              languages={languages}
              experiences={experiences}
              degrees={degrees}
              types={types}
              company_size={company_size}
              sectors={sectors}
              created={created}
              updated={updated}
              deleted={deleted}
              setOpportunityLike={setOpportunityLike}
              setOpportunityBookmark={setOpportunityBookmark}
              companyData={companyData.company}
              application_flow={application_flow}
              application={application}
            />
          )}
        </div>
        {applyModalShown && (
          <ApplyModal
            entity={entityForApply as IOpportunity}
            cancel={() => {
              setApplyModalShown(false);
              setEntityForApply({});
            }}
            confirm={() => {
              setOpportunityLike();
              setApplyModalShown(false);
              setEntityForApply({});
            }}
            text={(entityForApply as IOpportunity)?.application?.message ? (entityForApply as IOpportunity)?.application?.message : ''}
          />
        )}
      </ErrorBoundaries>
    </>
  );
};

export default OpportunityPage;
