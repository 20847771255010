import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    body: (p: {isMobile: boolean}) => ({
      padding: p.isMobile ? '0 15px 30px' : '0 30px 30px',
    }),
    divider: {
      width: '100%',
    },
    title: {
      margin: '1.5rem 0',
    },
    fields: {
      margin: '15px 0 0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    field: {
      marginBottom: '15px',
      width: '100%',
    },
    fieldHalf: (p) => ({
      width: p.isMobile ? '100%' : 'calc(50% - 7.5px)',
      marginBottom: '15px',
    }),
    fieldInput: () => ({
      maxWidth: 'none',
    }),
    fieldLabel: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '0.5rem 0',
    },
    buttonSwitcher: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonSwitcherLeft: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      maxWidth: 'none',
    },
    buttonSwitcherRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      maxWidth: 'none',
    },
    wrapper: {
      marginBottom: '15px',
      float: 'left',
      position: 'relative',
      '&:hover .tooltip': {
        display: 'inline-block',
      },
    },
    tooltip: {
      bottom: '100%',
      left: '100%',
      display: 'none',
      width: 'max-content',
      padding: '10px 20px',
      position: 'absolute',
      fontSize: '1rem',
      background: 'white',
      boxShadow: 'rgb(48 70 89 / 8%) 0px 5px 10px',
      borderRadius: '5px',
    },
    label: {
      justifyContent: 'flex-start',
      overflow: 'hidden',
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    labelOther: {
      color: '#282828',
      fontWeight: 500,
      maxWidth: 120,
      opacity: 1,
      '& svg *': {
        fill: theme.$primaryColor1,
      },
    },
    labelIcon: {},
    fieldName: {
      marginRight: 5,
    },
  };
});
