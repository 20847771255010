const colors = {
  REGULAR: '#F65532',
  PRIMARY: '#F65532',
  BORDER: '#E3EAEF',
  CLOSE_ICON: '#ACB7BF',
  ICON: '#DADADA',
  ICON_ACTIVE: '#F65532',
  ICON_BACKGROUND: 'rgba(246, 85, 50, 0.03)',
  BACKGROUND: 'rgb(245,246,248)',
  BACKGROUND_HINT: 'rgba(232, 239, 244, 0.5)',
  BACKGROUND_GROUP: 'rgba(232, 239, 244, 0.5)',
  REGULAR_TEXT: '#282828',
  DESCRIPTION_TEXT: '#656771',
  TEXT: '#656771',
  INPUT_COLOR: '#222222',
  HEADER_BACKGROUND: '#FFFFFF',
  HEADER_ICON_INACTIVE: '#222222',
  HEADER_ICON_ACTIVE: '#F65532',
  BUTTON_NOT_ACTIVE: '#F3F4F5',
  BUTTON_ACTIVE: '#F65532',
  BUTTON_COMBINE_OUTLINE: '#222222',
  BUTTON_SELECTOR_IS_SELECTED: '#ACB7BF',
  DEFAULT_CONTAINER_BANNER: '#FFFFFF',
  BLACK: '#000000',
  SUCCESS_GREEN: '#22C476',
  FAIL_RED: '#F65532',
};

export default colors;
