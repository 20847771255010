import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    body: (p: {isMobile: boolean}) => ({
      padding: p.isMobile ? '0 15px 30px' : '0 30px 30px',
    }),
    divider: {
      width: '100%',
    },
    title: {
      margin: '1.5rem 0',
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      margin: '15px 0 0 0',
    },
    listItem: {
      margin: '5px 15px 5px 0',
    },
    listItemText: {
      //color: '#282828'
    },
    footer: (p: {isMobile: boolean}) => ({
      width: '100%',
      padding: p.isMobile ? '0 15px' : '0 30px',
    }),
    agreement: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '15px 0 30px 0',
    },
    agreementText: {
      opacity: 1,
    },
    link: {
      color: theme.$primaryColor1,
      fontSize: '1.2rem',
      fontWeight: '500',
      fontFamily: 'Inter, sans-serif',
      cursor: 'pointer',
    },
  };
});
