import React, { useContext } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { FlatList, View } from 'react-native';
import Title from 'src/components/titles/Title/index.web';
import Item from '../Item';
import { Context as MessagesContext } from 'src/contexts/MessagesContext/index.web';
import Text from 'src/components/texts/Text/index.web';
import img from 'src/assets/images/bermuda-no-messages.png';

const List = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle } = props;

  const { t } = useTranslation();
  const { result, searchValue, setSearchNext, chosenConversation, setChosenConversation }: any = useContext(MessagesContext);

  return (
    <div className={`${styles.component} ${containerStyle}`}>
      <View style={{ width: '100%', height: '100%' }}>
        <FlatList
          key={1}
          data={result}
          numColumns={1}
          ListEmptyComponent={
            <div className={styles.noResults}>
              <img src={img} className={styles.noResultsImg} />
              <Title size={'h2'} containerStyle={styles.noResultsTitle}>
                {t('No messages yet')}
              </Title>
              <Text
                containerStyle={styles.noResultsText}
                type={'default'}
                text={t(
                  'You have no incoming messages. Companies will send invitations to like their page, events or opportunities if they find your profile interesting.',
                )}
              />
            </div>
          }
          extraData={[isMobile, searchValue]}
          onEndReached={setSearchNext}
          onEndReachedThreshold={0.01}
          contentContainerStyle={{ flexGrow: 1 }}
          renderItem={({ item, index }: { item: any; index: number }) => {
            return searchValue.includes(item.name.toLowerCase()) ? null : (
              <Item
                key={index}
                isMobile={isMobile}
                data={item}
                active={chosenConversation ? item.id === chosenConversation.id : false}
                onPress={() => setChosenConversation(item.id)}
              />
            );
          }}
        />
      </View>
    </div>
  );
};

export default List;
