import React, { FC } from 'react';
import Title from '../../../components/titles/Title/index.web';
import Icon from '../../../components/icons/Icon/index.web';
import { Props } from './types.web';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  icon?: string;
}

const TitleIcon: FC<Props> = (props) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });
  const { size, children, containerStyle, iconStyle, iconSize = 20, iconColor = theme.$primaryColor1, iconName } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    icon: `${styles.icon} ${iconStyle}`.trim(),
  };

  return (
    <Title size={size} containerStyle={cn.component}>
      {iconName ? (
        <div className={cn.icon}>
          <Icon name={iconName} size={iconSize} color={iconColor} />
        </div>
      ) : null}
      {children}
    </Title>
  );
};

export default TitleIcon;
