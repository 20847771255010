import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Link from 'src/components/links/Link/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from 'src/constant/themes';
import Avatar from '../../../Avatar';
import Divider from 'src/components/dividers/Divider/index.web';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import ButtonTransparentToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfileCV, selectProfileCVChange } from 'src/state/modules/user';
import Modal from 'src/web/components/Modal';
import ProfileCV from 'src/web/components/ProfileCV';

interface ComponentStyles {
  isMobile?: boolean;
  component?: string;
  name?: string;
  university?: string;
  links?: string;
  overall?: string;
}

const links = [
  { key: 'Facebook', value: 'https://facebook.com' },
  { key: 'Instagram', value: 'https://instagram.com' },
  { key: 'LinkedIn', value: 'https://linkedin.com' },
  { key: 'GitHub', value: 'https://github.com' },
];

const imageFormats = ['image/jpeg', 'image/png'];
const imageSize = 5;

const Main = (props: IProps) => {
  const theme: any = useTheme();
  const { containerStyle, onEdit, data, setAvatar, avatarData, isMobile, setCV, CVData, removeCV } = props;
  const { first_name, last_name, education_level = { name: '' }, city, institutes, short_bio, social_media, is_active } = data;
  const styles = useStyles({ ...props, ...theme, isActive: is_active });
  const { image_url, loading: avatarLoading } = avatarData;
  const { resumeUrl, loading: CVLoading } = CVData;

  const { name: instituteName, graduation_date, subject_name } = institutes[institutes.length - 1];

  const [textToggleVisible, setTextToggleVisible] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [showError, setShowError] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const profileCVChange = useSelector((state) => selectProfileCVChange(state));

  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  const modalClose = () => {
    dispatch(changeProfileCV());
    setModal(false);
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxSize: imageSize * 1024 * 1024,
    accept: imageFormats.join(','),
    onDropRejected: (e) => {
      setShowError(true);
      console.error(e);
    },
  });

  const isStudent = moment(graduation_date).diff(Date.now()) > 0 ? t('Student') : t('Graduated');

  //const graduationDate = `${t('Since')} ${moment(graduation_date).year()}`;

  useEffect(() => {
    if (acceptedFiles.length) {
      setAvatar(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (profileCVChange) {
      setModal(true);
    }
    return () => {
      setModal(false);
    };
  }, [profileCVChange]);

  useEffect(() => {
    if (textAreaRef.current) {
      if (textAreaRef.current.scrollHeight > textAreaRef.current.offsetHeight) {
        setTextToggleVisible(true);
      } else {
        setTextToggleVisible(false);
      }
    }
  }, [short_bio]);

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    name: `${styles.item} ${styles.name}`.trim(),
    university: `${styles.item} ${styles.university}`.trim(),
    links: `${styles.item} ${styles.links}`.trim(),
    overall: `${styles.item} ${styles.overall}`.trim(),
  };

  const readMoreOpenedHeight = textAreaRef.current !== null ? (textAreaRef.current.scrollHeight <= 300 ? textAreaRef.current.scrollHeight : 300) : 300;

  return (
    <>
      <div className={cn.component}>
        <ButtonMain
          type={'primary_1'}
          size={'xl'}
          containerStyle={styles.editLink}
          textStyle={styles.editLinkText}
          iconName={'edit'}
          iconSize={20}
          iconColor={theme.$primaryColor1}
          onPress={() => onEdit(true)}
        />
        <div style={{ position: 'relative' }} {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div onClick={open} className={styles.dropZoneImageContainer}>
            <Avatar src={image_url} size={'120px'} containerStyle={styles.avatar} />
            <div className={styles.dropZoneImage}>
              <div className={styles.dropZoneImageInner}>Open</div>
            </div>
          </div>
          {avatarLoading ? (
            <div className={styles.avatarLoader}>
              <Loader type="Oval" color={theme.$primaryColor1} height={50} width={50} />
            </div>
          ) : (
            ''
          )}
          {showError ? (
            <div className={styles.avatarError} onDoubleClick={() => setShowError(false)}>
              <div className={styles.close} onClick={() => setShowError(false)} />
              <p>{`${t('Acceptable formats')}:  ${imageFormats.join(', ')}`}</p>
              <br />
              <p>{t('The file you have uploaded exceeds the limit of size MB', { size: imageSize })}</p>
            </div>
          ) : null}
        </div>
        <div className={styles.titleContainer} title={`${first_name} ${last_name}`}>
          <Title size={'h2'} containerStyle={cn.name}>
            {first_name}
          </Title>
          <Title size={'h2'} containerStyle={cn.name}>
            {last_name}
          </Title>
        </div>
        <div className={styles.item}>
          <div className={styles.who}>{education_level.name}</div>
        </div>
        <div className={`${styles.item} ${styles.location}`}>
          <TextIcon key={'location'} iconName={'location'}>
            {city}
          </TextIcon>
        </div>
        <Text type={'secondary'} containerStyle={cn.university}>
          {isStudent}, {subject_name}, {instituteName}
        </Text>
        <Divider size={'xl'} containerStyle={styles.item} />
        <div className={cn.links}>
          {social_media ? (
            social_media.map((link: any) =>
              link.url ? (
                <Link key={link.type} containerStyle={styles.link} internal={false} href={link.url} size={'xs'}>
                  {link.type}
                </Link>
              ) : null,
            )
          ) : (
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              containerStyle={styles.addLink}
              textStyle={styles.addLinkText}
              onPress={() => onEdit(true, 'socials')}
              text={`+ ${t('Add social media')}`}
            />
          )}
          {/*<div className={styles.linksIcon}><Icon name={'link'} size={20}/></div>*/}
        </div>
        {isMobile ? <Divider size={'xl'} /> : null}
        <div className={`${styles.title} ${styles.overallTitleContainer}`}>
          <Icon size={18} name={'text'} color={theme.$primaryColor1} />
          <Title size={'h1'} containerStyle={styles.overallTitle}>
            {t('About me')}
          </Title>
        </div>
        <div className={cn.overall} style={{ height: readMore ? readMoreOpenedHeight : 74, overflowY: readMore ? 'auto' : 'hidden' }}>
          {short_bio ? (
            <textarea className={`${styles.overallValue} ${readMore ? styles.overallValueFull : ''}`} value={short_bio} disabled ref={textAreaRef} />
          ) : (
            <>
              <Text type={'secondary'} containerStyle={styles.overallDummyText}>
                {t('Tell a bit about yourself, write a short paragraph on your strengths and weaknesses. Include what you feel like!')}
              </Text>
              <ButtonMain
                type={'primary_1'}
                size={'xl'}
                containerStyle={styles.addLink}
                textStyle={styles.addLinkText}
                onPress={() => onEdit(true, 'bio')}
                text={`+ ${t('Add short bio')}`}
              />
            </>
          )}
        </div>

        {short_bio && (readMore ? true : textToggleVisible) ? (
          <div className={styles.overallBottom}>
            <ButtonTransparentToggle active={readMore} text={readMore ? t('Show less') : t('Read more')} onPress={() => setReadMore(!readMore)} />
          </div>
        ) : null}
        <div className={cn.overall} style={{ marginTop: 15 }}>
          {!resumeUrl ? (
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              containerStyle={styles.addLink}
              textStyle={styles.addLinkText}
              onPress={() => {
                dispatch(changeProfileCV(true));
              }}
              text={`+ ${t('Upload personal CV')}`}
            />
          ) : (
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              containerStyle={styles.addLink}
              textStyle={styles.addLinkText}
              iconName={'file'}
              iconSize={20}
              iconColor={theme.$primaryColor1}
              onPress={() => {
                dispatch(changeProfileCV(true));
              }}
              text={t('Personal CV')}
            />
          )}
        </div>
        <Modal wrapperStyles={styles.modal} isModalOpened={modal} onClose={modalClose}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <Title size={'h1'} containerStyle={styles.title} onPress={modalClose}>
                <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Upload personal CV')}
              </Title>
            </div>
            <div className={styles.modalMain}>
              <ProfileCV containerStyle={styles.modalGrade} profile_cv={resumeUrl} loading={CVLoading} setCV={setCV} removeCV={removeCV} />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Main;
