import React, { useContext, useMemo, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Title from '../../../../../components/titles/Title/index.web';
import TitleIcon from '../../../../../components/titles/TitleIcon/index.web';
import { ITheme } from '../../../../../constant/themes';
import ButtonToggle from '../../../../../components/buttons/ButtonRoundedToggle/index.web';
import Devider from '../../../../../components/dividers/Divider/index.web';
import Select from '../../../../../components/inputs/Select/index.web';
import { IOptionItem } from '../../../../../requests/models';
import { Storage } from '../../../../../utilities/Storage';
import { Platform } from 'react-native';
import { useQuery } from '@apollo/client';
import { INTERESTS } from '../../../../../requests/queries';
import { Context as ProfileContext } from '../../../../../web/contexts/ProfileContexts/index.web';
import ButtonMain from '../../../../../components/buttons/ButtonMain/index.web';
import { useDispatch } from 'react-redux';
import { addSubscription } from '../../../../../state/modules/ui';

const storage = new Storage(Platform.OS !== 'web');

const Account = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { title } = props;
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const { interests: defaultInterests, contactEmail, setData }: any = useContext(ProfileContext);
  const { data, loading, error } = useQuery(INTERESTS, { variables: { lang } });
  const [language, setLanguage] = useState(lang);
  const [interests, setInterests] = useState(defaultInterests);

  const dispatch = useDispatch();

  const onSelectInterest = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setInterests(interests.concat([item]));
    } else if (action === 'rem') {
      setInterests(interests.filter((i: IOptionItem) => i.name !== item.name).slice());
    }
  };

  const list: IOptionItem[] = useMemo(
    () => [
      { id: 'en', name: t('English') },
      { id: 'fr', name: t('Français') },
      { id: 'nl', name: t('Nederlands') },
    ],
    [t],
  );

  const onSelectLanguage = (value: IOptionItem) => {
    setLanguage(value.id);
  };

  const onSavePress = async () => {
    try {
      await setData({ interests, contact_email: contactEmail });
      await i18n.changeLanguage(language);
      await storage.setUserLanguage(language);
      dispatch(addSubscription({ id: '', name: '', type: 'd' }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.component}>
      <TitleIcon size={'h2'} iconName={'settings'} iconColor={theme.$primaryColor1} containerStyle={styles.tabTitle}>
        {t(title)}
      </TitleIcon>
      <Devider size={'xl'} />
      <div className={styles.componentItem}>
        <Title size={'h2'} containerStyle={styles.itemTitle}>
          {t('Language')}
        </Title>
        <Select value={list.find((l: IOptionItem) => l.id === language)} containerStyle={styles.select} list={list} onChoose={onSelectLanguage} noInput />
      </div>
      <div className={styles.componentItemDevider}>
        <Devider size={'xl'} />
      </div>
      <div className={styles.componentItem}>
        <Title size={'h2'} containerStyle={styles.itemTitle}>
          {t('Interests')}
        </Title>
        <div className={styles.interests}>
          {!loading &&
            !error &&
            data?.interests?.map((interestItem: IOptionItem) => {
              const active = !!interests.find((a: IOptionItem) => a.name === interestItem.name);
              return (
                <ButtonToggle
                  key={interestItem.name}
                  size={'lg'}
                  icon
                  active={active}
                  containerStyle={styles.interest}
                  text={interestItem.name}
                  onPress={() => onSelectInterest(interestItem, active ? 'rem' : 'add')}
                />
              );
            })}
        </div>
      </div>
      <div className={styles.componentItem}>
        <ButtonMain type={'primary_1'} size={'xl'} text={t('Save')} containerStyle={styles.saveBtn} onPress={onSavePress} />
      </div>
    </div>
  );
};

export default Account;
