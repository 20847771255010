import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  const componentSize = {
    xl: '5.4rem',
    lg: '4.4rem',
  };

  const radiusSize = {
    xl: 10,
    lg: 8,
  };

  return {
    component: (p: IProps) => {
      const { size } = p;

      return {
        width: '100%',
        maxWidth: componentSize[size],
        height: componentSize[size],
        backgroundColor: theme.$primaryColor1a05,
        border: 'none',
        borderRadius: radiusSize[size],
        '&:hover': {
          opacity: 0.9,
        },
      };
    },
    icon: {},
  };
});
