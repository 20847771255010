import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-jss';
import Header from 'src/web/components/Header';
import Likes from 'src/web/components/Likes';
import { IProps } from './types';
import { ITheme } from 'src/constant/themes';
import styles from './styles.module.scss';
import { Context as LikesContext } from 'src/contexts/LikesContext/index.web';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';

let flag = false;

const LikesPage = React.forwardRef((props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();

  const { isMobile } = props;

  const ref = useRef(window);

  const { setSearchNext, result }: any = useContext(LikesContext);

  const onScroll = useCallback(
    (e: any) => {
      e.stopPropagation();
      const c = document.documentElement;
      if (!flag && c.scrollTop + window.innerHeight >= c.scrollHeight - 10) {
        flag = true;
        setTimeout(() => {
          flag = false;
        }, 500);
        setSearchNext();
      }
    },
    [result, setSearchNext],
  );

  useEffect(() => {
    //@ts-ignore
    if (ref && ref.current) {
      //@ts-ignore
      ref.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (ref && ref.current) {
        //@ts-ignore
        ref.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [onScroll, ref]);

  return (
    <>
      <Helmet>
        <title>{'Likes'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          <Likes isMobile={isMobile} />
        </div>
      </ErrorBoundaries>
    </>
  );
});

export default LikesPage;
