import React, { FC } from 'react';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import { useStyles } from './styles.web';
import { ITheme } from 'src/constant/themes';
import Icon from 'src/components/icons/Icon/index.web';

interface ComponentStyles {
  component?: string;
}

const TextBlock: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });
  const { text, containerStyle } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
  };

  return (
    <div className={cn.component}>
      <div>
        <Icon size={20} name={'text'} color={theme.$secondaryColor2} />
      </div>
      <div className={styles.textContainer}>{text}</div>
    </div>
  );
};

export default TextBlock;
