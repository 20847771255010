import React, { ReactElement, useEffect, useState, Suspense } from 'react';
import { Provider as ReduxStateProvider } from 'react-redux';
import WithWidth from '../containers/WithWidth';
import { initStore } from 'src/state/store';
import Root from './root';
import '../i18n';
import '../assets/scss/_main.scss';
import { init as SentryInit } from '../utilities/sentry';

SentryInit();

const App = (): ReactElement => {
  return (
    <Suspense fallback={() => ''}>
      <ReduxStateProvider store={initStore()}>
        <WithWidth>
          <Root />
        </WithWidth>
      </ReduxStateProvider>
    </Suspense>
  );
};

export default App;
