import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    body: (p: {isMobile: boolean}) => ({
      padding: p.isMobile ? '0 15px 30px' : '0 30px 30px',
    }),
    divider: {
      width: '100%',
    },
    title: {
      margin: '1.5rem 0',
    },
    fields: {
      margin: '15px 0 0',
    },
    field: {
      marginBottom: '15px',

      '&:last-child': {
        marginBottom: 0,
      },
    },
    fieldInput: {
      maxWidth: '100% !important',
      width: '100% !important',
    },
    fieldLabel: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '0.5rem 0',
    },
    languages: (p) => ({
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    }),
    language: {
      margin: '10px 10px 0 0',
    },
    langInput: {
      marginTop: 15,
    },
  };
});
