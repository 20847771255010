export interface UserState {
  data: any;
  token: string | null;
  prevPage: number;
  currentPage: number;
  isOComplete: boolean;
  refreshToken: string | null;
  isLogged: boolean;
  gradeProofFlag: number | undefined;
  profileCVFlag: boolean | undefined;
  unreadMessagesCount: number;
}

export enum EUIActionTypes {
  SET_VALUE = '@user/SET_VALUE',
  SET_USER_DATA = '@user/SET_USER_DATA',
  SET_LOGOUT = '@user/SET_LOGOUT',
  CHANGE_GRADE_PROOF_FLAG = '@user/CHANGE_GRADE_PROOF_FLAG',
  CHANGE_PROFILE_CV_FLAG = '@user/CHANGE_PROFILE_CV_FLAG',
}

export type TSetValueAction = {
  type: EUIActionTypes.SET_VALUE;
  value: { key: string; value: any };
};

export type TSetDataValueAction = {
  type: EUIActionTypes.SET_USER_DATA;
  value: { key: string; value: any };
};

export type TSetLogout = {
  type: EUIActionTypes.SET_LOGOUT;
};

export type TSetChangeGradeProof = {
  type: EUIActionTypes.CHANGE_GRADE_PROOF_FLAG;
  value: number | undefined;
};

export type TSetChangeProfileCV = {
  type: EUIActionTypes.CHANGE_PROFILE_CV_FLAG;
  value: boolean | undefined;
};

export type TUIAction = TSetValueAction | TSetDataValueAction | TSetLogout | TSetChangeGradeProof | TSetChangeProfileCV;
