import React from 'react';
import { IProps, IDesktopWrapper } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import CompanyItem from 'src/web/components/Company/Item';
import OpportunityItem from 'src/web/components/Opportunity/Item';
import EventItem from './EventItem';
import Title from 'src/components/titles/Title/index.web';
import WhatsNew from 'src/web/components/Explore/WhatsNew';

const List = (props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const {
    isGlobal,
    isMobile,
    toggleCompanyLike,
    toggleOpportunityLike,
    toggleEventLike,
    toggleCompanyBookmark,
    toggleOpportunityBookmark,
    toggleEventBookmark,
    toggleCompanyDislike,
    result,
  } = props;
  const { t } = useTranslation();

  const DesktopWrapper = ({ children }: IDesktopWrapper) =>
    isGlobal && !isMobile ? <div style={{ margin: '0px 0px 15px 15px', width: 'calc(50% - 15px)' }}>{children}</div> : children;

  return (
    <>
      <div className={styles.component}>
        {result.length ? (
          result.map((item: any, index: number) => {
            if (item.type === 'company') {
              return (
                <React.Fragment key={`${item.type}_${item.id}_${index}`}>
                  {isMobile && index === 1 && <WhatsNew isMobile={isMobile} containerStyle={result.length > 1 ? styles.marginBottom : ''} />}
                  <DesktopWrapper>
                    <CompanyItem
                      isMobile={isMobile}
                      data={item}
                      view={'explorer'}
                      containerStyle={styles.item}
                      toggleDislike={toggleCompanyDislike}
                      toggleLike={toggleCompanyLike}
                      toggleBookmark={toggleCompanyBookmark}
                    />
                  </DesktopWrapper>
                </React.Fragment>
              );
            } else if (item.type === 'opportunity') {
              return (
                <React.Fragment key={`${item.type}_${item.id}_${index}`}>
                  {isMobile && index === 1 && <WhatsNew isMobile={isMobile} containerStyle={result.length > 1 ? styles.marginBottom : ''} />}
                  <DesktopWrapper>
                    <OpportunityItem
                      isMobile={isMobile}
                      data={item}
                      view={'explorer'}
                      containerStyle={styles.item}
                      toggleLike={toggleOpportunityLike}
                      toggleBookmark={toggleOpportunityBookmark}
                    />
                  </DesktopWrapper>
                </React.Fragment>
              );
            } else if (item.type === 'event') {
              return (
                <React.Fragment key={`${item.type}_${item.id}_${index}`}>
                  {isMobile && index === 1 && <WhatsNew isMobile={isMobile} containerStyle={result.length > 1 ? styles.marginBottom : ''} />}
                  <DesktopWrapper>
                    <EventItem
                      isMobile={isMobile}
                      data={item}
                      view={'explorer'}
                      containerStyle={styles.item}
                      toggleLike={toggleEventLike}
                      toggleBookmark={toggleEventBookmark}
                    />
                  </DesktopWrapper>
                </React.Fragment>
              );
            } else {
              return null;
            }
          })
        ) : (
          <Title size={'h2'} containerStyle={styles.noResults}>
            {t('No data found')}
          </Title>
        )}
      </div>
    </>
  );
};

export default List;
