import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      position: 'relative',
    },
    fields: {
      margin: '30px 0 0',
    },
    field: {
      marginBottom: '15px',
    },
    fieldInput: {
      maxWidth: 'none',
    },
    fieldLabel: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '0.5rem 0',
    },
    grades: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '30px',
    },
    grade: {
      marginRight: '10px',

      '&:last-child': {
        marginRight: 0,
      },
    },
    remove: {
      position: 'absolute',
      top: 8,
      right: 0,
      display: 'inline-flex',
      alignItems: 'center',
      padding: '5px 10px',
      cursor: 'pointer',
    },
    removeText: {
      color: theme.$primaryColor1,
      marginLeft: 9,
    },
  };
});
