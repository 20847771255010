import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';
import { node } from 'prop-types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: (p: IProps) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      padding: p.isMobile ? '15px' : '30px',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: '10px',
      overflow: 'hidden',
    }),
    item: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }),
    title: (p: IProps) => ({
      width: '100%',
      justifyContent: 'space-between',
      margin: 0,
    }),
    titleText: {
      margin: 0,
    },
    titlePercent: {},
    progress: {
      maxWidth: 'none',
      marginBottom: 20,
    },
    needToComplete: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },

    needToCompleteItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 35,
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${theme.$borderColor}`,
      maxWidth: 'none',

      '&:last-child': {
        border: 'none',
      },
    },

    needToCompleteItemNumber: {
      color: '#656771',
    },
    addLink: (p: IProps) => ({
      width: '100%',
      maxWidth: 'none',
      height: 'auto',
      top: p.isMobile ? 15 : 30,
      right: p.isMobile ? 15 : 30,
      borderColor: 'transparent',
      background: 'transparent',
    }),
    addLinkContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    addLinkText: {
      position: 'relative',
      color: theme.$primaryColor1,
      paddingLeft: 20,
      textAlign: 'left',

      '&:after': {
        content: '"+"',
        position: 'absolute',
        top: 0,
        left: 0,
        color: theme.$primaryColor1,
      },
    },
  };
});
