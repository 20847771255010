import moment from 'moment';

export const validateName = (text: string): boolean => {
  if (text.length <= 65) {
    return /^[^\d+=,.*!@#$%^&()\:;"?{}\[\]_|~<>№]*$/g.test(text);
  }
  return false;
};

export const validatePhone = (phone: string): boolean => {
  if (phone.length === 0) return true;
  const numLength = phone.replace(/\D/g, '').length;
  if (numLength < 8 || numLength > 15) return false;

  return /^\+?[0-9\s]*$/g.test(phone);
};

export const validateInput = (text: string): boolean => {
  if (text.length <= 65) {
    return /^[^\d+=,.*!@#$%^&()\-:;"?`{}\[\]_|~<>№]*$/g.test(text);
  }
  return false;
};

export const validateUniversity = (text: string): boolean => {
  if (text.length <= 90) {
    return /^[^\d+=*!@#$%^&"?`{}\[\]_|~<>№]*$/g.test(text);
  }
  return false;
};

export const validateEmail = (text: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
    text,
  );

//Get validation fro date min and max
export const validateDate = (currentDate: any, minDate: any, maxDate: any): boolean => {
  return currentDate.isBetween(minDate.toString(), maxDate.toString());
};

//Get user with age from 16 to 100 year
export const validateDateOfBorn = (selectedDate: any): boolean => {
  const minDate = moment()
    .year(moment().year() - 100)
    .toString();
  const maxDate = moment()
    .day(moment().day() - 1)
    .toString();
  return moment(selectedDate).isBetween(minDate, maxDate);
};

export const validatePhoneNumber = (text: string): boolean => {
  if (text?.length === 0) return true;
  return /^\+?[[0-9]*$/.test(text);
};
