import React, { FC, useContext, useEffect } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import LinkArrow from 'src/components/links/LinkArrow/index.web';
import Divider from 'src/components/dividers/Divider/index.web';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';

interface IProps {
  isMobile: boolean;
  children?: any;
}

const OnboardingLast: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const history = useHistory();
  const styles = useStyles({ ...props, ...theme });
  const { onRefresh: onProfileRefresh, profileData }: any = useContext(ProfileContext);
  const { t } = useTranslation();

  const onEditHandle = () => {
    history.push('/profile');
  };

  useEffect(() => {
    // @ts-ignore
    const dataLayerExists = window?.dataLayer?.find((o) => o.event === 'onboarding_successful');
    if (!dataLayerExists) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'onboarding_successful',
        eventProps: {
          account_id: profileData.id,
        },
      });
    }
    onProfileRefresh();
  }, []);

  return (
    <>
      <div className={styles.body}>
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Add more details to your profile')}
        </Title>
        <Text
          type={'default'}
          text={`${t('Complete your profile with a picture, a golden paragraph and few other details to be found much easier by our companies.')}`}
        />
        <ButtonMain
          type={'primary_1'}
          size={'xl'}
          text={t('Edit my profile')}
          iconName={'edit'}
          containerStyle={styles.editButton}
          outline
          onPress={onEditHandle}
        />
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Explore companies')}
        </Title>
        <Text
          type={'default'}
          text={`${t(
            'While Launch will make sure companies will contact you based on your profile, you can also explore the possibilities and show interest yourself.',
          )}`}
        />
        <LinkArrow internal containerStyle={styles.nextLink} href={'/explore'} text={t('Check out best matching companies')} />
      </div>
    </>
  );
};

export default OnboardingLast;
