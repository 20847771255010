import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import SearchInput from 'src/components/inputs/SearchInput/index.web';
import ButtonFilter from 'src/components/buttons/ButtonFilter/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/inputs/Select/index.web';
import { IOptionItem } from 'src/requests/models';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Modal from 'src/web/components/Modal/Modal';
import FilterMobile from './Mobile';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { Context as GlobalSearchContext } from 'src/contexts/SearchContext/GlobalSearchContext';
import { useHistory } from 'react-router';

const View = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle, clearScroll, isModalOpen, toggleModal } = props;
  const { t } = useTranslation();
  const [timerObject] = useState<any>({ timeout: undefined });

  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies_explore') },
    { id: '2', name: 'events', label: t('Events_explore') },
    { id: '3', name: 'opportunities', label: t('Opportunities_explore') },
  ];
  const history = useHistory();
  const { filters, setFilters, setEntity }: any = useContext(SearchContext);
  const { setSearchStart, setClear, loading, searchValue }: any = useContext(GlobalSearchContext);
  const [stateSearchValue, setStateSearchValue] = useState(searchValue);

  const { entity } = filters;
  const onSearchValueChange = (value: string) => {
    setStateSearchValue(value);
    setFilters('searchValue', value);
  };

  const onSearchValueChangeMob = (value: string) => {
    setFilters('searchValue', value);
    const localTimeout = setTimeout(() => {
      if (localTimeout === timerObject.timeout) {
        setSearchStart();
      }
    }, 1500);
    timerObject.timeout = localTimeout;
  };

  const onChooseEntity = (entity: IOptionItem) => {
    history.push(`/search/${entity.name !== 'everything' ? entity.name : 'all'}`);
    clearScroll();
    setFilters('entity', entity);
    setFilters('page', 1);
    setEntity(entity);
  };

  const onSubmit = () => {
    clearScroll();
    setSearchStart();
  };

  const onClear = () => {
    setClear();
    clearScroll();
    setSearchStart();
  };

  const onFilterPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    return () => {
      setClear();
    };
  }, []);

  useEffect(() => {
    setStateSearchValue(searchValue);
  }, [searchValue]);

  return isMobile ? (
    <>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div style={{ display: 'flex' }}>
          <SearchInput value={stateSearchValue} placeholder={t('Search')} onChange={onSearchValueChangeMob} />
          <ButtonFilter size={'xl'} containerStyle={styles.filterBtn} active={isModalOpen} onPress={() => toggleModal(!isModalOpen)} />
        </div>
      </div>
      <Modal isModalOpened={isModalOpen} onClose={() => toggleModal(false)}>
        {!loading && <FilterMobile isMobile={isMobile} onClose={() => toggleModal(false)} />}
      </Modal>
    </>
  ) : (
    <div className={`${styles.container} ${containerStyle}`.trim()} tabIndex={1} onKeyPress={onFilterPress}>
      {!loading && (
        <div className={styles.body}>
          <Title size={'h1'} containerStyle={styles.filterTitle}>
            {t('Search')}
          </Title>
          <div className={styles.filterBlockSearchInput}>
            <SearchInput value={stateSearchValue} placeholder={t('Search')} onChange={onSearchValueChange} />
          </div>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={onChooseEntity}
            noInput
          />
        </div>
      )}
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default View;
