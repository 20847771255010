import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

// @ts-ignore
export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: (p: IProps) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      padding: p.isMobile ? '15px' : '26px',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: '10px',
      overflow: 'hidden',
    }),
    item: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '10px 0 0',
      maxWidth: '100%',
    }),
    editLink: (p: IProps) => ({
      position: 'absolute',
      height: 'auto',
      top: p.isMobile ? 15 : 30,
      right: p.isMobile ? 15 : 30,
      borderColor: 'transparent',
      width: 'auto',
      background: 'transparent',
    }),
    editLinkText: {
      color: theme.$primaryColor1,
    },
    avatar: (p: { isActive?: boolean }) => ({
      borderRadius: '50%',
      border: `3px solid ${p.isActive ? '#22C576' : '#F65532'}`,

      '& svg': {
        width: 60,
        height: 60,
      },
    }),
    name: (p: IProps) => ({
      display: 'block',
      margin: '0px 5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    }),
    who: (p: IProps) => ({
      background: 'rgba(232, 239, 244, 0.5)',
      borderRadius: 3,
      padding: '3px 12px',
      margin: '0 10px',
      display: 'block',
      whiteSpace: 'pre',
      width: 'auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.2rem',
      color: 'rgba(34, 34, 34, 0.75)',
    }),
    location: (p: IProps) => ({
      '& span': {
        color: '#656771',
      },
    }),
    city: (p: IProps) => ({}),
    university: (p: IProps) => ({
      textAlign: 'center',
    }),
    titleContainer: {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      marginTop: 7,
    },
    title: (p) => ({
      width: '100%',
      display: p.isMobile ? 'none' : 'flex',
      alignItems: 'center',
      marginTop: 7,
    }),
    links: (p: IProps) => ({
      position: 'relative',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 10px 10px 0',
      marginTop: 0,
    }),
    link: {
      textTransform: 'capitalize',
    },
    linksIcon: {
      position: 'absolute',
      display: 'flex',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    overall: (p: IProps) => ({
      position: 'relative',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      transition: '0.5s all ease',
      overflowY: 'hidden',
      wordBreak: 'break-all',
    }),
    overallValue: {
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      resize: 'none',
      overflow: 'hidden',
    },
    overallValueFull: {
      overflow: 'auto',
    },
    overallTitleContainer: (p: IProps) => ({
      flexDirection: p.data.short_bio ? 'row' : 'column',
    }),
    overallTitle: (p: IProps) => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: p.data.short_bio ? 'flex-start' : 'center',
      margin: p.data.short_bio ? '0px 0px 0px 12px' : '6px 0 0 0',
    }),
    overallDummyText: {
      textAlign: 'center',
      wordBreak: 'break-word',
      marginBottom: 10,
    },
    overallBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0 0',
    },
    addLink: (p: IProps) => ({
      width: '100%',
      maxWidth: 'none',
      height: 'auto',
      borderColor: 'transparent',
      background: 'transparent',
    }),
    addLinkText: {
      color: theme.$primaryColor1,
    },
    dropZoneImage: {
      width: '100%',
      height: 120,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transition: '0.4s all ease',
      opacity: 0,
      borderRadius: '50%',
      '&:hover': {
        opacity: 1,
        background: 'rgba(246, 85, 50, 0.5)',
        cursor: 'pointer',
      },
    },
    dropZoneImageContainer: {
      position: 'relative',
    },
    dropZoneImageInner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 70,
      height: 26,
      borderRadius: 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      border: '1px solid white',
    },
    avatarLoader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'calc(100% + 4px)',
      height: 'calc(100% + 4px)',
      position: 'absolute',
      background: 'white',
      borderRadius: '50%',
    },
    avatarError: {
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: 220,
      padding: '15px 25px 15px 15px',
      borderRadius: 10,
      background: theme.$primaryColor1,
      zIndex: 10,

      '& > *': {
        fontSize: 12,
        color: 'white',
      },
    },
    close: {
      position: 'absolute',
      top: 15,
      right: 20,
      cursor: 'pointer',
      width: 1,
      height: 1,
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        width: 15,
        height: 2,
        background: 'white',
        transform: 'rotate(45deg)',
      },
      '&:before': {
        content: "''",
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 15,
        height: 2,
        background: 'white',
        transform: 'rotate(-45deg)',
      },
    },
    modal: (p: IProps) => ({
      cursor: 'pointer',
      height: p.isMobile ? '100%' : 400,
    }),
    modalContent: {
      height: '100%',
    },
    modalHeader: (p: IProps) => ({
      width: '100%',
      height: 60,
      display: 'flex',
      background: 'rgba(232, 239, 244, 0.25)',
      flexShrink: 0,
      padding: '10px 30px',
      borderTopLeftRadius: p.isMobile ? 0 : 15,
      borderTopRightRadius: p.isMobile ? 0 : 15,
    }),
    modalMain: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100% - 100px)',
    },
    modalButtons: {
      display: 'flex',
      padding: '0 15px',
      height: 100,
    },
    modalBtn: {
      flexGrow: 1,
      maxWidth: 'none',
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    modalGrade: (p: IProps) => ({
      maxWidth: p.isMobile ? 350 : 450,
    }),
  };
});
