import React, { useContext, useMemo, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Text from 'src/components/texts/Text/index.web';
import Title from 'src/components/titles/Title/index.web';
import TitleIcon from 'src/components/titles/TitleIcon/index.web';
import { ITheme } from 'src/constant/themes';
import Devider from 'src/components/dividers/Divider/index.web';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Toggle from '../../../../../components/toggles/Toggle/index.web';
import { useDispatch } from 'react-redux';
import { addSubscription } from 'src/state/modules/ui';

const Notifications = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { title } = props;
  const { t } = useTranslation();
  const { notifications: defaultNotifications, setData }: any = useContext(ProfileContext);
  const [notifications, setNotifications] = useState(defaultNotifications);

  const dispatch = useDispatch();

  const onSavePress = async () => {
    try {
      await setData({ notifications });
      dispatch(addSubscription({ id: '', name: '', type: 'd' }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.component}>
      <TitleIcon size={'h2'} iconName={'bell'} iconColor={theme.$primaryColor1} containerStyle={styles.tabTitle}>
        {t(title)}
      </TitleIcon>
      <div className={styles.componentItemDevider}>
        <Devider size={'xl'} />
        <Title size={'h2'} containerStyle={styles.itemTitle}>
          {t('Incoming messages')}
        </Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Text containerStyle={styles.fieldLabel}>{t('Notify me of new incoming messages (eg responses to applying for an event or opportunity)')}</Text>
          <Toggle
            active={notifications.incoming_messages}
            onPress={(e) =>
              setNotifications((prev) => ({
                ...notifications,
                incoming_messages: !prev.incoming_messages,
              }))
            }
          />
        </div>
      </div>
      <div className={styles.componentItemDevider}>
        <Devider size={'xl'} />
        <Title size={'h2'} containerStyle={styles.itemTitle}>
          {t('Opportunities or events')}
        </Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Text containerStyle={styles.fieldLabel}>{t('Notify me of new opportunities or events from the companies I follow')}</Text>
          <Toggle
            active={notifications.opportunities_or_events}
            onPress={(e) =>
              setNotifications((prev) => ({
                ...notifications,
                opportunities_or_events: !prev.opportunities_or_events,
              }))
            }
          />
        </div>
      </div>
      <div className={styles.componentItemDevider}>
        <Devider size={'xl'} />
        <Title size={'h2'} containerStyle={styles.itemTitle}>
          {t('New companies')}
        </Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Text containerStyle={styles.fieldLabel}>{t('Notify me of new companies joining the app that match my profile')}</Text>
          <Toggle
            active={notifications.new_companies}
            onPress={(e) =>
              setNotifications((prev) => ({
                ...notifications,
                new_companies: !prev.new_companies,
              }))
            }
          />
        </div>
      </div>
      <div className={styles.componentItemDevider}>
        <Devider size={'xl'} />
        <TitleIcon iconSize={26} size={'h2'} iconName={'speaker'} iconColor={theme.$primaryColor1} containerStyle={styles.itemTitle}>
          {t('News and updates')}
        </TitleIcon>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Text containerStyle={styles.fieldLabel}>{t('Receive weekly news, surveys, tips & product updates')}</Text>
          <Toggle
            active={notifications.news_and_updates}
            onPress={(e) =>
              setNotifications((prev) => ({
                ...notifications,
                news_and_updates: !prev.news_and_updates,
              }))
            }
          />
        </div>
      </div>
      <div className={styles.componentItem}>
        <ButtonMain type={'primary_1'} size={'xl'} text={t('Save')} containerStyle={styles.saveBtn} onPress={onSavePress} />
      </div>
    </div>
  );
};

export default Notifications;
