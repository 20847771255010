import React, { FC, ReactElement } from 'react';
import { useTheme } from 'react-jss';

import { Props } from './types';
import { useStyles } from './styles.web';
import { Link as RouterLink } from 'react-router-dom';

interface ComponentStyles {
  component?: string;
}

const Link: FC<Props> = (props): ReactElement => {
  const { children, text, href, containerStyle, onHover, onPress, onBlur, size = 'sm', internal } = props;
  const theme = useTheme();
  const styles = useStyles({ ...props, size, ...theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
  };

  const renderContent = (): React.ReactChild => {
    const content = children ? children : text ? text : null;

    return <>{content}</>;
  };

  return internal ? (
    <RouterLink to={href} className={cn.component} onMouseOver={onHover} onClick={onPress} onBlur={onBlur}>
      {renderContent()}
    </RouterLink>
  ) : (
    <a href={href} className={cn.component} onMouseOver={onHover} onClick={onPress} onBlur={onBlur} target={'_blank'}>
      {renderContent()}
    </a>
  );
};

export default Link;
