import React, { useState } from 'react';
import { IProps, IUniversity } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Link from 'src/components/links/Link/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from '../../../../../constant/themes';
import Title from 'src/components/titles/Title/index.web';
import Institute from './Institute';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Achievements from '../Achievements';
import Devider from 'src/components/dividers/Divider/index.web';
import { useTranslation } from 'react-i18next';

interface ComponentStyles {
  component?: string;
  title?: string;
}

const Education = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle, onEdit, list, achievementList, otherContentText = '' } = props;
  const { t } = useTranslation();

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    title: `${styles.item} ${styles.title}`.trim(),
  };

  const onEditFoo = () => {
    onEdit(true);
  };

  return (
    <>
      <div className={cn.component}>
        <ButtonMain type={'primary_1'} size={'xl'} containerStyle={styles.editLink} textStyle={styles.editLinkText} onPress={onEditFoo} text={t('Edit')} />
        <div className={cn.title}>
          <Icon size={18} name={'book'} color={theme.$primaryColor1} />
          <Title size={'h2'} containerStyle={styles.titleText}>
            {t('Education')}
          </Title>
        </div>
        <div className={styles.list}>
          {list.map((u: IUniversity, index: number) => (
            <Institute key={u.id} index={index} data={u} containerStyle={styles.listItem} onEdit={onEdit} isMobile={isMobile} />
          ))}
        </div>
        <Devider />
        <Achievements isMobile={isMobile} containerStyle={styles.achievements} list={achievementList} onEdit={onEditFoo} otherContentText={otherContentText} />
      </div>
    </>
  );
};

export default Education;
