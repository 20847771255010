import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  const componentSize = {
    md: '3.4rem',
    lg: '4.4rem',
  };

  const componentActive = (active: boolean) =>
    active
      ? {
          backgroundColor: theme.$secondaryColor2,
          padding: '0 11px',
        }
      : null;

  const textActive = (active: boolean) =>
    active
      ? {
          color: 'white',
        }
      : null;

  return {
    component: (p: IProps) => {
      const { size, active, icon, hoverable = true } = p;

      return {
        width: 'auto',
        padding: icon ? '0 20px 0 20px' : '0 11px',
        height: componentSize[size],
        backgroundColor: 'transparent',
        transition: 'none',
        borderColor: theme.$borderColor,
        borderRadius: size === 'md' ? '1.8rem' : '2.2rem',
        cursor: hoverable ? 'pointer' : 'default',
        '&:hover': {
          opacity: hoverable ? 0.9 : 1.0,
          backgroundColor: hoverable ? (active ? theme.$secondaryColor2 : theme.$secondaryColor2a05) : 'transparent',
        },
        ...componentActive(active),
      };
    },
    text: (p: IProps) => {
      const { active } = p;

      return {
        color: '#222222',
        fontWeight: '400',
        lineHeight: '1.6rem',
        opacity: 1,
        ...textActive(active),
      };
    },
    withIcon: (p: IProps) => ({
      marginLeft: p.active ? '7px' : '0',
    }),
  };
});
