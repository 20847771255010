import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import OpportunityItem from '../Item';
import { Context as OpportunitySearchContext } from 'src/contexts/SearchContext/OpportunitySearchContext';
import { FlatList, View } from 'react-native';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import { Context as LikesContext } from '../../../../contexts/LikesContext/index.web';

const List = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const [requestMade, setRequestMade] = useState(false);
  const { t } = useTranslation();
  const { isMobile, Item = OpportunityItem, containerStyle } = props;

  const { result }: any = useContext(OpportunitySearchContext);

  const { toggleLike: tL, toggleBookmark: tB }: any = useContext(LikesContext);
  const toggleLike = ({ id, application }: any, is_liked: string) => {
    if (application.redirectUrl) {
      window.open(application.redirectUrl);
    }

    tL(id, is_liked, 'o');
  };

  const toggleBookmark = (id: string, flag: boolean) => {
    tB(id, flag, 'o');
  };

  useEffect(() => {
    if (requestMade) {
      setTimeout(() => {
        setRequestMade(false);
      }, 1500);
    }
  }, [requestMade]);

  return (
    <div className={`${styles.container} ${containerStyle}`.trim()}>
      <View style={{ width: '100%', height: '100%' }}>
        {isMobile ? (
          <FlatList
            key={1}
            data={result}
            numColumns={1}
            keyExtractor={(item: any, index: number) => `${item.id}-${index}`}
            ListEmptyComponent={
              <Title size={'h2'} containerStyle={styles.noResults}>
                {t('No data found')}
              </Title>
            }
            renderItem={({ item, index }) => (
              <Item containerStyle={styles.item} isMobile={isMobile} toggleLike={toggleLike} toggleBookmark={toggleBookmark} data={item} />
            )}
          />
        ) : (
          <FlatList
            key={2}
            data={result}
            numColumns={2}
            keyExtractor={(item: any, index: number) => `${item.id}-${index}`}
            ListEmptyComponent={
              <Title size={'h2'} containerStyle={styles.noResults}>
                {t('No data found')}
              </Title>
            }
            renderItem={({ item, index }) => (
              <Item containerStyle={styles.item} isMobile={isMobile} toggleLike={toggleLike} toggleBookmark={toggleBookmark} data={item} />
            )}
          />
        )}
      </View>
    </div>
  );
};

export default List;
