import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as RNLocalize from 'react-native-localize';
import { Platform } from 'react-native';
// For datepicker locale
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';

// the translations
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationNL from './locales/nl/translation.json';
import { Storage } from './utilities/Storage';
import { momentTimeLocale, momentLocale } from './utilities';
import moment from 'moment';

registerLocale('fr', fr);
registerLocale('nl', nl);

const storage = new Storage(Platform.OS !== 'web');

export const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
};

const fallbackLng = 'en';

/**
 * Detects most preferable language amongst available for both web and mobile
 * "nl-BE" and "fr-BE" are shortened to "nl" and "fr".
 * It is supposed to have correct Belgian translate if it has any differences
 */

// export const detectLanguage = () => {
//   const languages = Object.keys(resources);

//   if (Platform.OS === 'web') {
//     let lng: string = fallbackLng;
//     // As navigator.languages is read only, make it's copy to reverse it
//     // It allows to iterate from less preferable and overwrite lng with most preferable language
//     const preferredLanguages = Array.from(navigator.languages).reverse();
//     preferredLanguages.forEach((language) => {
//       const lang = language.slice(0, 2);
//       if (languages.includes(lang)) {
//         lng = lang;
//       }
//     });
//     return lng;
//   }
//   const bestAvailableLanguage = RNLocalize.findBestAvailableLanguage(languages);
//   const lng = bestAvailableLanguage?.languageTag.slice(0, 2) || fallbackLng;
//   return languages.includes(lng) ? lng : fallbackLng;
// };

momentTimeLocale();

const languageDetector = {
  init: Function.prototype,
  type: 'languageDetector',
  async: true, // flags below detection to be async
  detect: async (callback: any) => {
    const languages = Object.keys(resources);
    const userLang = await storage.getUserLanguage();
    let lng: string = userLang ? userLang : fallbackLng;

    if (Platform.OS === 'web') {
      // As navigator.languages is read only, make it's copy to reverse it
      // It allows to iterate from less preferable and overwrite lng with most preferable language
      if (userLang) {
        callback(userLang);
      } else {
        const preferredLanguages = Array.from(navigator.languages).reverse();
        preferredLanguages.forEach((language) => {
          const lang = language.slice(0, 2);
          if (languages.includes(lang)) {
            lng = lang;
          }
        });
        callback(lng);
      }
    } else {
      //const selectLanguage = lng || RNLocalize.findBestAvailableLanguage(languages);
      //@ts-ignore
      moment.updateLocale(lng, momentLocale[lng]);
      callback(lng);
    }
  },
  cacheUserLanguage: () => {},
};

i18n
  //@ts-ignore
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    resources,
    nsSeparator: '__',
    // TODO: change to locally saved, like:  lng:localStorage.getItem('language')||'en', respect platform!
    //lng: detectLanguage(),
    fallbackLng, // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
