import React, { createContext, useState, memo, useEffect } from 'react';
import { IOptionItem } from '../../../requests/models';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IOpportunities } from '../types';

import { OPPORTUNITIES } from '../../../requests/queries';
import { UPDATE_OPPORTUNITY_BOOKMARK, UPDATE_OPPORTUNITY_LIKE } from '../../../requests/mutations';
import { useTranslation } from 'react-i18next';
import { IEvent } from '../../../components/cards/Event/types';
import { IOpportunity } from '../../../components/cards/Opportunity/types';

export interface IOpportunityFilter {
  searchValue: string;
  industry: IOptionItem[];
  companySize: IOptionItem[];
  types: IOptionItem[];
  experience: IOptionItem[];
  degree: IOptionItem[];
  companyId: string;
  limit: number;
  page: number;
}

interface IProps {
  children: any;
  filters: IOpportunityFilter;
  setFilters: any;
  setEntityByName: any;
}

const customLikeUpdate = (cache: any, { data: { updateOpportunityLike } }: any) => {
  const { id, is_liked, name } = updateOpportunityLike;
  const item: any = {
    __typename: 'Opportunity',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });
};

const customBookmarkUpdate = (cache: any, { data: { updateOpportunityBookmark } }: any) => {
  const { id, is_bookmarked, name } = updateOpportunityBookmark;
  const item: any = {
    __typename: 'Opportunity',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });
};

const Context = createContext({} as IOpportunities);
Context.displayName = '_OPPORTUNITY_SEARCH_';

const Provider = memo((props: IProps) => {
  const { children, filters, setFilters, setEntityByName } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const [enableSave, setEnableSave] = useState(false);
  const [enableFilters, setEnableFilters] = useState(false);

  const { searchValue, industry, companySize, types, experience, degree, companyId, limit, page } = filters;
  const [queryFilters, setQueryFilters] = useState(filters);

  const [
    getOpportunities,
    {
      loading,
      data = {
        opportunities: {
          page: 1,
          total: 1,
          limit: 10,
          result: [],
        },
      },
      fetchMore,
      called,
    },
  ] = useLazyQuery(OPPORTUNITIES, {
    variables: {
      lang,
      filters: {
        company_id: queryFilters.companyId,
        company_size: queryFilters.companySize.length ? filters.companySize.map((i: IOptionItem) => i.id) : undefined,
        sectors: queryFilters.industry.length ? queryFilters.industry.map((i: IOptionItem) => i.id) : undefined,
        types: queryFilters.types.length ? queryFilters.types.map((i: IOptionItem) => i.id) : undefined,
        experiences: queryFilters.experience.length ? queryFilters.experience.map((i: IOptionItem) => i.id) : undefined,
        degrees: queryFilters.degree.length ? queryFilters.degree.map((i: IOptionItem) => i.id) : undefined,
        name: queryFilters.searchValue ? queryFilters.searchValue : undefined,
      },
      limit: queryFilters.limit,
      page: queryFilters.page,
    },
    fetchPolicy: enableSave ? 'cache-and-network' : 'cache-only',
  });

  const [opportunityLike, { loading: opportunityLikeLoading }] = useMutation(UPDATE_OPPORTUNITY_LIKE);

  const [opportunityBookmark, { loading: opportunityBookmarkLoading }] = useMutation(UPDATE_OPPORTUNITY_BOOKMARK);

  const setData = (data: IOpportunityFilter) => {
    setFilters(undefined, data);
  };
  const setSearch = () => {
    getOpportunities();
    setEnableFilters(true);
  };
  const setClear = () => {
    setFilters(undefined, {
      searchValue: '',
      industry: [],
      companySize: [],
      types: [],
      experience: [],
      degree: [],
      companyId: undefined,
      page: 1,
    });
  };

  const setSearchStart = () => {
    setFilters('page', 1);
    setEntityByName('opportunities');
    setSearch();
  };

  const setSearchNext = async () => {
    if (!loading && data.opportunities && data.opportunities.result.length) {
      // if (page === 1 && data.opportunities.result.length !== data.opportunities.total) setSearch();
      setFilters('page', page + 1);
      if (called && data.opportunities.result.length !== data.opportunities.total) {
        //@ts-ignore
        await fetchMore({
          variables: {
            page: page + 1,
          },
          //@ts-ignore
          updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
            if (!fetchMoreResult || !fetchMoreResult.opportunities || !fetchMoreResult.opportunities.result.length) {
              return prev;
            }

            return Object.assign({}, prev, {
              opportunities: {
                ...fetchMoreResult.opportunities,
                result: [...prev.opportunities.result, ...fetchMoreResult.opportunities.result],
              },
            });
          },
        });
      }
    }
  };

  const setLike = async (id: number, is_liked: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customLikeUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };

      await opportunityLike({ variables: { like: { id: id + '', is_liked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  const setBookmark = async (id: number, is_bookmarked: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customBookmarkUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };

      await opportunityBookmark({ variables: { bookmark: { id: id + '', is_bookmarked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (enableFilters) {
      setQueryFilters({
        searchValue,
        industry,
        companySize,
        types,
        experience,
        degree,
        companyId,
        limit,
        page,
      });
      setEnableFilters(false);
      setEnableSave(true);
    }
  }, [searchValue, enableFilters, industry, companySize, types, experience, degree, companyId, limit, page]);

  useEffect(() => {
    if (enableSave && !loading && data) {
      setEnableSave(false);
    }
  }, [enableSave, loading, data]);

  return (
    <Context.Provider
      //@ts-ignore
      value={{
        searchValue,
        industry,
        companySize,
        types,
        experience,
        degree,
        limit,
        page,
        setData,
        setSearch,
        setSearchStart,
        setSearchNext,
        setClear,
        setBookmark,
        setLike,
        result: data.opportunities ? data.opportunities.result : [],
        companyId,
      }}>
      {children}
    </Context.Provider>
  );
});

export { Context, Provider };
