import Redux, { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';

import { rootReducer } from './modules';

import AsyncStorage from '@react-native-community/async-storage';
import { persistReducer, persistStore } from 'redux-persist';

const middlewares: Redux.Middleware[] = [thunkMiddleware, logger];

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['user'],
  blacklist: ['ui', 'session', 'error'],
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, {}, composeWithDevTools(applyMiddleware(...middlewares)));
export const persistor = persistStore(store);

export const initStore = (initialState = {}) => {
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
};

export type AppState = ReturnType<typeof rootReducer>;
