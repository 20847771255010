import React, { FC } from 'react';

import { Props } from './types';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  h1?: string;
  h2?: string;
  h3?: string;
}

const Title: FC<Props> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });
  const { size, children, containerStyle, onPress } = props;

  const cn: ComponentStyles = {
    h1: `${styles.component} ${styles.h1} ${containerStyle}`.trim(),
    h2: `${styles.component} ${styles.h2} ${containerStyle}`.trim(),
    h3: `${styles.component} ${styles.h3} ${containerStyle}`.trim(),
  };

  const renderComponent = (): React.ReactChild => {
    switch (size) {
      case 'h3': {
        return (
          <h3 className={cn.h3} onClick={onPress}>
            {children}
          </h3>
        );
      }
      case 'h2': {
        return (
          <h2 className={cn.h2} onClick={onPress}>
            {children}
          </h2>
        );
      }
      case 'h1': {
        return (
          <h1 className={cn.h1} onClick={onPress}>
            {children}
          </h1>
        );
      }
    }
  };

  return <>{renderComponent()}</>;
};

export default Title;
