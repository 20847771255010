import { createUseStyles } from 'react-jss';

import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    inboxCount: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 16,
      minHeight: 16,
      background: theme.$primaryColor1,
      color: 'white',
      borderRadius: '50%',
      lineHeight: '10px',
      padding: 3,
      marginLeft: 5,
      fontSize: 9,
    },
  };
});
