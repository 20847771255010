import React, { FC, useEffect, useState } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import Select from 'src/components/inputs/Select/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Divider from 'src/components/dividers/Divider/index.web';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { OnboardingData, IOptionItem } from 'src/requests/models';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { validateInput } from '../../../../utilities/validation';
import { selectLanguages } from 'src/state/modules/onboarding';

interface IProps {
  isMobile: boolean;
  children?: any;
  languageList: IOptionItem[];
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding3: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { setData, setPage, languageList, setIsNextDisabled } = props;
  const { t } = useTranslation();
  const languages = useSelector((state) => selectLanguages(state));
  const listOfNonPrimaryLangs = languageList.filter((l) => !l.is_primary);
  const listPrimaryLangs = languageList.filter((l) => l.is_primary);
  const selectedListOfNonPrimaryLangs = languages.filter((l: IOptionItem) => !l.is_primary);
  const [selectedOther, setSelectedOther] = useState<IOptionItem[]>(selectedListOfNonPrimaryLangs);

  const onSelectLanguage = (item: IOptionItem, action: 'add' | 'rem') => {
    if (item.is_primary) {
      if (action === 'add') {
        setData({ languages: languages.concat([item]) });
      } else if (action === 'rem') {
        setData({ languages: languages.filter((i: IOptionItem) => i.id !== item.id).slice() });
      }
    } else {
      const newSelectedOther = selectedOther.slice();
      const selectedIndex = newSelectedOther.findIndex((i: IOptionItem) => i.id === item.id);

      if (selectedIndex >= 0) {
        if (selectedOther[selectedIndex].id === item.id) {
          newSelectedOther.splice(selectedIndex, 1);
        } else {
          newSelectedOther[selectedIndex] = item;
        }
      } else {
        newSelectedOther.push(item);
      }
      const primaryLangs = languages.filter((l: IOptionItem) => l.is_primary);
      setData({ languages: [...primaryLangs, ...newSelectedOther] });
      setSelectedOther(newSelectedOther);
    }
  };

  const onChangeOther = (selectedIndex: number, other: IOptionItem) => {
    const newSelectedOther = selectedOther.slice();
    if (selectedOther[selectedIndex]) {
      if (selectedOther[selectedIndex].id === other.id) {
        newSelectedOther.splice(selectedIndex, 1);
      } else {
        newSelectedOther[selectedIndex] = other;
      }
    } else {
      newSelectedOther.push(other);
    }
    const primaryLangs = languages.filter((l) => l.is_primary);
    setData({ languages: [...primaryLangs, ...newSelectedOther] });
    setSelectedOther(newSelectedOther);
  };

  useEffect(() => {
    setIsNextDisabled(!languages.length);
  }, [languages]);

  useEffect(() => {
    setPage('3');
  }, []);

  return (
    <>
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Languages')}
        </Title>
        <Text type={'default'} text={t('Professional working proficiency or higher')} />
        <div className={styles.languages}>
          {[...listPrimaryLangs, ...selectedOther].map((languageItem: IOptionItem) => {
            const active = !!languages.find((a: IOptionItem) => a.id === languageItem.id);
            return (
              <ButtonToggle
                key={languageItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.language}
                text={languageItem.name}
                onPress={() => onSelectLanguage(languageItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        {selectedOther.length < 3 ? (
          <Select
            value={undefined}
            containerStyle={styles.langInput}
            placeholder={t('Other')}
            list={listOfNonPrimaryLangs.filter((lang: IOptionItem) => !selectedOther.find((sLang: IOptionItem) => lang.id === sLang.id))}
            onChoose={(item: IOptionItem) => onChangeOther(selectedOther.length, item)}
            inputValidation={validateInput}
          />
        ) : null}
      </div>
    </>
  );
};

export default Onboarding3;
