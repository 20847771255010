import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import Tab from '../../../components/tabs/Tab/index.web';
import { IProps, IListItem } from './types';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';
import { useTranslation } from 'react-i18next';

interface ComponentStyles {
  component?: string;
  activePointer?: string;
  tab?(disabled: boolean): string;
}

const TabList: FC<IProps> = (props) => {
  /* @ts-ignore */
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { list, active, containerStyle, tabStyle, onHover, onPress, onBlur, onChange } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    activePointer: `${styles.activePointer}`.trim(),
    tab: (disabled: boolean): string => `${styles.tab} ${tabStyle} ${disabled ? styles.tabDisabled : ''}`.trim(),
  };

  const { t } = useTranslation();

  const onPressTabHandle = (key: string) => {
    onChange(key);
  };

  return (
    <div onClick={onPress} onMouseOver={onHover} onBlur={onBlur} className={cn.component}>
      {list.map(({ key, value, disabled = false }: IListItem) => {
        const tabIsActive = key === active;
        return (
          //@ts-ignore
          <Tab key={key} active={tabIsActive} containerStyle={cn.tab(disabled)} onPress={() => (disabled ? '' : onPressTabHandle(key))}>
            <>
              {value}
              {tabIsActive ? <div className={cn.activePointer} /> : null}
            </>
          </Tab>
        );
      })}
    </div>
  );
};

export default TabList;
