import React, { FC, useContext, useEffect } from 'react';
import { useTheme } from 'react-jss';
import Header from 'src/web/components/Header';
import EventDesktop from 'src/web/components/Event/Page/Desktop';
import EventMobile from 'src/web/components/Event/Page/Mobile';
import { IProps } from './types';
import { ITheme } from 'src/constant/themes';
import { useLazyQuery } from '@apollo/client';
import { EVENT } from 'src/requests/queries';
import styles from './styles.module.scss';
import { useParams } from 'react-router';
import { COMPANY } from 'src/requests/queries';
import { ICompany } from 'src/requests/models';
import { useTranslation } from 'react-i18next';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';
import { Context as LikesContext } from '../../../contexts/LikesContext/index.web';
import { ApplyModal } from 'src/web/components/ApplyModal';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { IEvent } from 'src/components/cards/Event/types';
import { useDispatch } from 'react-redux';
import { addSubscription } from 'src/state/modules/ui';

const EventPage: FC<IProps> = (props) => {
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isMobile } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const [
    getEvent,
    {
      data = {
        event: {
          name: '',
          company_id: '',
          company_name: '',
          banner_img: '',
          main_description: '',
          description: '',
          address: '',
          logo: '',
          city: '',
          is_liked: false,
          is_bookmarked: false,
          types: [],
          company_size: { id: '', name: '', value: '' },
          start_date: '',
          end_date: '',
          deleted: '',
          application_flow: 'MESSAGE',
          redirection_link: undefined,
          application: { message: '', redirectUrl: '' },
        },
      },
      loading: eventLoading,
      called: eventCalled,
    },
  ] = useLazyQuery(EVENT, { variables: { lang, id } });

  const {
    name,
    company_id,
    company_name,
    banner_img,
    main_description,
    description,
    address,
    logo,
    city,
    is_liked,
    is_bookmarked,
    types,
    company_size,
    start_date,
    end_date,
    deleted,
    application_flow,
    redirection_link,
    application,
  } = data.event;

  const { toggleLike, toggleBookmark }: any = useContext(LikesContext);
  const { setApplyModalShown, setEntityForApply, entityForApply, applyModalShown } = useContext(SearchContext);

  const [
    getCompany,
    {
      data: companyData = {
        company: {
          id: 0,
          name: '',
          website: '',
          logo: '',
          background_photo: '',
          cities: [],
          additional_info: '',
          bio: '',
          company_size: { id: '', name: '', value: '' },
          emoji: [],
          tags: [],
          socials: [],
          sectors: [],
          is_liked: false,
          deleted: '',
        },
      },
      loading: companyLoading,
      called: companyCalled,
    },
  ] = useLazyQuery(COMPANY, { variables: { lang, id: '' + company_id } });

  const setEventLike = (undo = false) => {
    if (!application.redirectUrl) {
      dispatch(addSubscription({ id: '' + id, name, type: 'e' }));
    } else {
      window.open(application.redirectUrl);
    }

    toggleLike(id, true, 'e');
  };

  const setCompanyBookmark = () => {
    toggleBookmark(id, !is_bookmarked, 'e');
  };

  useEffect(() => {
    if (eventCalled && !eventLoading && !companyCalled) {
      getCompany();
    }
  }, [eventCalled, companyCalled, eventLoading]);

  useEffect(() => {
    getEvent();
  }, []);

  const company: ICompany = companyData.company;
  return (
    <>
      <Helmet>
        <title>{`Event - ${name}`}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          {eventLoading && companyLoading ? (
            <>Loading...</>
          ) : isMobile ? (
            <EventMobile
              id={id}
              name={name}
              is_liked={is_liked}
              is_bookmarked={is_bookmarked}
              company_name={company_name}
              banner_img={banner_img}
              company_id={company_id}
              main_description={main_description}
              description={description}
              city={city}
              address={address}
              logo={logo}
              types={types}
              deleted={deleted}
              start_date={start_date}
              end_date={end_date}
              setEventLike={setEventLike}
              setEventBookmark={setCompanyBookmark}
              companyData={company}
              application_flow={application_flow}
              application={application}
            />
          ) : (
            <EventDesktop
              id={id}
              name={name}
              is_liked={is_liked}
              is_bookmarked={is_bookmarked}
              company_name={company_name}
              banner_img={banner_img}
              company_id={company_id}
              main_description={main_description}
              description={description}
              city={city}
              address={address}
              logo={logo}
              types={types}
              deleted={deleted}
              start_date={start_date}
              end_date={end_date}
              setEventLike={setEventLike}
              setEventBookmark={setCompanyBookmark}
              companyData={company}
              application_flow={application_flow}
              application={application}
            />
          )}
        </div>
        {applyModalShown && (
          <ApplyModal
            entity={entityForApply as IEvent}
            cancel={() => {
              setApplyModalShown(false);
              setEntityForApply({});
            }}
            confirm={() => {
              setEventLike();
              setApplyModalShown(false);
              setEntityForApply({});
            }}
            text={(entityForApply as IEvent)?.application?.message ? (entityForApply as IEvent)?.application?.message : ''}
          />
        )}
      </ErrorBoundaries>
    </>
  );
};

export default EventPage;
