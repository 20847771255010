import React from 'react';
import styles from './styles.module.scss';
import { useQuery } from '@apollo/client';
import { TERMS_OF_USE } from 'src/requests/queries';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const TermsPage = () => {
  const { i18n } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const qLang = query.get('lang');
  const { data, loading } = useQuery(TERMS_OF_USE, {
    variables: {
      lang: qLang ? qLang : i18n.language,
    },
  });

  function createMarkup(data: any) {
    return { __html: data };
  }

  return (
    <>
      <Helmet>
        <title>{'Terms'}</title>
      </Helmet>
      <div className={styles.page}>
        {/*@ts-ignore*/}
        <div className={styles.pageInner} dangerouslySetInnerHTML={loading ? { __html: '' } : createMarkup(data.termsOfUse)} />
      </div>
    </>
  );
};

export default TermsPage;
