import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TOnboardingAction, EOnboardingActionTypes } from './onboarding.types';
import { Storage } from '../../../utilities/Storage';
import { State } from '../index';
import { OnboardingData } from '../../../requests/models';
import { selectData } from './onboarding.selectors';
import { MyThunkDispatch, MyThunkResult } from '../types';

export const setLoadingData = (payload: boolean): TOnboardingAction => ({
  type: EOnboardingActionTypes.SET_DATA_LOADING,
  payload,
});

export const setData = (payload: OnboardingData): TOnboardingAction => ({
  type: EOnboardingActionTypes.SET_DATA,
  payload,
});

export const setCurrentPage = (payload: string): TOnboardingAction => ({
  type: EOnboardingActionTypes.SET_CURRENT_PAGE,
  payload,
});

export const getDataOperation = (mobile: boolean): MyThunkResult<Promise<any>> => async (dispatch: MyThunkDispatch) => {
  const storage = new Storage(mobile);

  await dispatch(setLoadingData(true));

  const data = await storage.getOnboardingData();
  if (Object.keys(data).length) {
    await dispatch(setData(data));
  } else {
    await dispatch(setData({ lastPage: '/onboarding/1' }));
  }
  await dispatch(setLoadingData(true));

  return Promise.resolve({ data });
};

export const setDataOperation = (data: OnboardingData, mobile: boolean): MyThunkResult<Promise<any>> => async (dispatch: MyThunkDispatch, getStore) => {
  const storage = new Storage(mobile);
  const store = getStore();
  const storeData = selectData(store);

  await storage.setOnboardingData({ ...storeData, ...data });
  await dispatch(getDataOperation(mobile));
};

export const clearOnboardingOperation = (mobile: boolean): MyThunkResult<Promise<any>> => async (dispatch: MyThunkDispatch) => {
  const storage = new Storage(mobile);
  await storage.clearOnboardingData();
  await dispatch(setData({ lastPage: '/onboarding/1' }));
};

export const setDataToReducer = (iData: any): MyThunkResult<Promise<any>> => async (dispatch: MyThunkDispatch, getState: any) => {
  const { data } = getState().onboarding;
  const storage = new Storage(true);

  await dispatch(setLoadingData(true));

  await dispatch(setData({ ...data, ...iData }));
  await storage.setOnboardingData(iData);

  await dispatch(setLoadingData(false));
};
