import { createSelector } from 'reselect';

import { State } from '..';
import { UserState } from './user.types';

export const getUserState = (state: State): UserState => state.user;
const getUserData = (state: State): UserState => state.user.data;

export const selectUserData = createSelector([getUserData], (userState: any) => userState);

export const getUserEmail = createSelector([getUserState], (userState: any) => userState.email);

export const getUserLastName = createSelector([getUserData], (userState: any) => userState.lastName);

export const getUserFirstName = createSelector([getUserData], (userState: any) => userState.firstName);

export const getUserToken = createSelector([getUserState], (userState: any) => userState.token);

export const getUserIsLogged = createSelector([getUserState], (userState: any) => userState.isLogged);

export const getUnreadMessagesCount = createSelector([getUserState], (userState: any) => userState.unreadMessagesCount);

export const getUserProvider = createSelector([getUserState], (userState: any) => userState.provider);

export const getUserRefreshToken = createSelector([getUserState], (userState: any) => userState.refreshToken);

export const selectEducationLevel = createSelector([getUserData], (userState: any) => userState.education_level);

export const selectPrivacyTermsAgree = createSelector([getUserData], (userState: any) => userState.privacyTermsAgree);

export const selectInstitute = createSelector([getUserData], (userState: any) => userState.institute);

export const selectEducationalSubject = createSelector([getUserData], (userState: any) => userState.subject);

export const selectGraduationDate = createSelector([getUserData], (userState: any) => userState.graduation_date);

export const selectDegree = createSelector([getUserData], (userState: any) => userState.degree);

export const selectAverageGrade = createSelector([getUserData], (userState: any) => userState.average_grades);

export const selectAchievements = createSelector([getUserData], (userState: any) => userState.achievements || []);

export const selectIExperience = createSelector([getUserData], (userState: any) => userState.international_experiences || []);

export const selectActivities = createSelector([getUserData], (userState: any) => userState.activities || []);

export const selectIsActivityAbroad = createSelector([getUserData], (userState: any) => userState.is_board_member || false);

export const selectLanguages = createSelector([getUserData], (userState: any) => userState.languages || []);

export const selectWorkExperience = createSelector([getUserData], (userState: any) => userState.work_experiences || []);

export const selectFirstName = createSelector([getUserData], (userState: any) => userState.first_name || '');

export const selectLastName = createSelector([getUserData], (userState: any) => userState.last_name || '');

export const selectSex = createSelector([getUserData], (userState: any) => userState.sex || undefined);

export const selectBirthDate = createSelector([getUserData], (userState: any) => userState.birthday || undefined);

export const selectCountry = createSelector([getUserData], (userState: any) => userState.country || undefined);

export const selectCity = createSelector([getUserData], (userState: any) => userState.city || undefined);

export const selectAvatar = createSelector([getUserState], (useState: any) => useState.avatar || null);

export const selectEmail = createSelector([getUserState], (useState: any) => useState.email);

export const selectContactEmail = createSelector([getUserData], (useState: any) => useState.contact_email);

export const selectInterests = createSelector([getUserData], (useState: any) => useState.interests || []);

export const selectReferrer = createSelector([getUserData], (useState: any) => useState.referrer || '');

export const selectPhoneNumber = createSelector([getUserData], (useState: any) => useState.phone_number || '');

export const selectProfileDataForSave = createSelector([getUserData], (data: any) => {
  const result: any = {
    social_media: null,
    institutes: [
      {
        id: '',
        subject: '',
        graduation_date: '',
        degree: '',
        average_grades: '',
      },
    ],
  };
  Object.keys(data).forEach((key: any) => {
    if (Array.isArray(data[key])) {
      if (key === 'institute') {
        result.institutes[0].id = data[key].map((obj: any) => obj.id);
      } else {
        result[key] = data[key].map((obj: any) => obj.id);
      }
    } else if (typeof data[key] === 'object') {
      if (key === 'institute') {
        result.institutes[0].id = data[key].id;
      } else if (key === 'subject' || key === 'degree') {
        result.institutes[0][key] = data[key].id.toString();
      } else if (key === 'average_grades') {
        result.institutes[0].average_grades = data[key].name;
      } else if (key === 'city' || key === 'country') {
        result[key] = data[key].name;
      } else if (data[key] === null) {
        result[key] = null;
      } else if (!data[key]?.id) {
        result[key] = data[key];
      } else {
        result[key] = data[key].id.toString();
      }
    } else if (typeof data[key] === 'string') {
      if (key === 'graduation_date') {
        result.institutes[0].graduation_date = data[key];
      } else if (key !== 'avatar') {
        result[key] = data[key].toString();
      } else {
        result[key] = data[key];
      }
    } else {
      if (key !== 'privacyTermsAgree') {
        result[key] = data[key];
      }
    }
  });
  return result;
});

export const selectGradeProofChange = createSelector([getUserState], (userState: any) => userState.gradeProofFlag);
export const selectProfileCVChange = createSelector([getUserState], (userState: any) => userState.profileCVFlag);

export const selectCurrentPage = createSelector([getUserState], (useState: any) => useState.currentPage);
