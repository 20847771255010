import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: (p: IProps) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      padding: p.isMobile ? '15px 15px 0' : '30px 30px 0',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: '10px',
      overflow: 'hidden',
    }),
    item: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '10px 0 0',
    }),
    editLink: (p: IProps) => ({
      position: 'absolute',
      height: 'auto',
      top: p.isMobile ? 15 : 30,
      right: p.isMobile ? 15 : 30,
      borderColor: 'transparent',
      width: 'auto',
      background: 'transparent',
    }),
    editLinkText: {
      color: theme.$primaryColor1,
    },
    title: (p: IProps) => ({
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: 0,
      paddingLeft: p.isMobile ? 5 : 15,
      '& svg': {
        marginLeft: -5,
      },
    }),
    titleText: {
      margin: '0 0 0 10px',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    listItem: {
      borderBottom: `1px solid ${theme.$borderColor}`,
      '&:last-child': {
        border: 'none',
      },
    },
    achievements: {
      width: '100%',
    },
  };
});
