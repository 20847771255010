import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    title: (p: {ready: boolean, skip: boolean}) => ({
      width: '100%',
      display: 'flex',

      justifyContent: p.ready ? 'center' : p.skip ? 'space-between' : 'flex-start',
      alignItems: 'center',
      marginBottom: 10,
    }),
  };
});
