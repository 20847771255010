import React, { memo, useContext, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Select from 'src/components/inputs/Select/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { IOptionItem } from 'src/requests/models';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import { useTranslation } from 'react-i18next';
import Input from 'src/components/inputs/TextInput/index.web';
import Text from 'src/components/texts/Text/index.web';

const Languages = memo((props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { setPage, list, onClose } = props;

  const { languages: defaultLanguages, otherLanguages: defaultOtherLanguages, setData }: any = useContext(ProfileContext);

  const [languages, setLanguages] = useState(defaultLanguages);

  const listOfNonPrimaryLangs = list.filter((l) => !l.is_primary);
  const listPrimaryLangs = list.filter((l) => l.is_primary);
  const selectedListOfNonPrimaryLangs = languages.filter((l: IOptionItem) => !l.is_primary);

  const [selectedOther, setSelectedOther] = useState<IOptionItem[]>(selectedListOfNonPrimaryLangs);
  const [otherLanguages, setOtherLanguages] = useState(defaultOtherLanguages);

  const onSelectLanguage = (item: IOptionItem, action: 'add' | 'rem') => {
    if (item.is_primary) {
      if (action === 'add') {
        setLanguages(languages.concat([item]));
      } else if (action === 'rem') {
        setLanguages(languages.filter((i: IOptionItem) => i.id !== item.id).slice());
      }
    } else {
      const newSelectedOther = selectedOther.slice();
      const selectedIndex = newSelectedOther.findIndex((i: IOptionItem) => i.id === item.id);

      if (selectedIndex >= 0) {
        if (selectedOther[selectedIndex].id === item.id) {
          newSelectedOther.splice(selectedIndex, 1);
        } else {
          newSelectedOther[selectedIndex] = item;
        }
      } else {
        newSelectedOther.push(item);
      }
      const primaryLangs = languages.filter((l: IOptionItem) => l.is_primary);
      setLanguages([...primaryLangs, ...newSelectedOther]);
      setSelectedOther(newSelectedOther);
    }
  };

  const onChangeOther = (selectedIndex: number, other: IOptionItem) => {
    const newSelectedOther = selectedOther.slice();
    if (selectedOther[selectedIndex]) {
      if (selectedOther[selectedIndex].id === other.id) {
        newSelectedOther.splice(selectedIndex, 1);
      } else {
        newSelectedOther[selectedIndex] = other;
      }
    } else {
      newSelectedOther.push(other);
    }
    const primaryLangs = languages.filter((l: IOptionItem) => l.is_primary);
    setLanguages([...primaryLangs, ...newSelectedOther]);
    setSelectedOther(newSelectedOther);
  };

  const onCancelPress = () => {
    onClose();
  };

  const onSavePress = async () => {
    try {
      setData({ languages, otherLanguages });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPage('profileEdit');
  }, []);

  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Languages')}
        </Title>
      </div>
      <div className={styles.container}>
        <div className={styles.languages}>
          {[...listPrimaryLangs, ...selectedOther].map((languageItem: IOptionItem) => {
            const active = !!languages.find((a: IOptionItem) => a.id === languageItem.id);
            return (
              <ButtonToggle
                key={languageItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.language}
                text={languageItem.name}
                onPress={() => onSelectLanguage(languageItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        {selectedOther.length < 3 ? (
          <Select
            value={undefined}
            containerStyle={styles.langInput}
            placeholder={'Other'}
            list={listOfNonPrimaryLangs.filter((lang: IOptionItem) => !selectedOther.find((sLang: IOptionItem) => lang.id === sLang.id))}
            onChoose={(item: IOptionItem) => onChangeOther(selectedOther.length, item)}
          />
        ) : null}
        <div className={styles.textAreaTexts}>
          <Text containerStyle={styles.fieldLabel}>{t('Other languages')}</Text>
          <Text type={'secondary'} containerStyle={styles.textAreaMax}>
            500 {t('characters max')}
          </Text>
        </div>
        <Input value={otherLanguages} containerStyle={styles.textArea} inputStyle={styles.textAreaInput} type={'textarea'} onChange={setOtherLanguages} />
      </div>
      <div className={styles.footer}>
        <ButtonMain type={'primary_2'} size={'xl'} text={t('Cancel')} containerStyle={styles.footerButtonBack} outline onPress={onCancelPress} />
        <ButtonMain disabled={!languages.length} type={'primary_1'} size={'xl'} text={'Save'} containerStyle={styles.footerButtonNext} onPress={onSavePress} />
      </div>
    </div>
  );
});

export default Languages;
