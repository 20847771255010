import React, { useContext, useRef } from 'react';
import moment from 'moment';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Title from 'src/components/titles/Title/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import Text from 'src/components/texts/Text/index.web';
import { useHistory } from 'react-router';
import { getSlicedText } from '../../../../utilities';
import { useTranslation } from 'react-i18next';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import { Context as SearchContext } from 'src/contexts/SearchContext';

interface ComponentStyles {
  component?: string;
  header?: string;
  body?: string;
  footer?: string;
}

const Item = (props: IProps) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { isMobile, fixed, containerStyle, toggleLike, toggleBookmark, data, view } = props;
  const { setApplyModalShown, setEntityForApply } = useContext(SearchContext);
  const {
    id,
    name,
    company_name,
    cities,
    city,
    types = [],
    main_description,
    created,
    updated,
    is_liked = view === 'likes',
    is_bookmarked = view === 'bookmarks',
  } = data;

  const refBody = useRef(null);

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    header: `${styles.block} ${styles.header}`,
    body: `${styles.block} ${styles.body}`,
    footer: `${styles.block} ${styles.footer}`,
  };
  const showApplyModal = () => {
    setApplyModalShown(true);

    setEntityForApply(data);
  };

  const onViewJob = () => {
    history.push('/opportunity/' + id);
  };

  const set = fixed ? 1 : view && !isMobile ? 2 : 3;
  return (
    <div className={cn.component}>
      <div className={cn.header} onClick={onViewJob}>
        {set === 1 && (
          <>
            <div className={styles.headerItemTitle}>
              <Title size={'h2'} containerStyle={styles.title}>
                {name}
              </Title>
            </div>
            <div className={styles.headerItem}>
              <Text containerStyle={styles.opportunityType}>{types ? types[0].name : ''}</Text>
              <TextIcon iconName={'location'} iconSize={14} containerStyle={styles.location}>
                {cities && cities.length ? cities.join(', ') : city || ''}
              </TextIcon>
            </div>
          </>
        )}
        {set === 2 && (
          <>
            <div className={styles.headerItem}>
              <div className={styles.logo} />
              <div className={styles.company}>{company_name}</div>
              <TextIcon iconName={'location'} iconSize={12} containerStyle={styles.location}>
                {cities && cities.length ? cities.join(', ') : city || ''}
              </TextIcon>
            </div>
            <div className={styles.headerItemTitle}>
              <Title size={'h2'} containerStyle={styles.title}>
                {name}
              </Title>
              <Text containerStyle={styles.opportunityType}>{types ? types[0].name : ''}</Text>
            </div>
          </>
        )}
        {set === 3 && (
          <>
            <div className={styles.headerItem}>
              <div className={styles.logo} />
              <div className={styles.company}>{company_name}</div>
            </div>
            <div className={styles.headerItem} style={{ marginTop: 15 }}>
              <Text containerStyle={styles.opportunityType}>{types ? types[0].name : ''}</Text>
              <TextIcon iconName={'location'} iconSize={12} containerStyle={styles.location}>
                {cities && cities.length ? cities.join(', ') : city || ''}
              </TextIcon>
            </div>
            <div className={styles.headerItemTitle}>
              <Title size={'h2'} containerStyle={styles.title}>
                {name}
              </Title>
            </div>
          </>
        )}
      </div>
      <div className={cn.body} ref={refBody} onClick={onViewJob}>
        {main_description}
      </div>
      <div className={cn.footer}>
        {!view || view !== 'bookmarks' ? (
          <>
            {is_liked ? (
              <ButtonMain
                outline
                size={'lg'}
                type={'primary_1'}
                text={t('Interested')}
                containerStyle={`${styles.btnMain} ${styles.setInterest}`}
                onPress={() => {
                  showApplyModal();
                }}
              />
            ) : (
              <ButtonMain
                size={'lg'}
                type={'primary_1'}
                text={t('Show interest')}
                containerStyle={`${styles.btnMain} ${styles.setInterest}`}
                onPress={() => {
                  showApplyModal();
                }}
              />
            )}
          </>
        ) : null}
        {(!view || view === 'explorer') && !is_liked && (
          <ButtonIconToggle
            size={'lg'}
            activeIconName={'bookmark-fill'}
            inactiveIconName={'bookmark'}
            containerStyle={styles.btnMain}
            iconSize={20}
            iconColor={'#F65532'}
            active={is_bookmarked}
            onPress={() => toggleBookmark(id, !is_bookmarked)}
          />
        )}
        {view === 'bookmarks' ? (
          <ButtonMain
            outline
            size={'lg'}
            type={'primary_1'}
            text={t('Remove bookmark')}
            containerStyle={`${styles.btnMain} ${styles.setInterest}`}
            onPress={() => toggleBookmark(id, false)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Item;
