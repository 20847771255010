import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: (p: IProps) => ({
      color: `${p.active ? theme.$primaryColor1 : theme.$primaryColor2} !important`,
    }),
    activePointer: {
      position: 'absolute',
      left: 0,
      bottom: -1,
      width: '100%',
      height: '0.3rem',
      backgroundColor: theme.$primaryColor1,
    },
    tab: ({ isMobile }: IProps) => ({
      position: 'relative',
      borderBottom: `1px solid ${theme.$secondaryColor2}`,
      '& *': {
        fontSize: isMobile ? 14 : 16,
        letterSpacing: isMobile ? -0.5 : 0,
      },
    }),
    tabDisabled: () => ({
      cursor: 'not-allowed',
      opacity: 0.75,
    }),
  };
});
