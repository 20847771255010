import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        flexFlow: 'row wrap',
        alignItems: isMobile ? 'center' : 'flex-start',
        width: '100%',
        maxWidth: isMobile ? 380 : 'none',
        padding: isMobile ? '0 15px' : '0',
        height: 'auto',
        overflow: 'auto',
      };
    },
    list: {},
    item: ({ isMobile }: IProps) => ({
      margin: isMobile ? '0 0 15px 0' : '0 0 15px 15px',
    }),
    noResults: ({ isMobile }: IProps) => ({
      width: '100%',
      justifyContent: 'center',
      marginTop: 50,
      marginLeft: isMobile ? 0 : 15,
      color: theme.$primaryColor1,
    }),
  };
});
