import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { widthD } from 'src/constant/variables';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto 100px',
    },
    headerReplace: {
      position: 'fixed',
      display: 'flex',
      padding: '0 20px',
      top: 0,
      left: 0,
      width: '100%',
      height: 54,
      background: 'white',
      zIndex: 100,
    },
    companyBackground: ({ banner_img }: IProps) => ({
      backgroundImage: `url(${banner_img ? banner_img.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: 150,
    }),
    companyTitle: {
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      fontSize: 21,
      lineHeight: '19px',
      marginBottom: 5,
      whiteSpace: 'pre',
    },
    title: {
      margin: 0,
      lineHeight: '21px',

      '& svg': {
        cursor: 'pointer',
        marginRight: 10,
      },
    },
    opportunityTitle: {
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',

      marginBottom: 10,
    },
    main: {
      width: '100%',
      maxWidth: widthD,
      margin: '0 auto',
      padding: '10px 15px 15px',
      background: 'white',
      borderBottom: `1px solid ${theme.$borderColor}`,
    },
    mainHeader: {
      display: 'flex',
      width: '100%',
    },
    mainBody: {},
    mainInfoLogo: ({ logo }: IProps) => ({
      width: 60,
      height: 60,
      borderRadius: 3,
      marginRight: 15,
      flexShrink: 0,
      top: -20,
      border: '2px white solid',
      position: 'relative',
      //@ts-ignore
      backgroundImage: `url(${logo ? logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    sideLogo: {
      display: 'flex',
      width: 'calc(100% - 90px)',
      flexDirection: 'column',
    },
    labels: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    profileLink: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    label: {
      justifyContent: 'flex-start',
      overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'pre',
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    labelIcon: {
      display: 'inline',
      verticalAlign: 'middle',
    },
    labelLocation: {
      // maxWidth: 120,
      display: 'flex',
    },
    labelSection: {
      // maxWidth: 140,
    },
    labelUser: {
      // maxWidth: 120,
    },
    mainInfoTitle: {},
    mainInfoSite: {
      color: theme.$primaryColor1,
      fontSize: 12,
      lineHeight: '16px',
    },
    mainInfoLabels: {},
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 5,
    },
    tag: {
      padding: '5px 12px',
      background: 'rgba(232, 239, 244, 0.5)',
      borderRadius: '3px',
      fontSize: 12,
      lineHeight: '16px',
      color: '#656771',
      margin: '0 10px',

      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    overall: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      transition: '0.5s all ease',
      overflowY: 'hidden',
      wordBreak: 'break-all',
    },
    overallValue: {
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      resize: 'none',
      overflow: 'hidden',
    },
    overallTitle: {
      width: '100%',
      display: 'none',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 0,
    },
    overallBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0 0',
      marginBottom: 10,
    },
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    buttons: {
      display: 'flex',
      width: '100%',
    },
    opportunityMarkdown: {
      padding: 15,
      background: 'transparent',
      '& img': {
        maxWidth: '100%',
      },
    },
    labelTime: {},
    labelType: {
      margin: 0,
    },
    labelList: {
      width: 'auto',
    },
    opportunityInfo: {
      flexGrow: 1,
    },
    overallValueFull: {
      overflow: 'auto',
    },
    opportunityOther: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      padding: '20px 0',
      margin: '15px 0',
    },

    opportunityOtherLabels: {
      display: 'flex',
      width: '100%',
      maxWidth: 450,
      justifyContent: 'space-around',
    },
    opportunityInfoItem: {
      display: 'flex',
      alignItems: 'center',
    },
    labelOther: {
      color: '#282828',
      fontWeight: 500,
      maxWidth: 120,
      opacity: 1,
      '& svg *': {
        fill: theme.$primaryColor1,
      },
    },
    setInterest: {
      maxWidth: 'none',
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 74,
      padding: 15,
      background: 'white',
      borderTop: `1px solid ${theme.$borderColor}`,
    },
  };
});
