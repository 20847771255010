import React, { FC, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  content?: string;
}

const ButtonMain: FC<IProps> = (props) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const { value, containerStyle } = props;
  const styles = useStyles({ ...props, theme });
  const [valueNow, setValueNow] = useState(value);

  useEffect(() => {
    setValueNow(value);
  }, [value]);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    content: `${styles.content}`.trim(),
  };

  return (
    <div className={cn.component}>
      <div className={cn.content} style={{ width: `${valueNow}%` }} />
    </div>
  );
};

export default ButtonMain;
