import React, { createContext, useState, memo, useEffect } from 'react';
import { IOptionItem } from '../../../requests/models';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ICompanies } from '../types';
import { COMPANIES } from '../../../requests/queries';
import { UPDATE_COMPANY_BOOKMARK, UPDATE_COMPANY_LIKE } from '../../../requests/mutations';
import { useTranslation } from 'react-i18next';
import { UPDATE_COMPANY_DISLIKE } from '../../../requests/mutations';
import { IEvent } from '../../../components/cards/Event/types';
import { IOpportunity } from '../../../components/cards/Opportunity/types';

export interface ICompanyFilter {
  searchValue: string;
  hiringCompanies: boolean;
  industry: IOptionItem[];
  companySize: IOptionItem[];
  tags: IOptionItem[];
  perks: IOptionItem[];
  limit: number;
  page: number;
}

interface IProps {
  children: any;
  filters: ICompanyFilter;
  setFilters: any;
  setEntityByName: any;
}

const customLikeUpdate = (cache: any, { data: companyData }: { data: any }) => {
  const { id, is_liked, name } = companyData.updateCompanyLike;
  const item: any = {
    __typename: 'Company',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });
};

const customBookmarkUpdate = (cache: any, { data: companyData }: { data: any }) => {
  const { id, is_bookmarked, name } = companyData.updateCompanyBookmark;
  const item: any = {
    __typename: 'Company',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });
};

const customDislikeUpdate = (cache: any, { data: companyData }: { data: any }) => {
  const { id, is_disliked, name } = companyData.updateCompanyDislike;
  const item: any = {
    __typename: 'Company',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_disliked: (cached: any) => {
        return is_disliked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_disliked: (cached: any) => {
        return is_disliked;
      },
    },
  });
};

const Context = createContext({} as ICompanies);
Context.displayName = '_COMPANIES_SEARCH_';

const Provider = memo((props: IProps) => {
  const { children, filters, setFilters, setEntityByName } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const [enableSave, setEnableSave] = useState(false);
  const [enableFilters, setEnableFilters] = useState(false);
  const { searchValue, hiringCompanies, industry, companySize, tags, perks, limit, page } = filters;
  const [queryFilters, setQueryFilters] = useState(filters);

  const [
    getCompanies,
    {
      loading,
      data = {
        companies: {
          page: 1,
          total: 1,
          limit: 10,
          result: [],
        },
      },
      fetchMore,
      called,
    },
  ] = useLazyQuery(COMPANIES, {
    variables: {
      lang,
      filters: {
        company_size: queryFilters.companySize.length ? queryFilters.companySize.map((i: IOptionItem) => i.id) : undefined,
        sectors: queryFilters.industry.length ? queryFilters.industry.map((i: IOptionItem) => i.id) : undefined,
        emoji: queryFilters.perks.length ? queryFilters.perks.map((i: IOptionItem) => i.id) : undefined,
        name: queryFilters.searchValue ? queryFilters.searchValue : undefined,
        is_hiring: queryFilters.hiringCompanies,
      },
      limit: queryFilters.limit,
      page: queryFilters.page,
    },
    fetchPolicy: enableSave ? 'cache-and-network' : 'cache-only',
  });

  const [companyLike, { loading: companyLikeLoading }] = useMutation(UPDATE_COMPANY_LIKE);

  const [companyBookmark, { loading: companyBookmarkLoading }] = useMutation(UPDATE_COMPANY_BOOKMARK);

  const [companyDislike] = useMutation(UPDATE_COMPANY_DISLIKE);

  const setData = (data: ICompanyFilter) => {
    setFilters(undefined, data);
  };
  const setSearch = () => {
    getCompanies();
    setEnableFilters(true);
  };
  const setClear = () => {
    setFilters(undefined, {
      searchValue: '',
      is_hiring: false,
      industry: [],
      companySize: [],
      hiringCompanies: false,
      tags: [],
      perks: [],
      page: 1,
    });
  };

  const setSearchStart = () => {
    setFilters('page', 1);
    setEntityByName('companies');
    setSearch();
  };

  const setSearchNext = async () => {
    if (!loading && data.companies && data.companies.result.length) {
      // if (page === 1 && data.companies.result.length !== data.companies.total) setSearch();
      setFilters('page', page + 1);
      if (called && data.companies.result.length !== data.companies.total) {
        //@ts-ignore
        await fetchMore({
          variables: {
            page: page + 1,
          },
          //@ts-ignore
          updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
            if (!fetchMoreResult || !fetchMoreResult.companies || !fetchMoreResult.companies.result.length) {
              return data;
            }

            return Object.assign({}, prev, {
              companies: {
                ...fetchMoreResult.companies,
                result: [...prev.companies.result, ...fetchMoreResult.companies.result],
              },
            });
          },
        });
      }
    }
  };

  const setLike = async (id: number, is_liked: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customLikeUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };

      await companyLike({ variables: { like: { id: id + '', is_liked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  const setBookmark = async (id: number, is_bookmarked?: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customBookmarkUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };
      await companyBookmark({ variables: { bookmark: { id: id + '', is_bookmarked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  const setDislike = async (id: number, is_disliked?: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customDislikeUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };
      await companyDislike({ variables: { dislike: { id: id + '', is_disliked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (enableFilters) {
      setQueryFilters({
        searchValue,
        hiringCompanies,
        industry,
        companySize,
        tags,
        perks,
        limit,
        page,
      });
      setEnableFilters(false);
      setEnableSave(true);
    }
  }, [enableFilters, hiringCompanies, searchValue, industry, companySize, tags, perks, limit, page]);

  useEffect(() => {
    if (enableSave && !loading && data) {
      setEnableSave(false);
    }
  }, [enableSave, loading, data]);

  return (
    <Context.Provider
      value={{
        searchValue,
        hiringCompanies,
        industry,
        companySize,
        perks,
        tags,
        limit,
        page,
        setData,
        setSearch,
        setSearchStart,
        setSearchNext,
        setClear,
        setLike,
        setBookmark,
        setDislike,
        result: data.companies ? data.companies.result : [],
      }}>
      {children}
    </Context.Provider>
  );
});

export { Context, Provider };
