import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/inputs/Select/index.web';
import { IOptionItem } from 'src/requests/models';
import Devider from 'src/components/dividers/Divider/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import CompanyFilter from 'src/web/components/CompanyFilter';
import { useQuery } from '@apollo/client';
import { OPPORTUNITIES_DATA_AVAILABLES } from 'src/requests/queries';
import { Context as OpportunitySearchContext } from 'src/contexts/SearchContext/OpportunitySearchContext';
import { Context as SearchContext } from 'src/contexts/SearchContext';

const View = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, onClose } = props;
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies') },
    { id: '2', name: 'events', label: t('Events') },
    { id: '3', name: 'opportunities', label: t('Opportunities') },
  ];

  const filterQ = useQuery(OPPORTUNITIES_DATA_AVAILABLES, {
    variables: {
      lang,
    },
  });
  const { industryList, companySizeList, degreeList, typeList, experienceList } = filterQ.data
    ? filterQ.data
    : {
        degreeList: [],
        typeList: [],
        experienceList: [],
        industryList: [],
        companySizeList: [],
      };

  const { setFilters, entity, setEntityMob }: any = useContext(SearchContext);

  const {
    companyId,
    industry = [],
    types = [],
    companySize = [],
    experience = [],
    degree = [],
    setData,
    setSearchStart,
    setClear,
  }: any = useContext(OpportunitySearchContext);

  const [allIndustry, setAllIndustry] = useState(!industry.length);

  const onSelectIndustry = (item: IOptionItem, action: 'add' | 'rem') => {
    if (allIndustry) {
      setAllIndustry(false);
    }
    if (action === 'add') {
      setData({ industry: industry.concat([item]) });
    } else if (action === 'rem') {
      setData({ industry: industry.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectIndustryAll = () => {
    setAllIndustry(true);
    setData({ industry: [] });
  };

  const onSelectType = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ types: types.concat([item]) });
    } else if (action === 'rem') {
      setData({ types: types.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectSize = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ companySize: companySize.concat([item]) });
    } else if (action === 'rem') {
      setData({ companySize: companySize.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectExperience = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ experience: experience.concat([item]) });
    } else if (action === 'rem') {
      setData({ experience: experience.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectDegree = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ degree: degree.concat([item]) });
    } else if (action === 'rem') {
      setData({ degree: degree.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onCompanyIdClick = () => {
    setData({ companyId: undefined });
    setSearchStart();
  };

  const onSubmit = () => {
    setSearchStart();
    onClose();
    setFilters('entity', entity);
  };

  const onClear = () => {
    setClear();
    setSearchStart();
    onClose();
  };

  useEffect(() => {
    setAllIndustry(!industry.length);
  }, [industry]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.headerTitle} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Search details')}
        </Title>
      </div>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div className={styles.body}>
          {companyId ? <CompanyFilter containerStyle={styles.onFilterByCompany} companyId={companyId} onClick={onCompanyIdClick} /> : null}
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Searching for')}
          </Title>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={setEntityMob}
            noInput
          />
          <Devider size={'xl'} />
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Type')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {typeList.map((typeItem: IOptionItem) => {
              const active = !!types.find((item: IOptionItem) => item.id === typeItem.id);
              return (
                <ButtonToggle
                  key={typeItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={typeItem.name}
                  onPress={() => onSelectType(typeItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Industry')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            <ButtonToggle
              key={'all'}
              size={'lg'}
              active={allIndustry}
              containerStyle={styles.btnToggle}
              text={t('All')}
              onPress={() => onSelectIndustryAll()}
            />
            {industryList.map((industryItem: IOptionItem) => {
              const active = !!industry.find((item: IOptionItem) => item.id === industryItem.id);
              return (
                <ButtonToggle
                  key={industryItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={industryItem.name}
                  onPress={() => onSelectIndustry(industryItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Company size')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {companySizeList.map((sizeItem: IOptionItem) => {
              const active = !!companySize.find((item: IOptionItem) => item.id === sizeItem.id);
              return (
                <ButtonToggle
                  key={sizeItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={sizeItem.name}
                  onPress={() => onSelectSize(sizeItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Required experience')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {experienceList.map((experienceItem: IOptionItem) => {
              const active = !!experience.find((item: IOptionItem) => item.id === experienceItem.id);
              return (
                <ButtonToggle
                  key={experienceItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={experienceItem.name}
                  onPress={() => onSelectExperience(experienceItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Required degree')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {degreeList.map((degreeItem: IOptionItem) => {
              const active = !!degree.find((item: IOptionItem) => item.id === degreeItem.id);
              return (
                <ButtonToggle
                  key={degreeItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={degreeItem.name}
                  onPress={() => onSelectDegree(degreeItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default View;
