import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
    },
    header: {
      width: '100%',
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      background: 'white',
      flexShrink: 0,
      padding: '10px 15px',
      boxShadow: 'inset 0px -1px 0px rgba(145, 165, 182, 0.24)',

      '& svg': {
        cursor: 'pointer',
        flexShrink: 0,
        marginRight: 10,
      },
    },
    headerTitle: {
      margin: 0,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    headLogo: ({ data }: IProps) => ({
      width: 30,
      height: 30,
      borderRadius: '50%',
      backgroundImage: `url(${data && data.logo ? data.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexShrink: 0,
      marginRight: 15,
    }),
    headItem: {
      display: 'flex',
      alignItems: 'center',
    },
    withoutHeadLogo: {
      width: 30,
      height: 30,
      background: theme.$defaultColor1,
      borderRadius: '50%',
      marginRight: 15,
    },
    body: {
      padding: 15,
      flexShrink: 0,
      flexGrow: 1,
      overflow: 'auto',
      background: '#F3F5F8',
    },
    msg: {
      marginBottom: 15,
      wordBreak: 'break-word',

      '&:last-child': {
        marginBottom: 0,
      },
    },
    footer: {
      background: 'white',
      width: '100%',
      flexShrink: 0,
      padding: '10px 15px',
    },
    btn: {
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    btnReject: {
      maxWidth: 'none',
    },
    btnAccept: {
      position: 'relative',
      maxWidth: 'none',
      padding: '0 4rem',
    },
    btnArchived: {
      maxWidth: 'none',
    },
    btnText: {
      fontSize: 15,
      fontWeight: 500,
      color: 'white',
    },
    btnIcon: {
      position: 'absolute',
      display: 'flex',
      right: 10,
    },
  };
});
