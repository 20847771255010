import React, { useEffect, useState, memo, useRef, useContext } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Title from 'src/components/titles/Title/index.web';
import Devider from 'src/components/dividers/Divider/index.web';
import Text from 'src/components/texts/Text/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import Link from 'src/components/links/Link/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import ButtonTransparentToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';

import Markdown from 'src/web/components/Markdown';
import Label from 'src/components/labels/Label/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import { createLabel } from 'src/utilities/languages';
import { addSubscription } from '../../../../../state/modules/ui';
import { useDispatch } from 'react-redux';
import ButtonIcon from 'src/components/buttons/ButtonIcon/index.web';
import { Context as SearchContext } from 'src/contexts/SearchContext';

const Opportunity = memo((props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const {
    id,
    name,
    company_name,
    banner_img,
    promo_video,
    description,
    main_description,
    cities,
    city,
    is_liked,
    is_bookmarked = false,
    experiences,
    degrees,
    types = [],
    languages = [],
    setOpportunityLike,
    setOpportunityBookmark,
    company_size,
    sectors = [],
    created,
    updated,
    deleted,
    companyData,
    application_flow,
    application,
  } = props;

  const { website, id: company_id } = companyData;

  const [textToggleVisible, setTextToggleVisible] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const readMoreOpenedHeight = textAreaRef.current !== null ? textAreaRef.current.scrollHeight + 40 : 300;
  const { setApplyModalShown, setEntityForApply } = useContext(SearchContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showApplyModal = () => {
    setApplyModalShown(true);
    setEntityForApply(props);
  };
  useEffect(() => {
    if (textAreaRef.current) {
      if (textAreaRef.current.scrollHeight > textAreaRef.current?.offsetHeight) {
        setTextToggleVisible(true);
      } else {
        setTextToggleVisible(false);
      }
    }
  }, [main_description]);

  const getDegreeLabel = () => {
    if (degrees && degrees.length > 1) {
      const isBachelor = degrees.find((degree) => degree.name === "Bachelor's");
      const isMaster = degrees.find((degree) => degree.name === "Master's");

      if (isBachelor && isMaster) return t("Bachelor's or Master's");
    }

    return degrees && degrees[0] ? degrees[0].name : '';
  };

  return (
    <div className={styles.component}>
      <div className={styles.companyBackground} />
      <main className={styles.main}>
        <div className={styles.mainHeader}>
          <div className={styles.mainInfoLogo} />
          <div className={styles.sideLogo}>
            <Title size={'h2'} containerStyle={styles.companyTitle}>
              {company_name}
            </Title>
            <div className={styles.labels}>
              <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelSection}`} iconStyle={styles.labelIcon}>
                {sectors && sectors[0] && sectors[0].name}
              </TextIcon>
              <TextIcon iconName={'user'} iconSize={14} containerStyle={`${styles.label} ${styles.labelUser}`} iconStyle={styles.labelIcon}>
                {company_size && company_size.name}
              </TextIcon>
              {website && (
                <TextIcon iconName={'globe'} iconSize={14} containerStyle={`${styles.label} ${styles.labelSite}`} iconStyle={styles.labelIcon}>
                  <Link internal={false} href={website} size={'sm'} containerStyle={styles.link}>
                    <Text inline containerStyle={styles.linkText}>
                      {t('Company website')}
                    </Text>
                  </Link>
                </TextIcon>
              )}
            </div>
            <Link internal href={`/company/${company_id}`} size={'sm'} containerStyle={styles.profileLink}>
              {t('View profile')}
            </Link>
          </div>
        </div>
        <Devider size={'xl'} />
        <div className={`${styles.mainItem} ${styles.mainItemInfo}`}>
          <div className={styles.opportunityInfo}>
            <div className={styles.opportunityInfoItem}>
              <Title size={'h1'} containerStyle={styles.opportunityTitle}>
                {name}
              </Title>
            </div>
            <div className={styles.opportunityInfoItem}>
              {types.length && <Label item={types[0]} containerStyle={styles.labelType} />}
              <TextIcon iconName={'location'} iconSize={14} containerStyle={`${styles.label} ${styles.labelLocation}`} iconStyle={styles.labelIcon}>
                {cities && cities.length ? cities.join(', ') : city || ''}
              </TextIcon>
            </div>
          </div>
          <div className={styles.buttons}>
            {is_liked ? (
              <ButtonMain
                outline
                disabled={!!deleted}
                size={'xl'}
                type={'primary_1'}
                text={t('Interested')}
                containerStyle={styles.setInterest}
                onPress={() => {
                  showApplyModal();
                }}
              />
            ) : (
              <>
                <ButtonMain
                  size={'xl'}
                  disabled={!!deleted}
                  type={'primary_1'}
                  text={t('Show interest')}
                  containerStyle={styles.setInterest}
                  onPress={() => {
                    showApplyModal();
                  }}
                />
                <ButtonIconToggle
                  size={'xl'}
                  disabled={!!deleted}
                  activeIconName={'bookmark-fill'}
                  inactiveIconName={'bookmark'}
                  containerStyle={styles.btnMain}
                  iconSize={20}
                  iconColor={'#F65532'}
                  active={is_bookmarked}
                  onPress={setOpportunityBookmark}
                />
              </>
            )}
            <ButtonIcon
              size="xs"
              type="default"
              active
              activeIconName={'share'}
              iconColor="red"
              iconSize={20}
              containerStyle={styles.shareButton}
              onPress={() => {
                dispatch(addSubscription({ id: '' + id, name, type: 'share' }));
                navigator.clipboard.writeText(window.location.href);
              }}
            />
          </div>
        </div>
        <div className={styles.mainItem}>
          <div className={styles.opportunityOther}>
            <Title size={'h2'}>{t('Who are we looking for')}?</Title>
            <div className={styles.opportunityOtherLabels}>
              <div className={styles.wrapper}>
                <span className={`${styles.tooltip} tooltip`}>{t('Required languages')}</span>
                <TextIcon iconName={'chat'} iconSize={14} containerStyle={`${styles.label} ${styles.labelOther}`} iconStyle={styles.labelIcon}>
                  {createLabel((languages || []).map((item) => item.name))}
                </TextIcon>
              </div>
              <div className={styles.wrapper}>
                <span className={`${styles.tooltip} tooltip`}>{t('Required education')}</span>
                <TextIcon iconName={'award'} iconSize={14} containerStyle={`${styles.label} ${styles.labelOther}`} iconStyle={styles.labelIcon}>
                  {getDegreeLabel()}
                </TextIcon>
              </div>
              <div className={styles.wrapper}>
                <span className={`${styles.tooltip} tooltip`}>{t('Required work experience')}</span>
                <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelOther}`} iconStyle={styles.labelIcon}>
                  {experiences && experiences[0] && experiences[0].name}
                </TextIcon>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mainItem} style={{ display: 'block' }}>
          <div className={styles.overall} style={{ height: readMore ? readMoreOpenedHeight : 'auto', overflowY: readMore ? 'auto' : 'hidden' }}>
            <textarea className={styles.overallValue} value={main_description} disabled ref={textAreaRef} rows={3} />
            {main_description && (readMore ? true : textToggleVisible) ? (
              <div className={styles.overallBottom}>
                <ButtonTransparentToggle
                  active={readMore}
                  text={readMore ? t('Show less') : t('Read full description')}
                  onPress={() => setReadMore(!readMore)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </main>
      <div className={styles.opportunityMarkdown}>
        <Markdown isMobile={false} markdown={description} />
      </div>
    </div>
  );
});

export default Opportunity;
