import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    body: (p: {isMobile: boolean}) => ({
      padding: p.isMobile ? '0 15px 30px' : '0 30px 30px',
    }),
    divider: {
      width: '100%',
    },
    title: {
      margin: '1.5rem 0',
    },
    grades: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '30px',
    },
    grade: {
      marginRight: '10px',

      '&:last-child': {
        marginRight: 0,
      },
    },
    achievements: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    achievement: {
      margin: '10px 10px 0 0',
    },
    label: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '1.5rem 0 0.5rem',
    },
    buttonSwitcher: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonSwitcherLeft: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      maxWidth: 'none',
    },
    buttonSwitcherRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      maxWidth: 'none',
    },
  };
});
