import React, { useContext, useEffect, useState, useCallback } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import SearchInput from '../../../../components/inputs/SearchInput/index.web';
import ButtonFilter from '../../../../components/buttons/ButtonFilter/index.web';
import ButtonToggle from '../../../../components/buttons/ButtonRoundedToggle/index.web';
import Title from '../../../../components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from '../../../../components/inputs/Select/index.web';
import { IOptionItem } from '../../../../requests/models';
import Toggle from '../../../../components/toggles/Toggle/index.web';
import Devider from '../../../../components/dividers/Divider/index.web';
import ButtonMain from '../../../../components/buttons/ButtonMain/index.web';
import Modal from '../../../../web/components/Modal/Modal';
import FilterMobile from './Mobile';
import { Context as SearchContext } from '../../../../contexts/SearchContext';
import { Context as CompaniesSearchContext } from '../../../../contexts/SearchContext/CompaniesSearchContext';
import { useQuery } from '@apollo/client';
import { COMPANIES_DATA_AVAILABLES } from '../../../../requests/queries';
import _ from 'lodash';
import { useHistory } from 'react-router';

const TWO_SECONDS = 2000;

const Filter = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle, isModalOpen, toggleModal, clearScroll } = props;
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const history = useHistory();

  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies') },
    { id: '2', name: 'events', label: t('Events') },
    { id: '3', name: 'opportunities', label: t('Opportunities') },
  ];

  const filterQ = useQuery(COMPANIES_DATA_AVAILABLES, {
    variables: {
      lang,
    },
  });
  const { perksList, industryList, companySizeList } = filterQ.data
    ? filterQ.data
    : {
        perksList: [],
        industryList: [],
        companySizeList: [],
      };

  const { filters, setFilters, setEntity }: any = useContext(SearchContext);
  const { searchValue, hiringCompanies, industry, companySize, perks, setData, setClear, setSearchStart }: any = useContext(CompaniesSearchContext);

  const { entity } = filters;

  const [allIndustry, setAllIndustry] = useState(!industry.length);
  const [ready, setReady] = useState(!filterQ.loading && filterQ.data);
  const [stateSearchValue, setStateSearchValue] = useState(searchValue);

  const onToggleOnlyHiring = () => {
    setData({ hiringCompanies: !hiringCompanies });
  };

  const onSubmit = () => {
    clearScroll();
    setSearchStart();
  };

  const setWithTimeout = useCallback(
    _.debounce((f) => f(), TWO_SECONDS),
    [],
  );

  const onSelectIndustry = (item: IOptionItem, action: 'add' | 'rem') => {
    if (allIndustry) {
      setAllIndustry(false);
    }
    if (action === 'add') {
      setData({ industry: industry.concat([item]) });
    } else if (action === 'rem') {
      setData({ industry: industry.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
    onSubmit();
  };

  const onSelectSize = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ companySize: companySize.concat([item]) });
    } else if (action === 'rem') {
      setData({ companySize: companySize.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
    onSubmit();
  };

  const onSelectPerk = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ perks: perks.concat([item]) });
    } else if (action === 'rem') {
      setData({ perks: perks.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
    onSubmit();
  };

  const onSearchChange = (value: string) => {
    setStateSearchValue(value);
  };

  const onSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
      setFilters('searchValue', stateSearchValue);
    }
  };

  const onSearchValueChangeMob = (value: string) => {
    setFilters('searchValue', value);
    setWithTimeout(setSearchStart);
  };

  const onChooseEntity = (entity: IOptionItem) => {
    history.push(`/search/${entity.name !== 'everything' ? entity.name : 'all'}`);
    clearScroll();
    setFilters('entity', entity);
    setFilters('page', 1);
    setEntity(entity);
  };

  const onClear = () => {
    setClear();
    clearScroll();
    setSearchStart();
  };

  const onFilterPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    if (!filterQ.loading && filterQ.data) {
      setReady(true);
    }
  }, [filterQ.loading, filterQ.data]);

  useEffect(() => {
    setAllIndustry(!industry.length);
  }, [industry]);

  useEffect(() => {
    setStateSearchValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setSearchStart();
    if (entity.id === 1) {
      setFilters('entity', entitiesList[1]);
      setClear();
    }
  }, []);

  return isMobile ? (
    <>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div style={{ display: 'flex' }}>
          <SearchInput value={searchValue} placeholder={t('Search')} onChange={onSearchValueChangeMob} />
          <ButtonFilter size={'xl'} containerStyle={styles.filterBtn} active={isModalOpen} onPress={() => toggleModal(!isModalOpen)} />
        </div>
        {/* <div className={styles.forExample}>
          <Text inline>For example:</Text> <span className={styles.forExampleText}>marketing intern</span>,{' '}
          <span className={styles.forExampleText}>heineken</span>
        </div> */}
      </div>
      <Modal isModalOpened={isModalOpen} onClose={() => toggleModal(false)}>
        {ready && <FilterMobile isMobile={isMobile} onClose={() => toggleModal(false)} />}
      </Modal>
    </>
  ) : (
    <div className={`${styles.container} ${containerStyle}`.trim()} tabIndex={1} onKeyPress={onFilterPress}>
      {ready && (
        <div className={styles.body}>
          <Title size={'h1'} containerStyle={styles.filterTitle}>
            {t('Search')}
          </Title>
          <div className={styles.filterBlockSearchInput}>
            <SearchInput value={stateSearchValue} placeholder={t('Search')} onKeyPress={onSearchKeyPress} onChange={onSearchChange} />
            {/* <div className={styles.forExample}>
              <Text inline>For example:</Text> <span className={styles.forExampleText}>marketing intern</span>,{' '}
              <span className={styles.forExampleText}>heineken</span>
            </div> */}
          </div>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={onChooseEntity}
            noInput
          />
          <div className={styles.filterHiringCompanies}>
            <Title size={'h3'}>{t('Only hiring companies')}</Title>
            <Toggle active={hiringCompanies} onPress={onToggleOnlyHiring} />
          </div>
          <Devider size={'xl'} />
          <Title size={'h2'} containerStyle={styles.filterTitle}>
            {t('Industry')}
          </Title>
          <div className={styles.btnToggleList}>
            {industryList.map((industryItem: IOptionItem) => {
              const active = !!industry.find((item: IOptionItem) => item.id === industryItem.id);
              return (
                <ButtonToggle
                  key={industryItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={industryItem.name}
                  onPress={() => onSelectIndustry(industryItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={styles.filterTitle}>
            {t('Company size')}
          </Title>
          <div className={styles.btnToggleList}>
            {companySizeList.map((sizeItem: IOptionItem) => {
              const active = !!companySize.find((item: IOptionItem) => item.id === sizeItem.id);
              return (
                <ButtonToggle
                  key={sizeItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={sizeItem.name}
                  onPress={() => onSelectSize(sizeItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={styles.filterTitle}>
            {t('Perks')}
          </Title>
          <div className={styles.btnToggleList}>
            {perksList.map((perkItem: any, index: number) => {
              const active = !!perks.find((item: IOptionItem) => item.id === perkItem.id);

              return (
                <ButtonToggle
                  key={perkItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={`${styles.btnToggle} ${styles.btnTogglePerk}`}
                  textStyle={styles.perkItemText}
                  onPress={() => onSelectPerk(perkItem, active ? 'rem' : 'add')}>
                  <div className={styles.perkItemName}>{perkItem.name}</div>
                  <div className={styles.perkItemValue}>{perkItem.value}</div>
                </ButtonToggle>
              );
            })}
          </div>
        </div>
      )}
      <Devider size={'xl'} />
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default Filter;
