import React from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles.web';
import MessageCounter from '../../../web/components/MessageCounter';
import { ITheme } from '../../../constant/themes';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface ComponentStyles {
  component?: string;
  content?: string;
  svg?: string;
  counter?: string;
}

const RoundedProgress = (props: IProps) => {
  const { children, containerStyle, contentStyle, value = 0 } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    content: `${styles.content} ${contentStyle}`.trim(),
    svg: `${styles.svg}`.trim(),
    counter: `${styles.counter}`.trim(),
  };

  return (
    <CircularProgressbarWithChildren
      className={cn.component}
      text={''}
      styles={{
        path: {
          stroke: theme.$primaryColor1,
          strokeWidth: 4,
        },
        trail: {
          strokeWidth: 5,
        },
      }}
      value={value}>
      <div className={cn.content}>
        <MessageCounter className={cn.counter} />
        {children}
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default RoundedProgress;
