import React, { useEffect, useState, memo, useContext } from 'react';
import { withRouter, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDataOperation } from 'src/state/modules/onboarding';
import { OnboardingData } from '../../requests/models';
import { Context as ProfileContext } from '../../web/contexts/ProfileContexts/index.web';
import { Storage } from '../../utilities/Storage';

interface IProps extends RouteComponentProps {
  children: any;
}

const storage = new Storage(false);

const WithReferrer = memo((props: IProps) => {
  const dispatch = useDispatch();
  const setData = (data: OnboardingData) => dispatch(setDataOperation(data, false));
  const { profileData }: any = useContext(ProfileContext);

  const query = new URLSearchParams(useLocation().search);
  const referrer = process.env.REACT_APP_REFERRER_PARAM ? process.env.REACT_APP_REFERRER_PARAM : 'referrer';
  const referrer_q = query.get(referrer) ? query.get(referrer) : '';

  useEffect(() => {
    if (profileData && !profileData.is_profile_created) {
      if (referrer_q) {
        //@ts-ignore
        storage.setReferrer(referrer_q);
      }
    }
  }, []);

  return props.children;
});

export default withRouter<IProps, any>(WithReferrer);
