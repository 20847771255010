import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile }: IProps) => {
      return {
        display: 'block',
        margin: isMobile ? 30 : 60,
        textAlign: isMobile ? 'center' : 'left',
      };
    },
    img: {},
    title: ({ isMobile }: IProps) => ({
      fontSize: isMobile ? 21 : 25,
      justifyContent: isMobile ? 'center' : 'left',
      marginBottom: 20,
    }),
    subtitle: {
      marginBottom: 5,
    },
    text: {},
  };
});
