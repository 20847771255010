import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

type DefaultTheme = object | undefined;

export const useStyles = (p: IProps, t: DefaultTheme) =>
  createUseStyles((theme: ITheme) => {
    const { size, type } = p;

    const componentBackgroundColor = {
      primary: theme.$primaryColor1,
      secondary: theme.$secondaryColor1,
      default: theme.$defaultColor1,
    };
    const componentSize = {
      xl: '5.4rem',
      lg: '4.4rem',
      xs: '4.4rem',
    };

    return {
      component: {
        width: '100%',
        maxWidth: '18rem',
        height: componentSize[size],
        backgroundColor: componentBackgroundColor[type],
        borderRadius: '1rem',
      },
      icon: {},
    };
  })(t);
