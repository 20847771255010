import React, { useContext, useEffect, useState, useCallback } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import SearchInput from 'src/components/inputs/SearchInput/index.web';
import ButtonFilter from 'src/components/buttons/ButtonFilter/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/inputs/Select/index.web';
import { IOptionItem } from 'src/requests/models';
import Devider from 'src/components/dividers/Divider/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Modal from 'src/web/components/Modal/Modal';
import CompanyFilter from 'src/web/components/CompanyFilter';
import FilterMobile from './Mobile';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { Context as EventSearchContext } from 'src/contexts/SearchContext/EventSearchContext';
import { useQuery } from '@apollo/client';
import { EVENTS_DATA_AVAILABLES } from 'src/requests/queries';
import DatePicker from 'src/components/inputs/DatePicker/index.web';
import _ from 'lodash';
import { useHistory } from 'react-router';

const TWO_SECONDS = 2000;

const View = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle, isModalOpen, toggleModal, clearScroll } = props;
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies') },
    { id: '2', name: 'events', label: t('Events') },
    { id: '3', name: 'opportunities', label: t('Opportunities') },
  ];
  const history = useHistory();
  const filterQ = useQuery(EVENTS_DATA_AVAILABLES, {
    variables: {
      lang,
    },
  });
  const { typeList } = filterQ.data
    ? filterQ.data
    : {
        typeList: [],
      };

  const { filters, setFilters, setEntity }: any = useContext(SearchContext);
  const { searchValue, types, companyId, startDate, endDate, setData, setSearchStart, setClear }: any = useContext(EventSearchContext);

  const { entity } = filters;

  const [ready, setReady] = useState(!filterQ.loading && filterQ.data);

  const onSearchValueChange = (value: string) => {
    setFilters('searchValue', value);
  };

  const onSubmit = () => {
    clearScroll();
    setSearchStart();
  };

  const setWithTimeout = useCallback(
    _.debounce((f) => f(), TWO_SECONDS),
    [],
  );

  const onSearchValueChangeMob = (value: string) => {
    setFilters('searchValue', value);
    setWithTimeout(setSearchStart);
  };

  const onChooseEntity = (entity: IOptionItem) => {
    history.push(`/search/${entity.name !== 'everything' ? entity.name : 'all'}`);
    clearScroll();
    setFilters('entity', entity);
    setFilters('page', 1);
    setEntity(entity);
  };

  const onSelectType = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ types: types.concat([item]) });
    } else if (action === 'rem') {
      setData({ types: types.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
    onSubmit();
  };

  const onStartDateChange = (value: any) => {
    setData({ startDate: value });
    onSubmit();
  };

  const onEndDateChange = (value: any) => {
    setData({ endDate: value });
    onSubmit();
  };

  const onCompanyIdClick = () => {
    setData({ companyId: undefined });
    setSearchStart();
  };

  const onClear = () => {
    setClear();
    clearScroll();
    setSearchStart();
  };

  const onFilterPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    setSearchStart();
    if (entity.id === 2) {
      setFilters('entity', entitiesList[2]);
      setClear();
    }
  }, []);

  useEffect(() => {
    if (!filterQ.loading && filterQ.data) {
      setReady(true);
    }
  }, [filterQ.loading, filterQ.data]);

  return isMobile ? (
    <>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div style={{ display: 'flex' }}>
          <SearchInput value={searchValue} placeholder={t('Search')} onChange={onSearchValueChangeMob} />
          <ButtonFilter size={'xl'} containerStyle={styles.filterBtn} active={isModalOpen} onPress={() => toggleModal(!isModalOpen)} />
        </div>
        {/* <div className={styles.forExample}>
          <Text inline>{t('For example')}:</Text> <span className={styles.forExampleText}>marketing intern</span>,{' '}
          <span className={styles.forExampleText}>heineken</span>
        </div> */}
      </div>
      <Modal isModalOpened={isModalOpen} onClose={() => toggleModal(false)}>
        {ready && <FilterMobile isMobile={isMobile} onClose={() => toggleModal(false)} />}
      </Modal>
    </>
  ) : (
    <div className={`${styles.container} ${containerStyle}`.trim()} tabIndex={1} onKeyPress={onFilterPress}>
      {ready && (
        <div className={styles.body}>
          {companyId ? <CompanyFilter containerStyle={styles.onFilterByCompany} companyId={companyId} onClick={onCompanyIdClick} /> : null}
          <Title size={'h1'} containerStyle={styles.filterTitle}>
            {t('Search')}
          </Title>
          <div className={styles.filterBlockSearchInput}>
            <SearchInput value={searchValue} placeholder={t('Search')} onChange={onSearchValueChange} />
            {/* <div className={styles.forExample}>
              <Text inline>{t('For example')}:</Text> <span className={styles.forExampleText}>marketing intern</span>,{' '}
              <span className={styles.forExampleText}>heineken</span>
            </div> */}
          </div>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={onChooseEntity}
            noInput
          />
          <Devider size={'xl'} />
          <Title size={'h2'} containerStyle={styles.filterTitle}>
            {t('Type')}
          </Title>
          <div className={styles.btnToggleList}>
            {typeList.map((industryItem: IOptionItem) => {
              const active = !!types.find((item: IOptionItem) => item.id === industryItem.id && item.name === industryItem.name);
              return (
                <ButtonToggle
                  key={industryItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={industryItem.name}
                  onPress={() => onSelectType(industryItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={styles.filterTitle}>
            {t('Date')}
          </Title>
          <Title size={'h3'} containerStyle={styles.dateStartTitle}>
            {t('From')}
          </Title>
          <DatePicker isMobile={isMobile} value={startDate} onChange={onStartDateChange} type={'date'} showTimeInput maxDate={endDate} />
          <Title size={'h3'} containerStyle={styles.dateEndTitle}>
            {t('To')}
          </Title>
          <DatePicker isMobile={isMobile} value={endDate} onChange={onEndDateChange} type={'date'} showTimeInput minDate={startDate} />
        </div>
      )}
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default View;
