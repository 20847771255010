import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      position: 'fixed',
      width: 360,
      top: 100,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1002,
    },
    subscriptions: {
      position: 'relative',
      background: '#FFFFFF',
      border: '1px solid #E8EFF4',
      boxShadow: '0px 3px 13px -2px rgba(0, 0, 0, 0.25)',
      borderRadius: '15px',
    },
    subscription: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    text: {
      color: '#000000',
      fontFamily: 'Inter',
      fontSize: 12,
      lineHeight: '16px',
    },
    subscriptionName: {
      fontSize: 'inherit',
      wordBreak: 'break-all',
      fontFamily: 'inherit',
    },
    checkmark: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      width: 40,
      height: 40,
      margin: '0 10px',
    },
    grayLine: {
      width: '38px',
      height: '4px',
      background: '#EFEFEF',
      borderRadius: '2px',
      margin: '6px auto',
    },
  };
});
