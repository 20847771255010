import { TErrorActionType, EErrorActionTypes, IErrorObject, IError } from './error.types';

export const setErrorMessage = (error: IError, type: string): TErrorActionType => ({
  type: EErrorActionTypes.SET_MESSAGE,
  value: { error, type },
});

export const clearMessage = (id: string): TErrorActionType => ({
  type: EErrorActionTypes.CLEAR_MESSAGE,
  value: id,
});

export const clearAllMessages = (): TErrorActionType => ({
  type: EErrorActionTypes.CLEAR_ALL_MESSAGES,
});
