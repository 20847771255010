import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: (p: IProps) => ({
      width: '100%',
      maxWidth: 315,
      height: 10,
      borderRadius: 15,
      backgroundColor: theme.$borderColor,
    }),
    content: (p: IProps) => ({
      maxWidth: '100%',
      height: '100%',
      borderRadius: 15,
      transition: '0.5s all ease',
      backgroundColor: p.color ? p.color : theme.$primaryColor1,
    }),
  };
});
