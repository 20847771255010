import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    root: (p: IProps) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: 'white',
      width: '100%',
      height: '100%',
      borderRadius: p.isMobile ? 0 : 15,
    }),
    container: (p: IProps) => ({
      width: '100%',
      maxWidth: p.isMobile ? 510 : 'none',
      padding: p.isMobile ? '0 15px 30px' : '0 30px 30px',
      overflow: 'auto',
    }),
    header: (p: IProps) => ({
      width: '100%',
      height: 60,
      display: 'flex',
      background: 'rgba(232, 239, 244, 0.25)',
      flexShrink: 0,
      padding: '10px 30px',
      borderTopLeftRadius: p.isMobile ? 0 : 15,
      borderTopRightRadius: p.isMobile ? 0 : 15,
    }),
    title: {
      margin: 0,

      '& svg': {
        cursor: 'pointer',
        marginRight: 10,
        marginLeft: -10,
      },
    },
    titleAchievements: {
      marginTop: 15,
    },
    list: {},
    add: {
      width: '100%',
      padding: '30px 0',
    },
    addBtn: {
      maxWidth: 'none',
    },
    footer: (p) => ({
      padding: p.isMobile ? '30px 15px' : '30px 30px',
      width: '100%',
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'center',
    }),
    footerButtonBack: {
      width: '25%',
      maxWidth: 'none',
      marginRight: 15,
    },
    footerButtonNext: {
      width: 'calc(75% - 15px)',
      maxWidth: 'none',
    },
    achievements: (p) => ({
      height: 'auto',
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: 'auto',
      alignContent: 'flex-start',
    }),
    achievement: {
      margin: '10px 10px 0 0',
    },
    textAreaTexts: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '30px 0 0',
    },
    textArea: {
      padding: 15,
      maxWidth: 'none',
      height: 162,
    },
    textAreaInput: {
      width: '100%',
      height: '100%',
      resize: 'none',
      borderRadius: 0,
    },
    textAreaMax: {
      marginRight: 10,
    },
    fieldLabel: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '0.5rem 0',
    },
  };
});
