import React, { FC, useEffect } from 'react';
import { useTheme } from 'react-jss';
import Header from 'src/web/components/Header';
import Support from 'src/web/components/Support';
import { IProps } from './types';
import { ITheme } from 'src/constant/themes';
import styles from './styles.module.scss';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { useLocation } from 'react-router-dom';
import { Storage } from 'src/utilities/Storage';
import { Platform } from 'react-native';
import { resources } from '../../../i18n';
import { Helmet } from 'react-helmet';

const storage = new Storage(Platform.OS !== 'web');

const SupportPage: FC<IProps> = (props) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const query = new URLSearchParams(useLocation().search);
  const qLang = query.get('lang');
  const { t, i18n } = useTranslation();
  const { isMobile } = props;

  useEffect(() => {
    if (qLang && qLang in resources) {
      i18n.changeLanguage(qLang);
      storage.setUserLanguage(qLang);
    }
  }, [qLang]);

  return (
    <>
      <Helmet>
        <title>{'Support'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          <div className={styles.container}>
            {/*{!isMobile && <Title size={'h1'} containerStyle={styles.mainTitle}>{t('Support')}</Title>}*/}
            <Support isMobile={isMobile} />
          </div>
        </div>
      </ErrorBoundaries>
    </>
  );
};

export default SupportPage;
