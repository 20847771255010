import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: {
      position: 'relative',
      width: '100%',
      paddingBottom: 94,
    },
    headerReplace: {
      position: 'fixed',
      display: 'flex',
      padding: '0 20px',
      top: 0,
      left: 0,
      width: '100%',
      height: 54,
      background: 'white',
      zIndex: 100,
    },
    companyBackground: ({ background_photo }: IProps) => ({
      backgroundImage: `url(${background_photo ? background_photo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: 200,
    }),
    title: {
      margin: 0,
      lineHeight: '21px',

      '& svg': {
        cursor: 'pointer',
        marginRight: 10,
      },
    },
    main: {
      padding: 15,
      background: 'white',
      maxWidth: 512,
      width: '100%',
      margin: '0 auto',
    },
    mainTop: {
      display: 'flex',
    },
    mainInfoLogo: ({ logo }: IProps) => ({
      width: 72,
      height: 72,
      borderRadius: 3,
      marginRight: 15,
      flexShrink: 0,
      backgroundImage: `url(${logo ? logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    sideLogo: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    labels: {
      display: 'flex',
    },
    label: {
      justifyContent: 'flex-start',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    labelIcon: {},
    labelLocation: {
      // maxWidth: 80,
    },
    labelSection: {
      // maxWidth: 100,
    },
    labelUser: {
      // maxWidth: 80,
    },
    mainInfoTitle: {},
    mainInfoSite: {
      color: theme.$primaryColor1,
      fontSize: 12,
      lineHeight: '16px',
    },
    mainInfoLabels: {},
    linksContainer: {
      padding: '15px 0',
    },
    links: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    },
    linksIcon: {
      position: 'absolute',
      display: 'flex',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    link: {
      textTransform: 'capitalize',
      margin: 20,
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 5,
    },
    tag: {
      padding: '5px 12px',
      background: 'rgba(232, 239, 244, 0.5)',
      borderRadius: '3px',
      fontSize: 12,
      lineHeight: '16px',
      color: '#656771',
      margin: '0 10px',

      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    overall: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      transition: '0.5s all ease',
      overflowY: 'hidden',
      wordBreak: 'break-all',
    },
    overallValue: {
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      resize: 'none',
      overflow: 'hidden',
    },
    overallTitle: {
      width: '100%',
      display: 'none',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 0,
    },
    overallBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0 0',
      marginBottom: 10,
    },
    perks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      boxSizing: 'border-box',
      borderRadius: 10,
    },
    btnToggle: {},
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    perkItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '10px 20px 15px',
    },
    perkItemBtn: {
      width: 54,
      height: 54,
      borderRadius: 27,
      margin: 0,
      border: 'none',
    },
    perkItemValue: {
      fontSize: 24,
    },
    perkItemName: {},
    demoToggleTitle: {
      maxWidth: '37rem',
      width: '100%',
      cursor: 'pointer',
    },
    goals: {
      //overflow: 'hidden'
      borderTop: 'none',
      padding: 0,
    },
    howToStart: {
      borderTop: 'none',
      padding: 0,
    },
    workEnvironment: {
      borderTop: 'none',
      padding: 0,
    },
    moreInfo: {
      borderTop: 'none',
      padding: 0,
    },
    opportunityItems: {
      position: 'relative',
      marginTop: 70,
    },
    opportunityItemsContent: {},
    opportunityItemsList: {},
    eventItems: {
      position: 'relative',
      marginTop: 70,
    },
    eventItemsContent: {},
    eventItemsList: {},
    mainRightItem: {
      background: 'white',
      flexGrow: 1,
      padding: 30,
      borderRadius: 15,
    },
    mainRightItemTitle: {
      position: 'absolute',
      top: -40,
      left: 0,
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 94,
      padding: '0 15px 20px',
      background: 'white',
      borderTop: `1px solid ${theme.$borderColor}`,
    },
    setInterest: {
      maxWidth: 'none',
    },
  };
});
