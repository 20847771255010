import React, { memo, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { addDays } from 'date-fns';
import { useStyles } from './styles';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import Title from 'src/components/titles/Title/index.web';
import Devider from 'src/components/dividers/Divider/index.web';
import Text from 'src/components/texts/Text/index.web';
import Input from 'src/components/inputs/TextInput/index.web';
import Select from 'src/components/inputs/Select/index.web';
import DatePicker from 'src/components/inputs/DatePicker/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import { IOptionItem } from 'src/requests/models';
import { useQuery } from '@apollo/client';
import { LIST_CITIES } from 'src/requests/queries';
import Icon from 'src/components/icons/Icon/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { useAuth0 } from '@auth0/auth0-react';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';

import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateName, validatePhone } from '../../../../utilities/validation';
import CombinedInput from 'src/components/combined/InputField/index.web';

interface IState {
  firstName: string;
  lastName: string;
  birthday: Date;
  country: IOptionItem;
  city: IOptionItem;
  sex: IOptionItem;
  contactEmail: string;
  bio: string;
  socialMedia: any;
  educationLevel: IOptionItem;
  phone_number: string;
  referrer: string;
}

const reducer = (state: IState, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...state, [action.key]: action.value };
    default:
      throw new Error();
  }
};

const Edit = memo((props: IProps) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, setPage, countries, sexes, educationLevels, onClose, scrollTo } = props;
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  const { user } = useAuth0();
  const {
    firstName,
    lastName,
    birthday,
    country,
    city,
    sex,
    bio,
    contactEmail,
    socialMedia,
    educationLevel,
    dataReady,
    setData,
    onSaveData,
    phone_number,
    referrer,
  }: any = useContext(ProfileContext);
  const { i18n } = useTranslation();
  const {
    data = { cities: [] },
    loading,
    error,
  } = useQuery(LIST_CITIES, {
    variables: {
      lang: i18n.language,
      country: country ? country.name : '',
    },
  });

  const initialState = useMemo(
    () => ({
      firstName,
      lastName,
      birthday,
      country,
      city,
      sex,
      bio,
      socialMedia,
      contactEmail,
      educationLevel,
      phone_number,
      referrer,
    }),
    [firstName, lastName, country, city, sex, bio, socialMedia, educationLevel, contactEmail, phone_number, referrer],
  );

  const socials: any = {};

  const socialsRef = useRef(null);
  const shortBioRef = useRef(null);

  const [localState, dispatch] = useReducer(reducer, initialState);

  const nextDisabled = !(
    firstName &&
    lastName &&
    sex &&
    localState.birthday &&
    country.name &&
    city.name &&
    validateEmail(localState.contactEmail) &&
    validatePhone(localState.phone_number)
  );

  localState.socialMedia.map((media: any) => {
    socials[media.type] = media;
  });

  const onInputFirstName = (firstName: string) => {
    dispatch({ type: 'set', key: 'firstName', value: firstName });
  };

  const onInputLastName = (lastName: string) => {
    dispatch({ type: 'set', key: 'lastName', value: lastName });
  };

  const onInputEmail = (contactEmail: string) => {
    dispatch({ type: 'set', key: 'contactEmail', value: contactEmail });
  };

  const onInputPhoneNumber = (phone_number: string) => {
    dispatch({ type: 'set', key: 'phone_number', value: phone_number });
    setPhoneIsValid(validatePhone(phone_number));
  };

  const onInputReferrer = (referrer: string) => {
    dispatch({ type: 'set', key: 'referrer', value: referrer.toUpperCase() });
  };

  const onInput1Validation = (value: string) => {
    return validateName(value);
  };

  const onInputFacebook = (url: string) => {
    const index = localState.socialMedia.findIndex((media: any) => media.type === 'facebook');
    const socialMediaNew = localState.socialMedia.slice();
    if (index >= 0) {
      socialMediaNew.splice(index, 1, { type: 'facebook', url });
    } else {
      socialMediaNew.push({ type: 'facebook', url });
    }
    dispatch({ type: 'set', key: 'socialMedia', value: socialMediaNew });
  };

  const onInputLinkedIn = (url: string) => {
    const index = localState.socialMedia.findIndex((media: any) => media.type === 'linkedin');
    const socialMediaNew = localState.socialMedia.slice();
    if (index >= 0) {
      socialMediaNew.splice(index, 1, { type: 'linkedin', url });
    } else {
      socialMediaNew.push({ type: 'linkedin', url });
    }
    dispatch({ type: 'set', key: 'socialMedia', value: socialMediaNew });
  };

  const onInputInstagram = (url: string) => {
    const index = localState.socialMedia.findIndex((media: any) => media.type === 'instagram');
    const socialMediaNew = localState.socialMedia.slice();
    if (index >= 0) {
      socialMediaNew.splice(index, 1, { type: 'instagram', url });
    } else {
      socialMediaNew.push({ type: 'instagram', url });
    }
    dispatch({ type: 'set', key: 'socialMedia', value: socialMediaNew });
  };

  const onInputGitHub = (url: string) => {
    const index = localState.socialMedia.findIndex((media: any) => media.type === 'github');
    const socialMediaNew = localState.socialMedia.slice();
    if (index >= 0) {
      socialMediaNew.splice(index, 1, { type: 'github', url });
    } else {
      socialMediaNew.push({ type: 'github', url });
    }
    dispatch({ type: 'set', key: 'socialMedia', value: socialMediaNew });
  };

  const onInputBio = (value: string) => {
    dispatch({ type: 'set', key: 'bio', value });
  };

  const onSelectWhoAreYou = (value: IOptionItem) => {
    dispatch({ type: 'set', key: 'educationLevel', value });
  };

  const onSelectSex = (value: IOptionItem) => {
    dispatch({ type: 'set', key: 'sex', value });
  };

  const onSelectBirthDate = (value: any) => {
    dispatch({ type: 'set', key: 'birthday', value });
  };

  const onSelectCountry = (chosenCountry: IOptionItem) => {
    if (country && country.name !== chosenCountry.name) {
      dispatch({ type: 'set', key: 'city', value: { id: '', name: '' } });
    }
    dispatch({ type: 'set', key: 'country', value: chosenCountry });
  };

  const onSelectCity = (value: IOptionItem) => {
    dispatch({ type: 'set', key: 'city', value });
  };

  const onCancelPress = () => {
    onClose();
  };

  const onSavePress = async () => {
    try {
      if (validateEmail(localState.contactEmail)) {
        setData(localState);
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPage('profileEdit');
    if (scrollTo && socialsRef.current) {
      if (scrollTo === 'socials') {
        //@ts-ignore
        socialsRef.current.scrollIntoView();
      } else if (scrollTo === 'bio') {
        //@ts-ignore
        shortBioRef.current.scrollIntoView();
      }
    }
  }, []);

  const { t } = useTranslation();

  const displayedSocial = useMemo(() => {
    const result: any = {};
    localState.socialMedia.map((item: any) => {
      result[item.type] = item.url;
    });
    return result;
  }, [localState.socialMedia]);

  const countryOfResidence = countries.filter((c) => c.name === 'Belgium');
  return dataReady ? (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} />
          {` ${t('Edit Profile')}`}
        </Title>
      </div>
      <div className={styles.container}>
        <div className={`${styles.item} ${styles.details}`}>
          <Title size={'h2'}>{`${t('Personal details')}`}</Title>
          <div className={styles.fields}>
            <div className={styles.fieldHalf}>
              <Text containerStyle={styles.fieldLabel}>{`${t('First name')}`}</Text>
              <Input
                value={localState.firstName}
                placeholder={t('First name')}
                containerStyle={styles.fieldInput}
                onChange={onInputFirstName}
                validate={onInput1Validation}
              />
            </div>
            <div className={styles.fieldHalf}>
              <Text containerStyle={styles.fieldLabel}>{`${t('Last name')}`}</Text>
              <Input
                value={localState.lastName}
                placeholder={t('Last name')}
                containerStyle={styles.fieldInput}
                onChange={onInputLastName}
                validate={onInput1Validation}
              />
            </div>
            <div className={styles.fieldHalf}>
              <Text containerStyle={styles.fieldLabel}>{t('Sex')}</Text>
              <Select value={localState.sex} containerStyle={styles.fieldInput} placeholder={t('Select sex')} list={sexes} onChoose={onSelectSex} />
            </div>
            <div className={styles.field}>
              <Text containerStyle={styles.fieldLabel}>{`${t('Birth date')}`}</Text>
              <DatePicker
                isMobile={isMobile}
                value={localState.birthday ? new Date(localState.birthday) : undefined}
                maxDate={addDays(Date.now(), 0)}
                containerStyle={styles.fieldInput}
                onChange={onSelectBirthDate}
                openToDate={new Date('1998/01/01')}
                type={'date'}
              />
            </div>
            <div className={styles.fieldHalf}>
              <Text containerStyle={styles.fieldLabel}>{t('Country of residence')}</Text>
              <Select value={localState.country} activeBy={'name'} containerStyle={styles.fieldInput} list={countryOfResidence} onChoose={onSelectCountry} />
            </div>
            <div className={styles.fieldHalf}>
              <Text containerStyle={styles.fieldLabel}>{t('City / Town')}</Text>
              <Select value={localState.city} activeBy={'name'} containerStyle={styles.fieldInput} list={data.cities} onChoose={onSelectCity} />
            </div>
            {contactEmail && (
              <div className={styles.field}>
                <Text containerStyle={styles.fieldLabel}>{t('Contact email')}</Text>
                <CombinedInput
                  type={'email'}
                  placeholder={'example@mail.com'}
                  defaultValue={contactEmail}
                  value={contactEmail}
                  containerStyle={styles.fieldInput}
                  inputStyle={styles.fieldInput}
                  label={''}
                  onChange={onInputEmail}
                />
              </div>
            )}
            <div className={styles.field}>
              <Text containerStyle={styles.fieldLabel}>{t('Phone number')}</Text>
              <Input
                instantOnChange
                value={localState.phone_number}
                placeholder="+32 478 45 25 69"
                containerStyle={phoneIsValid ? styles.fieldInput : styles.invalidInput}
                inputStyle={styles.fieldInput}
                onChange={onInputPhoneNumber}
              />
            </div>
            <div className={styles.field}>
              <Text containerStyle={styles.fieldLabel}>Referral code</Text>
              <Input toUpper value={localState.referrer} placeholder={t('ABCD-1234')} containerStyle={styles.fieldInput} onChange={onInputReferrer} />
            </div>
          </div>
        </div>
        <Devider size={'xl'} />
        <div className={`${styles.item} ${styles.more}`}>
          <Title size={'h2'}>{`${t('More about you')}`}</Title>
          <Text containerStyle={styles.fieldLabel}>{t('Profile type')}</Text>
          <div className={styles.list}>
            {educationLevels.map((item: IOptionItem) => {
              return (
                <ButtonToggle
                  key={item.id}
                  containerStyle={styles.listItem}
                  size={'md'}
                  text={item.name}
                  active={item.id === localState.educationLevel.id}
                  onPress={() => onSelectWhoAreYou(item)}
                />
              );
            })}
          </div>
          <div className={styles.textAreaTexts} ref={shortBioRef}>
            <Text containerStyle={styles.fieldLabel}>{t('Short bio')}</Text>
            <Text type={'secondary'} containerStyle={styles.textAreaMax}>
              500 {t('characters max')}
            </Text>
          </div>
          <Input value={localState.bio} containerStyle={styles.textArea} inputStyle={styles.textAreaInput} type={'textarea'} onChange={onInputBio} />
        </div>
        <Devider size={'xl'} />
        <div className={styles.socials} ref={socialsRef}>
          <Title size={'h2'}>{t('Social media')}</Title>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{`${t('Facebook')}`}</Text>
            <div className={styles.socialInput}>
              <div className={styles.socialIcon}>
                <Icon name={'facebook'} size={20} color={theme.$secondaryColor1} />
              </div>
              <Input
                value={displayedSocial.facebook ? displayedSocial.facebook : ''}
                placeholder={`${t('Facebook')} ${t('handle')}`}
                containerStyle={styles.fieldInput}
                onChange={onInputFacebook}
              />
            </div>
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{`${t('Instagram')}`}</Text>
            <div className={styles.socialInput}>
              <div className={styles.socialIcon}>
                <Icon name={'instagram'} size={20} color={theme.$secondaryColor1} />
              </div>
              <Input
                value={displayedSocial.instagram ? displayedSocial.instagram : ''}
                placeholder={`${t('Instagram')} ${t('handle')}`}
                containerStyle={styles.fieldInput}
                onChange={onInputInstagram}
              />
            </div>
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{`${t('LinkedIn')}`}</Text>
            <div className={styles.socialInput}>
              <div className={styles.socialIcon}>
                <Icon name={'linkedin'} size={20} color={theme.$secondaryColor1} />
              </div>
              <Input
                value={displayedSocial.linkedin ? displayedSocial.linkedin : ''}
                placeholder={`${t('LinkedIn')} ${t('handle')}`}
                containerStyle={styles.fieldInput}
                onChange={onInputLinkedIn}
              />
            </div>
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('GitHub')}</Text>
            <div className={styles.socialInput}>
              <div className={styles.socialIcon}>
                <Icon name={'github'} size={20} color={theme.$secondaryColor1} />
              </div>
              <Input
                value={displayedSocial.github ? displayedSocial.github : ''}
                placeholder={`${t('GitHub')} ${t('handle')}`}
                containerStyle={styles.fieldInput}
                onChange={onInputGitHub}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonMain type={'primary_2'} size={'xl'} text={t('Cancel')} containerStyle={styles.footerButtonBack} outline onPress={onCancelPress} />
        <ButtonMain disabled={nextDisabled} type={'primary_1'} size={'xl'} text={t('Save')} containerStyle={styles.footerButtonNext} onPress={onSavePress} />
      </div>
    </div>
  ) : null;
});

export default Edit;
