import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionReady, selectToken } from '../../state/modules/session';
import { useAuth } from '../../hooks/useAuth';
import { useApolloClient } from './useApolloClient';

interface IProps {
  children: any;
}

const WithApolloWeb = (props: IProps) => {
  const { children } = props;
  const dispatch = useDispatch();
  const usedToken = useSelector((state) => selectToken(state));

  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const isReady = useMemo(() => {
    const currentRout = location.pathname.split('/')[1];
    const allowedRouts = ['login', 'privacy', 'terms', 'support'];
    return isAuthenticated || allowedRouts.includes(currentRout);
  }, [isAuthenticated, location.pathname]);

  const client = useApolloClient(usedToken);

  useEffect(() => {
    if (usedToken) {
      dispatch(setSessionReady(true));
    }
  }, [usedToken]);

  return isReady ? <ApolloProvider client={client}>{children}</ApolloProvider> : <></>;
};

export default WithApolloWeb;
