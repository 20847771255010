import React, { useContext, useEffect, useState, memo, useRef } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Icon from 'src/components/icons/Icon/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useHistory } from 'react-router';
import Devider from 'src/components/dividers/Divider/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import Link from 'src/components/links/Link/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import FixedOpportunityList from 'src/web/components/Opportunity/List/Fixed';
import FixedEventList from 'src/web/components/Event/List/Fixed';
import ButtonTransparentToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import ContentToggle from 'src/components/combined/ContentToggle/index.web';
import ReactMarkdown from 'react-markdown';
//@ts-ignore
import emoji from 'emoji-dictionary';
import { markdownList } from 'src/utilities';
import { useTranslation } from 'react-i18next';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Markdown from 'src/web/components/Markdown';
import { getSlicedText } from '../../../../../utilities';
import { isNull } from 'util';

const emojiSupport = (text: any) => text.value.replace(/:\w+:/gi, (name: any) => emoji.getUnicode(name));

const MarkdownItem = ({ title, styles, content, index }: { title: any; styles: any; content: any; index: number }) => {
  const [isOpen, setIsOpen] = useState(index === 0);

  return (
    <ContentToggle
      noIcon
      active={isOpen}
      containerStyle={styles.toggleText}
      contentStyle={styles.toggleTextItem}
      title={<ReactMarkdown source={title} renderers={{ text: emojiSupport }} />}
      onPress={() => setIsOpen(!isOpen)}>
      <ReactMarkdown source={content} escapeHtml={false} />
    </ContentToggle>
  );
};

const Company = memo((props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const {
    id,
    name,
    website,
    logo,
    cities,
    city,
    additional_info,
    bio,
    company_size = { name: '' },
    emoji = [],
    // tags = [],
    socials = [],
    sectors = [],
    is_liked,
    is_bookmarked = false,
    is_disliked = false,
    setCompanyLike,
    setCompanyBookmark,
    setCompanyDislike,
  } = props;

  const [textToggleVisible, setTextToggleVisible] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [markdowns, setMarkdowns] = useState<any>([]);

  const { t } = useTranslation();

  const renderMarkdown = () => {
    return markdowns.map((m: { title: string; markdown: string }, index: number) => {
      if (m.title) {
        return <MarkdownItem key={index} title={m.title} styles={styles} content={m.markdown} index={index} />;
      } else {
        return <ReactMarkdown key={index} source={m.markdown} escapeHtml={false} />;
      }
    });
  };

  const history = useHistory();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const readMoreOpenedHeight = textAreaRef.current !== null ? (textAreaRef.current.scrollHeight <= 300 ? textAreaRef.current.scrollHeight : 300) : 300;

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (textAreaRef.current) {
      //@ts-ignore
      if (textAreaRef.current.scrollHeight > textAreaRef.current.offsetHeight) {
        setTextToggleVisible(true);
      } else {
        setTextToggleVisible(false);
      }
    }
  }, [bio]);

  useEffect(() => {
    if (additional_info) {
      setMarkdowns(markdownList(additional_info));
    }
  }, [additional_info]);

  return (
    <div className={styles.container}>
      <div className={styles.headerReplace}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onBack}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Company details')}
        </Title>
      </div>
      <div className={styles.companyBackground} />
      <main className={styles.main}>
        <div className={styles.mainTop}>
          <div className={styles.mainInfoLogo} />
          <div className={styles.sideLogo}>
            <Title size={'h1'} containerStyle={styles.title}>
              {getSlicedText(name, 20)}
            </Title>
            <Link containerStyle={styles.mainInfoSite} size={'sm'} internal={false} href={website}>
              {t('Website')}
            </Link>
            <TextIcon iconName={'location'} iconSize={14} containerStyle={`${styles.label} ${styles.labelLocation}`} iconStyle={styles.labelIcon}>
              {cities && cities.length ? cities.join(', ') : city || ''}
            </TextIcon>
            <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelSection}`} iconStyle={styles.labelIcon}>
              {sectors && sectors[0] && sectors[0].name}
            </TextIcon>
            <TextIcon iconName={'user'} iconSize={14} containerStyle={`${styles.label} ${styles.labelUser}`} iconStyle={styles.labelIcon}>
              {company_size.name}
            </TextIcon>
          </div>
        </div>
        <div className={styles.linksContainer}>
          <Devider size={'xl'} />
          <div className={styles.links}>
            {socials.map((item) => (
              <Link
                key={item.name}
                containerStyle={styles.link}
                internal={false}
                //@ts-ignore
                href={item.value}
                size={'xs'}>
                {item.type ? <Icon key={item.name} name={`${item.type.toLowerCase()}-outline`} color="rgba(172, 183, 191, 1)" size={30} /> : item.name}
              </Link>
            ))}
            {/*<div className={styles.linksIcon}><Icon name={'link'} size={20}/></div>*/}
          </div>
          <Devider size={'xl'} />
        </div>
        {/*<div className={styles.tags}>*/}
        {/*{tags.map((tag) => <div className={styles.tag}>{tag.name}</div>)}*/}
        {/*</div>*/}
        <div className={styles.overall} style={{ height: readMore ? readMoreOpenedHeight : 74, overflowY: readMore ? 'auto' : 'hidden' }}>
          <textarea className={styles.overallValue} value={bio} disabled ref={textAreaRef} />
        </div>
        {(readMore ? true : textToggleVisible) ? (
          <div className={styles.overallBottom}>
            <ButtonTransparentToggle active={readMore} text={readMore ? t('Show less') : t('Read more')} onPress={() => setReadMore(!readMore)} />
          </div>
        ) : null}
        <div className={styles.perks}>
          {emoji.map((emojiItem) => (
            <div key={emojiItem.id} className={styles.perkItem}>
              <ButtonToggle
                key={emojiItem.id}
                size={'lg'}
                active={false}
                hoverable={false}
                containerStyle={`${styles.btnToggle} ${styles.perkItemBtn}`}
                textStyle={styles.perkItemValue}
                text={emojiItem.name}
              />
              <div className={styles.perkItemName}>{emojiItem.value}</div>
            </div>
          ))}
        </div>
        <Markdown isMobile={true} markdown={additional_info} />
        <FixedOpportunityList isMobile={false} containerStyle={styles.opportunityItemsList} company={id} />
        <FixedEventList isMobile={true} containerStyle={styles.eventItemsList} company={id} />
      </main>
      <div className={styles.footer}>
        {!is_disliked ? (
          <>
            {is_liked ? (
              <ButtonMain outline size={'xl'} type={'primary_1'} text={t('Subscribed')} containerStyle={styles.setInterest} onPress={setCompanyLike} />
            ) : (
              <ButtonMain size={'xl'} type={'primary_1'} text={t('Follow company')} containerStyle={styles.setInterest} onPress={setCompanyLike} />
            )}
            <ButtonIconToggle
              size={'xl'}
              activeIconName={'bookmark-fill'}
              inactiveIconName={'bookmark'}
              containerStyle={styles.btnMain}
              iconSize={20}
              iconColor={'#F65532'}
              active={is_bookmarked}
              onPress={setCompanyBookmark}
            />
          </>
        ) : (
          <ButtonMain size={'xl'} outline type={'primary_1'} text={t('Ignored')} containerStyle={styles.setInterest} onPress={setCompanyDislike} />
        )}
      </div>
    </div>
  );
});

export default Company;
