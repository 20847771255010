import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTries, setToken, setTries, signIn } from '../state/modules/session';
import { setErrorMessage } from '../state/modules/error';

export const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tries = useSelector((s) => selectTries(s));
  const [login, setLogin] = useState(false);
  const [isReady, setReady] = useState(false);
  const match = useRouteMatch();
  const { error, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (
      error?.message === 'Please verify your email before trying to log in.' ||
      error?.message === 'An unexpected error occurred. CAUSE: Please verify your email before trying to log in.'
    ) {
      dispatch(setErrorMessage({ message: error.message }, 'VerifyEmail'));
      return;
    }

    if (!isLoading && isAuthenticated) {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          dispatch(setToken(token, false));
          setReady(true);
          setLogin(false);
          dispatch(signIn());
          // eslint-disable-next-line no-undef
          const url = sessionStorage.getItem('url');
          dispatch(setTries());
          if (url && url !== '/login') {
            // eslint-disable-next-line no-undef
            sessionStorage.removeItem('url');
            history.push(url);
          }
        } catch (e) {
          console.log(e);
          if (tries > 0) {
            setLogin(true);
            dispatch(setErrorMessage({ message: 'Something went wrong with auth' }, 'AuthError'));
          }
          dispatch(setTries(tries - 1));
        }
      })();
    } else {
      setReady(false);
    }
  }, [isAuthenticated, isLoading, dispatch, getAccessTokenSilently, history, tries, error]);

  useEffect(() => {
    if ((!isAuthenticated && !isLoading) || login) {
      // eslint-disable-next-line no-undef
      sessionStorage.setItem('url', match.url);
      history.push('/login');
    }
  }, [isAuthenticated, isLoading, login, history]);

  return { isAuthenticated: isReady };
};
