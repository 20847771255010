import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IErrorObject, selectErrorMessages, setErrorMessage } from 'src/state/modules/error';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { ITheme } from 'src/constant/themes';
import { useStyles } from './styles';
import Errors from 'src/web/components/Errors';
import { lgDevice, mdDevice, smDevice, xsDevice } from 'src/state/modules/ui/ui.selectors';
import { AppState } from 'src/state/store';
import { IProps } from './types';
import { useAuth0 } from '@auth0/auth0-react';

const ErrorHandler = (props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { logout } = useAuth0();
  const { containerStyle, children, isMobile } = props;

  const errors = useSelector((state) => selectErrorMessages(state));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filteredErrors = errors.map((error: IErrorObject) => {
    const copy = JSON.parse(JSON.stringify(error));
    console.log(copy, error);
    if (error.error.message === 'Access denied! You need to be authorized to perform this action!') {
      logout();
    }
    switch (error.type) {
      case 'NetworkError': {
        copy.type = 'Oops';
        copy.error.message = "That didn't work well";

        break;
      }
      case 'VerifyEmail': {
        copy.type = t('Please verify your email');
        copy.error.message = t('Your email needs to be verified before you can log in.');

        break;
      }

      default: {
        copy.type = 'Oops';
        copy.error.message = 'Something went wrong';
      }
    }

    return copy;
  });

  return (
    <div className={`${styles.component} ${containerStyle}`}>
      <Errors isMobile={isMobile} errors={filteredErrors} containerStyle={styles.errors} />
      {children}
    </div>
  );
};

const mapState = (state: AppState) => {
  const result = {
    xs: xsDevice(state),
    sm: smDevice(state),
    md: mdDevice(state),
    lg: lgDevice(state),
  };

  return {
    isMobile: result.xs || result.sm || result.md,
  };
};

export default connect(mapState)(ErrorHandler);
