import React, { PureComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.scss';
import { Props } from './types';

export default class Modal extends PureComponent<Props> {
  modalRoot: HTMLElement | null;
  private wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.modalRoot = document.getElementById('modal-root');
  }

  renderModalContent = () => {
    return (
      <div ref={this.wrapperRef} className={`${styles.wrapper} ${this.props.wrapperStyles} ${this.props.isModalOpened ? styles.wrapper_visible : ''}`.trim()}>
        <div className={styles.container} onClick={this.props.onClose} />
        <div className={[styles.modal, this.props.contentStyles].join(' ')}>{this.props.children || null}</div>
      </div>
    );
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.isModalOpened !== this.props.isModalOpened && this.props.isModalOpened) {
      this.props.onOpen && this.props.onOpen();
      document.body.style.overflow = 'hidden';
    } else if (prevProps.isModalOpened !== this.props.isModalOpened && !this.props.isModalOpened) {
      document.body.style.overflow = 'auto';
    }
  }

  render() {
    if (!this.modalRoot || !this.props.isModalOpened) {
      return null;
    }
    return ReactDOM.createPortal(this.renderModalContent(), this.modalRoot);
  }
}
