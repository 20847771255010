export interface IOptionItem {
  id: string;
  name: string;
  value?: string;
  is_primary?: boolean;
  type?: string;
  label?: string;
  __typename?: string;
}

export interface ISubject {
  id: string;
  name: string;
  archetype: string;
}

export interface ICountry {
  id: string;
  name: string;
  abbreviation?: string;
}

export interface IOpportunity {
  id: number;
  name: string;
  company_id?: string;
  company_name: string;
  city?: string;
  cities?: string[];
  banner_img: string;
  logo: string;
  promo_video: string;
  main_description: string;
  description: string;
  types?: IOptionItem[];
  type?: string;
  degrees?: IOptionItem[];
  experiences?: IOptionItem[];
  sectors?: IOptionItem[];
  company_size?: IOptionItem;
  languages?: IOptionItem[];
  created?: string;
  updated?: string;
  deleted?: string;
  is_liked?: boolean;
  is_bookmarked?: boolean;
  application_flow: string;
  redirection_link?: string;
  application?: {
    redirectUrl: string;
    message: string;
  };
}

export interface IEvent {
  id: string;
  name: string;
  company_id: string;
  start_date: string;
  end_date: string;
  banner_img: string;
  address: string;
  city: string;
  main_description: string;
  description: string;
  company_name: string;
  logo: string;
  types: IOptionItem[];
  created?: string;
  updated?: string;
  deleted?: string;
  is_liked?: boolean;
  is_bookmarked?: boolean;
  type?: string;
  application_flow: string;
  redirection_link?: string;
  application?: {
    redirectUrl: string;
    message: string;
  };
}

export interface ICompany {
  id: number;
  name: string;
  website: string;
  city?: string;
  cities?: string[];
  logo: string;
  banner_photo?: string;
  background_photo: string;
  bio: string;
  additional_info: string;
  emoji?: IOptionItem[];
  tags?: IOptionItem[];
  socials?: IOptionItem[];
  sectors?: IOptionItem[];
  company_size?: IOptionItem;
  created?: string;
  deleted?: string;
  is_liked?: boolean;
  is_bookmarked?: boolean;
  is_disliked?: boolean;
}

export interface OnboardingData {
  lastPage?: string;
  whoAreYou?: IOptionItem;
  privacyTermsAgree?: boolean;
  institute?: IOptionItem;
  interests?: IOptionItem[];
  subject?: ISubject;
  graduationDate?: Date;
  degree?: IOptionItem;
  averageGrades?: IOptionItem;
  achievements?: IOptionItem[];
  internationalExperience?: IOptionItem[];
  activities?: IOptionItem[];
  isBoardMember?: boolean;
  languages?: IOptionItem[];
  otherLanguages?: IOptionItem[];
  workExperience?: IOptionItem[];
  firstName?: string;
  lastName?: string;
  referrer?: string | null;
  sex?: IOptionItem;
  birthDate?: Date;
  country?: IOptionItem;
  city?: IOptionItem;
  social_media?: string[];
  email?: string;
  contact_email?: string;
  interest?: IOptionItem[];
  phone_number?: string;
  custom_institute?: { name: string };
  custom_subject?: { name: string };
  archetype?: string;
  manual_input?: boolean;
  country_of_education?: string;
  minor?: string;
  archetype_override?: boolean;
}

export interface Profile {
  education_level?: string;
  institutes?: {
    id?: string;
    average_grades?: string;
    name?: string;
    subject_id?: string;
    subject_name?: string;
    degree_id?: string;
    graduation_date?: Date;
    grade_proof?: string;
    degree_name?: string;
    manual_input?: boolean;
    country_of_education?: string;
    minor?: string;
    archetype_override?: boolean;
  }[];
  interests?: string[];
  achievements?: string[];
  international_experiences?: string[];
  activities?: string[];
  languages?: string[];
  work_experiences?: string[];
  first_name?: string;
  last_name?: string;
  email?: string;
  contact_email?: string;
  sex?: string[];
  referrer?: string;
  birthday?: string;
  country?: string;
  city?: string;
  social_media?: string[];
  is_board_member?: boolean;
  phone_number?: string;
}

export interface IMessage {
  id: string;
  name: string;
  company_name: string;
  logo: string;
  created: string;
  banner_img: string;
  message_id: string;
  message_type: string;
  entity_id: string;
  account_id: string;
  message_created: string;
  text: string;
  is_accepted: boolean;
  is_archived: boolean;
  is_automated: boolean;
}

type TransferData<T> = {
  [K in keyof T]: any;
};

export const OnboardingDataToProfile = (data: TransferData<OnboardingData>) => {
  const result: Profile = {};
  for (const key in data) {
    switch (key) {
      case 'whoAreYou': {
        result.education_level = data.whoAreYou;
        break;
      }
      case 'institute': {
        result.institutes = data.institute;
        break;
      }
      case 'interest': {
        result.interests = data.interest;
        break;
      }
      case 'activities': {
        result.activities = data.activities;
        break;
      }
      case 'achievements': {
        result.achievements = data.achievements;
        break;
      }
      case 'languages': {
        result.languages = data.languages;
        break;
      }
      case 'otherLanguages': {
        break;
      }
      case 'workExperience': {
        result.work_experiences = data.workExperience;
        break;
      }
      case 'internationalExperience': {
        result.international_experiences = data.internationalExperience;
        break;
      }
      case 'firstName': {
        result.first_name = data.firstName;
        break;
      }
      case 'lastName': {
        result.last_name = data.lastName;
        break;
      }
      case 'contact_email': {
        result.contact_email = data.contact_email;
        break;
      }
      case 'referrer': {
        result.referrer = data.referrer;
        break;
      }
      case 'sex': {
        result.sex = data.sex;
        break;
      }
      case 'birthDate': {
        result.birthday = data.birthDate;
        break;
      }
      case 'country': {
        result.country = data.country;
        break;
      }
      case 'city': {
        result.city = data.city;
        break;
      }
      case 'social_media': {
        result.social_media = data.social_media;
        break;
      }
      case 'phone_number': {
        result.phone_number = data.phone_number;
        break;
      }
      case 'isBoardMember': {
        result.is_board_member = data.isBoardMember;
      }
    }
  }

  return result;
};
