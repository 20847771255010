import { TUIAction, EUIActionTypes } from './user.types';

interface PayloadType {
  key: string;
  value: any;
}

export const setUserData = (payload: PayloadType | any): TUIAction => ({
  type: EUIActionTypes.SET_USER_DATA,
  value: payload,
});

export const setUserValue = (payload: PayloadType | any): TUIAction => ({
  type: EUIActionTypes.SET_VALUE,
  value: payload,
});

export const setLogout = (): TUIAction => ({
  type: EUIActionTypes.SET_LOGOUT,
});

export const changeGradeProof = (index?: number): TUIAction => ({
  type: EUIActionTypes.CHANGE_GRADE_PROOF_FLAG,
  value: index,
});

export const changeProfileCV = (isCVExist?: boolean): TUIAction => ({
  type: EUIActionTypes.CHANGE_PROFILE_CV_FLAG,
  value: isCVExist,
});
