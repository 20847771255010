import { OnboardingData } from 'src/requests/models';
import AsyncStorage from '@react-native-community/async-storage';

class Storage {
  isMobile: boolean;

  constructor(isMobile: boolean) {
    this.isMobile = isMobile;
  }
  async getToken(): Promise<string> {
    let token: string;

    if (this.isMobile) {
      token = (await AsyncStorage.getItem('token')) || '';
    } else {
      token = localStorage.getItem('token') || '';
      console.log(token);
    }

    return token;
  }

  async setTokens(token: string): Promise<void> {
    if (this.isMobile) {
      await AsyncStorage.setItem('token', token);
    } else {
      localStorage.setItem('token', token);
    }
    return Promise.resolve();
  }

  async setReferrer(referrer: string): Promise<void> {
    if (this.isMobile) {
      await AsyncStorage.setItem('referrer', referrer);
    } else {
      localStorage.setItem('referrer', referrer);
    }
    return Promise.resolve();
  }

  async clearReferrer(): Promise<void> {
    if (this.isMobile) {
      await AsyncStorage.removeItem('referrer');
    } else {
      localStorage.removeItem('referrer');
    }
    return Promise.resolve();
  }

  async getUser(): Promise<string> {
    let user: string;

    if (this.isMobile) {
      user = (await AsyncStorage.getItem('user')) || '';
    } else {
      user = localStorage.getItem('user') || '';
    }

    return user;
  }

  async getReferrer(): Promise<string> {
    let referrer: string;

    if (this.isMobile) {
      referrer = (await AsyncStorage.getItem('referrer')) || '';
    } else {
      referrer = localStorage.getItem('referrer') || '';
    }

    console.log(referrer);

    return referrer;
  }

  async setUser(token: string): Promise<void> {
    if (this.isMobile) {
      await AsyncStorage.setItem('user', token);
    } else {
      localStorage.setItem('user', token);
    }
    return Promise.resolve();
  }

  async getOnboardingData(): Promise<OnboardingData> {
    let data = '';
    if (this.isMobile) {
      data = (await AsyncStorage.getItem('onboarding')) || '{}';
    } else {
      data = localStorage.getItem('onboarding') || '{}';
    }

    return JSON.parse(data);
  }

  async setOnboardingData(data: OnboardingData): Promise<void> {
    const dataFromStorage = (await this.getOnboardingData()) || {};
    if (this.isMobile) {
      await AsyncStorage.setItem('onboarding', JSON.stringify({ ...dataFromStorage, ...data }));
    } else {
      localStorage.setItem('onboarding', JSON.stringify({ ...dataFromStorage, ...data }));
    }
    return Promise.resolve();
  }

  async clearOnboardingData(): Promise<void> {
    if (this.isMobile) {
      await AsyncStorage.removeItem('onboarding');
    } else {
      localStorage.removeItem('onboarding');
    }
    return Promise.resolve();
  }

  async getUserLanguage(): Promise<string> {
    let data = '';
    if (this.isMobile) {
      data = (await AsyncStorage.getItem('userLanguage')) || '';
    } else {
      data = localStorage.getItem('userLanguage') || '';
    }
    return data;
  }

  async setUserLanguage(language: string): Promise<void> {
    if (this.isMobile) {
      await AsyncStorage.setItem('userLanguage', language);
    } else {
      localStorage.setItem('userLanguage', language);
    }
    return Promise.resolve();
  }
}

export default Storage;
