import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { Props } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& svg': {
        transform: 'rotate(-90deg)',
      },
    },
    text: {
      flexGrow: 1,
      color: theme.$primaryColor1,
      paddingRight: '1.5rem',
    },
  };
});
