import React, { useEffect, useState, memo, useRef, useContext } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import moment from 'moment';
import Title from 'src/components/titles/Title/index.web';
import Devider from 'src/components/dividers/Divider/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import EventOrganizer from '../EventOrganizer';
import ButtonTransparentToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/labels/Label/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { getSplittedAddress } from '../../../../../utilities';
import { addSubscription } from '../../../../../state/modules/ui';
import { useDispatch } from 'react-redux';
import ButtonIcon from 'src/components/buttons/ButtonIcon/index.web';
import Markdown from 'src/web/components/Markdown';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { formatInBrusselsTimeZone } from 'src/utilities/dateTimeFormatter';

const Event = memo((props: IProps) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const {
    id,
    name,
    company_name,
    address,
    main_description: mainDescription,
    description: additionalInfo,
    is_liked,
    is_bookmarked = false,
    types = [],
    setEventBookmark,
    start_date,
    end_date,
    deleted,
    companyData,
  } = props;

  const [textToggleVisible, setTextToggleVisible] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const readMoreOpenedHeight = textAreaRef.current !== null ? textAreaRef.current.scrollHeight + 40 : 300;

  const startDate = moment(start_date);
  const addressText = getSplittedAddress(address);
  const { setApplyModalShown, setEntityForApply } = useContext(SearchContext);
  useEffect(() => {
    if (textAreaRef.current) {
      if (textAreaRef.current.scrollHeight > textAreaRef.current?.offsetHeight) {
        setTextToggleVisible(true);
      } else {
        setTextToggleVisible(false);
      }
    }
  }, [mainDescription]);
  const showApplyModal = () => {
    setApplyModalShown(true);
    setEntityForApply(props);
  };

  return (
    <div className={styles.component}>
      <div className={styles.backgroundImage}>
        <div className={styles.eventInfo}>
          <div className={styles.eventType}>{types.length && <Label item={types[0]} containerStyle={styles.labelType} />}</div>
          <div className={styles.eventDate}>
            <div className={styles.eventDateDay}>{startDate.format('DD')}</div>
            <div className={styles.eventDateMonth}>{startDate.format('MMM')}</div>
          </div>
        </div>
      </div>
      <main className={styles.main}>
        <div className={styles.mainHeader}>
          <Title size={'h1'} containerStyle={styles.mainTitle}>
            {name}
          </Title>
          <div className={styles.buttons}>
            {is_liked ? (
              <ButtonMain
                outline
                disabled={!!deleted}
                size={'xl'}
                type={'primary_1'}
                text={t('Interested')}
                containerStyle={styles.setInterest}
                onPress={() => {
                  showApplyModal();
                }}
              />
            ) : (
              <>
                <ButtonMain
                  size={'xl'}
                  disabled={!!deleted}
                  type={'primary_1'}
                  text={t('Show interest event')}
                  containerStyle={styles.setInterest}
                  onPress={() => {
                    showApplyModal();
                  }}
                />
                <ButtonIconToggle
                  size={'xl'}
                  disabled={!!deleted}
                  activeIconName={'bookmark-fill'}
                  inactiveIconName={'bookmark'}
                  containerStyle={styles.btnMain}
                  iconSize={20}
                  iconColor={'#F65532'}
                  active={is_bookmarked}
                  onPress={setEventBookmark}
                />
              </>
            )}
          </div>
        </div>
        <Devider size={'xl'} />
        <div className={styles.mainItem} style={{ display: 'block', marginTop: 15 }}>
          <div className={styles.companyInfo}>
            <div className={styles.companyLogo} />
            <Title size={'h2'} containerStyle={styles.companyTitle}>
              {company_name}
            </Title>
            <ButtonIcon
              size="xs"
              type="default"
              active
              activeIconName={'share'}
              iconColor="red"
              iconSize={20}
              containerStyle={styles.shareButton}
              onPress={() => {
                dispatch(addSubscription({ id: '' + id, name, type: 'share' }));
                navigator.clipboard.writeText(window.location.href);
              }}
            />
          </div>
          <div className={styles.overall} style={{ height: readMore ? readMoreOpenedHeight : 'auto', overflowY: readMore ? 'auto' : 'hidden' }}>
            <textarea className={styles.overallValue} value={mainDescription} disabled ref={textAreaRef} rows={3} />
            {mainDescription && (readMore ? true : textToggleVisible) ? (
              <div className={styles.overallBottom}>
                <ButtonTransparentToggle
                  active={readMore}
                  text={readMore ? t('Show less') : t('Read full description')}
                  onPress={() => setReadMore(!readMore)}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.eventOverall}>
            <Title size={'h3'} containerStyle={styles.eventInfoItem}>
              <Icon size={20} color={theme.$primaryColor1} name={'calendar'} />
              {`${formatInBrusselsTimeZone(start_date)} - ${formatInBrusselsTimeZone(end_date)}`}
            </Title>
            <div className={styles.eventInfoItem}>
              <Icon size={20} color={theme.$primaryColor1} name={'home'} />
              <span style={{ fontWeight: 500 }}>{addressText[0]}</span>
              <span>{addressText[1]}</span>
            </div>
          </div>
          {additionalInfo && <Markdown isMobile={false} markdown={additionalInfo} />}
        </div>
      </main>
      <EventOrganizer isMobile={false} data={companyData} containerStyle={styles.eventOrganizer} />
    </div>
  );
});

export default Event;
