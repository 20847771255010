import React, { FC, useCallback, useMemo } from 'react';
import { DefaultTheme, useTheme } from 'react-jss';
import Text from 'src/components/texts/Text/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import Button from '../Button/index.web';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  content?: string;
  text?: string;
}

const ButtonTransparentToggle = React.forwardRef((props: IProps, ref: any) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const { disabled, active, text, containerStyle, textStyle, contentStyle, iconColor = theme.$primaryColor1, onHover, onPress, onBlur } = props;
  const styles = useStyles({ ...props, ...theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    content: `${styles.icon} ${active ? styles.iconActive : styles.iconInactive} ${contentStyle}`.trim(),
    text: `${styles.text} ${textStyle ? textStyle : ''}`.trim(),
  };

  const renderIcon = (): React.ReactChild | null => {
    return <Icon size={10} name={active ? 'arrow-down' : 'arrow-down'} color={iconColor} />;
  };

  const renderText = (): React.ReactChild | null => {
    return text ? (
      <Text containerStyle={cn.text} inline>
        {text}
      </Text>
    ) : null;
  };

  return (
    <Button disabled={disabled} onPress={onPress} onHover={onHover} onBlur={onBlur} containerStyle={cn.component} contentStyle={cn.content} ref={ref}>
      {renderText()}
      {renderIcon()}
    </Button>
  );
});

export default ButtonTransparentToggle;
