import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Context as ExploreContext } from 'src/contexts/ExploreContext';
import { Context as TypedExploreContext } from 'src/contexts/ExploreContext/TypedExploreContext';

import Header from 'src/web/components/Header';
import Explore from 'src/web/components/Explore';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { EExploreFeedTypes } from 'src/web/components/Explore/types';
import { IProps } from './types';
import styles from './styles.module.scss';
import { useLoadOnScroll } from '../../../hooks/useLoadOnScroll';

const routeMapper = {
  [EExploreFeedTypes.GENERAL]: 'general',
  [EExploreFeedTypes.COMPANY]: 'companies',
  [EExploreFeedTypes.EVENT]: 'events',
  [EExploreFeedTypes.OPPORTUNITY]: 'opportunities',
};

const ExplorePage = (props: IProps) => {
  const { isMobile, tab } = props;
  const history = useHistory();
  const { setSearchNext: setSearchNextTyped, result: resultTyped, getFeedByType }: any = useContext(TypedExploreContext);
  const { setSearchNext: setSearchNextGeneral, result: resultGeneral }: any = useContext(ExploreContext);

  useEffect(() => {
    if (tab !== EExploreFeedTypes.GENERAL) {
      getFeedByType(tab);
    }
  }, [tab]);

  const onChangeTab = (tabName: EExploreFeedTypes) => {
    history.push(`/explore/${routeMapper[tabName]}`);
  };

  const searchNext = tab === EExploreFeedTypes.GENERAL ? setSearchNextGeneral : setSearchNextTyped;
  const result = tab === EExploreFeedTypes.GENERAL ? resultGeneral : resultTyped;
  useLoadOnScroll({ setSearchNext: searchNext, result });

  return (
    <>
      <Helmet>
        <title>{'Explore'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          <Explore isMobile={isMobile} activeTab={tab} onChangeTab={onChangeTab} result={result} />
        </div>
      </ErrorBoundaries>
    </>
  );
};

export default React.forwardRef(ExplorePage);
