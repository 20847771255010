import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LocalError from 'src/web/components/LocalError';

interface IProps {
  Fallback?: any;
}

interface State {
  error: any;
  errorInfo: any;
}

const ErrorBoundaries = ({ Fallback, children }: { Fallback?: any; children: any }) => {
  return <ErrorBoundary FallbackComponent={Fallback ? Fallback : LocalError}>{children}</ErrorBoundary>;
};

export default ErrorBoundaries;

// class ErrorBoundary extends React.Component<IProps, State> {
//   constructor(props: any) {
//     super(props);
//     this.state = { error: null, errorInfo: null };
//   }
//
//   static getDerivedStateFromError(error: any) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }
//
//   componentDidCatch(error: any, errorInfo: any) {
//     // You can also log the error to an error reporting service
//    // logErrorToMyService(error, info);
//     this.setState({
//       error,
//       errorInfo
//     })
//   }
//
//   render() {
//     const {Fallback} = this.props;
//     const {error, errorInfo} = this.state;
//
//     if (this.state.error) {
//       // Error path
//       return Fallback ? <Fallback error={error} errorInfo={errorInfo}/> :
//         <LocalError error={error} errorInfo={errorInfo}/>;
//     }
//     // Normally, just render children
//     return this.props.children;
//   }
// }
//
// export default ErrorBoundary;
