import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import CompanyItem from '../Item';
import { Context as CompaniesSearchContext } from 'src/contexts/SearchContext/CompaniesSearchContext';
import { FlatList, View } from 'react-native';
import NoDataFound from 'src/web/components/NoDataFound';
import { Context as LikesContext } from '../../../../contexts/LikesContext/index.web';

const List = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const [requestMade, setRequestMade] = useState(false);

  const { result, searchValue }: any = useContext(CompaniesSearchContext);
  const { isMobile, Item = CompanyItem, containerStyle, view, companies = result } = props;

  const { toggleLike: tL, toggleBookmark: tB, toggleDislike: tD }: any = useContext(LikesContext);

  const toggleLike = (id: any, is_liked: string) => {
    tL(id.id || id, is_liked, 'c');
  };

  const toggleBookmark = (id: string, flag: boolean) => {
    tB(id, flag, 'c');
  };

  const toggleDislike = (id: string, flag: boolean) => {
    tD(id, flag, 'c');
  };

  useEffect(() => {
    if (requestMade) {
      setTimeout(() => {
        setRequestMade(false);
      }, 1500);
    }
  }, [requestMade]);

  const columns = isMobile ? 1 : 2;

  return (
    <div className={`${styles.container} ${containerStyle}`.trim()}>
      <View style={{ width: '100%', height: '100%' }}>
        <FlatList
          key={columns}
          data={companies}
          numColumns={columns}
          keyExtractor={(item: any, index: number) => `${item.id}-${index}`}
          ListEmptyComponent={<NoDataFound isMobile={isMobile} value={searchValue} />}
          renderItem={({ item }) => (
            <Item
              containerStyle={styles.item}
              isMobile={isMobile}
              data={item}
              toggleLike={toggleLike}
              toggleDislike={toggleDislike}
              toggleBookmark={toggleBookmark}
              view={view}
            />
          )}
        />
      </View>
    </div>
  );
};

export default List;
