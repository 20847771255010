import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import Icon from '../../../components/icons/Icon/index.web';
import Text from '../../../components/texts/Text/index.web';
import Button from '../Button/index.web';
import { Props } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  content?: string;
  text?: string;
  icon?: string;
}

const ButtonCombine: FC<Props> = (props) => {
  const { disabled, text, iconName, iconSize, iconColor, containerStyle, textStyle, onHover, onPress, onBlur } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles(props, theme);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    content: `${styles.content}`.trim(),
    text: `${styles.text} ${textStyle ? textStyle : ''}`.trim(),
    icon: `${styles.icon}`.trim(),
  };

  const renderText = (): React.ReactChild | null => {
    return (
      <Text containerStyle={cn.text} inline>
        {text}
      </Text>
    );
  };

  const renderIcon = (): React.ReactChild | null => {
    return (
      <div className={cn.icon}>
        <Icon size={iconSize} name={iconName} color={iconColor} />
      </div>
    );
  };

  return (
    <Button disabled={disabled} onPress={onPress} onHover={onHover} onBlur={onBlur} containerStyle={cn.component} contentStyle={cn.content}>
      {renderIcon()}
      {renderText()}
    </Button>
  );
};

export default ButtonCombine;
