import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/inputs/Select/index.web';
import { IOptionItem } from 'src/requests/models';
import Toggle from 'src/components/toggles/Toggle/index.web';
import Devider from 'src/components/dividers/Divider/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { useQuery } from '@apollo/client';
import { COMPANIES_DATA_AVAILABLES } from 'src/requests/queries';
import { Context as CompaniesSearchContext } from 'src/contexts/SearchContext/CompaniesSearchContext';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { FlatList, View } from 'react-native';

const FilterMobile = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, onClose } = props;
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies') },
    { id: '2', name: 'events', label: t('Events') },
    { id: '3', name: 'opportunities', label: t('Opportunities') },
  ];

  const filterQ = useQuery(COMPANIES_DATA_AVAILABLES, {
    variables: {
      lang,
    },
  });
  const { perksList, tagList, industryList, companySizeList } = filterQ.data
    ? filterQ.data
    : {
        perksList: [],
        tagList: [],
        industryList: [],
        companySizeList: [],
      };

  const { setFilters, setEntity, entity, setEntityMob }: any = useContext(SearchContext);
  const { hiringCompanies, industry, companySize, tags, perks, setData, setSearchStart, setClear }: any = useContext(CompaniesSearchContext);

  const [allIndustry, setAllIndustry] = useState(!industry.length);

  const onToggleOnlyHiring = () => {
    setData({ hiringCompanies: !hiringCompanies });
  };

  const onSelectIndustry = (item: IOptionItem, action: 'add' | 'rem') => {
    if (allIndustry) {
      setAllIndustry(false);
    }
    if (action === 'add') {
      setData({ industry: industry.concat([item]) });
    } else if (action === 'rem') {
      setData({ industry: industry.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectIndustryAll = () => {
    setAllIndustry(true);
    setData({ industry: [] });
  };

  const onSelectSize = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ companySize: companySize.concat([item]) });
    } else if (action === 'rem') {
      setData({ companySize: companySize.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectTags = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ tags: tags.concat([item]) });
    } else if (action === 'rem') {
      setData({ tags: tags.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSelectPerk = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ perks: perks.concat([item]) });
    } else if (action === 'rem') {
      setData({ perks: perks.filter((i: IOptionItem) => i.id !== item.id).slice() });
    }
  };

  const onSubmit = () => {
    setSearchStart();
    onClose();
    setFilters('entity', entity);
  };

  const onClear = () => {
    setClear();
    setSearchStart();
    onClose();
  };

  useEffect(() => {
    setAllIndustry(!industry.length);
  }, [industry]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.headerTitle} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Search details')}
        </Title>
      </div>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div className={styles.body}>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Searching for')}
          </Title>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={setEntityMob}
            noInput
          />
          <div className={styles.filterHiringCompanies}>
            <Title size={'h3'}>{t('Only hiring companies')}</Title>
            <Toggle active={hiringCompanies} onPress={onToggleOnlyHiring} />
          </div>
          <Devider size={'xl'} />
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Industry')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {industryList.map((industryItem: IOptionItem) => {
              const active = !!industry.find((item: IOptionItem) => item.id === industryItem.id);
              return (
                <ButtonToggle
                  key={industryItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={industryItem.name}
                  onPress={() => onSelectIndustry(industryItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Company size')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            {companySizeList.map((sizeItem: IOptionItem) => {
              const active = !!companySize.find((item: IOptionItem) => item.id === sizeItem.id);
              return (
                <ButtonToggle
                  key={sizeItem.id}
                  size={'lg'}
                  active={active}
                  containerStyle={styles.btnToggle}
                  text={sizeItem.name}
                  onPress={() => onSelectSize(sizeItem, active ? 'rem' : 'add')}
                />
              );
            })}
          </div>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Perks')}
          </Title>
          <div className={`${styles.bodyItem} ${styles.btnToggleList}`}>
            <FlatList
              data={perksList.length % 4 ? [...perksList, ...new Array(4 - (perksList.length % 4)).fill({})] : perksList}
              extraData={perks}
              numColumns={4}
              keyExtractor={(item: any, index: number) => `${item.id}-${index}`}
              renderItem={({ item: perkItem, index }) => {
                if (perkItem.id) {
                  const active = !!perks.find((item: IOptionItem) => item.id === perkItem.id);
                  return (
                    <View style={{ width: '25%', paddingRight: 5, paddingLeft: 5 }}>
                      <div className={styles.perkItem}>
                        <ButtonToggle
                          size={'lg'}
                          active={active}
                          containerStyle={`${styles.btnToggle} ${styles.perkItemBtn} ${active ? styles.perkItemBtnActive : ''}`}
                          textStyle={styles.perkItemValue}
                          text={perkItem.name}
                          onPress={() => onSelectPerk(perkItem, active ? 'rem' : 'add')}
                        />
                        <div className={`${styles.perkItemName} ${active ? styles.perkItemNameActive : ''}`}>{perkItem.value}</div>
                      </div>
                    </View>
                  );
                } else {
                  return (
                    <View style={{ width: '25%' }}>
                      <div className={styles.perkItem} />
                    </View>
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default FilterMobile;
