import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

const multiply = 0.625;

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    position: 'relative',
    width: '100%',
    maxWidth: '34.5rem',
    height: 54,
    backgroundColor: 'white',
    border: `1px solid ${theme.$borderColor}`,
    padding: '0 15px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: '0.25s all ease',
    '& .react-datepicker-wrapper': {
      border: 'none',
      width: '100%',
      height: '100%',
    },
    '& .react-datepicker__input-container': {
      height: '100%',
    },
    '& input': {
      width: '100%',
      height: '100%',
      color: '#222222',
      fontFamily: 'Inter',
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
      borderRadius: 10,
      border: 'none',
      outline: 'none',
      '&::placeholder': {
        color: '#222222',
        opacity: 0.3,
      },
      '&:focus, &:active': {
        color: theme.$primaryColor2,
        opacity: 1,
      },
    },
  },
  componentActive: {
    border: `1px solid ${theme.$primaryColor1}`,
  },
  input: {
    width: '100%',
    height: '100%',
    color: '#656771',
    fontFamily: 'Inter',
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    borderRadius: 10,
    border: 'none',
    outline: 'none',
    transition: '0.25s all ease',
    '& .react-date-picker__calendar': {
      width: '100%',
    },
    '&:placeholder': {
      opacity: 0.5,
      color: '#656771',
    },
    '&:focus, &:active': {
      color: theme.$primaryColor2,
      opacity: 1,
    },
  },
  icon: {
    display: 'flex',
  },
  calendar: {
    // transform: 'translate(0, -20px)',

    '& .react-datepicker__triangle': {
      display: 'none',
    },
  },
  iconCalendar: {
    display: 'flex',
    cursor: 'pointer',
  },
}));
