import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

//@ts-ignore;
const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_WEB_CLIENT_ID;
  const audience = process.env.REACT_APP_AUDIENCE;

  const history = useHistory();
  //@ts-ignore;
  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider useRefreshTokens={true} domain={domain} clientId={clientId} redirectUri={window.location.origin} audience={audience} onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
