import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
}

const Avatar: FC<IProps> = (props) => {
  const { src, alt, containerStyle } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
  };

  return src ? (
    <img referrerPolicy="no-referrer" src={src} alt={alt ? alt : src} className={cn.component} />
  ) : (
    <div className={cn.component}>
      <Icon name={'user'} size={20} color={'white'} />
    </div>
  );
};

export default Avatar;
