import { useLocation, matchPath } from 'react-router';
import { Platform } from 'react-native';
import { useContext, useEffect } from 'react';
import { Context as SearchContext } from 'src/contexts/SearchContext';

const pathsExcept = ['/search', '/opportunity/:id', '/company/:id', '/event/:id'];

const PathChecker = ({ children }: { children: any }) => {
  const location = useLocation();
  const { clearSearchFilters } = useContext(SearchContext);

  useEffect(() => {
    if (Platform.OS === 'web' && !pathsExcept.some((path: string) => !!matchPath(location.pathname, { path, exact: false, strict: true }))) {
      clearSearchFilters();
    }
  }, [location.pathname]);

  return children;
};

export default PathChecker;
