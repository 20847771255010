import { TOnboardingState, TOnboardingAction, EOnboardingActionTypes } from './onboarding.types';

const initialState: TOnboardingState = {
  loading: false,
  saveProfileFlag: false,
  currentPage: '1',
  data: {
    lastPage: '/onboarding/1',
  },
};

export const onboardingReducer = (state: TOnboardingState = initialState, action: TOnboardingAction): TOnboardingState => {
  switch (action.type) {
    case EOnboardingActionTypes.SET_DATA_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case EOnboardingActionTypes.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case EOnboardingActionTypes.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case EOnboardingActionTypes.SET_PROFILE_SAVE: {
      return {
        ...state,
        saveProfileFlag: action.payload,
      };
    }

    default:
      return state;
  }
};
