import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    body: (p: {isMobile: boolean}) => ({
      padding: p.isMobile ? '0 15px 30px' : '0 30px 30px',
    }),
    divider: {
      width: '100%',
    },
    title: {
      margin: '1.5rem 0',
    },
    experiences: (p: {isMobile: boolean}) => ({
      height: 'auto',
      maxHeight: p.isMobile ? 250 : 200,
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: 'auto',
      alignContent: 'flex-start',
    }),
    experience: {
      margin: '10px 10px 0 0',
    },
    label: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '1.5rem 0 0.5rem',
    },
  };
});
