import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import Text from 'src/components/texts/Text/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import Button from '../Button/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  text?: string;
}

const ButtonRoundedToggle: FC<IProps> = (props) => {
  const { disabled, active, icon, text, containerStyle, textStyle, onHover, onPress, onBlur, hoverable = true, children = null } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    text: `${styles.text} ${textStyle ? textStyle : ''} ${icon ? styles.withIcon : ''}`.trim(),
  };

  const renderIcon = (): React.ReactChild | null => {
    return <Icon name={'checkmark'} color={'white'} size={11} />;
  };

  const renderText = (): React.ReactChild | null => {
    return (
      <Text containerStyle={cn.text} type={'secondary'} inline>
        {text ? text : children}
      </Text>
    );
  };

  return (
    <Button disabled={disabled} onPress={onPress} onHover={onHover} onBlur={onBlur} containerStyle={cn.component}>
      {active && icon ? renderIcon() : null}
      {renderText()}
    </Button>
  );
};

export default ButtonRoundedToggle;
