import React, { FC, memo } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types.web';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  input?: string;
  label?: string;
}

const RadioButtonGroup: FC<IProps> = memo((props) => {
  const { data, containerStyle, onChange = () => undefined, inputStyle, labelStyle } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    input: `${styles.input} ${inputStyle}`.trim(),
    label: `${styles.label} ${labelStyle}`.trim(),
  };

  return (
    <div className={cn.component} onChange={onChange}>
      {data.length > 0 &&
        data.map(({ label, value }) => (
          <label className={cn.label} key={label}>
            <input className={cn.input} type="radio" name="radio" value={value} />
            {label}
          </label>
        ))}
    </div>
  );
});

export default RadioButtonGroup;
