import React, { createContext, useEffect, useMemo, useState } from 'react';
import { PROFILE_DATA } from '../../../requests/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE, SAVE_PROFILE_AVATAR, UPDATE_PROFILE_CV, REMOVE_PROFILE_CV } from '../../../requests/mutations';

import { setErrorMessage } from '../../../state/modules/error';
import { useTranslation } from 'react-i18next';

import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { selectIsReady } from '../../../state/modules/session';
import { getByPath, checkOnEmptyURL, pick } from '../../../utilities';
import { setUser } from '../../../utilities/sentry';

const ProfileInput = {
  first_name: '',
  last_name: '',
  image_url: '',
  contact_email: '',
  short_bio: '',
  country: '',
  city: '',
  social_media: [],
  is_board_member: false,
  fcm_token: '',
  referrer: '',
  institutes: [],
  international_experiences: [],
  other_international_experiences: '',
  activities: [],
  other_activities: '',
  languages: [],
  other_languages: '',
  work_experiences: [],
  other_work_experiences: '',
  interests: [],
  notifications: [],
  sex: '',
  birthday: null,
  education_level: '',
  achievements: [],
  other_achievements: '',
  phone_number: '',
};

interface IProps {
  children: any;
}

interface IProfileState {
  id: string;
  firstName: string;
  lastName: string;
  sex: string;
  birthday: string;
  contactEmail: string;
  isProfileCreated: boolean;
  country: { name: string };
  city: { name: string };
  socialMedia: any[];
  educationLevel: string;
  institutes: any[];
  workExperiences: any[];
  otherWorkExperiences: string;
  interests: any[];
  notifications: any[];
  activities: any[];
  otherActivities: '';
  achievements: any[];
  otherAchievements: string;
  languages: string[];
  otherLanguages: string;
  internationalExperiences: any[];
  otherInternationalExperiences: string;
  bio: string;
  isBoardMember: boolean;
  profileData: any;
  avatarData: any;
  CVData: any;
  dataReady: boolean;
  setData: any;
  onSaveData: any;
  onSetAvatar: any;
  onSetCV: any;
  onRemoveCV: any;
  onRefresh: any;
  onGetProfile: () => void;
  phone_number: string;
  referrer: string;
}

const DEFAULT_SOCIALS: any = {
  facebook: 'https://www.facebook.com/',
  linkedin: 'https://www.linkedin.com/in/',
  github: 'https://github.com/',
  instagram: 'https://www.instagram.com/',
};

const Context = createContext({} as IProfileState);
Context.displayName = '_PROFILE_';

const Provider = (props: IProps) => {
  // PROPS
  const { children } = props;
  const isReady = useSelector((state) => selectIsReady(state));
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  // STATE
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [CVLoading, setCVLoading] = useState(false);

  const [dataReady, setDataReady] = useState(false);
  // QUERIES
  const [getProfileData, profileQ] = useLazyQuery(PROFILE_DATA, {
    variables: { lang },
  });
  const { data: profileData, loading: profileLoading, called: profileCalled, updateQuery: profileUpdate, refetch: profileRefetch } = profileQ;

  const [saveP] = useMutation(UPDATE_STUDENT_PROFILE);

  const [saveA] = useMutation(SAVE_PROFILE_AVATAR, {
    update(cache, { data: { image_url } }) {
      cache.modify({
        id: cache.identify(profileData.profile),
        fields: {
          image_url: (cache) => image_url,
        },
      });
    },
  });

  const [saveCV] = useMutation(UPDATE_PROFILE_CV, {
    update(cache, { data: { resumeUrl } }) {
      cache.modify({
        id: cache.identify(profileData.profile),
        fields: {
          resumeUrl: (cache) => resumeUrl,
        },
      });
    },
  });

  const [removeCV] = useMutation(REMOVE_PROFILE_CV, {
    update(cache, { data: { resumeUrl } }) {
      cache.modify({
        id: cache.identify(profileData.profile),
        fields: {
          resumeUrl: (cache) => null,
        },
      });
    },
  });

  // HELPERS
  // Превращение данных для бека
  // Может быть запущен перед созданием данных в кеше по этому ифы нужны
  const makeProfileData = (data: any) => {
    const result: any = {};
    const getMaped = (arr: []) => (arr && arr.length ? arr.map((item: any) => item.id) : null);
    result.first_name = data.firstName ? data.firstName : getByPath(profileData, ['profile', 'first_name'], '');
    result.last_name = data.lastName ? data.lastName : getByPath(profileData, ['profile', 'last_name'], '');
    result.last_name = data.lastName ? data.lastName : getByPath(profileData, ['profile', 'last_name'], '');
    result.sex = data.sex ? data.sex.id : getByPath(profileData, ['profile', 'sex', 'id'], { id: '' });
    result.birthday = new Date(data.birthday ? data.birthday : getByPath(profileData, ['profile', 'birthday'], ''));
    result.country = data.country ? data.country.name : getByPath(profileData, ['profile', 'country'], '');
    result.contact_email = data.contactEmail ? data.contactEmail : getByPath(profileData, ['profile', 'contact_email'], '');
    result.social_media = data.socialMedia ? checkOnEmptyURL(data.socialMedia) : getByPath(profileData, ['profile', 'social_media'], null);
    result.social_media = result.social_media
      ? result.social_media.map((item: { url: string; type: string }) => {
          if (!item.url.includes('https://')) {
            return { url: DEFAULT_SOCIALS[item.type] + item.url, type: item.type };
          } else {
            return { url: item.url, type: item.type };
          }
        })
      : null;
    result.city = data.city ? data.city.name : getByPath(profileData, ['profile', 'city'], '');
    result.education_level = data.educationLevel ? data.educationLevel.id : getByPath(profileData, ['profile', 'education_level', 'id'], '');
    result.institutes = data.institutes
      ? data.institutes.map((item: any) =>
          item.custom_institute && item.custom_subject && item.archetype
            ? {
                name: item.custom_institute.name,
                archetype: item.archetype,
                subject_name: item.custom_subject.name,
                degree: item.degree.id,
                graduation_date: item.graduation_date,
                average_grades: item.average_grades ? item.average_grades.name : null,
                country_of_education: item.country_of_education,
                subject_id: item.subject_id || '',
                grade_proof: item.grade_proof || '',
                minor: item?.minor || '',
                archetype_override: item?.archetype_override || false,
              }
            : {
                id: item.institute.id,
                subject: item.subject.id,
                degree: item.degree.id,
                graduation_date: item.graduation_date,
                average_grades: item.average_grades ? item.average_grades.name : null,
                archetype: item.archetype,
                minor: item?.minor || '',
                archetype_override: item?.archetype_override || false,
              },
        )
      : profileData && profileData.profile && profileData.profile.institutes
      ? profileData.profile.institutes.map((item: any) =>
          item.manual_input && item.subject_name && item.archetype
            ? {
                name: item.name,
                archetype: item.archetype,
                subject_name: item.subject_name,
                degree: item.degree_id,
                graduation_date: item.graduation_date,
                average_grades: item.average_grades,
                country_of_education: item.country_of_education,
                subject_id: item.subject_id || '',
                grade_proof: item.grade_proof || '',
                minor: item?.minor || '',
                archetype_override: item?.archetype_override || false,
              }
            : {
                id: item.id,
                subject: item.subject_id,
                degree: item.degree_id,
                graduation_date: item.graduation_date,
                average_grades: item.average_grades,
                archetype: item.archetype,
                minor: item?.minor || '',
                archetype_override: item?.archetype_override || false,
              },
        )
      : [];
    result.work_experiences = data.workExperiences ? getMaped(data.workExperiences) : getMaped(getByPath(profileData, ['profile', 'work_experiences'], null));
    result.other_work_experiences =
      data.otherWorkExperiences !== undefined ? data.otherWorkExperiences : getByPath(profileData, ['profile', 'other_work_experiences'], '');
    result.interests = data.interests ? getMaped(data.interests) : getMaped(getByPath(profileData, ['profile', 'interests'], null));
    result.notifications = data.notifications
      ? {
          incoming_messages: data.notifications.incoming_messages,
          opportunities_or_events: data.notifications.opportunities_or_events,
          new_companies: data.notifications.new_companies,
          news_and_updates: data.notifications.news_and_updates,
        }
      : {
          incoming_messages: getByPath(profileData, ['profile', 'notifications'], null).incoming_messages,
          opportunities_or_events: getByPath(profileData, ['profile', 'notifications'], null).opportunities_or_events,
          new_companies: getByPath(profileData, ['profile', 'notifications'], null).new_companies,
          news_and_updates: getByPath(profileData, ['profile', 'notifications'], null).news_and_updates,
        };
    result.activities = data.activities ? getMaped(data.activities) : getMaped(getByPath(profileData, ['profile', 'activities'], null));
    result.other_achievements = data.otherAchievements !== undefined ? data.otherAchievements : getByPath(profileData, ['profile', 'other_achievements'], '');
    result.other_activities = data.otherActivities !== undefined ? data.otherActivities : getByPath(profileData, ['profile', 'other_activities'], '');
    result.achievements = data.achievements ? getMaped(data.achievements) : getMaped(getByPath(profileData, ['profile', 'achievements'], null));
    result.languages = data.languages
      ? getMaped(data.languages)?.map((i) => i.toString())
      : getMaped(getByPath(profileData, ['profile', 'languages'], null))?.map((i) => i.toString());
    result.other_languages = data.otherLanguages !== undefined ? data.otherLanguages : getByPath(profileData, ['profile', 'other_languages'], '');
    result.international_experiences = data.internationalExperiences
      ? getMaped(data.internationalExperiences)
      : getMaped(getByPath(profileData, ['profile', 'international_experiences'], null));
    result.other_international_experiences =
      data.otherInternationalExperiences !== undefined
        ? data.otherInternationalExperiences
        : getByPath(profileData, ['profile', 'other_international_experiences'], '');
    result.short_bio = data.bio !== undefined ? data.bio : getByPath(profileData, ['profile', 'short_bio'], '');
    result.is_board_member = data.isBoardMember !== undefined ? data.isBoardMember : getByPath(profileData, ['profile', 'is_board_member'], false);
    result.phone_number = data.phone_number !== undefined ? data.phone_number : getByPath(profileData, ['profile', 'phone_number'], '');
    result.referrer = data.referrer ? data.referrer : getByPath(profileData, ['profile', 'referrer'], '');

    return result;
  };

  const onSetAvatar = async (file: any, next: any, error: any) => {
    const onError = (e: any) => {
      setErrorMessage(e, 'Error');
      if (error) {
        error(e);
      }
    };

    try {
      setAvatarLoading(true);
      const res = await saveA({
        variables: {
          file,
        },
      });
      next(res);
    } catch (e) {
      onError(e);
    } finally {
      setAvatarLoading(false);
    }
    return Promise.resolve();
  };
  const onSetCV = async (file: any, next: any, error: any) => {
    const onError = (e: any) => {
      setErrorMessage(e, 'Error');
      if (error) {
        error(e);
      }
    };

    try {
      setCVLoading(true);
      const res = await saveCV({
        variables: {
          file,
        },
      });
      next(res);
    } catch (e) {
      onError(e);
    } finally {
      setCVLoading(false);
    }
    return Promise.resolve();
  };

  const onRemoveCV = async (file: any, next: any, error: any) => {
    const onError = (e: any) => {
      setErrorMessage(e, 'Error');
      if (error) {
        error(e);
      }
    };

    try {
      const res = await removeCV({
        variables: {},
      });
      next(res);
    } catch (e) {
      onError(e);
    }
    return Promise.resolve();
  };

  const onSaveData = async (data: any, next?: any, error?: any) => {
    const onError = (e: any) => {
      setErrorMessage(e, 'Error');
      if (error) {
        error(e);
      }
    };
    try {
      const input = {
        ...profileData.profile,
        ...data,
      };

      const res = await saveP({
        variables: {
          input: pick(Object.keys(ProfileInput), input),
        },
      });
      if (profileQ.called) {
        await profileQ.refetch();
      }
      if (next) {
        next(res);
      }
    } catch (e) {
      onError(e);
    }
    return Promise.resolve();
  };

  const setData = async (data: any) => {
    await onSaveData(makeProfileData(data));
  };

  const onGetProfile = () => {
    if (profileCalled) {
      //@ts-ignore
      profileRefetch();
    } else {
      getProfileData();
    }
  };

  const avatarData = useMemo(() => {
    const mainData = profileData ? { image_url: profileData.profile.image_url } : {};
    const metaData = { loading: avatarLoading };

    return { ...mainData, ...metaData };
  }, [profileData, avatarLoading]);

  const CVData = useMemo(() => {
    const mainData = profileData ? { resumeUrl: profileData.profile.resumeUrl } : {};
    const metaData = { loading: CVLoading };

    return { ...mainData, ...metaData };
  }, [profileData, CVLoading]);
  const institutes = useMemo(() => {
    if (profileData) {
      return profileData.profile.institutes.map((i: any) => ({
        institute: { id: i.id, name: i.name },
        subject: { id: i.subject_id, name: i.subject_name },
        degree: { id: i.degree_id, name: i.degree_name },
        graduation_date: i.graduation_date,
        average_grades: { name: i.average_grades },
        manual_input: i.manual_input,
        archetype: i.archetype,
        country_of_education: i.country_of_education,
        minor: i.minor || '',
        archetype_override: i.archetype_override || false,
      }));
    } else {
      return [];
    }
  }, [profileData]);

  useEffect(() => {
    setDataReady(!profileLoading && profileCalled);
  }, [profileLoading]);

  useEffect(() => {
    if ((Platform.OS === 'web' && isReady) || Platform.OS !== 'web') {
      if (profileQ.called) {
        profileQ.refetch();
      } else {
        getProfileData();
      }
    }
  }, [isReady]);

  useEffect(() => {
    if (profileData) {
      const sentryUser = {
        id: profileData.profile.id,
        email: profileData.profile.contact_email,
      };
      setUser(sentryUser);
    }
  }, [profileData]);

  return (
    <Context.Provider
      value={{
        id: getByPath(profileData, ['profile', 'id'], ''),
        firstName: getByPath(profileData, ['profile', 'first_name'], ''),
        lastName: getByPath(profileData, ['profile', 'last_name'], ''),
        sex: getByPath(profileData, ['profile', 'sex'], { id: '' }),
        birthday: getByPath(profileData, ['profile', 'birthday'], ''),
        country: { name: getByPath(profileData, ['profile', 'country'], '') },
        city: { name: getByPath(profileData, ['profile', 'city'], '') },
        socialMedia: getByPath(profileData, ['profile', 'social_media'], []),
        educationLevel: getByPath(profileData, ['profile', 'education_level'], ''),
        institutes,
        contactEmail: getByPath(profileData, ['profile', 'contact_email'], ''),
        workExperiences: getByPath(profileData, ['profile', 'work_experiences'], []),
        otherWorkExperiences: getByPath(profileData, ['profile', 'other_work_experiences'], ''),
        activities: getByPath(profileData, ['profile', 'activities'], []),
        otherActivities: getByPath(profileData, ['profile', 'other_activities'], ''),
        achievements: getByPath(profileData, ['profile', 'achievements'], []),
        otherAchievements: getByPath(profileData, ['profile', 'other_achievements'], ''),
        languages: getByPath(profileData, ['profile', 'languages'], []),
        otherLanguages: getByPath(profileData, ['profile', 'other_languages'], ''),
        internationalExperiences: getByPath(profileData, ['profile', 'international_experiences'], []),
        otherInternationalExperiences: getByPath(profileData, ['profile', 'other_international_experiences'], ''),
        interests: getByPath(profileData, ['profile', 'interests'], []),
        notifications: getByPath(profileData, ['profile', 'notifications'], []),
        bio: getByPath(profileData, ['profile', 'short_bio'], ''),
        isBoardMember: getByPath(profileData, ['profile', 'is_board_member'], false),
        isProfileCreated: getByPath(profileData, ['profile', 'is_profile_created'], false),
        phone_number: getByPath(profileData, ['profile', 'phone_number'], ''),
        referrer: getByPath(profileData, ['profile', 'referrer'], ''),
        profileData: profileData ? profileData.profile : {},
        avatarData,
        CVData,
        dataReady,
        setData,
        onSaveData,
        onSetAvatar,
        onSetCV,
        onRemoveCV,
        onRefresh: profileRefetch,
        onGetProfile,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
