import React, { FC } from 'react';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { IOptionItem } from 'src/requests/models';
import { useStyles } from './styles.web';

interface IProps {
  value: IOptionItem | undefined;
  values: IOptionItem[];
  onChoose(value: IOptionItem): void;
}

const ButtonSwitcher: FC<IProps> = ({ value, values, onChoose }) => {
  const styles = useStyles();

  const btnConfig = (active: boolean, index: number) => {
    if (index === 0) {
      return {
        containerStyle: styles.buttonSwitcherLeft,
        outline: !active,
      };
    } else {
      return {
        containerStyle: styles.buttonSwitcherRight,
        outline: !active,
      };
    }
  };

  return (
    <div className={styles.buttonSwitcher}>
      {values.map((item, index) => {
        return (
          <ButtonMain
            key={item.id}
            type={'primary_1'}
            size={'lg'}
            text={item.name}
            onPress={() => onChoose(item)}
            {...btnConfig(value ? item.id === value.id : false, index)}
          />
        );
      })}
    </div>
  );
};

export default ButtonSwitcher;
