import React, { FC } from 'react';
import ArrowToggle from 'src/components/toggles/ArrowToggle/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  title?: string;
  header?: string;
  content?: string;
}

const ContentToggle: FC<IProps> = (props) => {
  const { noIcon, active, title, onPress, children, iconName, containerStyle, contentStyle } = props;

  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...theme, ...props });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    title: `${styles.title}`.trim(),
    header: `${styles.header}`,
    content: `${styles.content} ${contentStyle}`.trim(),
  };

  return (
    <div className={cn.component}>
      <div className={cn.header} onClick={onPress}>
        {title}
        <ArrowToggle active={active} />
      </div>
      <div className={cn.content} style={{ height: active ? '100%' : 0 }}>
        {children}
      </div>
    </div>
  );
};

export default ContentToggle;
