import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Title from 'src/components/titles/Title/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Link from 'src/components/links/Link/index.web';
import Message from '../Message';
import { IMessage } from 'src/requests/models';
import { useHistory } from 'react-router-dom';

const getEntityType = (type: string) => (type === '1' ? 'company' : type === '2' ? 'opportunity' : type === '3' ? 'event' : '');

const ConversationMobile = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, accept, reject, archive, data, onClose } = props;

  const { t } = useTranslation();

  const { id, logo, name, entity_id: entityId, message_type, is_accepted: isAccepted, is_archived: isArchived, is_automated: isAutomated } = data;

  const history = useHistory();

  const entityType = getEntityType(message_type);

  const msgs = [data];

  useEffect(() => {
    if (isAutomated && isAccepted === null) {
      accept(id);
    }
  }, [isAutomated, isAccepted, id]);

  useEffect(() => {
    if (!isAutomated && isAccepted === null) {
      reject(id);
    }
  }, [isAutomated, isAccepted, id]);

  const textButton = useMemo(() => {
    if (entityType === 'company') {
      return t('Go to company details and subscribe');
    } else if (entityType === 'opportunity') {
      return t('Go to opportunity details and apply');
    } else if (entityType === 'event') {
      return t('Go to event details and apply');
    }
  }, [entityType]);

  return (
    <div className={`${styles.component} ${containerStyle}`}>
      <div className={styles.header}>
        <div className={styles.headItem} style={{ width: 'calc(100% - 120px)' }}>
          <div onClick={onClose}>
            <Icon size={24} name={'arrow-left2'} color={'#222222'} />
          </div>
          <div className={`${logo ? styles.headLogo : styles.withoutHeadLogo}`} />
          <Title size={'h3'} containerStyle={styles.headerTitle}>
            {name}
          </Title>
        </div>
        <div className={styles.headItem}>
          <Link internal href={`${entityType}/${entityId}`} size={'xs'}>
            {t('View Profile')}
          </Link>
        </div>
      </div>
      <div className={styles.body} style={{ height: `calc(100% - ${isAccepted ? 80 + 60 : 160 + 60}px)` }}>
        {msgs.map((item: IMessage) => (
          <Message key={item.id} isMobile={true} data={item} background={'white'} containerStyle={styles.msg} />
        ))}
      </div>
      <div className={styles.footer}>
        {!isAccepted && !isAutomated ? (
          <>
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              // text={textButton}
              onPress={() => {
                accept(id);
                history.push(`${entityType}/${entityId}`);
              }}
              containerStyle={styles.btnAccept}>
              <div className={styles.btnText}>{textButton}</div>
              <div className={styles.btnIcon}>
                <Icon name={'arrow-right'} color="white" size={15} />
              </div>
            </ButtonMain>
          </>
        ) : isArchived ? (
          <ButtonMain
            type={'secondary_1'}
            size={'xl'}
            outline
            onPress={() => archive(id, false)}
            text={t('Move back to inbox')}
            containerStyle={styles.btnArchived}
          />
        ) : (
          <ButtonMain
            type={'primary_1'}
            size={'xl'}
            outline
            onPress={() => archive(id, true)}
            text={t('Archive conversation')}
            containerStyle={styles.btnArchived}
          />
        )}
      </div>
    </div>
  );
};

export default ConversationMobile;
