import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        width: 'inherit',
        border: `1px solid ${theme.$borderColor}`,
        borderRadius: 10,
        paddingTop: 30,
      };
    },
    head: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 15,
    },
    titleContainer: {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      marginTop: 7,
    },
    item: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '10px 0 0',
    }),
    avatar: (p: IProps) => ({
      borderRadius: '50%',

      '& svg': {
        width: 60,
        height: 60,
      },
    }),
    name: (p: IProps) => ({
      display: 'block',
      margin: '0px 5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    }),
    university: {
      textAlign: 'center',
    },
    whatsNew: {
      //padding: 15,
    },
    msgs: {
      padding: 15,
    },
    msgsEmpty: {
      padding: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: 'rgba(246, 85, 50, 0.03)',
      borderRadius: 10,
    },
    msgsImg: {},
    footer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
    },
    link: {
      padding: 5,
    },
    msgsEmptyTitle: {},
    msgsEmptyText: {
      textAlign: 'center',
    },
    msgsList: {
      display: 'block',
    },
    msgsListItem: {
      display: 'flex',
      height: 37,
      marginBottom: 13,
      cursor: 'pointer',

      '&:last-child': {
        marginBottom: 0,
      },
    },
    msgLogo: {
      width: 35,
      height: 35,
      //  background: theme.$secondaryColor2,
      borderRadius: '50%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      marginRight: 10,
    },
    msgLogoWithout: {
      width: 35,
      height: 35,
      background: theme.$secondaryColor2,
      borderRadius: '50%',
      marginRight: 10,
    },
    msgTitle: {
      margin: 0,
      overflow: 'hidden',
      maxWidth: 190,
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      display: 'block',
    },
    msgLabel: {
      marginLeft: 10,
    },
  };
});
