import { OnboardingData } from 'src/requests/models';

export type TOnboardingState = {
  data: OnboardingData;
  saveProfileFlag: boolean;
  currentPage: string;
  loading: boolean;
};

export enum EOnboardingActionTypes {
  SET_DATA_LOADING = '@onboarding/SET_DATA_LOADING',
  //GET_DATA = '@onboarding/GET_DATA',
  SET_DATA = '@onboarding/SET_DATA',
  SET_CURRENT_PAGE = '@onboarding/SET_CURRENT_PAGE',
  SET_PROFILE_SAVE = '@onboarding/SET_PROFILE_SAVE',
}

export type TSetDataLoadingAction = {
  type: EOnboardingActionTypes.SET_DATA_LOADING;
  payload: boolean;
};

export type TSetData = {
  type: EOnboardingActionTypes.SET_DATA;
  payload: OnboardingData;
};

export type TSetCurrentPage = {
  type: EOnboardingActionTypes.SET_CURRENT_PAGE;
  payload: string;
};

export type TSetProfileSave = {
  type: EOnboardingActionTypes.SET_PROFILE_SAVE;
  payload: boolean;
};

export type TOnboardingAction = TSetDataLoadingAction | TSetData | TSetCurrentPage | TSetProfileSave;
