import React, { FC, MouseEvent, useContext, memo, useMemo, useRef, useState, useEffect } from 'react';
import Icon from 'src/components/icons/Icon/index.web';
import SearchInput from 'src/components/inputs/SearchInput/index.web';
import RoundedProgress from 'src/components/svgs/RoundedProgress/index.web';
import ButtonToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import Avatar from '../Avatar';
import { IProps } from './types';
import colors from 'src/constant/colors';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';

import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { selectIsReady } from 'src/state/modules/session';
import Menu from './Menu';
import { Context as ProfileContext } from '../../../web/contexts/ProfileContexts/index.web';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import Link from 'src/components/links/Link/index.web';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Title from 'src/components/titles/Title/index.web';
import { Context as CompaniesSearchContext } from 'src/contexts/SearchContext/CompaniesSearchContext';
import { Context as EventSearchContext } from '../../../contexts/SearchContext/EventSearchContext';
import { Context as OpportunitySearchContext } from '../../../contexts/SearchContext/OpportunitySearchContext';
import { Context as EverythingSearchContext } from 'src/contexts/SearchContext/GlobalSearchContext';
import { IOptionItem } from 'src/requests/models';

const Header: FC<IProps> = memo((props) => {
  const history = useHistory();
  const location = useLocation();
  const { isMobile, containerStyle } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const isReady = useSelector((state) => selectIsReady(state));
  const { setFilters, setEntityByName }: any = useContext(SearchContext);
  const { setSearchStart: onSearchCompanies }: any = useContext(CompaniesSearchContext);
  const { setSearchStart: onSearchOpportunities, setClear: onClearOpportunities }: any = useContext(OpportunitySearchContext);
  const { setSearchStart: onSearchEvents, setClear: onClearEvents }: any = useContext(EventSearchContext);
  const { setSearchStart: onSearchEverything }: any = useContext(EverythingSearchContext);
  const { profileData }: any = useContext(ProfileContext);
  const { image_url, is_profile_created, completeness = { value: 0 }, first_name, last_name } = profileData;
  const toggleRef = useRef(null);
  const { t } = useTranslation();
  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies') },
    { id: '2', name: 'events', label: t('Events') },
    { id: '3', name: 'opportunities', label: t('Opportunities') },
  ];

  const [menuOpen, setMenuOpen] = useState(false);
  const [stateSearchValue, setStateSearchValue] = useState('');

  const { user, isAuthenticated } = useAuth0();
  const fullyDisplay = isAuthenticated && is_profile_created;

  const userName = fullyDisplay ? `${first_name} ${last_name}`.trim() : user ? user.name : '';

  const onSearchChange = (value: string) => {
    setStateSearchValue(value);
  };

  const onSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
      setEntityByName('everything');
      setFilters('searchValue', stateSearchValue);
      setFilters('entity', entitiesList[0]);
      setStateSearchValue('');
      onSearchEverything();
      history.push('/search/all');
    }
  };

  useEffect(() => {
    if (isReady) {
      onSearchEverything();
      onSearchCompanies();
      onSearchOpportunities();
      onSearchEvents();
    }
  }, [isReady]);

  const onLinkPress = (value: string) => {
    if (value === 'explore') {
      history.push('/explore');
    } else if (value === 'companies') {
      setEntityByName('companies');
      setFilters('entity', entitiesList[1]);
      setStateSearchValue('');
      onSearchCompanies();
      history.push(`/search/${value}`);
    } else if (value === 'opportunities') {
      setEntityByName('opportunities');
      setFilters('entity', entitiesList[3]);
      setStateSearchValue('');
      onClearOpportunities();
      onSearchOpportunities();
      history.push(`/search/${value}`);
    } else if (value === 'events') {
      setEntityByName('events');
      setFilters('entity', entitiesList[2]);
      setStateSearchValue('');
      onClearEvents();
      onSearchEvents();
      history.push(`/search/${value}`);
    } else {
      console.error('onLinkPress wrong argument');
    }
  };

  const onMenuToggle = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(!menuOpen);
  };

  const onClose = () => {
    setMenuOpen(false);
  };

  const onClick = () => {
    if (location.pathname === '/support') {
      return history.push('/login');
    }

    return fullyDisplay ? history.push('/explore') : '';
  };
  console.log('location', location.pathname);

  const renderContent = useMemo(() => {
    return isMobile ? (
      <>
        <div className={styles.row} style={{ width: 100 }}>
          <div className={`${styles.logo} ${fullyDisplay ? styles.clickable : ''}`} onClick={() => (fullyDisplay ? history.push('/explore') : '')}>
            <Icon name="logo" color="white" size={35} />
          </div>
          {fullyDisplay ? (
            <Link internal size={'sm'} href={'/search'}>
              <Icon name="search" color={colors.HEADER_ICON_INACTIVE} size={25} />
            </Link>
          ) : null}
        </div>
        <Title size={'h1'} containerStyle={styles.title}>
          <Switch>
            <Route path={'/profile'}>{t('My profile')}</Route>
            <Route path={'/messages'}>{t('Messages')}</Route>
            <Route path={'/likes'}>{t('Likes')}</Route>
            <Route path={'/search/all'}>{t('Search')}</Route>
            <Route path={'/explore'}>{t('Explore')}</Route>
            <Route path={'/settings'}>{t('Settings')}</Route>
            <Route path={'/support'}>{t('Support')}</Route>
          </Switch>
        </Title>
        <div className={styles.row} style={{ width: 100 }}>
          <div className={styles.profileImgContainer}>
            <RoundedProgress containerStyle={styles.profileImgMain} value={completeness.value}>
              <Avatar src={image_url} alt={'somePhoto'} size={'30px'} containerStyle={styles.avatar} />
            </RoundedProgress>
          </div>
          <ButtonToggle
            active={menuOpen}
            onPress={onMenuToggle}
            containerStyle={styles.profileTitle}
            textStyle={styles.profileTitleText}
            iconColor={theme.$primaryColor2}
            ref={toggleRef}
          />
        </div>
      </>
    ) : (
      <>
        <div className={styles.row}>
          <div className={`${styles.logo} ${fullyDisplay || location.pathname === '/support' ? styles.clickable : ''}`} onClick={onClick}>
            <Icon name="big-logo" color="white" size={35} />
          </div>
          {fullyDisplay ? (
            <SearchInput
              value={stateSearchValue}
              placeholder={t('Search companies')}
              containerStyle={styles.searchInput}
              inputStyle={styles.searchInputInput}
              onChange={onSearchChange}
              onKeyPress={onSearchKeyPress}
            />
          ) : null}
        </div>
        <div className={styles.row}>
          {fullyDisplay ? (
            <div className={styles.desktopLinks}>
              <div>
                <div
                  className={`${styles.desktopLink} ${location.pathname === '/explore' ? styles.desktopLinkActive : ''}`.trim()}
                  onClick={() => onLinkPress('explore')}
                  style={{ borderRight: '1px solid #E8EFF4' }}>
                  <div className={styles.desktopLinkIcon}>
                    <Icon
                      size={20}
                      color={location.pathname === '/explore' && styles.desktopLinkActive ? theme.$primaryColor1 : theme.$primaryColor2}
                      name={'grid'}
                    />
                  </div>
                  {t('Explore')}
                </div>
              </div>
              <div className={styles.desktopLinks}>
                <div
                  className={`${styles.desktopLink} ${location.pathname === '/search/companies' ? styles.desktopLinkActive : ''}`.trim()}
                  onClick={() => onLinkPress('companies')}>
                  {t('All companies')}
                </div>
                <div
                  className={`${styles.desktopLink} ${location.pathname === '/search/events' ? styles.desktopLinkActive : ''}`.trim()}
                  onClick={() => onLinkPress('events')}>
                  {t('All events')}
                </div>
                <div
                  className={`${styles.desktopLink} ${location.pathname === '/search/opportunities' ? styles.desktopLinkActive : ''}`.trim()}
                  onClick={() => onLinkPress('opportunities')}>
                  {t('All opportunities')}
                </div>
              </div>
            </div>
          ) : null}
          <div className={styles.profile}>
            <div className={styles.profileImgContainer}>
              <RoundedProgress containerStyle={styles.profileImgMain} value={is_profile_created ? completeness.value : 0}>
                <Avatar src={image_url} alt={'somePhoto'} size={'44px'} containerStyle={styles.avatar} />
              </RoundedProgress>
            </div>
            <ButtonToggle
              active={menuOpen}
              onPress={onMenuToggle}
              containerStyle={styles.profileTitle}
              textStyle={styles.profileTitleText}
              iconColor={theme.$primaryColor2}
              text={userName}
              ref={toggleRef}
            />
            <Menu isMobile={isMobile} isOpen={!isMobile && menuOpen} containerStyle={styles.menu} onClose={onClose} ref={toggleRef} showFull={fullyDisplay} />
          </div>
        </div>
      </>
    );
  }, [isMobile, menuOpen, image_url, profileData, stateSearchValue, location.pathname]);

  return (
    <>
      <header className={`${styles.component} ${containerStyle ? containerStyle : ''}`.trim()}>
        <div className={styles.content}>{renderContent}</div>
        <Menu isMobile={isMobile} isOpen={isMobile && menuOpen} containerStyle={styles.menu} onClose={onClose} ref={toggleRef} showFull={fullyDisplay} />
      </header>
    </>
  );
});

export default Header;
