import React, { FC, useContext, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles';
import { useDropzone } from 'react-dropzone';
import Loader from 'react-loader-spinner';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from '../../../constant/themes';
import { useTranslation } from 'react-i18next';
import { UPDATE_GRADE_PROOF, REMOVE_GRADE_PROOF } from '../../../requests/mutations';
import { useMutation } from '@apollo/client';
import Text from 'src/components/texts/Text/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { Context as ProfileContext } from '../../contexts/ProfileContexts/index.web';
import { extension } from '../../../utilities';

interface ComponentStyles {
  component?: string;
  mainText?: string;
  secondaryText?: string;
  uploadBtn?: string;
  file?: string;
}

const imageFormats = ['image/jpeg', 'image/png', 'application/pdf'];
const imageSize = 5;

const GradeProof: FC<IProps> = (props) => {
  const { containerStyle, institute_id, subject_id, grade_proof, degree_id, institute_name, subject_name } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });

  const [showError, setShowError] = useState(false);
  const [filePath, setFilePath] = useState(grade_proof);

  const { onRefresh: refreshProfile }: any = useContext(ProfileContext);

  const [upload, { loading: uploadLoading, data: uploadData }] = useMutation(UPDATE_GRADE_PROOF);

  const [unupload, { loading: unuploadLoading, data: unuploadData }] = useMutation(REMOVE_GRADE_PROOF);

  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: imageFormats.join(','),
    maxSize: imageSize * 1024 * 1024,
    onDropRejected: (e) => {
      setShowError(true);
      console.error(e);
    },
  });

  const onCancelFile = () => {
    setFilePath('');
    unupload({
      variables: {
        input: {
          institute_id,
          subject_id,
          degree_id,
        },
      },
      update: (cache: any, result: any) => {
        const institute = {
          __typename: 'Institute',
          id: institute_id,
          subject_id,
          degree_id,
        };

        cache.modify({
          id: cache.identify(institute),
          fields: {
            grade_proof: () => undefined,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (acceptedFiles.length) {
      upload({
        variables: {
          file: acceptedFiles[0],
          input: {
            institute_id: institute_id ? institute_id : '',
            subject_id: subject_id ? subject_id : '',
            degree_id,
          },
        },
        update: (cache: any, result: any) => {
          const { grade_proof } = result.data.updateGradeProof;

          const institute = {
            __typename: 'Institute',
            id: institute_id,
            subject_id,
            degree_id,
          };

          cache.modify({
            id: cache.identify(institute),
            fields: {
              grade_proof: () => grade_proof,
            },
          });

          setFilePath(grade_proof);
        },
      }).then(() => refreshProfile());
    }
  }, [acceptedFiles]);

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    mainText: `${styles.mainText}`,
    secondaryText: `${styles.secondaryText}`,
    uploadBtn: `${styles.uploadBtn}`,
    file: `${styles.file}`,
  };

  const displayedFilePath = filePath ? filePath.split('/').reverse()[0] : '';

  const fileExtension = extension(grade_proof ? grade_proof : acceptedFiles[0]?.name);

  return (
    <div className={cn.component}>
      <div style={{ position: 'relative' }} {...getRootProps({ className: 'dropzone' })}>
        <Text containerStyle={cn.mainText} type={'button'}>
          {t('Grades proof certificate')}
        </Text>

        <div className={styles.university}>
          {institute_name && <div className={styles.universityName}>{institute_name}</div>}
          {subject_name && <div className={styles.subjectName}>{subject_name}</div>}
        </div>
        <Text containerStyle={cn.secondaryText} type={'secondary'}>
          {t('Please attach a document that proves the grades you claimed for given university and education')}
        </Text>
        <input {...getInputProps()} />
        {displayedFilePath ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
            <div className={cn.file}>
              <a className={styles.path} href={filePath} target={'_blank'} title={filePath}>{`file${fileExtension ? '.' + fileExtension : ''}`}</a>
              <div className={styles.close} onClick={onCancelFile}>
                <Icon name={'close'} color={'white'} size={7} />
              </div>
            </div>
          </div>
        ) : (
          <ButtonMain type={'primary_1'} containerStyle={cn.uploadBtn} size={'lg'} text={t('Upload')} iconName={'upload'} onPress={open} outline />
        )}
        {uploadLoading ? (
          <div className={styles.loader}>
            <Loader type="Oval" color={theme.$primaryColor1} height={50} width={50} />
          </div>
        ) : (
          ''
        )}
        {showError ? (
          <div className={styles.avatarError} onDoubleClick={() => setShowError(false)}>
            <div className={styles.closeError} onClick={() => setShowError(false)} />
            <p>{`${t('Acceptable formats')}:  ${imageFormats.join(', ')}`}</p>
            <br />
            <p>{t('The file you have uploaded exceeds the limit of size MB', { size: imageSize })}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GradeProof;
