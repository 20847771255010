import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }),
    content: (p: IProps) => ({
      color: p.active ? theme.$primaryColor1 : theme.$primaryColor2,
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '2rem',
      padding: '0.6rem',
    }),
  };
});
