import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import ExplorePage from '../containers/ExplorePage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { EExploreFeedTypes } from 'src/web/components/Explore/types';

const Container = () => (
  <Switch>
    <PrivateRoute path={'/explore/general'}>
      <Page>{({ isMobile, pageRef }: IPassProps) => <ExplorePage isMobile={isMobile} ref={pageRef} tab={EExploreFeedTypes.GENERAL} />}</Page>
    </PrivateRoute>
    <PrivateRoute exact path={'/explore/companies'}>
      <Page>{({ isMobile, pageRef }: IPassProps) => <ExplorePage isMobile={isMobile} ref={pageRef} tab={EExploreFeedTypes.COMPANY} />}</Page>
    </PrivateRoute>
    <PrivateRoute exact path={'/explore/events'}>
      <Page>{({ isMobile, pageRef }: IPassProps) => <ExplorePage isMobile={isMobile} ref={pageRef} tab={EExploreFeedTypes.EVENT} />}</Page>
    </PrivateRoute>
    <PrivateRoute exact path={'/explore/opportunities'}>
      <Page>{({ isMobile, pageRef }: IPassProps) => <ExplorePage isMobile={isMobile} ref={pageRef} tab={EExploreFeedTypes.OPPORTUNITY} />}</Page>
    </PrivateRoute>
    <Redirect to={'/explore/general'} />
  </Switch>
);

export default Container;
