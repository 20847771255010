import React, { useContext, useRef, useLayoutEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Context as MessagesContext } from 'src/contexts/MessagesContext/index.web';
import Title from 'src/components/titles/Title/index.web';
import WhatsNew from '../WhatsNew';
import Text from 'src/components/texts/Text/index.web';
import Avatar from '../../Avatar';
import Devider from 'src/components/dividers/Divider/index.web';
import Link from 'src/components/links/Link/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import img from '../../../../assets/images/bermuda-missing-piece.png';
import { ITheme } from '../../../../constant/themes';
import { useHistory } from 'react-router';

const Side = (props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, profileData, avatarData, ready, position } = props;
  const {
    first_name = '',
    last_name = '',
    education_level = { name: '' },
    institutes = [{ name: '', graduation_date: null, subject_name: '' }],
  } = ready ? profileData : {};
  const { image_url } = avatarData;
  const { name: instituteName, graduation_date, subject_name } = institutes[0];

  const history = useHistory();
  const { t } = useTranslation();
  const { result }: any = useContext(MessagesContext);

  const isStudent = education_level.id != 4 ? t('Student') : t('Graduated');

  const resultFiltered = result.filter((item: any) => item.is_accepted === null).slice(0, 3);

  const sideRef = useRef<any>(null);
  const [sidePosition, setSidePosition] = useState({});

  useLayoutEffect(() => {
    if (position) {
      if (sideRef.current.offsetHeight + position.y > window.innerHeight) {
        setSidePosition({ position: 'relative', height: 'max-content' });
      } else {
        setSidePosition({ position: 'fixed', left: position.x, top: position.y });
      }
    }
  }, [position]);

  return (
    <div ref={sideRef} className={styles.component} style={sidePosition}>
      <div className={styles.head}>
        <Avatar src={image_url} size={'90px'} containerStyle={styles.avatar} />
        <div className={styles.titleContainer} title={`${first_name} ${last_name}`}>
          <Title size={'h2'} containerStyle={`${styles.item} ${styles.name}`.trim()}>
            {first_name}
          </Title>
          <Title size={'h2'} containerStyle={`${styles.item} ${styles.name}`.trim()}>
            {last_name}
          </Title>
        </div>
        <Text type={'secondary'} containerStyle={`${styles.item} ${styles.university}`.trim()}>
          {isStudent}, {subject_name}, {instituteName}
        </Text>
      </div>
      <WhatsNew isMobile={isMobile} />
      <div className={styles.msgs}>
        {resultFiltered.length ? (
          <div className={styles.msgsList}>
            {resultFiltered.map((item: any) => (
              <div key={item.id} className={styles.msgsListItem} onClick={() => history.push('/messages')}>
                {item.logo ? (
                  <div className={styles.msgLogo} style={{ backgroundImage: `url(${item.logo && item.logo.replace(/ /g, '%20')})` }} />
                ) : (
                  <div className={styles.msgLogoWithout} />
                )}
                <div style={{ flexGrow: 1 }}>
                  <Title size={'h3'} containerStyle={styles.msgTitle}>
                    {item.company_name}
                  </Title>
                  <Icon size={10} color={theme.$primaryColor1} name={'mail'} />
                  <Text type={'secondary'} text={t('Sent you a request')} containerStyle={styles.msgLabel} inline />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.msgsEmpty}>
            <img className={styles.msgsImg} src={img} />
            <Title size={'h3'} containerStyle={styles.msgsEmptyTitle}>
              {t('Customize your profile')}
            </Title>
            <Text
              type={'default'}
              containerStyle={styles.msgsEmptyText}
              text={t('Try adding more details or editing your profile to make it stand out even more and increase your chances!')}
            />
          </div>
        )}
      </div>
      <Devider />
      <div className={styles.footer}>
        <Link internal href={'/profile'} size={'sm'} text={t('Edit profile')} containerStyle={styles.link} />
      </div>
    </div>
  );
};

export default Side;
