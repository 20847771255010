import React, { useEffect, useState, memo, useContext } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Icon from '../../../../../components/icons/Icon/index.web';
import EventItem from '../../../../../web/components/Event/Item';
import Title from '../../../../../components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { EVENTS } from '../../../../../requests/queries';
import { Context as EventSearchContext } from '../../../../../contexts/SearchContext/EventSearchContext';
import { Context as SearchContext } from '../../../../../contexts/SearchContext';
import { useHistory } from 'react-router';
import { Context as LikesContext } from '../../../../../contexts/LikesContext/index.web';

const FixedList = memo((props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const [showAll, setShowAll] = useState(true);
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const history = useHistory();
  const { isMobile, Item = EventItem, containerStyle, company: company_id } = props;

  const { setEntityByName }: any = useContext(SearchContext);
  const { setData, setSearchStart, setClear }: any = useContext(EventSearchContext);

  const { toggleLike: tL, toggleBookmark: tB }: any = useContext(LikesContext);

  const onViewAll = () => {
    setClear();
    setData({ companyId: company_id });
    setEntityByName('events');
    setSearchStart();
    history.push('/search/events');
  };

  const {
    loading,
    data: eventsData = {
      events: {
        page: 1,
        total: 1,
        limit: 5,
        result: [],
      },
    },
    refetch,
  } = useQuery(EVENTS, {
    variables: {
      lang,
      filters: { company_id: '' + company_id },
      limit: 5,
      page: 1,
    },
    fetchPolicy: 'cache-and-network',
  });

  const toggleLike = async ({ id, application }: any, is_liked: boolean) => {
    if (application.redirectUrl) {
      window.open(application.redirectUrl);
    }

    tL(id, is_liked, 'e');

    refetch();
  };

  const toggleBookmark = async (id: string, flag: boolean) => {
    await tB(id, flag, 'e');
    refetch();
  };

  useEffect(() => {
    if (eventsData.events.result.length > 2) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  }, [eventsData]);

  return eventsData.events.result.length ? (
    <div className={`${styles.container} ${containerStyle}`.trim()}>
      <div className={styles.titleContainer}>
        <Title size={'h1'} containerStyle={styles.title}>
          <Icon name={'breafcase'} size={17} color={theme.$primaryColor1} />
          {t('Upcoming events')}
        </Title>
        {showAll && (
          <div className={styles.viewAll} onClick={onViewAll}>
            {t('View all')}
          </div>
        )}
      </div>
      <div className={styles.list}>
        {eventsData.events.result.slice(0, 5).map((item: any, index: number) => (
          <Item key={index} fixed containerStyle={styles.item} isMobile={isMobile} data={item} toggleLike={toggleLike} toggleBookmark={toggleBookmark} />
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
});

export default FixedList;
