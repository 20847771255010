import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    body: (p: {isMobile: boolean}) => ({
      padding: p.isMobile ? '0 15px 0' : '0 30px 0',
    }),
    divider: {
      width: '100%',
    },
    title: {
      margin: '1.5rem 0',
    },
    editButton: {
      width: '100%',
      maxWidth: 'none',
      margin: '1.5rem 0 3rem',
    },
    label: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '1.5rem 0 0.5rem',
    },
    nextLink: {
      marginTop: '15px',
    },
  };
});
