import React, { useEffect, useState, memo, useRef, useContext } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Icon from 'src/components/icons/Icon/index.web';
import Title from 'src/components/titles/Title/index.web';
import { useHistory } from 'react-router';
import Devider from 'src/components/dividers/Divider/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import Link from 'src/components/links/Link/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import ButtonTransparentToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import Markdown from 'src/web/components/Markdown';
import Label from 'src/components/labels/Label/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import { createLabel } from 'src/utilities/languages';
import { useDispatch } from 'react-redux';
import { addSubscription } from '../../../../../state/modules/ui';
import { Context as SearchContext } from 'src/contexts/SearchContext';

const Opportunity = memo((props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const {
    id,
    name,
    company_name,
    banner_img,
    promo_video,
    main_description,
    description,
    cities,
    city,
    experiences,
    degrees,
    is_liked,
    is_bookmarked = false,
    types = [],
    languages = [],
    company_size,
    sectors,
    created,
    updated,
    setOpportunityLike,
    setOpportunityBookmark,
    companyData,
    application_flow,
    application,
  } = props;

  const { additional_info, id: company_id } = companyData;

  const [textToggleVisible, setTextToggleVisible] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const { setApplyModalShown, setEntityForApply } = useContext(SearchContext);

  const { t } = useTranslation();
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };
  const showApplyModal = () => {
    setApplyModalShown(true);
    setEntityForApply(props);
  };

  const readMoreOpenedHeight = textAreaRef.current !== null ? (textAreaRef.current.scrollHeight <= 300 ? textAreaRef.current.scrollHeight : 300) : 300;

  useEffect(() => {
    if (textAreaRef.current) {
      //@ts-ignore
      if (textAreaRef.current.scrollHeight > textAreaRef.current?.offsetHeight) {
        setTextToggleVisible(true);
      } else {
        setTextToggleVisible(false);
      }
    }
  }, [description]);

  return (
    <div className={styles.component}>
      <div className={styles.headerReplace}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onBack}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Opportunity details')}
        </Title>
      </div>
      <div className={styles.companyBackground} />
      <main className={styles.main}>
        <div className={styles.mainHeader}>
          <div className={styles.mainInfoLogo} />
          <div className={styles.sideLogo}>
            <Title size={'h2'} containerStyle={styles.companyTitle}>
              {company_name}
            </Title>
            <div className={styles.labels} style={{ paddingRight: 110 }}>
              <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelSection}`} iconStyle={styles.labelIcon}>
                {sectors && sectors[0] && sectors[0].name}
              </TextIcon>
              <TextIcon iconName={'user'} iconSize={14} containerStyle={`${styles.label} ${styles.labelUser}`} iconStyle={styles.labelIcon}>
                {company_size && company_size.name}
              </TextIcon>
              <Link internal href={`/company/${company_id}`} size={'sm'} containerStyle={styles.profileLink}>
                {t('View profile')}
              </Link>
            </div>
          </div>
        </div>
        <Devider />
        <div style={{ marginTop: 15 }}>
          <Title size={'h1'} containerStyle={styles.opportunityTitle}>
            {name}
          </Title>
          <div className={styles.labels}>
            {types.length && <Label item={types[0]} containerStyle={styles.labelType} />}
            <TextIcon iconName={'location'} iconSize={14} containerStyle={`${styles.label} ${styles.labelLocation}`} iconStyle={styles.labelIcon}>
              {cities && cities.length ? cities.join(', ') : city || ''}
            </TextIcon>
          </div>
        </div>
        <div className={styles.opportunityOther}>
          <div className={styles.opportunityOtherLabels}>
            <TextIcon iconName={'chat'} iconSize={14} containerStyle={`${styles.label} ${styles.labelOther}`} iconStyle={styles.labelIcon}>
              {createLabel((languages || []).map((item) => item.name))}
            </TextIcon>
            <TextIcon iconName={'award'} iconSize={14} containerStyle={`${styles.label} ${styles.labelOther}`} iconStyle={styles.labelIcon}>
              {degrees && degrees[0] && degrees[0].name}
            </TextIcon>
            <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelOther}`} iconStyle={styles.labelIcon}>
              {experiences && experiences[0] && experiences[0].name}
            </TextIcon>
          </div>
        </div>
        <div className={styles.overall} style={{ height: readMore ? readMoreOpenedHeight : 'auto', overflowY: readMore ? 'auto' : 'hidden' }}>
          <textarea className={styles.overallValue} value={main_description} disabled ref={textAreaRef} rows={3} />
        </div>
        {description && (readMore ? true : textToggleVisible) ? (
          <div className={styles.overallBottom}>
            <ButtonTransparentToggle active={readMore} text={readMore ? t('Show less') : t('Read full description')} onPress={() => setReadMore(!readMore)} />
          </div>
        ) : null}
      </main>
      <div className={styles.opportunityMarkdown}>
        <Markdown isMobile={false} markdown={description} />
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          {is_liked ? (
            <ButtonMain
              outline
              size={'xl'}
              type={'primary_1'}
              text={t('Interested')}
              containerStyle={styles.setInterest}
              onPress={() => {
                showApplyModal();
              }}
            />
          ) : (
            <>
              <ButtonMain
                size={'xl'}
                type={'primary_1'}
                text={t('Show interest')}
                containerStyle={styles.setInterest}
                onPress={() => {
                  showApplyModal();
                }}
              />
              <ButtonIconToggle
                size={'xl'}
                activeIconName={'bookmark-fill'}
                inactiveIconName={'bookmark'}
                containerStyle={styles.btnMain}
                iconSize={20}
                iconColor={'#F65532'}
                active={is_bookmarked}
                onPress={setOpportunityBookmark}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default Opportunity;
