import gql from 'graphql-tag';

export const SAVE_PROFILE = gql`
  mutation updateProfile(
    $institutes: [InstituteInput!]
    $international_experiences: [String!]
    $achievements: [String!]
    $activities: [String!]
    $languages: [String!]
    $work_experiences: [String!]
    $first_name: String
    $last_name: String
    $contact_email: String
    $sex: String
    $birthday: DateTime
    $referrer: String
    $interests: [String!]
    $country: String
    $city: String
    $short_bio: String
    $social_media: [SocialMediaInput!]
    $education_level: String
    $is_board_member: Boolean
    $fcm_token: String
    $phone_number: String
  ) {
    updateProfile(
      profile: {
        institutes: $institutes
        interests: $interests
        international_experiences: $international_experiences
        activities: $activities
        achievements: $achievements
        languages: $languages
        work_experiences: $work_experiences
        first_name: $first_name
        last_name: $last_name
        contact_email: $contact_email
        sex: $sex
        referrer: $referrer
        birthday: $birthday
        country: $country
        city: $city
        social_media: $social_media
        short_bio: $short_bio
        education_level: $education_level
        is_board_member: $is_board_member
        fcm_token: $fcm_token
        phone_number: $phone_number
      }
    ) {
      id
    }
  }
`;

export const UPDATE_STUDENT_PROFILE = gql`
  mutation UpdateStudentProfile($input: ProfileInput!) {
    updateStudentProfile(input: $input) {
      id
    }
  }
`;

export const SAVE_PROFILE_AVATAR = gql`
  mutation uploadProfileImage($file: Upload!) {
    uploadProfileImage(file: $file) {
      id
      image_url
    }
  }
`;

export const UPDATE_PROFILE_CV = gql`
  mutation uploadResume($file: Upload!) {
    uploadResume(file: $file) {
      id
      resumeUrl
    }
  }
`;

export const UPDATE_GRADE_PROOF = gql`
  mutation updateGradeProof($file: Upload!, $input: GradeProofInput!) {
    updateGradeProof(file: $file, input: $input) {
      id
      institute_id
      subject_id
      degree_id
      grade_proof
    }
  }
`;

export const UPDATE_OPPORTUNITY_LIKE = gql`
  mutation updateOpportunityLike($like: LikeInput!) {
    updateOpportunityLike(like: $like) {
      id
      name
      type
      is_liked
    }
  }
`;

export const UPDATE_COMPANY_LIKE = gql`
  mutation updateCompanyLike($like: LikeInput!) {
    updateCompanyLike(like: $like) {
      id
      name
      type
      is_liked
    }
  }
`;

export const UPDATE_COMPANY_DISLIKE = gql`
  mutation updateCompanyDislike($dislike: DislikeInput!) {
    updateCompanyDislike(dislike: $dislike) {
      id
      name
      is_disliked
    }
  }
`;

export const UPDATE_EVENT_LIKE = gql`
  mutation updateEventLike($like: LikeInput!) {
    updateEventLike(like: $like) {
      id
      name
      type
      is_liked
    }
  }
`;

export const UPDATE_OPPORTUNITY_BOOKMARK = gql`
  mutation updateOpportunityBookmark($bookmark: BookmarkInput!) {
    updateOpportunityBookmark(bookmark: $bookmark) {
      id
      name
      is_bookmarked
    }
  }
`;

export const UPDATE_COMPANY_BOOKMARK = gql`
  mutation updateCompanyBookmark($bookmark: BookmarkInput!) {
    updateCompanyBookmark(bookmark: $bookmark) {
      id
      name
      is_bookmarked
    }
  }
`;

export const UPDATE_EVENT_BOOKMARK = gql`
  mutation updateEventBookmark($bookmark: BookmarkInput!) {
    updateEventBookmark(bookmark: $bookmark) {
      id
      name
      is_bookmarked
    }
  }
`;

export const UPDATE_MESSAGE_STATUS = gql`
  mutation updateMessageStatus($id: String!, $is_accepted: Boolean!) {
    updateMessageStatus(id: $id, is_accepted: $is_accepted) {
      id
      name
      company_name
      logo
      created
      banner_img
      message_type
      entity_id
      account_id
      message_created
      text
      is_accepted
      is_archived
    }
  }
`;

export const SET_ARCHIVE_MESSAGE = gql`
  mutation archiveMessage($id: String!, $is_archived: Boolean!) {
    archiveMessage(id: $id, is_archived: $is_archived) {
      id
      name
      company_name
      logo
      created
      banner_img
      message_type
      entity_id
      account_id
      message_created
      text
      is_accepted
      is_archived
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword {
    resetPassword
  }
`;

export const CHANGE_EMAIL = gql`
  mutation changeEmail($input: EmailChangeInput!) {
    changeEmail(input: $input) {
      email
    }
  }
`;

export const DEACTIVATE_ACCOUNT = gql`
  mutation deactivateAccount($is_deactivated: Boolean!) {
    deactivateAccount(is_deactivated: $is_deactivated)
  }
`;

export const DEACTIVATE_PROFILE = gql`
  mutation DeactivateProfile($input: ProfileDeactivationInput!) {
    deactivateProfile(input: $input)
  }
`;

export const SEND_SUPPORT_MESSAGE = gql`
  mutation sendSupport($message: String!, $subject: String!, $email: String!) {
    sendSupport(message: $message, subject: $subject, email: $email)
  }
`;

export const UPLOAD_GRADE_PROOF = gql`
  mutation uploadGradeProof($file: Upload!, $institute_id: String!, $subject_id: String!, $degree_id: String!) {
    uploadGradeProof(file: $file, institute_id: $institute_id, subject_id: $subject_id, degree_id: $degree_id) {
      id
      institute_id
      subject_id
      degree_id
      grade_proof
    }
  }
`;

export const DELETE_GRADE_PROOF = gql`
  mutation deleteGradeProof($institute_id: String!, $subject_id: String!, $degree_id: String!) {
    deleteGradeProof(institute_id: $institute_id, subject_id: $subject_id, degree_id: $degree_id)
  }
`;

export const REMOVE_GRADE_PROOF = gql`
  mutation removeGradeProof($input: GradeProofInput!) {
    removeGradeProof(input: $input)
  }
`;

export const REMOVE_PROFILE_CV = gql`
  mutation deleteResume {
    deleteResume {
      resumeUrl
    }
  }
`;

export const FINISH_ONBOARDING = gql`
  mutation {
    finishOnboarding
  }
`;
