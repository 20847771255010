import React, { FC } from 'react';
import { ThemeProvider } from 'react-jss';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Auth0ProviderWithHistory from '../containers/Auth0ProviderWithHistory';
import Login from './pages/login';
import PrivateRoute from './components/PrivateRoute';
import ErrorHandler from './components/ErrorHandler';
import Modals from './components/Modals';
import Onboarding from './pages/onboarding';
import Profile from './pages/profile';
import SearchRoute from './pages/search';
import Company from './pages/company';
import Opportunity from './pages/opportunity';
import Event from './pages/event';
import Likes from './pages/likes';
import Explore from './pages/explore';
import Messages from './pages/messages';
import Error from './pages/error';
import Support from './pages/support';
import Settings from './pages/settings';
import { themes } from 'src/constant/themes';
import { getChosenTheme } from 'src/state/modules/ui/ui.selectors';
import WithApollo from '../containers/WithApollo/index.web';
import { Provider as DataProvider } from 'src/contexts/OnboardingContext';
import { Provider as ProfileProvider } from 'src/web/contexts/ProfileContexts/index.web';
import { Provider as SearchProvider } from 'src/contexts/SearchContext';
import { Provider as LikesProvider } from 'src/contexts/LikesContext/index.web';
import { Provider as ExploreProvider } from 'src/contexts/ExploreContext';
import { Provider as TypedExploreProvider } from 'src/contexts/ExploreContext/TypedExploreContext';
import { Provider as MessagesProvider } from 'src/contexts/MessagesContext/index.web';
import PrivacyPage from './containers/PrivacyPage';
import TermsPage from './containers/TermsPage';
import GlobalError from './components/GlobalError';
import PathChecker from './containers/PathChecker';
import SubscribeBanners from './components/SubscribeBanners';

const Root: FC = () => {
  const chosenTheme = useSelector((state) => getChosenTheme(state));

  return (
    <Router>
      <Auth0ProviderWithHistory>
        <ThemeProvider theme={themes[chosenTheme]}>
          <ErrorHandler />
          <GlobalError>
            <WithApollo>
              <DataProvider>
                <ProfileProvider>
                  <SearchProvider>
                    <MessagesProvider>
                      <LikesProvider>
                        <PathChecker>
                          <Switch>
                            <PrivateRoute path="/onboarding">
                              <Onboarding />
                            </PrivateRoute>
                            <PrivateRoute exact path="/profile">
                              <ExploreProvider>
                                <Profile />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/">
                              <ExploreProvider>
                                <Redirect to={'/explore'} />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/invite">
                              <ExploreProvider>
                                <Redirect to={'/explore'} />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/search">
                              <ExploreProvider>
                                <Redirect to={'/search/all'} />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/search/:entities">
                              <ExploreProvider>
                                <SearchRoute />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/likes">
                              <ExploreProvider>
                                <Likes />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/messages">
                              <ExploreProvider>
                                <Messages />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/settings">
                              <ExploreProvider>
                                <Settings />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/company/:id">
                              <ExploreProvider>
                                <Company />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/opportunity/:id">
                              <ExploreProvider>
                                <Opportunity />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/event/:id">
                              <ExploreProvider>
                                <Event />
                              </ExploreProvider>
                            </PrivateRoute>
                            <PrivateRoute path="/explore">
                              <ExploreProvider>
                                <TypedExploreProvider>
                                  <Explore />
                                </TypedExploreProvider>
                              </ExploreProvider>
                            </PrivateRoute>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/privacy" component={PrivacyPage} />
                            <Route exact path="/terms" component={TermsPage} />
                            <Route exact path="/support" component={Support} />
                            <Route>
                              <Error />
                            </Route>
                          </Switch>
                        </PathChecker>
                      </LikesProvider>
                      <Modals />
                      <SubscribeBanners />
                    </MessagesProvider>
                  </SearchProvider>
                </ProfileProvider>
              </DataProvider>
            </WithApollo>
          </GlobalError>
        </ThemeProvider>
      </Auth0ProviderWithHistory>
    </Router>
  );
};

export default Root;
