import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Context as MessagesContext } from 'src/contexts/MessagesContext/index.web';
import SearchInput from 'src/components/inputs/SearchInput/index.web';
import TabList from 'src/components/tabs/TabList/index.web';
import List from './List';

const ConversationList = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile } = props;

  const { t } = useTranslation();
  const { searchValue, setSearchValue, setChosenConversation, tab, onSetTab, inboxCount }: any = useContext(MessagesContext);

  const tabList = [
    {
      key: '0',
      value: useMemo(
        () => (
          <div className={styles.inboxTab}>
            <div>{t('Inbox')}</div>
            {inboxCount ? <div className={styles.inboxCount}>{inboxCount}</div> : null}
          </div>
        ),
        [inboxCount],
      ),
    },
    { key: '1', value: useMemo(() => <div className={styles.archiveTab}>{t('Archive')}</div>, [inboxCount]) },
  ];

  const onTabChange = (tab: string) => {
    onSetTab(tab);
    setChosenConversation(undefined);
  };

  return (
    <div className={styles.component}>
      <div className={styles.head}>
        <SearchInput value={searchValue} containerStyle={styles.searchField} onChange={setSearchValue} placeholder={t('Search conversations')} />
        <TabList isMobile={isMobile} active={tab} list={tabList} onChange={onTabChange} containerStyle={styles.tabs} tabStyle={styles.tab} />
      </div>
      <div className={styles.body}>
        <List isMobile={isMobile} containerStyle={styles.msgList} />
      </div>
    </div>
  );
};

export default ConversationList;
