import React, { createContext, useEffect, useState } from 'react';
import { ONBOARDING_DATA, ONBOARDING_COMPANY_LIST } from '../../requests/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SAVE_PROFILE, FINISH_ONBOARDING } from '../../requests/mutations';
import { setErrorMessage } from '../../state/modules/error';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { selectIsReady } from '../../state/modules/session';
import { selectUserData } from '../../state/modules/user';
import {
  selectArchetype,
  selectCustomInstitute,
  selectCustomSubject,
  selectWhoAreYou,
  selectCountryOfEducation,
  selectMinor,
} from '../../state/modules/onboarding';

interface IProps {
  children: any;
  isMobile?: boolean;
}

const Context = createContext({});
Context.displayName = '_ONBOARDING_';

const Provider = (props: IProps) => {
  const { children, isMobile = false } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const [availableEducationalLevel, setAvailableEducationalLevel] = useState([]);
  const [availableDegrees, setAvailableDegrees] = useState([]);
  const [availableInstitutes, setAvailableInstitutes] = useState([]);
  const [availableEducationalGrades, setAvailableEducationalGrades] = useState([]);
  const [availableAchievements, setAvailableAchievements] = useState([]);
  const [availableWorkingExperiences, setAvailableWorkingExperiences] = useState([]);
  const [availableInternationalExperiences, setAvailableInternationalExperiences] = useState([]);
  const [availableActivities, setAvailableActivities] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableSexes, setAvailableSexes] = useState([]);
  const [availableInterests, setAvailableInterests] = useState([]);

  const custom_subject = useSelector((state) => selectCustomSubject(state));
  const custom_institute = useSelector((state) => selectCustomInstitute(state));
  const archetype = useSelector((state) => selectArchetype(state));
  const selectedMinor = useSelector((state) => selectMinor(state));
  const country_of_education = useSelector((state) => selectCountryOfEducation(state));
  const whoAreYou = useSelector((state) => selectWhoAreYou(state));
  const user = useSelector((state) => selectUserData(state));

  const isReady = useSelector((state) => selectIsReady(state));

  const [getOnboardingData, onboardingData] = useLazyQuery(ONBOARDING_DATA, { variables: { lang } });

  const archetypeId = (isMobile ? user?.archetype : archetype) || '';
  const personaId = (isMobile ? user?.education_level?.id : whoAreYou?.id) || '';
  const customInstitute = isMobile ? user?.custom_institute : custom_institute;
  const customSubject = isMobile ? user?.custom_subject : custom_subject;
  const countryOfEducation = isMobile ? user?.country_of_education : country_of_education;
  const minor = isMobile ? user?.minor : selectedMinor;

  const [
    fetchCompanies,
    {
      loading,
      data: companiesData = {
        onboardingCompanyList: {
          page: 1,
          limit: 10,
          is_last: false,
          result: [],
        },
      },
      fetchMore,
      called,
    },
  ] = useLazyQuery(ONBOARDING_COMPANY_LIST, {
    variables: {
      lang,
      archetypeId,
      personaId,
      limit: 10,
      page: 1,
    },
  });

  const fetchMoreCompanies = async () => {
    if (called && !loading && companiesData.onboardingCompanyList && !companiesData.onboardingCompanyList.is_last) {
      //@ts-ignore
      await fetchMore({
        variables: {
          lang,
          archetypeId,
          personaId,
          limit: 10,
          page: companiesData.onboardingCompanyList.page + 1,
        },
        //@ts-ignore
        updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
          if (!fetchMoreResult || !fetchMoreResult.onboardingCompanyList) {
            return prev;
          }
          return Object.assign({}, prev, {
            onboardingCompanyList: {
              ...fetchMoreResult.onboardingCompanyList,
              result: [...prev.onboardingCompanyList.result, ...fetchMoreResult.onboardingCompanyList.result],
            },
          });
        },
      });
    }
  };

  const [saveProfile, ...accountStatus] = useMutation(SAVE_PROFILE);
  const [finishOnboarding] = useMutation(FINISH_ONBOARDING);

  const onSaveData = async (data: any, next: any, error: any) => {
    if (!archetypeId) return;
    const onError = (e: any) => {
      setErrorMessage(e, 'Error');
      if (error) {
        error(e);
      }
    };
    try {
      const res = await saveProfile({
        variables: {
          ...data,
          languages: data?.languages ? data.languages.map((i: number) => i.toString()) : ['1'],
          institutes:
            customInstitute && customSubject
              ? [
                  {
                    degree: data?.institutes[0].degree,
                    graduation_date: data?.institutes[0].graduation_date,
                    average_grades: data?.institutes[0].average_grades,
                    name: customInstitute.name,
                    archetype: archetypeId,
                    subject_name: customSubject.name,
                    country_of_education: countryOfEducation,
                    minor: minor,
                  },
                ]
              : data.institutes,
        },
      });
      next(res);
    } catch (e) {
      onError(e);
    }
  };

  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    if (!onboardingData.loading && onboardingData.data) {
      setAvailableEducationalLevel(onboardingData.data.educationLevels);
      setAvailableDegrees(onboardingData.data.degrees);
      setAvailableInstitutes(onboardingData.data.institutes);
      setAvailableEducationalGrades(onboardingData.data.educationalGrades);
      setAvailableAchievements(onboardingData.data.achievements);
      setAvailableWorkingExperiences(onboardingData.data.workingExperiences);
      setAvailableInternationalExperiences(onboardingData.data.internationalExperiences);
      setAvailableActivities(onboardingData.data.activities);
      setAvailableLanguages(onboardingData.data.languages);
      setAvailableCountries(onboardingData.data.countries);
      setAvailableSexes(onboardingData.data.sexes);
      setAvailableInterests(onboardingData.data.interests);
      setDataReady(true);
    } else {
      setDataReady(false);
    }
  }, [onboardingData.loading, onboardingData.data]);

  useEffect(() => {
    if ((Platform.OS === 'web' && isReady) || Platform.OS !== 'web') {
      getOnboardingData();
    }
  }, [isReady]);

  return (
    <Context.Provider
      value={{
        availableEducationalLevel,
        availableDegrees,
        availableInstitutes,
        availableEducationalGrades,
        availableAchievements,
        availableWorkingExperiences,
        availableInternationalExperiences,
        availableActivities,
        availableLanguages,
        availableCountries,
        availableSexes,
        availableInterests,
        dataReady,
        onSaveData,
        finishOnboarding,
        fetchCompanies,
        fetchMoreCompanies,
        companies: companiesData.onboardingCompanyList ? companiesData.onboardingCompanyList.result : [],
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
