import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: ({ active }: IProps) => {
    return {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flexStart',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        transition: '0.25s all ease',
      },
    };
  },
  componentActive: {
    '& svg': {
      transform: 'scale(1, -1);',
    },
  },
  componentInactive: {
    '& svg': {
      transform: 'scale(-1, 1);',
    },
  },
}));
