import React, { FC, useEffect } from 'react';
import { addDays } from 'date-fns';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import Input from 'src/components/inputs/TextInput/index.web';
import Select from 'src/components/inputs/Select/index.web';
import DatePicker from 'src/components/inputs/DatePicker/index.web';
import CombinedInput from 'src/components/combined/InputField/index.web';
import { IOptionItem } from 'src/requests/models';
import Divider from 'src/components/dividers/Divider/index.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { OnboardingData } from 'src/requests/models';
import {
  selectBirthDate,
  selectCity,
  selectCountry,
  selectDegree,
  selectFirstName,
  selectGraduationDate,
  selectInstitute,
  selectLastName,
  selectSex,
  selectSubject,
  selectWhoAreYou,
  selectContactEmail,
  selectCustomInstitute,
  selectPhoneNumber,
} from '../../../../state/modules/onboarding';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { LIST_CITIES } from 'src/requests/queries';
import { useTranslation } from 'react-i18next';
import { validateInput, validateName, validatePhone } from 'src/utilities/validation';
import { validateEmail } from '../../../../utilities/validation';
import { useStyles } from './styles.web';
import Icon from 'src/components/icons/Icon/index.web';

interface IProps {
  isMobile: boolean;
  children?: any;
  countries: IOptionItem[];
  sexes: IOptionItem[];
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding10: FC<IProps> = (props) => {
  const { user } = useAuth0();
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { countries, sexes, isMobile, setData, setPage, setIsNextDisabled } = props;
  const { t, i18n } = useTranslation();
  const firstName = useSelector((state) => selectFirstName(state));
  const lastName = useSelector((state) => selectLastName(state));
  const sex = useSelector((state) => selectSex(state));
  const birthDate = useSelector((state) => selectBirthDate(state));
  const country = useSelector((state) => selectCountry(state));
  const city = useSelector((state) => selectCity(state));
  const contact_email = useSelector((state) => selectContactEmail(state));
  const phone_number = useSelector((state) => selectPhoneNumber(state));
  const whoAreYou = useSelector((state) => selectWhoAreYou(state));
  const institute = useSelector((state) => selectInstitute(state));
  const custom_institute = useSelector((state) => selectCustomInstitute(state));
  const subject = useSelector((state) => selectSubject(state));
  const graduationDate = useSelector((state) => selectGraduationDate(state));
  const degree = useSelector((state) => selectDegree(state));

  const { data = { cities: [] } } = useQuery(LIST_CITIES, {
    variables: { lang: i18n.language, country: country ? country.name : '' },
  });

  const onInputFirstName = (firstName: string) => {
    setData({ firstName });
  };

  const onInput1Validation = (value: string) => {
    return validateName(value);
  };

  const onInputLastName = (lastName: string) => {
    setData({ lastName });
  };

  const onInputEmail = (contact_email: string) => {
    setData({ contact_email });
  };

  const onSelectSex = (sex: IOptionItem) => {
    setData({ sex });
  };

  const onSelectBirthDate = (date: any) => {
    setData({ birthDate: date });
  };

  const onSelectCountry = (chosenCountry: IOptionItem) => {
    if (country && country.name !== chosenCountry.name) {
      setData({ country: chosenCountry, city: undefined });
    } else {
      setData({ country: chosenCountry });
    }
  };

  const onSelectCity = (city: IOptionItem) => {
    setData({ city });
  };

  const onInputPhone = (phone_number: string) => {
    setData({ phone_number });
  };

  const provider = user?.sub?.split('|')[0] || '';
  const hiddenEmail = user?.email?.includes('@privaterelay.appleid.com') || user?.email?.includes('@private.launch.career');

  useEffect(() => {
    setPage('10');
    setData({
      firstName: firstName || user?.given_name || undefined,
      lastName: lastName ? lastName : user?.family_name ? user?.family_name : user?.name ? user?.name.split(' ').slice(1).join(' ') : '',
      contact_email: contact_email ? contact_email : hiddenEmail ? '' : user?.email,
      birthDate: undefined,
    });
  }, []);

  useEffect(() => {
    setIsNextDisabled(
      !(
        firstName &&
        lastName &&
        sex &&
        birthDate &&
        country &&
        city &&
        whoAreYou &&
        ((institute && subject) || custom_institute) &&
        graduationDate &&
        degree &&
        contact_email &&
        validateEmail(contact_email) &&
        validatePhone(phone_number)
      ),
    );
  }, [
    birthDate,
    city,
    contact_email,
    country,
    custom_institute,
    degree,
    firstName,
    graduationDate,
    institute,
    lastName,
    phone_number,
    sex,
    subject,
    whoAreYou,
  ]);

  const countryOfResidence = countries.filter((c) => c.name === 'Belgium');

  return (
    <>
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Personal details')}
        </Title>
        <Text type={'default'} text={`${t('We will only share these with companies whenever you ask us to do so. With Launch, you are in control!')}.`} />
        <div className={styles.fields}>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('First name')}</Text>
            <Input
              value={firstName}
              placeholder={t('First name')}
              containerStyle={styles.fieldInput}
              onChange={onInputFirstName}
              validate={onInput1Validation}
            />
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Last name')}</Text>
            <Input value={lastName} placeholder={t('Last name')} containerStyle={styles.fieldInput} onChange={onInputLastName} validate={onInput1Validation} />
          </div>
          <div className={styles.fieldHalf}>
            <Text containerStyle={styles.fieldLabel}>{t('Sex')}</Text>
            <Select
              value={sex}
              containerStyle={styles.fieldInput}
              placeholder={t('Select sex')}
              list={sexes}
              onChoose={onSelectSex}
              inputValidation={validateInput}
            />
          </div>
          <div className={styles.fieldHalf}>
            <Text containerStyle={styles.fieldLabel}>{t('Birth date')}</Text>
            <DatePicker
              isMobile={isMobile}
              value={birthDate ? new Date(birthDate) : undefined}
              containerStyle={styles.fieldInput}
              maxDate={addDays(Date.now(), 0)}
              openToDate={new Date('1998/01/01')}
              onChange={onSelectBirthDate}
              type={'date'}
            />
          </div>
          <div className={styles.fieldHalf}>
            <Text containerStyle={styles.fieldLabel}>{t('Country of residence')}</Text>
            <Select
              value={country}
              activeBy={'name'}
              containerStyle={styles.fieldInput}
              list={countryOfResidence}
              onChoose={onSelectCountry}
              inputValidation={validateInput}
            />
          </div>
          <div className={styles.fieldHalf}>
            <Text containerStyle={styles.fieldLabel}>{t('City / Town')}</Text>
            <Select
              value={city}
              disabled={!country}
              activeBy={'name'}
              containerStyle={styles.fieldInput}
              list={data.cities}
              onChoose={onSelectCity}
              inputValidation={validateInput}
            />
          </div>
          {provider !== 'auth0' ? (
            <div className={styles.field}>
              <Text containerStyle={`${styles.fieldLabel} ${styles.wrapper}`}>
                <span className={styles.fieldName}>{t('Contact email')}</span>
                <Icon color="#97A3AC" size={20} name="circle-question" />
                <span className={`${styles.tooltip} tooltip`}>{t('Please add an email')}</span>
              </Text>
              <CombinedInput
                type={'email'}
                defaultValue={contact_email}
                value={contact_email}
                label={''}
                containerStyle={styles.fieldInput}
                inputStyle={styles.fieldInput}
                onChange={onInputEmail}
                placeholder={'example@mail.com'}
              />
            </div>
          ) : null}
          <div className={styles.field}>
            <Text containerStyle={`${styles.fieldLabel} ${styles.wrapper}`}>
              <span className={styles.fieldName}>{t('Phone number')}</span>
              <Icon color="#97A3AC" size={20} name="circle-question" />
              <span className={`${styles.tooltip} tooltip`}>{t('Enter your phone number')}</span>
            </Text>
            <Input placeholder="+32 478 45 25 69" value={phone_number} containerStyle={styles.fieldInput} onChange={onInputPhone} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding10;
