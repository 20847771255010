import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: (p: IProps) => ({
      width: '100%',
      height: p.size === 'xl' ? 1.19 : 1,
      backgroundColor: '#E8EFF4',
    }),
  };
});
