import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      width: '100%',
      height: '100%',
      maxHeight: 635,
      background: 'white',
      //marginTop: isMobile ? 15 : 60,
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 15,
    }),
    head: {
      display: 'flex',
      height: 105,
      padding: '30px 30px 15px',
      borderBottom: `1px solid ${theme.$borderColor}`,
    },
    headTitle: {
      marginBottom: 0,
      display: 'block',
      maxWidth: 400,
      minWidth: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },
    emptyTitle: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      color: theme.$primaryColor2,
      opacity: 0.25,
    },
    headLogo: ({ data }: IProps) => ({
      width: 60,
      height: 60,
      borderRadius: '50%',
      backgroundImage: `url(${data && data.logo ? data.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      marginRight: 15,
    }),
    withoutHeadLogo: {
      width: 60,
      height: 60,
      background: theme.$defaultColor1,
      borderRadius: '50%',
      marginRight: 15,
    },
    headItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    body: {
      height: 'calc(100% - 105px - 114px)',
      padding: '15px 30px',
      overflow: 'auto',
    },
    msg: {
      marginBottom: 15,
      wordBreak: 'break-word',

      '&:last-child': {
        marginBottom: 0,
      },
    },
    footer: {
      display: 'flex',
      background: 'white',
      justifyContent: 'center',
      height: 114,
      padding: 30,
      borderTop: `1px solid ${theme.$borderColor}`,
    },
    btnReject: {
      maxWidth: 'calc(50% - 7.5px)',
    },
    btnAccept: {
      position: 'relative',
      width: 'auto',
      maxWidth: 'none',
      padding: '0 4rem',
    },
    btnArchived: {
      maxWidth: 'none',
    },

    btnText: {
      fontSize: 15,
      fontWeight: 500,
      color: 'white',
    },
    btnIcon: {
      position: 'absolute',
      display: 'flex',
      right: 10,
    },
  };
});
