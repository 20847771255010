import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { IProps } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  content?: string;
}

const Tab: FC<IProps> = (props) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { children, containerStyle, contentStyle, onHover, onPress, onBlur } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    content: `${styles.content} ${contentStyle}`.trim(),
  };

  const renderContent = (): React.ReactChild | null => {
    return <div className={cn.content}>{children}</div>;
  };

  return (
    <div onClick={onPress} onMouseOver={onHover} onBlur={onBlur} className={cn.component}>
      {renderContent()}
    </div>
  );
};

export default Tab;
