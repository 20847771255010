import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? 345 : 'auto',
        border: isMobile ? 'none' : `1px solid ${theme.$borderColor}`,
        borderRadius: 10,
        background: isMobile ? 'transparent' : '#FFFFFF',
        padding: isMobile ? '15px 0' : 0,
        outline: 'none',
      };
    },
    searchInputMob: {
      width: 'auto',
    },
    forExample: {
      marginTop: 15,
    },
    forExampleText: {
      color: theme.$primaryColor1,
    },
    entitySelect: {
      marginBottom: 30,
    },
    entityList: {
      textTransform: 'capitalize',
    },
    entityListOption: {
      textTransform: 'capitalize',
    },
    filterBtn: {
      marginLeft: 15,
    },
    filterTitle: {
      marginBottom: 15,
    },
    filterBlockSearchInput: {
      paddingBottom: 30,
    },
    filterHiringCompanies: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px 0',
    },
    btnToggleList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexShrink: 0,
      alignContent: 'flex-start',
      marginBottom: 15,
    },
    btnToggle: {
      margin: '0 10px 10px 0',
    },
    body: ({ isMobile }: IProps) => ({
      position: 'relative',
      // display: 'flex',
      // flexDirection: 'column',
      padding: isMobile ? '10px 0' : '15px',
    }),
    footer: ({ isMobile }: IProps) => ({
      display: 'flex',
      flexDirection: 'column',
      padding: isMobile ? '10px 0' : '15px 30px',
    }),
    onFilterByCompany: {
      position: 'absolute',
      top: 40,
      right: 20,
      transform: 'translate(0, -50%)',
    },
    footerBtn: {
      maxWidth: 'none',
    },
    footerBtnSubmit: {
      marginBottom: 10,
    },
    footerBtnCancel: {
      border: 'none',
      '&:hover': {
        border: `1px solid ${theme.$primaryColor1}`,
      },
    },
    perkItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flex: '1',
      marginBottom: 10,
    },
    perkItemBtn: {
      width: 54,
      height: 54,
      borderRadius: 27,
      margin: 0,
    },
    perkItemValue: {
      fontSize: 24,
    },
    perkItemName: {},
    perkItemBtnActive: {},
    perkItemNameActive: {
      fontWeight: 700,
    },
  };
});
