import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import Select from 'src/components/inputs/Select/index.web';
import DatePicker from 'src/components/inputs/DatePicker/index.web';
import Divider from 'src/components/dividers/Divider/index.web';
import ButtonSwitcher from 'src/components/buttons/ButtonSwitcher/index.web';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { ICountry, OnboardingData, IOptionItem, ISubject } from 'src/requests/models';
import {
  selectArchetype,
  selectCustomInstitute,
  selectCustomSubject,
  selectDegree,
  selectGraduationDate,
  selectInstitute,
  selectSubject,
  selectMinor,
  selectCountryOfEducation,
} from '../../../../state/modules/onboarding';
import { useSelector } from 'react-redux';
import { LIST_COUNTRIES, LIST_ARCHETYPES, LIST_UNIVERSITY_SPECIALITIES } from 'src/requests/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { validateUniversity } from '../../../../utilities/validation';
import Toggle from '../../../../components/toggles/Toggle/index.web';
import Input from 'src/components/inputs/TextInput/index.web';

interface IProps {
  isMobile: boolean;
  children?: any;
  institutes: IOptionItem[];
  degrees: IOptionItem[];
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding2: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, institutes, degrees, setData, setPage, setIsNextDisabled } = props;
  const { t, i18n } = useTranslation();
  const institute = useSelector((state) => selectInstitute(state));
  const subject = useSelector((state) => selectSubject(state));
  const minor = useSelector((state) => selectMinor(state));
  const custom_subject = useSelector((state) => selectCustomSubject(state));
  const custom_institute = useSelector((state) => selectCustomInstitute(state));
  const archetype = useSelector((state) => selectArchetype(state));
  const graduationDate = useSelector((state) => selectGraduationDate(state));
  const degree = useSelector((state) => selectDegree(state));
  const country_of_education = useSelector((state) => selectCountryOfEducation(state));

  const [getUniversityList, { data = { educationalSubjects: [] }, called, refetch }] = useLazyQuery(LIST_UNIVERSITY_SPECIALITIES, {
    variables: { institute: institute?.id || '' },
  });

  const [manual, setManual] = useState(false);
  const [defaultArchetype, setDefaultArchetype] = useState('');
  const [minors, setMinors] = useState([]);

  const { data: countriesList = { countries: [] } } = useQuery(LIST_COUNTRIES);
  useEffect(() => {
    if (manual) {
      setData({ country_of_education: 'Belgium' });
    }
  }, [manual]);

  const onSelectCountry = ({ name }: any) => {
    setData({ country_of_education: name });
  };

  const { data: archData = { archetypes: [] } } = useQuery(LIST_ARCHETYPES, {
    variables: { lang: i18n.language },
  });

  useEffect(() => {
    setIsNextDisabled(!(((institute && subject) || manual) && graduationDate && degree));
  }, [degree, graduationDate, institute, manual, subject]);

  const onSelectInstitute = (chosenInstitute: IOptionItem) => {
    if (institute && institute.name !== chosenInstitute.name) {
      setData({ institute: chosenInstitute, subject: undefined });
    } else {
      setData({ institute: chosenInstitute });
    }
  };

  const onSelectSubject = (subject: ISubject) => {
    setData({ subject, archetype: subject.archetype });
    setDefaultArchetype(subject.archetype);
  };

  const onSelectGraduationDate = (date: any) => {
    setData({ graduationDate: date });
  };

  const onSelectDegree = (degree: IOptionItem) => {
    setData({ degree });
  };

  const onSelectCustomInstitute = (name: string) => {
    setData({ custom_institute: { name } });
  };

  const onSelectCustomSubject = (name: string) => {
    setData({ custom_subject: { name } });
  };

  const onSelectArchetype = ({ name }: any) => {
    const data: any = { archetype: name, minor: '' };
    if (name !== defaultArchetype) data.archetype_override = true;
    setData(data);
  };

  useEffect(() => {
    const currentArchetype: any = archData?.archetypes?.filter((item: any) => item.name_en === archetype);

    setMinors(currentArchetype?.[0]?.minors);
  }, [archetype, archData]);

  const onSelectMinor = ({ name }: any) => {
    setData({ minor: name });
  };

  useEffect(() => {
    setPage('2');

    if (!degree) {
      onSelectDegree(degrees[1]);
    }
    if (institute) {
      if (called) {
        //@ts-ignore
        refetch();
      } else {
        getUniversityList();
      }
    }
  }, [degree, institute]);

  return (
    <>
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Education')}
        </Title>
        <Text type={'default'} text={`${t('Please provide your most relevant education. Other educations can be added later via your profile.')}`} />
        <div className={styles.fields}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Text containerStyle={styles.fieldLabel}>{t('My education is not in the list')}</Text>
            <Toggle active={manual} onPress={() => setManual((prev) => !prev)} />
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Institute')}</Text>
            {manual ? (
              <Input
                value={custom_institute?.name || ''}
                placeholder={t('Enter your institute name')}
                containerStyle={styles.fieldInput}
                onChange={onSelectCustomInstitute}
              />
            ) : (
              <Select
                value={institute}
                containerStyle={styles.fieldInput}
                placeholder={t('Select institute')}
                list={institutes}
                inputValidation={validateUniversity}
                onChoose={onSelectInstitute}
              />
            )}
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Education subject')}</Text>
            {manual ? (
              <Input value={custom_subject?.name || ''} placeholder={t('Select subject')} containerStyle={styles.fieldInput} onChange={onSelectCustomSubject} />
            ) : (
              <Select
                disabled={!institute}
                value={subject}
                containerStyle={styles.fieldInput}
                placeholder={t('Select subject')}
                list={data.educationalSubjects}
                inputValidation={validateUniversity}
                onChoose={onSelectSubject}
              />
            )}
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Field of education')}</Text>
            <Select
              disabled={!manual && !subject}
              containerStyle={styles.fieldInput}
              placeholder={t('Field of education')}
              list={archData?.archetypes?.map((item: any) => ({ id: item.id, name: item.name_en }))}
              value={archData?.archetypes?.find((item: any) => item.name_en === archetype)}
              onChoose={onSelectArchetype}
            />
          </div>
          {minors?.length > 0 && (
            <div className={styles.field}>
              <Text containerStyle={styles.fieldLabel}>{t('Add an optional specialization')}</Text>
              <Select
                disabled={!archetype}
                containerStyle={styles.fieldInput}
                placeholder={t('Add an optional specialization')}
                list={minors.map((name: string) => ({ id: name, name }))}
                value={{ id: minor, name: minor }}
                onChoose={onSelectMinor}
              />
            </div>
          )}
          {manual && (
            <div className={styles.field}>
              <Text containerStyle={styles.fieldLabel}>{t('Country of education')}</Text>
              <Select
                containerStyle={styles.fieldInput}
                placeholder={t('Country of education')}
                onChoose={onSelectCountry}
                list={countriesList?.countries?.map((country: ICountry) => ({ id: country.id, name: country.name }))}
                value={countriesList?.countries?.find((country: ICountry) => country.name === country_of_education)}
              />
            </div>
          )}
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('(Expected) Date of graduation')}</Text>
            <DatePicker
              value={graduationDate ? new Date(graduationDate) : undefined}
              isMobile={isMobile}
              containerStyle={styles.fieldInput}
              onChange={onSelectGraduationDate}
              type={'months'}
              minDate={moment().subtract(10, 'years').toDate()}
              maxDate={moment().add(10, 'years').toDate()}
            />
          </div>
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Degree')}</Text>
            <ButtonSwitcher value={degree} values={degrees} onChoose={onSelectDegree} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding2;
