import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'src/web/components/Header';
import Footer from 'src/web/components/Footer';
import OnboardingTitle from 'src/web/components/Onboarding/Title';
import OnboardingSubtitle from 'src/web/components/Onboarding/Subtitle';
import Onboarding1 from 'src/web/components/Onboarding/1';
import Onboarding2 from 'src/web/components/Onboarding/2';
import Onboarding3 from 'src/web/components/Onboarding/3';
import Onboarding4 from 'src/web/components/Onboarding/4';
import Onboarding5 from 'src/web/components/Onboarding/5';
import Onboarding6 from 'src/web/components/Onboarding/6';
import Onboarding7 from 'src/web/components/Onboarding/7';
import Onboarding8 from 'src/web/components/Onboarding/8';
import Onboarding9 from 'src/web/components/Onboarding/9';
import Onboarding10 from 'src/web/components/Onboarding/10';
import OnboardingLast from 'src/web/components/Onboarding/Last';
import styles from './styles.module.scss';
import { IProps } from './types.web';
import { useHistory } from 'react-router';
import { Context as OnboardingContext } from 'src/contexts/OnboardingContext';
import { OnboardingData } from 'src/requests/models';
import { selectProfileDataForSave, setCurrentPage, setDataOperation } from 'src/state/modules/onboarding';
import { MyThunkDispatch } from 'src/state/modules/types';
import { Storage } from 'src/utilities/Storage';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';

const Onboarding: FC<IProps> = (props) => {
  const { isMobile } = props;
  const [ready, setReady] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const {
    availableEducationalLevel,
    availableDegrees,
    availableInstitutes,
    availableEducationalGrades,
    availableAchievements,
    availableWorkingExperiences,
    availableInternationalExperiences,
    availableActivities,
    availableLanguages,
    availableCountries,
    availableSexes,
    availableInterests,
    onSaveData,
    dataReady,
    finishOnboarding,
  }: any = useContext(OnboardingContext);
  const dispatch: MyThunkDispatch = useDispatch();
  const profile = useSelector((state) => selectProfileDataForSave(state));
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const skipRef = useRef();

  const reference = location.pathname.split(match.path)[1];

  const setData = (data: OnboardingData) => dispatch(setDataOperation(data, false));
  const setPage = (page: string) => dispatch(setCurrentPage(page));

  const onBack = (back: string) => {
    setData({ lastPage: back });
    history.push(back);
  };

  const onNext = async (next: string) => {
    await onSaveData(profile);
    setData({ lastPage: next });
    history.push(next);
  };

  useEffect(() => {
    setReady(reference === '/last');
  }, [location]);

  useEffect(() => {
    const storage = new Storage(false);
    storage.getReferrer().then((referrer) => {
      if (referrer) {
        setData({ referrer });
        storage.clearReferrer();
      }
    });
    return () => {
      storage.clearOnboardingData();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{'Onboarding'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.head}>
                <OnboardingTitle ready={ready} />
                <OnboardingSubtitle ready={ready} reference={reference} ref={skipRef} />
              </div>
              {dataReady ? (
                <Switch>
                  <Route path={`${match.path}/1`}>
                    <Onboarding1
                      isMobile={isMobile}
                      list={availableEducationalLevel}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/2`}>
                    <Onboarding2
                      isMobile={isMobile}
                      institutes={availableInstitutes}
                      degrees={availableDegrees}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/3`}>
                    <Onboarding3
                      isMobile={isMobile}
                      languageList={availableLanguages}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/4`}>
                    <Onboarding4
                      isMobile={isMobile}
                      ref={skipRef}
                      gradeList={availableEducationalGrades}
                      achievementList={availableAchievements}
                      next={`${match.path}/5`}
                      onNext={onNext}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/5`}>
                    <Onboarding5
                      isMobile={isMobile}
                      ref={skipRef}
                      experienceList={availableInternationalExperiences}
                      next={`${match.path}/6`}
                      onNext={onNext}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/6`}>
                    <Onboarding6
                      isMobile={isMobile}
                      ref={skipRef}
                      experienceList={availableWorkingExperiences}
                      activityList={availableActivities}
                      next={`${match.path}/7`}
                      onNext={onNext}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/7`}>
                    <Onboarding7
                      isMobile={isMobile}
                      ref={skipRef}
                      interestList={availableInterests}
                      next={`${match.path}/8`}
                      onNext={onNext}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/8`}>
                    <Onboarding8 isMobile={isMobile} ref={skipRef} setData={setData} setPage={setPage} setIsNextDisabled={setIsNextDisabled} />
                  </Route>
                  <Route path={`${match.path}/9`}>
                    <Onboarding9
                      isMobile={isMobile}
                      ref={skipRef}
                      next={`${match.path}/10`}
                      onNext={onNext}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/10`}>
                    <Onboarding10
                      isMobile={isMobile}
                      countries={availableCountries}
                      sexes={availableSexes}
                      setData={setData}
                      setPage={setPage}
                      setIsNextDisabled={setIsNextDisabled}
                    />
                  </Route>
                  <Route path={`${match.path}/last`}>
                    <OnboardingLast isMobile={isMobile} />
                  </Route>
                  <Redirect to={`${match.path}/1`} />
                </Switch>
              ) : null}
            </div>
          </div>
        </div>
      </ErrorBoundaries>
      {reference !== '/last' && (
        <Footer isMobile={isMobile} isNextDisabled={isNextDisabled} onBack={onBack} onNext={onNext} finishOnboarding={finishOnboarding} />
      )}
    </>
  );
};

export default Onboarding;
