import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: isMobile ? 'center' : 'flex-start',
        width: '100%',
        height: '100%',
      };
    },
    list: ({ isMobile }: IProps) => ({
      width: '100%',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginBottom: '30px',
    }),
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '30px 0 15px',
      width: '100%',
    },
    title: {
      margin: 0,
      '& svg': {
        marginRight: 15,
      },
    },
    viewAll: {
      color: theme.$primaryColor1,
      cursor: 'pointer',
      padding: '0 0 0 5px',
    },
    item: ({ isMobile }: IProps) => ({
      margin: '3px',
      maxWidth: isMobile ? 'none' : 'calc(50% - 7.5px)',
      flexGrow: 1,
    }),
    noResults: ({ isMobile }: IProps) => ({
      width: '100%',
      justifyContent: 'center',
      marginTop: 50,
      marginLeft: isMobile ? 0 : 15,
      color: theme.$primaryColor1,
    }),
  };
});
