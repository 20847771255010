import * as Sentry from '@sentry/react';

export const init = () => {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    normalizeDepth: 10,
  });
};

export const setUser = ({ id, email, username }: { id?: string; email?: string; username?: string }) => {
  Sentry.setUser({ id, email, username });
};
