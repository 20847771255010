import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      maxWidth: isMobile ? 'none' : 405,
      width: '100%',
      height: '100%',
      background: 'transparent',
      '& *::-webkit-scrollbar': {
        width: 4,
      },
    }),
    noResults: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noResultsImg: {
      width: 92,
      height: 80,
    },
    noResultsTitle: {},
    noResultsText: {
      width: 220,
      textAlign: 'center',
    },
  };
});
