import { EUIActionTypes, TUIAction, UserState } from './user.types';

const initialState: UserState = {
  data: {
    degree: { id: '3', name: "Master's" },
  },
  isOComplete: false,
  currentPage: 1,
  prevPage: 1,
  token: null,
  refreshToken: null,
  isLogged: false,
  gradeProofFlag: undefined,
  profileCVFlag: undefined,
  unreadMessagesCount: 0,
};

export const userReducer = (state: any = initialState, action: TUIAction): any => {
  switch (action.type) {
    case EUIActionTypes.SET_VALUE: {
      return {
        ...state,
        ...action.value,
      };
    }
    case EUIActionTypes.SET_USER_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.value,
        },
      };
    }
    case EUIActionTypes.CHANGE_GRADE_PROOF_FLAG: {
      return {
        ...state,
        gradeProofFlag: action.value !== undefined ? action.value : undefined,
      };
    }

    case EUIActionTypes.CHANGE_PROFILE_CV_FLAG: {
      return {
        ...state,
        profileCVFlag: action.value ? action.value : undefined,
      };
    }

    case EUIActionTypes.SET_LOGOUT: {
      return {
        data: {
          degree: { id: '3', name: "Master's" },
        },
        currentPage: 1,
        token: null,
        refreshToken: null,
        isLogged: false,
      };
    }

    default:
      return state;
  }
};
