import React, { useEffect } from 'react';
import ContactUsPage from '../containers/SupportPage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { initGA } from '../../utilities';

const Container = () => (
  <Page>
    {({ isMobile }: IPassProps) => {
      return <ContactUsPage isMobile={isMobile} />;
    }}
  </Page>
);

export default Container;
