import React, { FC } from 'react';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
}

const Label: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { item, containerStyle, onPress } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
  };

  return (
    <div className={cn.component} onClick={onPress}>
      {item.name}
    </div>
  );
};

export default Label;
