import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { widthD } from 'src/constant/variables';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
    },
    companyBackground: ({ background_photo }: IProps) => ({
      backgroundImage: `url(${background_photo ? background_photo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: 350,
      marginBottom: -100,
    }),
    title: {
      margin: 0,
      lineHeight: '21px',

      '& svg': {
        cursor: 'pointer',
        marginRight: 10,
      },
    },
    titleLogo: {
      marginBottom: 5,
    },
    main: {
      width: '100%',
      maxWidth: widthD,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'flex-start',
    },
    mainTop: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 30,
    },
    socialLink: {
      borderColor: 'transparent',
      background: 'transparent',
    },
    socialLinkText: {
      color: theme.$primaryColor1,
    },
    mainInfoLogo: ({ isMobile, logo }: IProps) => ({
      width: isMobile ? 72 : 90,
      height: isMobile ? 72 : 90,
      borderRadius: isMobile ? 3 : 8,
      marginRight: 15,
      flexShrink: 0,
      backgroundImage: `url(${logo ? logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    mainInfoSite: {
      color: theme.$primaryColor1,
      fontSize: 12,
      lineHeight: '16px',
      margin: '5px 0',
    },
    mainLeft: {
      width: 420,
      flexShrink: 0,
      background: 'white',
      marginRight: 30,
      borderRadius: 15,
      padding: 30,
    },
    mainRight: {
      width: 'calc(100% - 450px)',
    },
    sideLogo: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    labels: {
      display: 'flex',
    },
    label: {
      justifyContent: 'flex-start',
      overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'pre',
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    labelIcon: {},
    labelLocation: {
      // maxWidth: 80,
    },
    labelSection: {
      // maxWidth: 100,
    },
    labelUser: {
      // maxWidth: 80,
    },
    linksContainer: {
      padding: '15px 0 0',
    },
    links: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    },
    linksIcon: {
      position: 'absolute',
      display: 'flex',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    link: {
      textTransform: 'capitalize',
      margin: 20,
    },
    perks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      boxSizing: 'border-box',
      borderRadius: 10,
      marginBottom: 15,
    },
    btnToggle: {},
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    perkItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '10px 20px 15px',
    },
    perkItemBtn: {
      width: 54,
      height: 54,
      borderRadius: 27,
      margin: 0,
      border: 'none',
    },
    perkItemValue: {
      fontSize: 24,
    },
    perkItemName: {},
    demoToggleTitle: {
      maxWidth: '37rem',
      width: '100%',
      cursor: 'pointer',
    },
    toggleText: {
      borderTop: 'none',
      padding: 0,
      alignItems: 'flex-start',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    toggleTextItem: {},
    buttons: {
      display: 'flex',
      width: '100%',
    },
    setInterest: {
      maxWidth: 'none',
    },
    bio: {
      margin: '10px 0',
      lineHeight: '21px',
    },
    sideEntitiesItems: {
      position: 'relative',
      marginTop: 30,
    },
    sideEntitiesItemsTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    sideEntitiesItemsContent: {},
    sideEntitiesItemsList: {},
    viewAll: {
      color: theme.$primaryColor1,
      cursor: 'pointer',
      padding: '0 0 0 5px',
    },
    mainRightItem: {
      background: 'white',
      flexGrow: 1,
      padding: '30px 30px 15px',
      borderRadius: 15,
    },
    mainRightItemTitle: {},
    shareButton: {
      background: 'transparent',
      backgroundColor: 'transparent !important',
      border: '1px solid #F65532',
      width: '40px !important',
      height: '40px !important',
      margin: '20px',
    },
  };
});
