import React, { FC, useEffect } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import { IOptionItem } from 'src/requests/models';
import Divider from 'src/components/dividers/Divider/index.web';
import SkipPortal from '../SkipPortal';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { OnboardingData } from '../../../../requests/models';
import { selectWorkExperience, selectActivities, selectIsActivityAbroad } from '../../../../state/modules/onboarding';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckBox from 'src/components/checkboxes/CheckBox/index.web';

interface IProps {
  isMobile: boolean;
  children?: any;
  experienceList: IOptionItem[];
  ref: any;
  next: any;
  activityList: IOptionItem[];
  onNext(next: string): void;
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding6: FC<IProps> = React.forwardRef((props, ref: any) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { activityList, experienceList, next, onNext, setData, setPage, setIsNextDisabled } = props;
  const workExperience = useSelector((state) => selectWorkExperience(state));
  const activities = useSelector((state) => selectActivities(state));
  const isBoardMember = useSelector((state) => selectIsActivityAbroad(state));
  const { t } = useTranslation();

  const onSelectExperience = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ workExperience: workExperience.concat([item]) });
    } else if (action === 'rem') {
      setData({ workExperience: workExperience.filter((i: IOptionItem) => i.name !== item.name).slice() });
    }
  };

  const onSelectActivityExperience = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ activities: activities.concat([item]) });
    } else if (action === 'rem') {
      const result = activities.filter((i: IOptionItem) => i.name !== item.name).slice();
      setData({ activities: result, isBoardMember: result.length ? isBoardMember : false });
    }
  };

  const onCheck = (e: any) => {
    setData({ isBoardMember: !isBoardMember });
  };

  const onSkipPress = async () => {
    await setData({ workExperience: undefined, activities: undefined, isBoardMember: undefined });
    await onNext(next);
  };

  useEffect(() => {
    setPage('6');
    setIsNextDisabled(false);
  }, []);

  return (
    <>
      <SkipPortal current={ref.current} onSkipPress={onSkipPress} />
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Additional strong points')}
        </Title>
        <Text
          type={'default'}
          text={t('Additional strong points will help you to stand out towards companies. We recommend you to add details regarding these in your CV.')}
        />
        <Text containerStyle={styles.label}>{t('Activities')}</Text>
        <div className={styles.activities}>
          {activityList.map((activityItem: IOptionItem) => {
            const active = !!activities.find((a: IOptionItem) => a.name === activityItem.name);
            return (
              <ButtonToggle
                key={activityItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.activity}
                text={activityItem.name}
                onPress={() => onSelectActivityExperience(activityItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        <Divider containerStyle={styles.divider} />
        <div className={`${styles.checkSection} ${!activities.length ? styles.checkSectionDisabled : ''}`}>
          <CheckBox active={isBoardMember} onPress={onCheck} />
          <Text containerStyle={styles.checkSectionText} inline>
            {t('I’ve been in a board of one above')}
          </Text>
        </div>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Work experience')}
        </Title>
        <Text type={'default'} text={`${t('Practical work experience can be a real differentiator, please select the ones that apply.')}`} />
        <div className={styles.experiences}>
          {experienceList.map((experienceItem: IOptionItem) => {
            const active = !!workExperience.find((a: IOptionItem) => a.name === experienceItem.name);
            return (
              <ButtonToggle
                key={experienceItem.name}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.experience}
                text={experienceItem.name}
                onPress={() => onSelectExperience(experienceItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
      </div>
    </>
  );
});

export default Onboarding6;
