import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    display: 'flex',
    'align-items': 'center',
    'font-size': '1.4rem',
    height: 'auto',
    background: theme.$defaultColor1,
    border: `0.1rem solid ${theme.$borderColor}`,
    'border-radius': '0.5rem',
    cursor: 'pointer',
    color: theme.$textColor,
    transition: 'all 0.25s ease',
    opacity: '1',
  },
  componentDisabled: {
    opacity: '0.3 !important',
    cursor: 'not-allowed',
  },
  content: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    width: '100%',
    cursor: 'inherit',
  },
}));
