import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Markdown from '../Markdown';
interface IProps {
  close: () => void;
  next: () => void;
  text: string | undefined;
  isLast: boolean;
  allMemosCount: number;
  current: number;
}

export const MemosModal = ({ close, next, text, isLast, allMemosCount, current }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.count}>{`${current} / ${allMemosCount}`}</div>
        <div className={styles.text}>
          <Markdown isMobile={false} markdown={text} isApplication />
        </div>
        <div className={styles.buttons}>
          {!isLast ? (
            <>
              <button className={styles.cancelButton} onClick={close}>
                {t('Close')}
              </button>
              <ButtonMain containerStyle={styles.applyButton} size={'lg'} type={'primary_1'} text={t('Next')} onPress={next} />
            </>
          ) : (
            <ButtonMain containerStyle={styles.applyButton} size={'lg'} type={'primary_1'} text={t('Close')} onPress={close} />
          )}
        </div>
      </div>
    </div>
  );
};
