import React, { useRef } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Title from 'src/components/titles/Title/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Link from 'src/components/links/Link/index.web';

interface ComponentStyles {
  component?: string;
  header?: string;
  body?: string;
  footer?: string;
}

const View = (props: IProps) => {
  const theme: any = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle, data } = props;

  const { name, city, cities, sectors = [], company_size = { name: '0' }, bio, id } = data;

  const refBody = useRef(null);

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    header: `${styles.block} ${styles.header}`,
    body: `${styles.block} ${styles.body}`,
    footer: `${styles.block} ${styles.footer}`,
  };

  return (
    <>
      {isMobile && (
        <div className={styles.head}>
          <Title size={'h2'} containerStyle={styles.headTitle}>
            {t('Event organizer')}
          </Title>
          <Link internal href={'/company/' + id} size={'sm'} text={t('View profile')} />
        </div>
      )}
      <div className={cn.component}>
        {!isMobile && (
          <div className={styles.head}>
            <Title size={'h2'} containerStyle={styles.headTitle}>
              {t('Event organizer')}
            </Title>
            <Link internal href={'/company/' + id} size={'sm'} text={t('View profile')} />
          </div>
        )}
        <div className={cn.header}>
          <div className={styles.logo} />
          <div className={styles.info}>
            <Title size={'h2'} containerStyle={styles.title}>
              {name}
            </Title>
            <div className={styles.labels}>
              <TextIcon iconName={'location'} iconSize={14} containerStyle={`${styles.label} ${styles.labelLocation}`} iconStyle={styles.labelIcon}>
                {cities && cities.length ? cities.join(', ') : city || ''}
              </TextIcon>
              {sectors.length ? (
                <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelSection}`} iconStyle={styles.labelIcon}>
                  {sectors[0].name}
                </TextIcon>
              ) : null}
            </div>
          </div>
        </div>
        <div className={cn.body} ref={refBody}>
          {bio}
        </div>
      </div>
    </>
  );
};

export default View;
