import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Loading from '../components/Loading';

const Error = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1500);
  }, []);
  return ready ? (
    <Redirect to={'/explore'} />
  ) : (
    <div style={{ height: '100vh' }}>
      <Loading />
    </div>
  );
};

export default Error;
