import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { widthD } from 'src/constant/variables';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: isMobile ? 510 : 'none',
        width: isMobile ? '100%' : widthD,
        margin: isMobile ? '30px auto' : '60px auto',
        // height: '100%',
        padding: isMobile ? '0 15px' : '0',
      };
    },
    viewItem: {
      // display: 'flex',
      // flexDirection: 'column',
    },
    viewItem1: ({ isMobile }: IProps) => ({
      width: isMobile ? '100%' : 375,
      flexShrink: 0,
      marginRight: isMobile ? 0 : 30,
    }),
    viewItem2: {
      width: 'auto',
      flexGrow: 1,
    },
    main: {
      flexShrink: 0,
    },
    completeness: {
      marginTop: 15,
      flexShrink: 0,
    },
    education: (p: IProps) => ({
      marginTop: p.isMobile ? 15 : 0,
      flexShrink: 0,
    }),
    achievements: {
      marginTop: 15,
      flexShrink: 0,
    },
    internationalExperience: {
      marginTop: 15,
      flexShrink: 0,
    },
    workExperience: {
      marginTop: 15,
      flexShrink: 0,
    },
    languages: {
      marginTop: 15,
      flexShrink: 0,
    },
    activities: {
      marginTop: 15,
      flexShrink: 0,
    },
  };
});
