import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { Props } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => {
  const fontSize = {
    sm: '1.5rem',
    xs: '1.2rem',
  };

  const fontWeight = {
    sm: 500,
    xs: 400,
  };

  return {
    component: (p: Props) => {
      const { size } = p;

      return {
        fontFamily: 'AvenirNextCyr, sans-serif',
        fontSize: fontSize[size],
        color: theme.$primaryColor1,
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontWeight: fontWeight[size],
        textDecoration: 'none',
        '& *': {
          fontFamily: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
        },
        '&:hover': {
          opacity: 0.75,
        },
      };
    },
  };
});
