import React, { useContext, useEffect, useMemo, useRef, useState, memo } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Title from 'src/components/titles/Title/index.web';
import TabList from 'src/components/tabs/TabList/index.web';
import { ITheme } from 'src/constant/themes';
import Account from './Tabs/Account';
import Preferences from './Tabs/Preferences';
import Notifications from './Tabs/Notifications';

const Settings = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile } = props;

  const { t } = useTranslation();

  const tabList = useMemo(
    () => [
      { key: '0', value: t('Preferences') },
      { key: '1', value: t('Account') },
      { key: '2', value: t('Notifications') },
    ],
    [t],
  );
  const [tab, setTab] = useState('0');

  return (
    <div className={styles.component}>
      <div className={styles.head}>
        <div className={styles.container}>
          <div className={styles.headTitle}>
            {!isMobile && (
              <Title size={'h1'} containerStyle={styles.mainTitle}>
                {t('Settings')}
              </Title>
            )}
          </div>
          <TabList isMobile={isMobile} active={tab} list={tabList} containerStyle={styles.tabs} tabStyle={styles.tab} onChange={setTab} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.container}>
          {tabList.map((tabItem) => {
            const { key: tKey, value } = tabItem;
            if (tKey === '0') {
              return tab === tKey && <Preferences key={tKey} title={value} isMobile={isMobile} />;
            } else if (tKey === '1') {
              return tab === tKey && <Account key={tKey} title={value} isMobile={isMobile} />;
            } else if (tKey === '2') {
              return tab === tKey && <Notifications key={tKey} title={value} isMobile={isMobile} />;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Settings;
