import React from 'react';
//@ts-ignore
import Loader from 'react-loader-spinner';

import styles from './styles.module.scss';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';

const Loading = () => {
  //@ts-ignore
  const theme: ITheme = useTheme();
  return (
    <div className={styles.loaderContainer}>
      <Loader type="Oval" color={theme.$primaryColor1} height={100} width={100} />
    </div>
  );
};

export default Loading;
