import React, { useContext } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/inputs/Select/index.web';
import { IOptionItem } from 'src/requests/models';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { Context as GlobalSearchContext } from 'src/contexts/SearchContext/GlobalSearchContext';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { useHistory } from 'react-router';

const View = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, onClose } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const entitiesList: IOptionItem[] = [
    { id: '4', name: 'everything', label: t('Everything') },
    { id: '1', name: 'companies', label: t('Companies_explore') },
    { id: '2', name: 'events', label: t('Events_explore') },
    { id: '3', name: 'opportunities', label: t('Opportunities_explore') },
  ];

  const { setEntity, entity }: any = useContext(SearchContext);
  const { setFilters }: any = useContext(SearchContext);
  const { setSearchStart, setClear }: any = useContext(GlobalSearchContext);

  const onChooseEntity = (entity: IOptionItem) => {
    history.push(`/search/${entity.name !== 'everything' ? entity.name : 'all'}`);
    setEntity(entity);
    setFilters('entity', entity);
  };

  const onSubmit = () => {
    setSearchStart();
    onClose();
  };

  const onClear = () => {
    setClear();
    setSearchStart();
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.headerTitle} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Search')}
        </Title>
      </div>
      <div className={`${styles.container} ${containerStyle}`.trim()}>
        <div className={styles.body}>
          <Title size={'h2'} containerStyle={`${styles.bodyItem} ${styles.filterTitle}`}>
            {t('Searching for')}
          </Title>
          <Select
            value={entity}
            list={entitiesList}
            containerStyle={styles.entitySelect}
            inputStyle={styles.entityList}
            optionStyle={styles.entityListOption}
            onChoose={onChooseEntity}
            noInput
          />
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnSubmit}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Apply filters')}
          onPress={onSubmit}
        />
        <ButtonMain
          containerStyle={`${styles.footerBtn} ${styles.footerBtnCancel}`}
          type={'primary_1'}
          size={'xl'}
          text={t('Clear filters')}
          onPress={onClear}
          outline
        />
      </div>
    </div>
  );
};

export default View;
