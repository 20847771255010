import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: 'white',
      width: '100%',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      maxWidth: 510,
      margin: '0 auto',
      background: '#FFFFFF',
      padding: '15px',
      overflow: 'auto',
    },
    headerTitle: {
      '& svg': {
        marginRight: 15,
      },
    },
    searchInputMob: {
      width: 'auto',
    },
    filterBtn: {
      marginLeft: 15,
    },
    filterTitle: {
      marginBottom: 15,
    },
    entitySelect: {
      maxWidth: 'none',
      marginBottom: 30,
    },
    entityList: {
      textTransform: 'capitalize',
    },
    entityListOption: {
      textTransform: 'capitalize',
    },
    filterBlockSearchInputBlock: {
      paddingBottom: 60,
    },
    filterBlockSearchInput: {
      maxWidth: 'none',
    },
    filterBlockSelectInput: {
      maxWidth: 'none',
    },
    filterHiringCompanies: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px 0',
    },
    btnToggleList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      marginBottom: 15,
    },
    btnToggle: {
      margin: '0 10px 10px 0',
    },
    header: {
      width: '100%',
      height: 60,
      display: 'flex',
      background: 'rgba(232, 239, 244, 0.25)',
      flexShrink: 0,
      padding: '10px 30px',
    },
    body: {
      position: 'relative',
      //  display: 'flex',
      // flexDirection: 'column',
      padding: '10px 0',
    },
    bodyItem: {
      //  flexShrink: 0
    },
    onFilterByCompany: {
      position: 'absolute',
      top: 30,
      right: 10,
      transform: 'translate(0, -50%)',
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 15px 15px',
      width: '100%',
      maxWidth: 510,
      margin: '0 auto',
      background: '#FFFFFF',
    },
    footerBtn: {
      maxWidth: 'none',
    },
    footerBtnSubmit: {
      marginBottom: 10,
    },
    footerBtnCancel: {
      border: 'none',
      '&:hover': {
        border: `1px solid ${theme.$primaryColor1}`,
      },
    },
    perkItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flex: '1',
      marginBottom: 10,
    },
    perkItemBtn: {
      width: 54,
      height: 54,
      borderRadius: 27,
      margin: 0,
    },
    perkItemValue: {
      fontSize: 24,
    },
    perkItemName: {},
    perkItemBtnActive: {},
    perkItemNameActive: {
      fontWeight: 700,
    },
  };
});
