import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      height: 151,
      width: '100%',
      borderRadius: 10,
      padding: 15,
    },
    dropZoneImage: {
      width: '100%',
      height: 90,
      transition: '0.4s all ease',
      '&:hover': {
        background: 'rgba(246, 85, 50, 0.5)',
        cursor: 'pointer',
      },
    },
    dropZoneImageContainer: {
      position: 'relative',
    },
    dropZoneImageInner: {
      width: 70,
      height: 26,
      borderRadius: 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      border: '1px solid white',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 20,
      height: 20,
      position: 'absolute',
      background: 'white',
      borderRadius: '50%',
    },
    avatarError: {
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: 220,
      padding: '15px 25px 15px 15px',
      borderRadius: 10,
      background: theme.$primaryColor1,
      zIndex: 10,

      '& > *': {
        fontSize: 12,
        color: 'white',
      },
    },
    university: {
      margin: '12px 0',
      textAlign: 'center',
    },
    universityName: {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
    },
    subjectName: {
      fontSize: 11,
      fontWeight: 400,
      fontFamily: 'Inter, sans-serif',
    },
    mainText: {
      textAlign: 'center',
    },
    secondaryText: {
      textAlign: 'center',
    },
    uploadBtn: {
      maxWidth: 'none',
      background: 'transparent',
      marginTop: 15,
    },
    file: {
      display: 'inline-flex',
      alignItems: 'center',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 17,
      padding: '10px 15px',
      color: '#282828',
      margin: '0 auto',
      maxWidth: 300,
    },
    path: {
      display: 'block',
      width: '90%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    close: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: 15,
      height: 15,
      borderRadius: '50%',
      background: '#ACB7BF',
      marginLeft: 10,
      cursor: 'pointer',
    },
    closeError: {
      position: 'absolute',
      top: 15,
      right: 20,
      cursor: 'pointer',
      width: 1,
      height: 1,
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        width: 15,
        height: 2,
        background: 'white',
        transform: 'rotate(45deg)',
      },
      '&:before': {
        content: "''",
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 15,
        height: 2,
        background: 'white',
        transform: 'rotate(-45deg)',
      },
    },
  };
});
