import React from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth0 } from '@auth0/auth0-react';
import Title from 'src/components/titles/Title/index.web';
import Button from 'src/components/buttons/ButtonMain/index.web';
import Text from 'src/components/texts/Text/index.web';
import Link from 'src/components/links/Link/index.web';

import { IOptionItem } from '../../../requests/models';
import { Storage } from '../../../utilities/Storage';
import Modal from '../../components/Modal/Modal';

import styles from './styles.module.scss';

const storage = new Storage(Platform.OS !== 'web');

const LoginPage: React.FunctionComponent = () => {
  const { loginWithRedirect, logout, isLoading, isAuthenticated } = useAuth0();
  const [ready, setReady] = React.useState(false);
  const [modalIsActive, setModalIsActive] = React.useState(false);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const qLang = query.get('lang');

  const onLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  const { t, i18n } = useTranslation();

  const list: IOptionItem[] = React.useMemo(
    () => [
      { id: 'en', name: t('English') },
      { id: 'fr', name: t('Français') },
      { id: 'nl', name: t('Nederlands') },
    ],
    [t],
  );

  const onModalClose = React.useCallback(() => {
    setModalIsActive(false);
  }, []);

  const onChooseLanguage = React.useCallback(
    (lang: string) => {
      const chooseLanguage = async () => {
        await i18n.changeLanguage(lang);
        await storage.setUserLanguage(lang);
        onModalClose();
      };

      chooseLanguage();
    },
    [onModalClose, i18n],
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  React.useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 500);
  }, []);

  React.useEffect(() => {
    if (qLang && list.find((item) => item.id === qLang)) {
      i18n.changeLanguage(qLang);
      storage.setUserLanguage(qLang);
    }
  }, [qLang, list, i18n]);

  return ready ? (
    <>
      <Helmet>
        <title>{'Login | Launch'}</title>
      </Helmet>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.langPicker} onClick={() => setModalIsActive(true)}>
            {i18n.language}
          </div>
          <Title size={'h2'} containerStyle={styles.title}>
            {t('Welcome')}
          </Title>
          <Text type={'default'} containerStyle={styles.subtitle}>
            {t('Log in to Launch to continue')}
          </Text>
          {!isLoading ? (
            isAuthenticated ? (
              <Button size={'xl'} type={'primary_1'} onPress={onLogout} containerStyle={styles.login} text={t('Logout')} />
            ) : (
              <Button size={'xl'} type={'primary_1'} onPress={loginWithRedirect} containerStyle={styles.login} text={t('Login')} />
            )
          ) : null}
          <Link internal href={'/support'} size={'sm'} containerStyle={styles.forgotPass}>
            {t('Have some problems? Write to support')}
          </Link>
        </div>
      </div>
      <Modal isModalOpened={modalIsActive} wrapperStyles={styles.modal} contentStyles={styles.modalContent} onClose={onModalClose}>
        <div className={styles.langContent}>
          <Title size={'h2'} containerStyle={styles.modalTitle}>
            {t('Choose language')}
          </Title>
          <div className={styles.langList}>
            {list.map((lItem: IOptionItem) => (
              <div
                key={lItem.id}
                className={`${styles.langItem} ${i18n.language === lItem.id ? styles.langItemActive : ''}`.trim()}
                onClick={() => onChooseLanguage(lItem.id)}>
                {lItem.name}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  ) : null;
};

export default React.memo(LoginPage);
