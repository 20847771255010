import React, { FC } from 'react';
import Icon from 'src/components/icons/Icon/index.web';
import { useTheme } from 'react-jss';
import { useStyles } from './styles.web';

import { IProps } from './types';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
}

const ArrowToggle: FC<IProps> = (props) => {
  const { containerStyle, active, onPress } = props;
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...theme, active });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle} ${active ? styles.componentActive : styles.componentInactive}`.trim(),
  };

  return (
    <div className={cn.component} onClick={onPress}>
      <Icon name={'arrow-down'} size={12} color={theme.$primaryColor2} />
    </div>
  );
};

export default ArrowToggle;
