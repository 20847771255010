import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      margin: '5px 0',
      '& *': {
        maxWidth: '100%',
        padding: 'revert',
      },
      '& p': {
        margin: '10px 0',
      },
    },
    title: {},
    bio: {},
    toggleText: {
      borderTop: 'none',
      padding: 0,
      alignItems: 'flex-start',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    toggleTextItem: {},
  };
});
