export type TSessionState = {
  readonly isAuthenticated: boolean;
  readonly ready: boolean;
  tries: number;
  token: undefined | string;
};

export enum ESessionActionTypes {
  TRIES = '@session/TRIES',
  SIGN_IN = '@session/SIGN_IN',
  SIGN_OUT = '@session/SIGN_OUT',
  READY = '@session/READY',
  TOKEN = '@session/TOKEN',
}

export type TSignInAction = {
  type: ESessionActionTypes.SIGN_IN;
};

export type TSignOutAction = {
  type: ESessionActionTypes.SIGN_OUT;
};

export type TReadyAction = {
  type: ESessionActionTypes.READY;
  value: boolean;
};

export type TokenAction = {
  type: ESessionActionTypes.TOKEN;
  value: undefined | string;
};

export type TriesAction = {
  type: ESessionActionTypes.TRIES;
  value?: number;
};

export type TSessionAction = TSignInAction | TSignOutAction | TReadyAction | TokenAction | TriesAction;
