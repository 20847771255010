import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
    },
    container: (p: IProps) => ({
      width: '100%',
      maxWidth: p.isMobile ? 512 : 710,
      margin: '0 auto',
      padding: p.isMobile ? '0 15px' : 0,
    }),
    head: (p: IProps) => ({
      background: p.isMobile ? 'transparent' : 'white',
      width: '100%',
      height: p.isMobile ? 120 : 172,
      paddingTop: p.isMobile ? 15 : 55,
    }),
    headTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    mainTitle: {
      fontSize: 32,
      lineHeight: '32px',
    },
    tabs: (p: IProps) => ({
      justifyContent: 'flex-start',
      marginTop: p.isMobile ? 10 : 26,
    }),
    tab: (p: IProps) => ({
      padding: p.isMobile ? '4px 6px' : '4px 10px',
      borderBottom: 'none',
      margin: p.isMobile ? 0 : '0 5px',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    }),
    body: (p: IProps) => ({
      paddingTop: p.isMobile ? 15 : 30,
    }),
    search: (p: IProps) => ({
      background: 'white',
      maxWidth: p.isMobile ? ['100%', '!important'] : ['50%', '!important'],
    }),
    filters: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    filtersLabel: {
      margin: '0 2px',
      color: '#656771',
    },
    filtersValue: {
      margin: '0 5px',
      cursor: 'pointer',
      fontWeight: 500,
      color: theme.$secondaryColor1,
    },
    filtersValueActive: {
      color: theme.$primaryColor2,
    },
    order: {
      display: 'flex',
      alignItems: 'center',
    },
    orderDirection: {
      display: 'flex',
      alignItems: 'center',
    },
    account: (p: IProps) => ({
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 15,
      padding: p.isMobile ? '15px 0' : '30px 0',
    }),
    accountTitle: (p: IProps) => ({
      padding: p.isMobile ? '0 15px' : '0 30px',
      marginTop: 0,
    }),
    deactivateTitle: {},
    deactivate: (p: IProps) => ({
      padding: p.isMobile ? '0 15px' : '0 30px',
    }),
    resetButton: () => ({
      maxWidth: 'none',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    }),
    emailInputs: (p) => ({
      marginTop: p.isMobile ? '15px' : '30px',
      marginBottom: p.isMobile ? '0' : '15px',
    }),
    fieldInputContainer: () => ({
      maxWidth: 'none',
      marginBottom: '15px',
    }),
    fieldInput: () => ({
      maxWidth: 'none',
    }),
    deactivateButtons: {},
    deactivateButton: {
      maxWidth: 'none',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    deletedString: {
      color: 'red',
      textAlign: 'center',
    },
    modal: {},
    modalHeader: ({ isMobile }: IProps) => ({
      display: 'flex',
      alignItems: 'center',
      height: isMobile ? 55 : 70,
      padding: '15px 30px',
      borderRadius: '15px 15px 0 0',
    }),
    modalBody: ({ isMobile }: IProps) => ({
      padding: isMobile ? 15 : 30,
      maxHeight: '58vh',
      overflow: 'auto',
    }),
    modalFooter: ({ isMobile }: IProps) => ({
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      padding: isMobile ? 15 : 30,
    }),
    modalFooterBtn: ({ isMobile }: IProps) => ({
      maxWidth: 'none',
      marginRight: isMobile ? 0 : 15,
      marginBottom: isMobile ? 15 : 0,
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    }),
    modalHeaderTitle: ({ isMobile }: IProps) => ({
      fontSize: isMobile ? 18 : 24,
    }),
    modalContent: {
      height: 'auto',
      width: '80%',
      minWidth: 320,
      maxWidth: 520,
      borderRadius: 15,
    },
    deactivateContent: {},
    deleteContent: {},
    bodyIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 46,
      height: 46,
      background: 'rgba(40, 40, 40, 0.08)',
      borderRadius: 5,
    },
    bodyTitle: {
      textAlign: 'center',
      maxWidth: 320,
    },
    bodyText: {
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '18px',
    },
    bodyText2: {
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '18px',
      marginTop: '20px',
      marginBottom: '42px',
    },
    center: {
      margin: '0 auto',
    },
  };
});
