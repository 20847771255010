import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      maxWidth: '34.5rem',
      height: 54,
      padding: '0 15px',
      backgroundColor: 'white',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '0.25s all ease',
    },
    componentActive: {
      //border: `1px solid ${theme.$primaryColor1}`
    },
    componentInvalid: {
      border: '1px solid #dd0000',
    },
    input: {
      width: '100%',
      height: '100%',
      fontFamily: 'Inter',
      lineHeight: '16px',
      color: '#222222',
      fontSize: 14,
      borderRadius: 10,
      border: 'none',
      outline: 'none',
      transition: '0.25s all ease',
      '&::placeholder': {
        color: '#222222',
        opacity: 0.3,
      },
      '&:focus, &:active': {
        color: theme.$primaryColor2,
        opacity: 1,
      },
    },
    icon: {
      display: 'flex',
    },
    iconSearch: {},
    iconClose: {},
  };
});
