import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      width: '100%',
    }),
    msg: ({ background = '#F9FBFC' }: IProps) => ({
      position: 'relative',
      padding: '15px',
      background: background,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 0,
      fontFamily: 'Inter',
      fontSize: 14,
      color: '#282828',

      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 0,
        height: 0,
        bottom: -10,
        left: 0,
        borderStyle: 'solid',
        borderWidth: '22px 22px 0 0',
        borderColor: `${background} transparent transparent transparent`,
      },
    }),
    date: {
      marginTop: 5,
      marginLeft: 15,
      fontSize: 10,
      background: 'transparent',
    },
  };
});
