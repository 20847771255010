import React, { useEffect, useState } from 'react';
import Text from 'src/components/texts/Text/index.web';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import { ITheme } from 'src/constant/themes';
import { selectCurrentPage } from '../../../../state/modules/onboarding';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IProps {
  ready: boolean;
  reference: string;
  ref: any;
}

const pagesWithSkip = ['3', '4', '5', '6', '7', '9'];

const CustomSubTitle = React.forwardRef((props: IProps, ref: any) => {
  const { reference } = props;
  const { t } = useTranslation();
  const currentPage = useSelector((state) => selectCurrentPage(state));
  const [skip, setSkip] = useState(false);
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, skip, ...theme });
  const textes = {
    '/1': t('Let’s make you an awesome profile'),
    '/2': t('Let’s start with the basics, your education.'),
    '/3': t('You’re awesome. Let’s detail it a bit further.'),
    '/4': t('Perfect, companies love to know the following details.'),
    '/5': t('Almost there, few more things we need to know.'),
    '/6': t('Last but not least, do you already have any work experience?'),
    '/7': t('Last but not least, which topics are you interested in?'),
    '/8': t('When you follow companies, you’ll see their updates in your feed and also get relevant recommendations.'),
    '/9': t('That’s it! Please, complete add referral organisation details and you will Launch!'),
    '/10': t('That’s it! Please complete your personal details and you will Launch!'),
    '/last': t('Fine tune your profile to make it appeal more'),
  };
  // eslint-disable-next-line no-prototype-builtins
  const text = textes.hasOwnProperty(reference) ? textes[reference] : '';

  useEffect(() => {
    setSkip(pagesWithSkip.includes(currentPage));
  }, [currentPage]);

  return (
    <Text type={'default'} containerStyle={styles.title}>
      {text}
      <div ref={ref} />
    </Text>
  );
});

export default CustomSubTitle;
