import React, { createContext, useContext, useEffect } from 'react';
import { FEED } from '../../requests/queries';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  UPDATE_COMPANY_LIKE,
  UPDATE_EVENT_LIKE,
  UPDATE_OPPORTUNITY_LIKE,
  UPDATE_COMPANY_DISLIKE,
  UPDATE_COMPANY_BOOKMARK,
  UPDATE_OPPORTUNITY_BOOKMARK,
  UPDATE_EVENT_BOOKMARK,
} from '../../requests/mutations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsReady } from '../../state/modules/session';
import { Platform } from 'react-native';
import { Context as LikesContext } from '../LikesContext/index.web';
import { Context as CompanySearchContext } from '../../contexts/SearchContext/CompaniesSearchContext';
import { Context as OpportunitySearchContext } from '../../contexts/SearchContext/OpportunitySearchContext';
import { Context as EventSearchContext } from '../../contexts/SearchContext/EventSearchContext';

interface IProps {
  children: any;
}

interface ExplorerContextState {
  setSearch: () => void;
  toggleLike: (id: string, is_liked: boolean, set: 'c' | 'e' | 'o') => void;
  toggleBookmark: (id: string, is_bookmarked: boolean, set: 'c' | 'e' | 'o') => void;
  toggleDislike: (id: string) => void;
  toggleCompanyLike: any;
  toggleOpportunityLike: any;
  toggleEventLike: any;
  toggleCompanyBookmark: any;
  toggleOpportunityBookmark: any;
  toggleEventBookmark: any;
  toggleCompanyDislike: any;
  result: any;
  setSearchNext: () => void;
  loading: boolean;
}

const Context = createContext({} as ExplorerContextState);
Context.displayName = '_EXPLORE_';

const cacheClear = (cache: any, id: number, data: any): void => {
  const itemRef = cache.identify(data.feed.result.find((item: any) => item.id === id));
  cache.modify({
    id: cache.identify(data.feed),
    fields: {
      result: (cache: any): any => {
        return cache.filter((c: any) => c.__ref !== itemRef);
      },
    },
  });
};

const Provider = (props: IProps) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const isReady = useSelector((state) => selectIsReady(state));
  const { toggleLike, toggleBookmark, toggleDislike }: any = useContext(LikesContext);

  const { setLike: setCompanyEntityLike, setBookmark: setCompanyEntityBookmark } = useContext(CompanySearchContext);
  const { setLike: setOpportunityEntityLike, setBookmark: setOpportunityEntityBookmark } = useContext(OpportunitySearchContext);
  const { setLike: setEventEntityLike, setBookmark: setEventEntityBookmark } = useContext(EventSearchContext);

  const [
    getFeed,
    {
      loading,
      data = {
        feed: {
          result: [],
          page: 1,
          is_last: true,
          limit: 10,
        },
      },

      refetch,
      fetchMore,
      called,
    },
  ] = useLazyQuery(FEED, {
    variables: {
      lang,
      limit: 10,
      page: 1,
    },
  });

  /**FOR MOBILE*/
  const [companyBookmarkExpolre] = useMutation(UPDATE_COMPANY_BOOKMARK, {
    update(cache, { data: { updateCompanyBookmark } }) {
      const { id, is_bookmarked, name } = updateCompanyBookmark;
      setCompanyEntityBookmark(id, is_bookmarked);
    },
  });
  const [opportunityBookmarkExpolre] = useMutation(UPDATE_OPPORTUNITY_BOOKMARK, {
    update(cache, { data: { updateOpportunityBookmark } }) {
      const { id, is_bookmarked, name } = updateOpportunityBookmark;
      setOpportunityEntityBookmark(id, is_bookmarked);
    },
  });
  const [eventBookmarkExpolre] = useMutation(UPDATE_EVENT_BOOKMARK, {
    update(cache, { data: { updateEventBookmark } }) {
      const { id, is_bookmarked, name } = updateEventBookmark;
      setEventEntityBookmark(id, is_bookmarked);
    },
  });
  const [companyLikeExplore] = useMutation(UPDATE_COMPANY_LIKE, {
    update(cache, { data: { updateCompanyLike } }) {
      const { id, is_liked, name } = updateCompanyLike;
      setCompanyEntityLike(id, is_liked);
    },
  });
  const [companyDislikeExplore] = useMutation(UPDATE_COMPANY_DISLIKE, {
    update(cache, { data: { updateCompanyDislike } }) {
      cacheClear(cache, updateCompanyDislike.id, data);
    },
  });
  const [opportunityLikeExplore] = useMutation(UPDATE_OPPORTUNITY_LIKE, {
    update(cache, { data: { updateOpportunityLike } }) {
      const { id, is_liked, name } = updateOpportunityLike;
      setOpportunityEntityLike(id, is_liked);
    },
  });
  const [eventLikeExplore] = useMutation(UPDATE_EVENT_LIKE, {
    update(cache, { data: { updateEventLike } }) {
      const { id, is_liked, name } = updateEventLike;
      setEventEntityLike(id, is_liked);
    },
  });

  const toggleLikeExplore = async (id: string, is_liked: boolean, set: 'c' | 'e' | 'o') => {
    try {
      if (set === 'c') {
        await companyLikeExplore({ variables: { like: { id: id + '', is_liked } } });
      } else if (set === 'e') {
        await eventLikeExplore({ variables: { like: { id: id + '', is_liked } } });
      } else if (set === 'o') {
        await opportunityLikeExplore({ variables: { like: { id: id + '', is_liked } } });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const toggleDislikeExplore = async (id: string) => {
    try {
      await companyDislikeExplore({ variables: { dislike: { id: id + '', is_disliked: true } } });
    } catch (e) {
      console.error(e);
    }
  };
  const toggleBookmarkExplore = async (id: string, is_bookmarked: boolean, set: 'c' | 'e' | 'o') => {
    try {
      if (set === 'c') {
        await companyBookmarkExpolre({ variables: { bookmark: { id: id + '', is_bookmarked } } });
      } else if (set === 'o') {
        await opportunityBookmarkExpolre({ variables: { bookmark: { id: id + '', is_bookmarked } } });
      } else if (set === 'e') {
        await eventBookmarkExpolre({ variables: { bookmark: { id: id + '', is_bookmarked } } });
      }
    } catch (e) {
      console.error(e);
    }
  };
  /**FOR MOBILE*/

  /**FOR WEB*/
  const toggleCompanyLike = (id: string, is_liked: boolean) => toggleLike(id, is_liked, 'c');
  const toggleOpportunityLike = (id: string, is_liked: boolean) => toggleLike(id, is_liked, 'o');
  const toggleEventLike = (id: string, is_liked: boolean) => toggleLike(id, is_liked, 'e');

  const toggleCompanyBookmark = (id: string, is_liked: boolean) => toggleBookmark(id, is_liked, 'c');
  const toggleOpportunityBookmark = (id: string, is_liked: boolean) => toggleBookmark(id, is_liked, 'o');
  const toggleEventBookmark = (id: string, is_liked: boolean) => toggleBookmark(id, is_liked, 'e');

  const toggleCompanyDislike = (id: string, is_liked: boolean) => toggleDislike(id, is_liked, 'c');
  /**FOR WEB*/

  const setSearch = () => {
    if (called) {
      //@ts-ignore
      refetch();
    } else {
      getFeed();
    }
  };

  const setSearchNext = async () => {
    if (!loading && data.feed && !data.feed.is_last) {
      //@ts-ignore
      await fetchMore({
        variables: {
          lang,
          limit: 10,
          page: data.feed.page + 1,
        },
        //@ts-ignore
        updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
          if (!fetchMoreResult || !fetchMoreResult.feed) {
            return prev;
          }
          return Object.assign({}, prev, {
            feed: {
              ...fetchMoreResult.feed,
              result: [...prev.feed.result, ...fetchMoreResult.feed.result],
            },
          });
        },
      });
    }
  };

  useEffect(() => {
    if ((Platform.OS === 'web' && isReady) || Platform.OS !== 'web') {
      setSearch();
    }
  }, [isReady]);

  return (
    <Context.Provider
      value={{
        setSearch,
        toggleCompanyLike,
        toggleOpportunityLike,
        toggleEventLike,
        toggleLike: toggleLikeExplore,
        toggleDislike: toggleDislikeExplore,
        toggleBookmark: toggleBookmarkExplore,
        toggleCompanyBookmark,
        toggleOpportunityBookmark,
        toggleEventBookmark,
        toggleCompanyDislike,
        result: data.feed ? data.feed.result : [],
        setSearchNext,
        loading,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
