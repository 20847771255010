import React, { useEffect } from 'react';
import MessagePage from '../containers/MessagePage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { initGA } from '../../utilities';

const Container = () => (
  <Page>
    {({ isMobile, pageRef }: IPassProps) => {
      return <MessagePage isMobile={isMobile} ref={pageRef} />;
    }}
  </Page>
);

export default Container;
