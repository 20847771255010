import React, { FC, useEffect, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { GET_REFERRALS } from 'src/requests/queries';

import Text from 'src/components/texts/Text/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import Input from 'src/components/inputs/TextInput/index.web';
import Select from 'src/components/inputs/Select/index.web';
import Divider from 'src/components/dividers/Divider/index.web';
import Toggle from 'src/components/toggles/Toggle/index.web';
import ButtonSwitcher from 'src/components/buttons/ButtonSwitcher/index.web';
import SkipPortal from '../SkipPortal';

import { useTheme } from 'react-jss';
import { useStyles } from './styles.web';
import { ITheme } from 'src/constant/themes';

import { OnboardingData, IOptionItem } from 'src/requests/models';
import { selectReferrer } from 'src/state/modules/onboarding';

interface IProps {
  isMobile: boolean;
  children?: any;
  ref: any;
  next: any;
  onNext(next: string): void;
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding9: FC<IProps> = forwardRef((props, ref: any) => {
  const { t } = useTranslation();
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const referrer = useSelector((state) => selectReferrer(state));
  const { next, onNext, setData, setPage, setIsNextDisabled } = props;

  const [availableReferrals, setAvailableReferrals] = useState<IOptionItem[]>([]);
  const [referralFilter, setReferralFilter] = useState('');
  const [hasReferral, setHasReferral] = useState(!!referrer);
  const [inputType, setInputType] = useState('list');
  const [queryPage, setQueryPage] = useState(1);

  const toggleHasReferral = () => {
    if (hasReferral) setData({ referrer: '' });
    setHasReferral((prev) => !prev);
  };

  const inputTypes: IOptionItem[] = [
    { id: 'list', name: t('Select from list') },
    { id: 'manual', name: t('Manual input') },
  ];

  const { data: referralsData } = useQuery(GET_REFERRALS, {
    variables: {
      page: queryPage,
      limit: 100,
      filters: { code: referralFilter },
    },
  });

  const onSkipPress = async () => {
    await setData({ referrer: undefined });
    await onNext(next);
  };

  useEffect(() => {
    setPage('9');
    setIsNextDisabled(false);
  }, []);

  useEffect(() => {
    const referralOptions: IOptionItem[] =
      referralsData?.referrals?.result?.map((item) => ({
        id: item?.id,
        name: item?.code,
      })) || [];
    setAvailableReferrals([...availableReferrals, ...referralOptions]);
  }, [referralsData]);

  const handleSwitchInputType = (option: IOptionItem) => {
    option.id !== inputType && setInputType(option.id);
  };

  const handleFilterReferrals = (code: string) => {
    setAvailableReferrals([]);
    setQueryPage(1);
    setReferralFilter(code);
  };

  const onInputReferrer = (value: string) => {
    const referrer = inputType === 'list' ? value : value.replace(/\s+/g, '')?.toUpperCase();
    setData({ referrer });
  };

  const handleScroll = (e: { target: HTMLElement }) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setQueryPage((current) => current + 1);
    }
  };

  return (
    <>
      <SkipPortal current={ref.current} onSkipPress={onSkipPress} />
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <div className={styles.toggler}>
          <Text containerStyle={`${styles.fieldLabel} ${styles.wrapper}`}>
            <span className={styles.fieldName}>{hasReferral ? t('I have referral code') : t('I don’t have referral code')}</span>
            <Icon color="#97A3AC" size={20} name="circle-question" />
            <span className={`${styles.tooltip} tooltip`}>{t('Enter referral code')}</span>
          </Text>
          <Toggle active={hasReferral} onPress={toggleHasReferral} />
        </div>
        {hasReferral && (
          <>
            <ButtonSwitcher value={inputType === 'list' ? inputTypes[0] : inputTypes[1]} values={inputTypes} onChoose={handleSwitchInputType} />
            <div className={styles.field}>
              {inputType === 'list' ? (
                <Select
                  value={availableReferrals?.find((item: any) => item?.name === referrer)}
                  containerStyle={styles.fieldInput}
                  placeholder="ABCD-1234"
                  list={availableReferrals || []}
                  onChange={handleFilterReferrals}
                  onChoose={(item) => item && onInputReferrer(item.name)}
                  onScroll={handleScroll}
                />
              ) : (
                <Input value={referrer} placeholder="ABCD-1234" containerStyle={styles.fieldInput} onChange={onInputReferrer} />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default Onboarding9;
