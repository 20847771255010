import React, { FC, useContext } from 'react';
import Header from 'src/web/components/Header';
import CompanyDesktop from 'src/web/components/Company/Page/Desktop';
import CompanyMobile from 'src/web/components/Company/Page/Mobile';
import { Context as LikesContext } from 'src/contexts/LikesContext/index.web';

import { IProps } from './types';
import { useQuery } from '@apollo/client';
import styles from './styles.module.scss';
import { COMPANY } from 'src/requests/queries';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';
import { ApplyModal } from 'src/web/components/ApplyModal';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { IOpportunity } from 'src/components/cards/Opportunity/types';
import { addSubscription } from 'src/state/modules/ui';
import { useDispatch } from 'react-redux';

const CompanyPage: FC<IProps> = (props) => {
  // @ts-ignore
  const { id } = useParams();
  const { isMobile } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const {
    data: companyData = {
      company: {
        name: '',
        website: '',
        logo: '',
        background_photo: '',
        cities: [],
        additional_info: '',
        bio: '',
        deleted: '',
        company_size: { id: '', name: '', value: '' },
        emoji: [],
        tags: [],
        socials: [],
        sectors: [],
        is_liked: false,
        is_bookmarked: false,
        is_disliked: false,
      },
    },
    loading,
  } = useQuery(COMPANY, { variables: { lang, id } });

  const {
    name,
    website,
    logo,
    background_photo,
    cities,
    additional_info,
    bio,
    company_size,
    emoji,
    tags,
    deleted,
    socials,
    sectors,
    is_liked,
    is_bookmarked,
    is_disliked,
  } = companyData.company;

  const { toggleLike, toggleBookmark, toggleDislike }: any = useContext(LikesContext);
  const { setApplyModalShown, setEntityForApply, applyModalShown, entityForApply } = useContext(SearchContext);
  const dispatch = useDispatch();
  const setCompanyLike = () => {
    toggleLike(id, !is_liked, 'c');
  };

  const handleEOLike = ({ id, name, application, type, __typename }: any, is_liked: boolean) => {
    if (application.redirectUrl) {
      window.open(application.redirectUrl);
    } else {
      dispatch(addSubscription({ id: '' + id, name, type: type === 'opportunity' ? 'o' : 'e' }));
    }
    if (type === 'opportunity' || __typename === 'Opportunity') {
      toggleLike(id, true, 'o');
    } else if (type === 'event' || __typename === 'Event') {
      toggleLike(id, true, 'e');
    }
  };

  const setCompanyBookmark = () => {
    toggleBookmark(id, !is_bookmarked, 'c');
  };

  const setCompanyDislike = () => {
    toggleDislike(id, !is_disliked, 'c');
  };

  return (
    <>
      <Helmet>
        <title>{`Company - ${name}`}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          {loading ? (
            <>Loading...</>
          ) : isMobile ? (
            <CompanyMobile
              id={id}
              name={name}
              website={website}
              logo={logo}
              background_photo={background_photo}
              cities={cities || []}
              additional_info={additional_info}
              bio={bio}
              company_size={company_size}
              emoji={emoji}
              tags={tags}
              socials={socials}
              sectors={sectors}
              deleted={deleted}
              is_liked={is_liked}
              is_bookmarked={is_bookmarked}
              is_disliked={is_disliked}
              setCompanyLike={setCompanyLike}
              setCompanyBookmark={setCompanyBookmark}
              setCompanyDislike={setCompanyDislike}
            />
          ) : (
            <CompanyDesktop
              isMobile={false}
              id={id}
              name={name}
              website={website}
              logo={logo}
              background_photo={background_photo}
              cities={cities || []}
              additional_info={additional_info}
              bio={bio}
              company_size={company_size}
              emoji={emoji}
              tags={tags}
              deleted={deleted}
              socials={socials}
              sectors={sectors}
              is_liked={is_liked}
              is_bookmarked={is_bookmarked}
              is_disliked={is_disliked}
              setCompanyLike={setCompanyLike}
              setCompanyBookmark={setCompanyBookmark}
              setCompanyDislike={setCompanyDislike}
            />
          )}
        </div>
        {applyModalShown && (
          <ApplyModal
            entity={entityForApply as IOpportunity}
            cancel={() => {
              setApplyModalShown(false);
              setEntityForApply({});
            }}
            confirm={() => {
              handleEOLike(entityForApply, true);
              setApplyModalShown(false);
              setEntityForApply({});
            }}
            text={(entityForApply as IOpportunity)?.application?.message ? (entityForApply as IOpportunity)?.application?.message : ''}
          />
        )}
      </ErrorBoundaries>
    </>
  );
};

export default CompanyPage;
