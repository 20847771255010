import { AppState } from 'src/state/store';
import { lgDevice, mdDevice, smDevice, xsDevice } from 'src/state/modules/ui/ui.selectors';
import { connect } from 'react-redux';
import React, { FC, memo, cloneElement, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { initGA } from '../../../utilities';
const Page = memo(({ children, isMobile }: { children: any; isMobile: boolean }) => {
  const pageRef = useRef(null);

  useEffect(() => {
    initGA();
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push(['js', new Date()]);
    // @ts-ignore
    window.dataLayer.push(['config', process.env.REACT_APP_GTAG]);
  }, []);

  return (
    <div className={styles.root} ref={pageRef} id={'page'}>
      {children({ pageRef, isMobile })}
    </div>
  );
});

export const mapState = (state: AppState) => {
  const result = {
    xs: xsDevice(state),
    sm: smDevice(state),
    md: mdDevice(state),
    lg: lgDevice(state),
  };

  return {
    ...result,
    isMobile: result.xs || result.sm || result.md,
  };
};

export default connect(mapState)(Page);
