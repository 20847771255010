import React, { useContext, memo } from 'react';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';

import Title from 'src/components/titles/Title/index.web';
import TabList from 'src/components/tabs/TabList/index.web';
import SearchInput from 'src/components/inputs/Input/index.web';
import Text from 'src/components/texts/Text/index.web';
import { Context as LikesContext } from 'src/contexts/LikesContext/index.web';
import List from './List';
import ViewSelect from './ViewSelect';
import { useStyles } from './styles';

const Likes = memo((props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile } = props;
  const { t } = useTranslation();
  const {
    view,
    setView,
    tab,
    setTab,
    searchValue,
    setSearchValue,
    setSearch,
    tabList,
    orderList,
    orderDirectionList,
    order,
    orderDirection,
    setOrder,
    setOrderDirection,
    toggleLike,
    toggleBookmark,
    toggleDislike,
    total,
    result,
  }: any = useContext(LikesContext);

  const handleLike = (data: any, is_liked: boolean) => {
    const { id, application, type } = data;
    if (type === 'opportunity' || type === 'event') {
      if (application.redirectUrl) {
        window.open(application.redirectUrl);
      }

      toggleLike(id, is_liked, type[0]);
    } else {
      toggleLike(id, is_liked, 'c');
    }
  };

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={styles.component}>
      <div className={styles.head}>
        <div className={styles.container}>
          <div className={styles.headTitle}>
            {!isMobile && (
              <Title size={'h1'} containerStyle={styles.mainTitle}>
                {t('Likes')}
              </Title>
            )}
            <ViewSelect isMobile={isMobile} value={view} onChoose={setView} />
          </div>
          <TabList isMobile={isMobile} active={tab} list={tabList} containerStyle={styles.tabs} tabStyle={styles.tab} onChange={setTab} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.container}>
          <div className={styles.filters}>
            <SearchInput value={searchValue} placeholder={t('Search')} onChange={onSearchChange} containerStyle={styles.search} />
            {isMobile ? null : (
              <>
                <div className={styles.order}>
                  <Text containerStyle={styles.filtersLabel}>{t('Sort by')}:</Text>
                  {orderList.map((item: { id: string; key: string; labelWeb: string; labelMob: string }) => (
                    <div
                      key={item.key}
                      className={`${styles.filtersValue} ${order === item.id ? styles.filtersValueActive : ''}`}
                      onClick={() => setOrder(item.id)}>
                      {t(item.labelWeb)}
                    </div>
                  ))}
                </div>
                <div className={styles.orderDirection}>
                  <Text containerStyle={styles.filtersLabel}>{t('Direction')}:</Text>
                  {orderDirectionList.map((item: { key: string; labelWeb: string; labelMob: string }) => (
                    <div
                      key={item.key}
                      className={`${styles.filtersValue} ${orderDirection === item.key ? styles.filtersValueActive : ''}`}
                      onClick={() => setOrderDirection(item.key)}
                      style={{ fontSize: 18 }}>
                      {isMobile ? item.labelMob : item.labelWeb}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <List
            isMobile={isMobile}
            view={view}
            set={tab}
            searchValue={searchValue}
            order={order}
            orderDirection={orderDirection}
            orderList={orderList}
            orderDirectionList={orderDirectionList}
            setOrder={setOrder}
            setOrderDirection={setOrderDirection}
            toggleLike={handleLike}
            toggleDislike={toggleDislike}
            toggleBookmark={toggleBookmark}
            total={total}
            result={result}
          />
        </div>
      </div>
    </div>
  );
});

export default Likes;
