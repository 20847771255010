import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      maxWidth: '34.5rem',
      height: 54,
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      height: '100%',
      fontFamily: 'Inter',
      padding: '19px 10px',
      color: '#222222',
      fontSize: 14,
      borderRadius: 10,
      lineHeight: '16px',
      border: 'none',
      outline: 'none',
      '&::placeholder': {
        opacity: 0.3,
        color: '#222222',
      },
      '&:focus, &:active': {
        color: theme.$primaryColor2,
        opacity: 1,
      },
    },
    icon: {
      paddingLeft: 20,
    },
  };
});
