import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { useStyles } from './styles';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import { LIST_ARCHETYPES, LIST_UNIVERSITY_SPECIALITIES, LIST_COUNTRIES } from 'src/requests/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import Text from 'src/components/texts/Text/index.web';
import Select from 'src/components/inputs/Select/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import DatePicker from 'src/components/inputs/DatePicker/index.web';
import { IOptionItem, ICountry, ISubject } from 'src/requests/models';
import ButtonSwitcher from 'src/components/buttons/ButtonSwitcher/index.web';
import Devider from 'src/components/dividers/Divider/index.web';
import Icon from 'src/components/icons/Icon';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import { validateUniversity } from '../../../../../../utilities/validation';
import Toggle from '../../../../../../components/toggles/Toggle/index.web';
import Input from 'src/components/inputs/TextInput/index.web';

const University = memo((props: IProps) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { isMobile, index, list, degrees, grades, data, setData, checkForMinors } = props;
  const { data: countriesList = { countries: [] } } = useQuery(LIST_COUNTRIES);

  const { t, i18n } = useTranslation();

  const {
    institute,
    graduation_date,
    degree,
    subject,
    average_grades,
    grade_proof,
    manual_input,
    custom_institute,
    custom_subject,
    archetype,
    country_of_education,
    minor,
  } = data;

  const [getSpecialties, specialities = { data }] = useLazyQuery(LIST_UNIVERSITY_SPECIALITIES, {
    variables: { lang: i18n.language, institute: data?.institute?.id || '' },
  });

  const [manual, setManual] = useState(!!manual_input);

  const { data: archData = { archetypes: [] } } = useQuery(LIST_ARCHETYPES, {
    variables: { lang: i18n.language },
  });

  const [defaultArchetype, setDefaultArchetype] = useState('');
  const [minors, setMinors] = useState([]);

  useEffect(() => {
    const currentArchetype: any = archData?.archetypes?.filter((item: any) => item.name_en === archetype);

    setMinors(currentArchetype?.[0]?.minors);

    checkForMinors(currentArchetype?.[0]?.minors);
  }, [archetype, archData]);

  const onSelectInstitute = (chosenInstitute: IOptionItem) => {
    if (institute?.id !== chosenInstitute?.id) {
      setData(index, {
        institute: chosenInstitute,
        subject: undefined,
        custom_institute: undefined,
        custom_subject: undefined,
        archetype: undefined,
        manual_input: false,
        minor: '',
      });
    } else {
      setData(index, { institute: chosenInstitute, custom_institute: undefined, custom_subject: undefined, archetype: undefined, manual_input: false });
    }
  };

  const onSelectMinor = ({ name }: any) => {
    setData(index, { minor: name });
  };

  const onSelectSubject = (subject: ISubject) => {
    setData(index, { subject, archetype: subject.archetype, archetype_override: false });

    setDefaultArchetype(subject.archetype);
  };

  const onSelectCustomInstitute = (name: string) => {
    setData(index, { custom_institute: { name }, institute: undefined, subject: undefined, manual_input: true });
  };

  const onSelectCustomSubject = (name: string) => {
    setData(index, { custom_subject: { name }, institute: undefined, subject: undefined, manual_input: true });
  };

  const onSelectArchetype = ({ name }: any) => {
    const data: any = { archetype: name, minor: '' };
    if (!manual && name !== defaultArchetype) data.archetype_override = true;

    setData(index, data);
  };

  useEffect(() => {
    if (manual) {
      setData(index, { country_of_education: country_of_education ? country_of_education : 'Belgium' });
    }
  }, [manual]);

  const onSelectCountry = ({ name }: any) => {
    setData(index, { country_of_education: name });
  };

  const onSelectGraduationDate = (graduation_date: any) => {
    setData(index, { graduation_date });
  };

  const onSelectDegree = (degree: IOptionItem) => {
    setData(index, { degree });
  };

  const onSelectGrades = (average_grades: IOptionItem) => {
    setData(index, { average_grades });
  };

  const onRemove = () => {
    setData(index);
  };

  useEffect(() => {
    if (data?.institute?.id) {
      getSpecialties();
    }
  }, [data?.institute?.id]);

  return (
    <div className={styles.component} style={index ? { paddingTop: 16 } : {}}>
      {index ? (
        <div className={styles.remove} onClick={onRemove}>
          <Icon name={'close'} color={theme.$primaryColor1} size={12} /> <span className={styles.removeText}>{t('Remove')}</span>
        </div>
      ) : null}
      <div className={styles.fields}>
        {!manual && (
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Institute')}</Text>
            <Select
              value={institute}
              containerStyle={styles.fieldInput}
              placeholder={t('Select institute')}
              list={list}
              onChoose={onSelectInstitute}
              inputValidation={validateUniversity}
            />
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Text containerStyle={styles.fieldLabel}>{t("I'm studying (-ed) outside of Belgium")}</Text>
          <Toggle active={manual} onPress={() => setManual((prev) => !prev)} />
        </div>
        {manual && (
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Institute')}</Text>
            <Input
              value={custom_institute?.name}
              placeholder={t('Enter your institute name')}
              containerStyle={styles.fieldInput}
              onChange={onSelectCustomInstitute}
            />
          </div>
        )}
        <div className={styles.field}>
          <Text containerStyle={styles.fieldLabel}>{t('Education subject')}</Text>
          {manual ? (
            <Input value={custom_subject?.name} placeholder={t('Select subject')} containerStyle={styles.fieldInput} onChange={onSelectCustomSubject} />
          ) : (
            <Select
              disabled={!institute?.id}
              value={subject}
              containerStyle={styles.fieldInput}
              placeholder={t('Select subject')}
              list={specialities.data ? specialities.data.educationalSubjects : []}
              onChoose={onSelectSubject}
              inputValidation={validateUniversity}
            />
          )}
        </div>
        <div className={styles.field}>
          <Text containerStyle={styles.fieldLabel}>{t('Field of education')}</Text>
          <Select
            containerStyle={styles.fieldInput}
            placeholder={t('Field of education')}
            list={archData?.archetypes?.map((item: any) => ({ id: item.id, name: item.name_en }))}
            value={archData?.archetypes?.find((item: any) => item.name_en === archetype)}
            onChoose={onSelectArchetype}
          />
        </div>
        {minors?.length > 0 && (
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Add an optional specialization')}</Text>
            <Select
              disabled={!archetype}
              containerStyle={styles.fieldInput}
              placeholder={t('Add an optional specialization')}
              list={minors.map((name: string) => ({ id: name, name }))}
              value={{ id: minor, name: minor }}
              onChoose={onSelectMinor}
            />
          </div>
        )}
        {manual ? (
          <div className={styles.field}>
            <Text containerStyle={styles.fieldLabel}>{t('Country of education')}</Text>
            <Select
              containerStyle={styles.fieldInput}
              placeholder={t('Country of education')}
              onChoose={onSelectCountry}
              list={countriesList?.countries?.map((country: ICountry) => ({ id: country.id, name: country.name }))}
              value={countriesList?.countries?.find((country: ICountry) => country.name === country_of_education)}
            />
          </div>
        ) : null}
        <div className={styles.field}>
          <Text containerStyle={styles.fieldLabel}>{t('(Expected) Date of graduation')}</Text>
          <DatePicker
            isMobile={isMobile}
            value={graduation_date}
            containerStyle={styles.fieldInput}
            onChange={onSelectGraduationDate}
            type={'months'}
            minDate={moment().subtract(10, 'years').toDate()}
            maxDate={moment().add(10, 'years').toDate()}
          />
        </div>
        <div className={styles.field}>
          <Text containerStyle={styles.fieldLabel}>{t('Degree')}</Text>
          <ButtonSwitcher value={degree} values={degrees} onChoose={onSelectDegree} />
        </div>
        <div className={styles.field}>
          <Text containerStyle={styles.fieldLabel}>{t('Average grades')}</Text>
          <div className={styles.grades}>
            {grades.map((gradeItem: IOptionItem) => (
              <ButtonToggle
                key={gradeItem.id}
                size={'lg'}
                active={average_grades ? average_grades.name === gradeItem.name : false}
                containerStyle={styles.grade}
                text={gradeItem.name}
                onPress={() => onSelectGrades(gradeItem)}
              />
            ))}
          </div>
        </div>
      </div>
      <Devider size={'xl'} />
    </div>
  );
});

export default University;
