import gql from 'graphql-tag';

export const LIST_UNIVERSITY_SPECIALITIES = gql`
  query ($institute: String!) {
    educationalSubjects: availableEducationalSubjects(institute_id: $institute) {
      id
      name
      archetype
    }
  }
`;

export const LIST_ARCHETYPES = gql`
  query {
    archetypes: availableArchetypes {
      name_en
      id
      minors
    }
  }
`;

export const LIST_COUNTRIES = gql`
  query {
    countries: availableCountries {
      name
      id
    }
  }
`;

export const LIST_CITIES = gql`
  query {
    cities: availableCities {
      name
    }
  }
`;

export const UNIVERSITY_LIST = gql`
  query {
    institutes: availableInstitutes {
      id
      name
    }
  }
`;

export const DEGREE_LIST = gql`
  query ($lang: String!) {
    degrees: availableDegrees(lang: $lang) {
      id
      name
    }
  }
`;

export const GRADE_LIST = gql`
  query {
    grades: availableEducationalGrades {
      name
    }
  }
`;

export const EDUCATION_LEVELS = gql`
  query ($lang: String!) {
    educationLevels: availableEducationLevels(lang: $lang) {
      id
      name
    }
  }
`;

export const INTERESTS = gql`
  query ($lang: String!) {
    interests: availableInterests(lang: $lang) {
      id
      name
    }
  }
`;

export const GET_REFERRALS = gql`
  query referrals($page: Float, $limit: Float, $filters: ReferralFilter) {
    referrals(page: $page, limit: $limit, filters: $filters) {
      result {
        code
        id
      }
      page
      limit
      total
    }
  }
`;

export const ONBOARDING_DATA = gql`
  query ($lang: String!) {
    internationalExperiences: availableInternationalExperiences(lang: $lang) {
      id
      name
    }
    achievements: availableAchievements(lang: $lang) {
      id
      name
    }
    educationalGrades: availableEducationalGrades {
      name
    }
    educationLevels: availableEducationLevels(lang: $lang) {
      id
      name
    }
    activities: availableActivities(lang: $lang) {
      id
      name
    }
    workingExperiences: availableWorkingExperiences(lang: $lang) {
      id
      name
    }
    institutes: availableInstitutes {
      id
      name
    }
    degrees: availableDegrees(lang: $lang) {
      id
      name
    }
    sexes: availableSex(lang: $lang) {
      id
      name
    }
    countries: availableCountries {
      name
    }
    languages: availableLanguages {
      id
      name
      is_primary
    }
    interests: availableInterests(lang: $lang) {
      id
      name
    }
  }
`;

export const ONBOARDING_DATA_MOBILE = gql`
  query ($lang: String!) {
    internationalExperiences: availableInternationalExperiences(lang: $lang) {
      id
      name
    }
    achievements: availableAchievements(lang: $lang) {
      id
      name
    }
    educationalGrades: availableEducationalGrades {
      name
    }
    educationLevels: availableEducationLevels(lang: $lang) {
      id
      name
    }
    activities: availableActivities(lang: $lang) {
      id
      name
    }
    workingExperiences: availableWorkingExperiences(lang: $lang) {
      id
      name
    }
    institutes: availableInstitutes {
      id
      name
    }
    degrees: availableDegrees(lang: $lang) {
      id
      name
    }
    sexes: availableSex(lang: $lang) {
      id
      name
    }
    countries: availableCountries {
      name
    }
    cities: availableCities {
      name
    }
    languages: availableLanguages {
      id
      name
      is_primary
    }
    interests: availableInterests(lang: $lang) {
      id
      name
    }
  }
`;

export const PROFILE_DATA_NEEDED = gql`
  query ($lang: String!) {
    sexes: availableSex(lang: $lang) {
      id
      name
    }
    countries: availableCountries {
      name
    }
    educationLevels: availableEducationLevels(lang: $lang) {
      id
      name
    }
    internationalExperiences: availableInternationalExperiences(lang: $lang) {
      id
      name
    }
    workExperiences: availableWorkingExperiences(lang: $lang) {
      id
      name
    }
    activities: availableActivities(lang: $lang) {
      id
      name
    }
    achievements: availableAchievements(lang: $lang) {
      id
      name
    }
    languages: availableLanguages {
      id
      name
      is_primary
    }
  }
`;

export const PROFILE_DATA = gql`
  query ($lang: String!) {
    profile: getProfile(lang: $lang) {
      id
      first_name
      last_name
      sex {
        id
        name
      }
      birthday
      country
      city
      persona_update_time
      contact_email
      short_bio
      resumeUrl
      education_level {
        id
        name
      }
      social_media {
        url
        type
      }
      institutes {
        id
        name
        subject_id
        subject_name
        degree_id
        degree_name
        graduation_date
        average_grades
        grade_proof
        manual_input
        archetype
        country_of_education
        minor
        archetype_override
      }
      interests {
        id
        name
      }
      work_experiences {
        id
        name
      }
      other_work_experiences
      achievements {
        id
        name
      }
      activities {
        id
        name
      }
      languages {
        id
        name
        is_primary
      }
      international_experiences {
        id
        name
      }
      other_international_experiences
      other_achievements
      other_languages
      other_activities
      image_url
      completeness {
        value
        fields_to_fill {
          name
          value
        }
      }
      is_board_member
      is_profile_created
      is_deleted
      is_deactivated
      is_active
      fcm_token
      referrer
      phone_number
      notifications {
        incoming_messages
        opportunities_or_events
        new_companies
        news_and_updates
      }
      isOnboarding
    }
  }
`;

export const AVATAR_DATA = gql`
  query ($lang: String!) {
    profile: getProfile(lang: $lang) {
      id
      image_url
    }
  }
`;

export const COMPANIES_DATA_AVAILABLES = gql`
  query ($lang: String!) {
    perksList: availableEmojis(lang: $lang) {
      id
      name
      value
    }
    industryList: availableSectors(lang: $lang) {
      id
      name
    }
    companySizeList: availableCompanySizes {
      id
      name
    }
  }
`;

export const COMPANIES = gql`
  query ($lang: String!, $filters: CompanyFilterInput!, $limit: Float, $page: Float) {
    companies(lang: $lang, filters: $filters, limit: $limit, page: $page) {
      page
      limit
      total
      result {
        id
        name
        logo
        bio
        cities
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        tags {
          id
          name
        }
        created
        is_liked
        is_bookmarked
        is_disliked
      }
    }
  }
`;

export const COMPANY = gql`
  query ($lang: String!, $id: String!) {
    company(lang: $lang, id: $id) {
      id
      name
      website
      logo
      background_photo
      banner_photo
      cities
      additional_info
      bio
      created
      company_size {
        id
        name
      }
      emoji {
        id
        name
        value
      }
      tags {
        id
        name
      }
      socials {
        type
        value
      }
      sectors {
        id
        name
      }
      is_liked
      is_bookmarked
      is_disliked
      deleted
    }
  }
`;

export const COMPANY_LIKED = gql`
  query ($lang: String!, $id: String!) {
    company(lang: $lang, id: $id) {
      id
      name
      logo
      bio
      cities
      company_size {
        id
        name
      }
      sectors {
        id
        name
      }
      tags {
        id
        name
      }
      is_liked
    }
  }
`;

export const OPPORTUNITIES_DATA_AVAILABLES = gql`
  query ($lang: String!) {
    typeList: availableOpportunityTypes(lang: $lang) {
      id
      name
    }
    degreeList: availableDegrees(lang: $lang, type: "opportunities") {
      id
      name
    }
    experienceList: availableOpportunityExperiences(lang: $lang) {
      id
      name
    }
    industryList: availableSectors(lang: $lang) {
      id
      name
    }
    companySizeList: availableCompanySizes {
      id
      name
    }
  }
`;

export const OPPORTUNITIES = gql`
  query ($lang: String!, $filters: OpportunityFilterInput!, $limit: Float, $page: Float) {
    opportunities(lang: $lang, filters: $filters, limit: $limit, page: $page) {
      page
      limit
      total
      result {
        id
        name
        company_id
        company_name
        cities
        city
        application_flow
        redirection_link
        banner_img
        promo_video
        main_description
        description
        types {
          id
          name
        }
        degrees {
          id
          name
        }
        experiences {
          id
          name
        }
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        logo
        is_liked
        is_bookmarked
        created
        updated
        application {
          redirectUrl
          message
        }
      }
    }
  }
`;

export const OPPORTUNITY = gql`
  query ($lang: String!, $id: String!) {
    opportunity(lang: $lang, id: $id) {
      id
      name
      company_id
      banner_img
      promo_video
      description
      application_flow
      redirection_link
      main_description
      types {
        id
        name
      }
      degrees {
        id
        name
      }
      experiences {
        id
        name
      }
      languages {
        id
        name
      }
      company_size {
        id
        name
      }
      languages {
        id
        name
      }
      sectors {
        id
        name
      }
      company_name
      cities
      logo
      is_liked
      is_bookmarked
      created
      updated
      deleted
      application {
        redirectUrl
        message
      }
    }
  }
`;

export const EVENT = gql`
  query ($lang: String!, $id: String!) {
    event(lang: $lang, id: $id) {
      id
      name
      company_id
      start_date
      end_date
      banner_img
      address
      application_flow
      redirection_link
      description
      company_name
      logo
      city
      types {
        id
        name
      }
      main_description
      is_liked
      is_bookmarked
      created
      updated
      deleted
      application {
        redirectUrl
        message
      }
    }
  }
`;

export const EVENTS_DATA_AVAILABLES = gql`
  query ($lang: String!) {
    typeList: availableEventTypes(lang: $lang) {
      id
      name
    }
  }
`;

export const EVENTS = gql`
  query ($lang: String!, $filters: EventFilterInput!, $limit: Float, $page: Float) {
    events(lang: $lang, filters: $filters, limit: $limit, page: $page) {
      page
      limit
      total
      result {
        id
        name
        company_id
        start_date
        end_date
        application_flow
        redirection_link
        company_name
        address
        logo
        banner_img
        main_description
        description
        types {
          id
          name
        }
        is_liked
        is_bookmarked
        created
        updated
        application {
          redirectUrl
          message
        }
      }
    }
  }
`;

export const LIKES = gql`
  query ($lang: String, $type: String, $search: String, $limit: Float, $page: Float, $order: String, $orderDirection: String) {
    likes: getProfileLikes(lang: $lang, search: $search, type: $type, limit: $limit, page: $page, order: $order, orderDirection: $orderDirection) {
      page
      limit
      total
      result {
        id
        name
        website
        logo
        city
        application_flow
        redirection_link

        additional_info
        bio
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        created
        banner_img
        description
        types {
          id
          name
        }
        degrees {
          id
          name
        }
        experiences {
          id
          name
        }
        languages {
          id
          name
        }
        company_name
        main_description
        start_date
        end_date
        address
        like_id
        like_type
        entity_id
        account_id
        like_created

        application {
          redirectUrl
          message
        }
      }
    }
  }
`;

export const BOOKMARKS = gql`
  query ($lang: String, $type: String, $search: String, $limit: Float, $page: Float, $order: String, $orderDirection: String) {
    bookmarks: getProfileBookmarks(lang: $lang, search: $search, type: $type, limit: $limit, page: $page, order: $order, orderDirection: $orderDirection) {
      page
      limit
      total
      result {
        id
        name
        website
        logo
        city
        additional_info
        bio
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        created
        banner_img
        description
        types {
          id
          name
        }
        degrees {
          id
          name
        }
        experiences {
          id
          name
        }
        languages {
          id
          name
        }
        company_name
        main_description
        start_date
        end_date
        address

        bookmark_id
        bookmark_type
        entity_id
        account_id
        bookmark_created
      }
    }
  }
`;

export const DISLIKES = gql`
  query ($lang: String, $search: String, $limit: Float, $page: Float, $order: String, $orderDirection: String) {
    dislikes: getProfileDislikes(lang: $lang, search: $search, limit: $limit, page: $page, order: $order, orderDirection: $orderDirection) {
      page
      limit
      total
      result {
        id
        name
        company_name
        website
        logo
        city
        additional_info
        bio
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        created
        dislike_id
        dislike_type
        entity_id
        account_id
        dislike_created
      }
    }
  }
`;

export const PRIVACY_POLICY = gql`
  query ($lang: String!) {
    privatePolicy(lang: $lang)
  }
`;

export const TERMS_OF_USE = gql`
  query ($lang: String!) {
    termsOfUse(lang: $lang)
  }
`;

export const UNREAD_MEMOS = gql`
  query ($lang: String!) {
    unreadMemos(lang: $lang) {
      message
    }
  }
`;

export const FEED = gql`
  query ($lang: String!, $limit: Float!, $page: Float!) {
    feed: exploreFeed(lang: $lang, page: $page, limit: $limit) {
      page
      limit
      is_last
      result {
        id
        name
        website
        logo
        cities
        city
        application_flow
        redirection_link
        additional_info
        bio
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        created
        banner_photo
        banner_img
        description
        types {
          id
          name
        }
        degrees {
          id
          name
        }
        experiences {
          id
          name
        }
        languages {
          id
          name
        }
        company_name
        main_description
        start_date
        end_date
        is_liked
        is_bookmarked
        is_disliked
        address
        type
        background_photo
        feedScore
        application {
          redirectUrl
          message
        }
      }
    }
  }
`;

export const GLOBAL_SEARCH = gql`
  query ($lang: String!, $limit: Float, $page: Float, $filters: GlobalFilterInput!) {
    globalSearch(lang: $lang, page: $page, limit: $limit, filters: $filters) {
      page
      limit
      is_last
      result {
        id
        name
        website
        logo
        cities
        city
        application_flow
        redirection_link
        additional_info
        bio
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        created
        banner_photo
        banner_img
        description
        types {
          id
          name
        }
        degrees {
          id
          name
        }
        experiences {
          id
          name
        }
        languages {
          id
          name
        }
        company_name
        main_description
        start_date
        end_date
        is_liked
        is_bookmarked
        is_disliked
        address
        type
        background_photo
        application {
          redirectUrl
          message
        }
      }
    }
  }
`;

export const MESSAGES = gql`
  query ($limit: Float, $page: Float, $filters: MessageFilterInput!) {
    messages(limit: $limit, page: $page, filters: $filters) {
      page
      limit
      total
      inbox_count
      result {
        id
        name
        company_name
        logo
        created
        banner_img
        message_type
        entity_id
        account_id
        message_created
        text
        is_accepted
        is_archived
        is_automated
      }
      filters {
        is_archived
      }
    }
  }
`;

export const MESSAGE = gql`
  query ($id: String!) {
    message(id: $id) {
      id
      name
      company_name
      logo
      created
      banner_img
      message_type
      entity_id
      account_id
      message_created
      text
      is_accepted
      is_archived
      is_automated
    }
  }
`;

export const UNREAD_MESSAGES = gql`
  query ($limit: Float, $page: Float, $filters: MessageFilterInput!) {
    messages(limit: $limit, page: $page, filters: $filters) {
      inbox_count
    }
  }
`;

export const TYPED_FEED = gql`
  query ($page: Float, $limit: Float, $lang: String, $type: String!) {
    typedFeed(page: $page, limit: $limit, lang: $lang, type: $type) {
      result {
        id
        name
        company_name
        website
        logo
        city
        cities
        additional_info
        bio
        company_size {
          name
        }
        sectors {
          name
        }
        created
        banner_img
        description
        types {
          name
        }
        degrees {
          name
        }
        experiences {
          name
        }
        languages {
          name
        }
        main_description
        start_date
        end_date
        address
        type
        is_liked
        is_bookmarked
        is_disliked
        background_photo
        banner_photo
        relevance
        application_flow
        redirection_link
        feedScore
        feedMultipliers
        application {
          message
        }
      }
      page
      limit
      is_last
    }
  }
`;

export const ONBOARDING_COMPANY_LIST = gql`
  query ($page: Float, $limit: Float, $lang: String, $archetypeId: String!, $personaId: String!, $searchText: String) {
    onboardingCompanyList(page: $page, limit: $limit, lang: $lang, archetypeId: $archetypeId, personaId: $personaId, searchText: $searchText) {
      result {
        id
        name
        company_name
        website
        logo
        city
        cities
        additional_info
        bio
        company_size {
          id
          name
        }
        sectors {
          id
          name
        }
        created
        banner_img
        description
        types {
          id
          name
        }
        degrees {
          id
          name
        }
        experiences {
          id
          name
        }
        languages {
          id
          name
        }
        main_description
        start_date
        end_date
        address
        type
        is_liked
        is_bookmarked
        is_disliked
        background_photo
        banner_photo
        relevance
        application_flow
        redirection_link
        feedScore
        feedMultipliers
        application {
          redirectUrl
          message
        }
      }
      page
      limit
      is_last
    }
  }
`;
