import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      position: 'relative',
      width: '100%',
      maxWidth: '34.5rem',
      height: 54,
    },
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 54,
      backgroundColor: 'white',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      transition: '0.25s all ease',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    contentWithOptions: {
      maxHeight: 216,
      height: 'auto',
      zIndex: 999,
    },
    field: {
      display: 'flex',
      width: '100%',
      height: '52px',
      flexShrink: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 15px',
    },
    value: {
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
      width: '100%',
      height: '100%',
      fontFamily: 'Inter',
      color: '#222222',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      transition: '0.25s all ease',
      '&::placeholder': {
        color: '#222222',
        opacity: 0.3,
      },
      '&:focus, &:active': {
        color: theme.$primaryColor2,
        opacity: 1,
      },
    },
    valueOpened: {
      opacity: 1,
      color: theme.$primaryColor2,
      backgroundColor: 'transparent',
    },
    arrow: {},
    options: {
      position: 'relative',
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '0 15px',
      backgroundColor: 'white',
      zIndex: 1,
    },
    option: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      color: '#282828',
      height: 54,
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
      cursor: 'pointer',
    },
    optionActive: {
      color: theme.$primaryColor1,
    },
  };
});
