import React, { FC, useEffect } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import { IOptionItem } from 'src/requests/models';
import Divider from 'src/components/dividers/Divider/index.web';
import SkipPortal from '../SkipPortal';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { OnboardingData } from '../../../../requests/models';
import { selectInterest } from '../../../../state/modules/onboarding';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IProps {
  isMobile: boolean;
  children?: any;
  interestList: IOptionItem[];
  ref: any;
  next: any;
  onNext(next: string): void;
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding7: FC<IProps> = React.forwardRef((props, ref: any) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { interestList, next, onNext, setData, setPage, setIsNextDisabled } = props;
  const interest = useSelector((state) => selectInterest(state));
  const { t } = useTranslation();

  const onSelectInterest = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setData({ interest: interest.concat([item]) });
    } else if (action === 'rem') {
      setData({ interest: interest.filter((i: IOptionItem) => i.name !== item.name).slice() });
    }
  };

  const onSkipPress = async () => {
    await setData({ interest: undefined });
    await onNext(next);
  };

  useEffect(() => {
    setPage('7');
    setIsNextDisabled(false);
  }, []);

  return (
    <>
      <SkipPortal current={ref.current} onSkipPress={onSkipPress} />
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Your professional interests')}
        </Title>
        <Text type={'default'} text={`${t('Which fields and specialties are you interested in?')}`} />
        <div className={styles.interests}>
          {interestList.map((interestItem: IOptionItem) => {
            const active = !!interest.find((a: IOptionItem) => a.name === interestItem.name);
            return (
              <ButtonToggle
                key={interestItem.name}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.interest}
                text={interestItem.name}
                onPress={() => onSelectInterest(interestItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
      </div>
    </>
  );
});

export default Onboarding7;
