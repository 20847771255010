import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: 5,
      alignItems: 'center',
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      zIndex: 2,
    },
    svg: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
    counter: {
      position: 'absolute',
      top: -5,
      left: '50%',
      zIndex: 1,
    },
  };
});
