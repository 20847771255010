import React, { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';
import Config from 'react-native-config';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, InMemoryCache, defaultDataIdFromObject, from } from '@apollo/client';
import { SentryLink } from 'apollo-link-sentry';
import { useDispatch } from 'react-redux';
import { onError } from '@apollo/client/link/error';
import { setErrorMessage } from '../../state/modules/error';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const GraphQLError = (errorData: any) => {
  Sentry.captureException(errorData);
};

export const useApolloClient = (token: string | undefined | null) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const uploadLink = useCallback(() => {
    return createUploadLink({
      uri: Platform.OS === 'web' ? process.env.REACT_APP_GRAPHQL : Config.REACT_APP_GRAPHQL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }, [token]);

  let isAuthError = false;
  const errorLink = onError((error) => {
    if (error.graphQLErrors) {
      error.graphQLErrors.map((errorData) => {
        const { message, locations, path } = errorData;
        // workaround to check the access token expiration since we receive 500 from the backend
        if (message === 'jwt expired') {
          isAuthError = true;
          return dispatch(setErrorMessage({ message }, 'AuthError'));
        }
        dispatch(setErrorMessage({ message }, 'GraphQLError'));
        GraphQLError(errorData);
        console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
      });
    }
    if (error.networkError && !isAuthError) {
      dispatch(setErrorMessage(error.networkError, 'NetworkError'));
      console.log(`[Network error]: ${error.networkError}`);
    }
    isAuthError = false;
  });

  const cache = useMemo(
    () =>
      new InMemoryCache({
        dataIdFromObject(responseObject) {
          switch (responseObject.__typename) {
            case 'Account':
              return `Account:${responseObject.id}:${responseObject.username}:${responseObject.created}`;
            case 'AccountInput':
              return `AccountInput:${responseObject.username}`;
            case 'Companies':
              return 'Companies';
            case 'Company':
              return `Company:${responseObject.id}`;
            case 'Completeness':
              return 'Completeness';
            case 'Event':
              return `Event:${responseObject.id}`;
            case 'Events':
              return `Events:${responseObject.total}:${responseObject.page}`;
            case 'FieldToFill':
              return `FieldToFill:${responseObject.name}:${responseObject.value}`;
            case 'GeneralType':
              return `GeneralType:${responseObject.id}:${responseObject.name}`;
            case 'Institute':
              return `Institute:${responseObject.id}:${responseObject.subject_id}:${responseObject.degree_id}`;
            case 'InstituteInput':
              return `InstituteInput:${responseObject.id}:${responseObject.subject}`;
            case 'InternationalExperience':
              return `InternationalExperience:${responseObject.id}:${responseObject.name}`;
            case 'Language':
              return `Language:${responseObject.id}`;
            case 'Like':
              return `Like:${responseObject.id}:${responseObject.like_type}`;
            case 'Dislike':
              return `Dislike:${responseObject.id}:${responseObject.dislike_type}`;
            case 'Bookmark':
              return `Bookmark:${responseObject.id}:${responseObject.bookmark_type}`;
            case 'LikeInput':
              return `LikeInput:${responseObject.id}`;
            case 'Likes':
              return 'Likes';
            case 'Bookmarks':
              return 'Bookmarks';
            case 'Dislikes':
              return 'Dislikes';
            case 'Opportunities':
              return `Opportunities:${responseObject.total}:${responseObject.page}`;
            case 'Opportunity':
              return `Opportunity:${responseObject.id}`;
            case 'Profile':
              return `Profile:${responseObject.id}`;
            case 'SocialMedia':
              return `SocialMedia:${responseObject.type}:${responseObject.url}`;
            case 'SocialMediaInput':
              return `SocialMediaInput:${responseObject.type}:${responseObject.url}`;
            case 'SocialsType':
              return `SocialsType:${responseObject.type}:${responseObject.value}`;
            case 'UpdatedLike':
              return `UpdatedLike:${responseObject.id}:${responseObject.name}:${responseObject.is_liked}`;
            case 'ValueType':
              return `ValueType:${responseObject.id}:${responseObject.name}`;
            case 'FeedElementType':
              return `FeedElementType:${responseObject.id}:${responseObject.name}`;
            case 'Messages':
              //@ts-ignore
              return `Messages:${responseObject.filters.is_archived}`;
            case 'MessageFilter':
              return `MessageFilter:${responseObject.is_archived}`;
            case 'Message':
              return `Message:${responseObject.id}:${responseObject.name}`;
            case 'FeedType':
              return `FeedType:${responseObject.is_last}:${responseObject.page}`;
            case 'ExtendedFeedType':
              return `ExtendedFeedType:${responseObject?.result?.[0]?.type}:${responseObject.is_last}:${responseObject.page}`;
            case 'OnboardingFeedType':
              return `OnboardingFeedType:${responseObject.is_last}:${responseObject.page}`;
            default:
              return defaultDataIdFromObject(responseObject);
          }
        },
        typePolicies: {
          Profile: {
            fields: {
              interests: {
                read(interests) {
                  return interests === null ? [] : interests;
                },
              },
              short_bio: {
                read(short_bio) {
                  return short_bio === null ? '' : short_bio;
                },
              },
              institutes: {
                read(institutes) {
                  return institutes === null ? [] : institutes;
                },
              },
              activities: {
                read(activities) {
                  return activities === null ? [] : activities;
                },
              },
              achievements: {
                read(achievements) {
                  return achievements === null ? [] : achievements;
                },
              },
              social_media: {
                read(social_media) {
                  return social_media === null ? [] : social_media;
                },
              },
              languages: {
                read(languages) {
                  return languages === null ? [] : languages;
                },
              },
              international_experiences: {
                read(international_experiences) {
                  return international_experiences === null ? [] : international_experiences;
                },
              },
              work_experiences: {
                read(work_experiences) {
                  return work_experiences === null ? [] : work_experiences;
                },
              },
            },
          },
        },
      }),
    [token, i18n.language],
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([errorLink, uploadLink(), new SentryLink()]),
        cache,
        queryDeduplication: false,
      }),
    [token, cache],
  );

  return client;
};
