import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from 'src/web/components/Header';
import CompanyList from 'src/web/components/Company/List';
import OpportunityList from 'src/web/components/Opportunity/List';
import EventList from 'src/web/components/Event/List';
import ExploreList from 'src/web/components/Explore/List';
import CompanyFilter from 'src/web/components/Company/Filter';
import EventsFilter from 'src/web/components/Event/Filter';
import OpportunityFilter from 'src/web/components/Opportunity/Filter';
import styles from './styles.module.scss';
import { IProps } from './types';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { Context as CompaniesSearchContext } from 'src/contexts/SearchContext/CompaniesSearchContext';
import { Context as OpportunitySearchContext } from 'src/contexts/SearchContext/OpportunitySearchContext';
import { Context as EventSearchContext } from 'src/contexts/SearchContext/EventSearchContext';
import { Context as GlobalSearchContext } from 'src/contexts/SearchContext/GlobalSearchContext';
import { Context as ExploreContext } from 'src/contexts/ExploreContext';
import { Context as LikesContext } from 'src/contexts/LikesContext/index.web';
import { IEvent } from 'src/components/cards/Event/types';

import _ from 'lodash';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import GlobalFilter from 'src/web/components/Explore/Filter';
import { ApplyModal } from 'src/web/components/ApplyModal';
import { IOpportunity } from 'src/components/cards/Opportunity/types';
import { addSubscription } from 'src/state/modules/ui';
import { useDispatch } from 'react-redux';

let flag = false;
const entities = ['companies', 'opportunities', 'events', 'everything'];

const Search: FC<IProps> = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const qEntity = query.get('entity');
  const { entity, isMobile } = props;
  const { setEntityByName, applyModalShown, setApplyModalShown, setEntityForApply, entityForApply }: any = useContext(SearchContext);
  const { result: cResult, setSearchNext: cSetSearchNext, setSearchStart: cSetSearchStart }: any = useContext(CompaniesSearchContext);
  const { result: oResult, setSearchNext: oSetSearchNext, setSearchStart: oSetSearchStart }: any = useContext(OpportunitySearchContext);
  const { result: eResult, setSearchNext: eSetSearchNext, setSearchStart: eSetSearchStart }: any = useContext(EventSearchContext);
  const { setSearchNext: gSetSearchNext, setSearchStart: gSetSearchStart, result: gResult }: any = useContext(GlobalSearchContext);
  const {
    toggleCompanyLike,
    toggleOpportunityLike,
    toggleEventLike,
    toggleCompanyBookmark,
    toggleOpportunityBookmark,
    toggleEventBookmark,
    toggleCompanyDislike,
  }: any = useContext(ExploreContext);
  const { toggleLike: toggleEntityLike }: any = useContext(LikesContext);
  const dispatch = useDispatch();
  const containRef = useRef(window);
  const filterRef = useRef<any>(null);
  const [clear, setClear] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [filterY, setFilterY] = useState(0);
  const toggleLike = (entity: IEvent | IOpportunity) => {
    if (entity.application?.redirectUrl) {
      window.open(entity.application?.redirectUrl);
    } else {
      dispatch(
        addSubscription({ id: '' + entity.id, name: entity.name, type: entity.__typename === 'Opportunity' || entity.type === 'opportunity' ? 'o' : 'e' }),
      );
    }
    toggleEntityLike(entity.id, true, entity.__typename === 'Opportunity' || entity.type === 'opportunity' ? 'o' : 'e');
  };

  const onScroll = useCallback(
    (e: any) => {
      e.stopPropagation();
      const { scrollTop: cSTop, scrollHeight: cSHeight } = document.documentElement;
      if (isMobile) {
        setFilterY(0);
      } else {
        setPrevScrollTop(cSTop);
        if (cSTop >= 0) {
          if (window.innerHeight + cSTop - 76 - 120 - (filterRef.current?.offsetHeight + filterY) > 0) {
            setFilterY(window.innerHeight + cSTop - 76 - 120 - filterRef.current?.offsetHeight);
          } else if (filterY > cSTop) {
            setFilterY(filterY - (filterY - cSTop));
          }
        }
      }
      if (!flag && cSTop + window.innerHeight >= cSHeight - 10) {
        flag = true;
        setTimeout(() => {
          flag = false;
        }, 100);
        if (entity === 'companies') {
          cSetSearchNext();
        } else if (entity === 'opportunities') {
          oSetSearchNext();
        } else if (entity === 'events') {
          eSetSearchNext();
        } else if (entity === 'everything') {
          gSetSearchNext();
        }
      }
    },
    [cResult, oResult, eResult, gResult, prevScrollTop, entity],
  );

  const clearScroll = () => {
    containRef.current.scrollTo(0, 0);
    setFilterY(0);
    setClear(true);
  };

  const handleCLike = ({ id, type }: any, is_liked: boolean) => toggleCompanyLike(id, is_liked, type[0]);
  const handleEOLike = ({ id, application, type }: any, is_liked: boolean) => {
    if (application.redirectUrl) {
      window.open(application.redirectUr);
    }

    if (type === 'opportunity') {
      toggleOpportunityLike(id, is_liked, type[0]);
    } else if (type === 'event') {
      toggleEventLike(id, is_liked, type[0]);
    }
  };

  const renderCompanies = useMemo(() => <CompanyList containerStyle={styles.list} isMobile={isMobile} />, [isMobile, filterModal]);

  const renderOpportunities = useMemo(() => <OpportunityList containerStyle={styles.list} isMobile={isMobile} />, [isMobile, filterModal]);

  const renderEvents = useMemo(() => <EventList containerStyle={styles.list} isMobile={isMobile} />, [isMobile, filterModal]);

  const renderEverything = useMemo(
    () => (
      <ExploreList
        isGlobal
        isMobile={isMobile}
        toggleCompanyLike={handleCLike}
        toggleOpportunityLike={handleEOLike}
        toggleEventLike={handleEOLike}
        toggleCompanyBookmark={toggleCompanyBookmark}
        toggleOpportunityBookmark={toggleOpportunityBookmark}
        toggleEventBookmark={toggleEventBookmark}
        toggleCompanyDislike={toggleCompanyDislike}
        result={gResult}
      />
    ),
    [isMobile, filterModal, gResult],
  );

  const renderCompaniesFilter = useMemo(
    () => <CompanyFilter containerStyle={styles.filter} isMobile={isMobile} isModalOpen={filterModal} toggleModal={setFilterModal} clearScroll={clearScroll} />,
    [isMobile, filterModal],
  );

  const renderOpportunitiesFilter = useMemo(
    () => (
      <OpportunityFilter
        containerStyle={styles.filter}
        isMobile={isMobile}
        isModalOpen={filterModal}
        toggleModal={setFilterModal}
        filter={{}}
        setFilter={() => {}}
        clearScroll={clearScroll}
      />
    ),
    [isMobile, filterModal],
  );

  const renderEventFilter = useMemo(
    () => <EventsFilter containerStyle={styles.filter} isMobile={isMobile} isModalOpen={filterModal} toggleModal={setFilterModal} clearScroll={clearScroll} />,
    [isMobile, filterModal],
  );

  const renderEverythingFilter = useMemo(
    () => <GlobalFilter containerStyle={styles.filter} isMobile={isMobile} clearScroll={clearScroll} isModalOpen={filterModal} toggleModal={setFilterModal} />,
    [isMobile, filterModal],
  );

  const debouncedListener = _.debounce(onScroll, 100);
  useEffect(() => {
    if (containRef && containRef.current) {
      containRef.current.addEventListener('scroll', debouncedListener);
    }

    return () => {
      if (containRef && containRef.current) {
        containRef.current.removeEventListener('scroll', debouncedListener);
      }
    };
  }, [onScroll, containRef]);

  useEffect(() => {
    if (entity) {
      clearScroll();
    }
  }, [entity]);
  useEffect(() => {
    if (qEntity && entities.includes(qEntity)) {
      setEntityByName(qEntity);
      if (qEntity === 'companies') {
        cSetSearchStart();
      } else if (qEntity === 'opportunities') {
        oSetSearchStart();
      } else if (qEntity === 'events') {
        eSetSearchStart();
      }
    }
  }, [qEntity]);

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear]);

  useEffect(() => {
    if (!qEntity) {
      if (entity === 'companies') {
        cSetSearchStart();
      } else if (entity === 'opportunities') {
        oSetSearchStart();
      } else if (entity === 'events') {
        console.log('entity', entity);
        eSetSearchStart();
      } else if (entity === 'everything') {
        gSetSearchStart();
      }
    }
  }, [qEntity]);
  return (
    <>
      <Helmet>
        <title>{'Search'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          <div className={styles.main}>
            <div style={{ width: isMobile ? 350 : 370, flexShrink: 0 }}>
              <div ref={filterRef} style={{ position: 'relative', top: 0 }}>
                {entity === 'companies' && renderCompaniesFilter}
                {entity === 'opportunities' && renderOpportunitiesFilter}
                {entity === 'events' && renderEventFilter}
                {entity === 'everything' && renderEverythingFilter}
              </div>
            </div>
            {entity === 'companies' && renderCompanies}
            {entity === 'opportunities' && renderOpportunities}
            {entity === 'events' && renderEvents}
            {entity === 'everything' && renderEverything}
            {applyModalShown && (
              <ApplyModal
                entity={entityForApply}
                cancel={() => {
                  setApplyModalShown(false);
                  setEntityForApply({});
                }}
                confirm={() => {
                  toggleLike(entityForApply);
                  setApplyModalShown(false);
                  setEntityForApply({});
                }}
                text={entityForApply?.application?.message ? entityForApply?.application?.message : ''}
              />
            )}
          </div>
        </div>
      </ErrorBoundaries>
    </>
  );
};

export default Search;
