import React, { useEffect } from 'react';
import JobPage from '../containers/OpportunityPage/index';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { initGA } from '../../utilities';

const Container = () => {
  return (
    <Page>
      {({ isMobile }: IPassProps) => {
        return <JobPage isMobile={isMobile} />;
      }}
    </Page>
  );
};

export default Container;
