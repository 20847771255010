import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: (p: IProps) => ({
      maxWidth: p.isMobile ? 512 : p.language === 'nl' ? 350 : 350,
      borderRadius: 27,
    }),
    componentContent: {
      padding: '0 5px',
      borderRadius: 27,
    },
    activeComponentContent: {
      padding: '0 5px',
      height: 'auto',
      borderRadius: 27,
    },
    option: {},
    options: {
      overflow: 'hidden',
    },
    optionText: {
      fontSize: 18,
      flexGrow: 1,
      padding: 10,
      marginLeft: 10,
      lineBreak: 'anywhere',
      color: theme.$textColor,
    },
  };
});
