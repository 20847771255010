import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import Icon from 'src/components/icons/Icon/index.web';
import Link from 'src/components/links/Link/index.web';
import Text from 'src/components/texts/Text/index.web';
import { ITheme } from '../../../constant/themes';
import { Props } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  text?: string;
}

const ButtonTransparentToggle: FC<Props> = (props) => {
  const theme: ITheme = useTheme();
  const { href, text, containerStyle, onHover, onPress, onBlur, internal } = props;
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    text: `${styles.text}`.trim(),
  };

  return (
    <Link internal={internal} href={href} containerStyle={cn.component} size={'sm'} onPress={onPress} onHover={onHover} onBlur={onBlur}>
      <Text type={'button'} containerStyle={cn.text} text={text} inline />
      <Icon size={10} name={'arrow-down'} color={theme.$primaryColor1} />
    </Link>
  );
};

export default ButtonTransparentToggle;
