import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { MESSAGES } from '../../requests/queries';
import { SET_ARCHIVE_MESSAGE, UPDATE_MESSAGE_STATUS } from '../../requests/mutations';
import { IMessage } from '../../requests/models';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { selectIsReady } from '../../state/modules/session';
import { Platform } from 'react-native';

interface IProps {
  children: any;
}

interface IMessageContext {
  loading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  chosenConversation: any;
  setChosenConversation: any;
  acceptMessage: (id: string) => void;
  rejectMessage: (id: string) => void;
  archiveMessage: (id: string, is_archived: boolean) => void;
  setSearch: (value?: any) => void;
  setSearchNext: () => void;
  result: any;
}

const Context = createContext({} as IMessageContext);
Context.displayName = '_MESSAGES_';

const Provider = (props: IProps) => {
  const { children } = props;

  const isReady = useSelector((state) => selectIsReady(state));
  const [tab, setTab] = useState('0');
  const [searchValue, setSearchValue] = useState('');
  const [inboxCount, setInboxCount] = useState(0);
  const [chosenConversation, setChosenConversation] = useState(undefined);
  const [filterSearch, setFilterSearch] = useState('');

  const [
    getMessages,
    {
      data = {
        messages: {
          result: [],
          page: 1,
          limit: 10,
          total: 0,
          inbox_count: 0,
        },
      },
      loading,
      refetch,
      fetchMore,
      called,
    },
  ] = useLazyQuery(MESSAGES, {
    variables: {
      filters: {
        name: filterSearch,
        is_archived: tab === '1',
      },
      limit: 10,
      page: 1,
    },
    onCompleted: ({ messages }) => {
      if (tab !== '1') {
        setInboxCount(messages.inbox_count);
      }
    },
  });

  const { messages } = data;
  const { inbox_count } = messages;

  const [setMessageStatus] = useMutation(UPDATE_MESSAGE_STATUS, {
    update(cache, { data: { updateMessageStatus } }) {
      const { is_accepted } = updateMessageStatus;
      // console.log(cache.identify(updateMessageStatus));
      cache.modify({
        id: cache.identify(updateMessageStatus),
        fields: {
          is_accepted: (cached) => {
            return is_accepted;
          },
        },
      });

      setInboxCount(inboxCount - 1);
      // cache.modify({
      //   id: cache.identify(messages),
      //   fields: {
      //     inbox_count: (cached) => {
      //       return inbox_count - 1;
      //     },
      //   },
      // });
    },
  });

  const [setArchiveStatus] = useMutation(SET_ARCHIVE_MESSAGE, {
    update(cache, { data: { archiveMessage } }) {
      const { is_archived, id } = archiveMessage;
      cache.modify({
        id: cache.identify(data.messages),
        fields: {
          result: (cached) => {
            return cached.filter((item: any) => item.__ref !== cache.identify(archiveMessage));
          },
        },
      });
    },
  });

  const acceptMessage = (id: string) => setMessageStatus({ variables: { id: id + '', is_accepted: true } });

  const rejectMessage = (id: string) => setMessageStatus({ variables: { id: id + '', is_accepted: false } });

  const archiveMessage = async (id: string, is_archived: boolean) => await setArchiveStatus({ variables: { id: id + '', is_archived } });

  const setSearch = useCallback(async () => {
    if (called) {
      try {
        //@ts-ignore
        const result = await refetch();
        const { messages } = result.data;
        if (tab !== '1') {
          setInboxCount(messages.inbox_count);
        }
      } catch (e) {
        console.dir(e);
      }
    }
  }, [called]);

  const setSearchNext = async () => {
    if (!loading && data.messages && data.messages.page < Math.ceil(data.messages.total / data.messages.limit)) {
      if (called) {
        //@ts-ignore
        await fetchMore({
          variables: {
            limit: 10,
            page: data.messages.page + 1,
          },
          //@ts-ignore
          updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
            if (!fetchMoreResult || !fetchMoreResult.messages || !fetchMoreResult.messages.result.length) {
              return prev;
            }

            return Object.assign({}, prev, {
              messages: {
                ...fetchMoreResult.messages,
                result: [...prev.messages.result, ...fetchMoreResult.messages.result],
              },
            });
          },
        });
      }
    }
  };

  const onSetTab = (value: string) => {
    setTab(value);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setFilterSearch(searchValue);
      setSearch();
    }, 500);
    return (): void => {
      clearTimeout(debounce);
    };
  }, [searchValue]);

  useEffect(() => {
    if ((Platform.OS === 'web' && isReady) || Platform.OS !== 'web') {
      getMessages();
    }
  }, [isReady]);

  return (
    <Context.Provider
      value={{
        loading,
        searchValue,
        setSearchValue,
        chosenConversation: useMemo(() => data.messages.result.find((item: any) => item.id == chosenConversation), [loading, data, chosenConversation]),
        setChosenConversation,
        acceptMessage,
        rejectMessage,
        archiveMessage,
        //@ts-ignore
        tab,
        onSetTab,
        setSearch,
        setSearchNext,
        result: data.messages.result,
        inboxCount,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
