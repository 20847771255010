import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    display: 'flex',
    flexDirection: 'row',
    'justify-content': 'flex-start',
    alignItems: 'center',
    margin: '1rem 0',
    color: theme.$primaryColor2,
    'font-weight': '500',
  },
  h1: {
    'font-size': '2.1rem',
    'line-height': '2.9rem',
  },
  h2: {
    'font-size': '1.8rem',
    'line-height': '2.5rem',
  },
  h3: {
    'font-size': '1.4rem',
    'line-height': '1.9rem',
  },
}));
