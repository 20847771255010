import React, { FC, useMemo } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './styles.web';

import { Props } from './types';

interface ComponentStyles {
  component?: string;
}

const Text: FC<Props> = (props) => {
  const { type = 'default', text, style = {}, children, inline, containerStyle, onPress = () => {} } = props;
  const theme = useTheme();
  const styles = useStyles({ ...theme, type });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
  };

  const renderContent = (): React.ReactChild => {
    return <>{children ? children : text}</>;
  };

  return inline ? (
    <span className={cn.component} onClick={onPress} style={style}>
      {renderContent()}
    </span>
  ) : (
    <p className={cn.component} onClick={onPress} style={style}>
      {renderContent()}
    </p>
  );
};

export default Text;
