import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
    },
    container: (p: IProps) => ({
      width: '100%',
      maxWidth: 512,
      margin: '0 auto',
      padding: p.isMobile ? '0 15px' : 0,
    }),
    head: (p: IProps) => ({
      background: p.isMobile ? 'transparent' : 'white',
      width: '100%',
      height: p.isMobile ? 'auto' : 172,
      paddingTop: p.isMobile ? 15 : 55,
    }),
    headTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    mainTitle: {
      fontSize: 32,
      lineHeight: '32px',
    },
    tabs: (p: IProps) => ({
      justifyContent: p.isMobile ? 'center' : 'flex-start',
      marginTop: p.isMobile ? 10 : 26,
    }),
    tab: (p: IProps) => ({
      padding: p.isMobile ? '4px 6px' : '4px 10px',
      borderBottom: p.isMobile ? 'default' : 'none',
      margin: p.isMobile ? 0 : '0 5px',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
      '& *': {
        letterSpacing: 0,
      },
    }),
    body: (p: IProps) => ({
      paddingTop: p.isMobile ? 15 : 30,
    }),
    search: (p: IProps) => ({
      background: 'white',
      maxWidth: p.isMobile ? ['100%', '!important'] : ['50%', '!important'],
    }),
    filters: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    filtersLabel: {
      margin: '0 2px',
      color: '#656771',
    },
    filtersValue: {
      margin: '0 5px',
      cursor: 'pointer',
      fontWeight: 500,
      color: theme.$secondaryColor1,
    },
    filtersValueActive: {
      color: theme.$primaryColor2,
    },
    order: {
      display: 'flex',
      alignItems: 'center',
    },
    orderDirection: {
      display: 'flex',
      alignItems: 'center',
    },
    account: (p: IProps) => ({
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 15,
      padding: p.isMobile ? '15px 0' : '30px 0',
    }),
    accountTitle: (p: IProps) => ({
      padding: p.isMobile ? '0 15px' : '0 30px',
    }),
    deactivateTitle: {},
    deactivate: (p: IProps) => ({
      padding: p.isMobile ? '0 15px' : '0 30px',
    }),
    deactivateButtons: {},
    deactivateButton: {
      maxWidth: 'none',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    deletedString: {
      color: 'red',
      textAlign: 'center',
    },
  };
});
