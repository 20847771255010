import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Terms from 'src/web/containers/TermsPage';
import Privacy from 'src/web/containers/PrivacyPage';
import { selectModalPrivacy, selectModalTerms, setModal } from '../../../state/modules/ui';
import Modal from '../Modal/Modal';
import styles from './styles.module.scss';

const Modals = () => {
  const dispatch = useDispatch();
  const terms = useSelector((state) => selectModalTerms(state));
  const privacy = useSelector((state) => selectModalPrivacy(state));

  const onClose = () => {
    dispatch(setModal({}));
  };

  return (
    <Modal isModalOpened={terms || privacy} onClose={onClose} contentStyles={styles.content}>
      <div className={styles.close} onClick={onClose} />
      {terms && <Terms />}
      {privacy && <Privacy />}
    </Modal>
  );
};

export default Modals;
