import { createSelector } from 'reselect';

import { State } from '..';

export const getSessionState = (state: State) => state.session;

export const selectIsAuthenticated = createSelector([getSessionState], (sessionState) => sessionState.isAuthenticated);

export const selectIsReady = createSelector([getSessionState], (sessionState) => sessionState.ready);

export const selectToken = createSelector([getSessionState], (sessionState) => sessionState.token);

export const selectTries = createSelector([getSessionState], (sessionState) => sessionState.tries);
