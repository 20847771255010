import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Main from './Main';
import Completeness from './Completeness';
import Education from './Education';
import InternationalExperience from './InternationalExperience';
import WorkExperience from './WorkExperience';
import Languages from './Languages';
import Activities from './Activities';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import { changeGradeProof, changeProfileCV } from 'src/state/modules/user';
import { IUniversity } from './Education/types';

const View = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, setPage, onEditLang, onEditEducation, onEditActivities, onEditExperience, onEditProfile } = props;

  const dispatch = useDispatch();

  const { user } = useAuth0();
  const { profileData, avatarData, CVData, dataReady, onSetAvatar, onSetCV, onRemoveCV }: any = useContext(ProfileContext);

  const editFunctions = useMemo(
    () => ({
      activities: onEditActivities,
      achievements: onEditEducation,
      international_experiences: onEditExperience,
      work_experiences: onEditExperience,
      languages: onEditLang,
      short_bio: onEditProfile,
      social_media: onEditProfile,
      grade_proof: () => dispatch(changeGradeProof(profileData.institutes.findIndex((u: IUniversity) => !u.grade_proof))),
      resume: () => dispatch(changeProfileCV(true)),
    }),
    [profileData.institutes],
  );

  useEffect(() => {
    setPage('Edit Profile');
  }, []);

  return dataReady ? (
    <>
      <div className={styles.container}>
        <div className={`${styles.viewItem} ${styles.viewItem1}`}>
          <Main
            isMobile={isMobile}
            data={profileData}
            avatarData={avatarData}
            CVData={CVData}
            containerStyle={styles.main}
            onEdit={onEditProfile}
            setAvatar={onSetAvatar}
            setCV={onSetCV}
            removeCV={onRemoveCV}
          />
          <Completeness isMobile={isMobile} data={profileData.completeness} containerStyle={styles.completeness} editFunctions={editFunctions} />
        </div>
        <div className={`${styles.viewItem} ${styles.viewItem2}`}>
          <Education
            isMobile={isMobile}
            containerStyle={styles.education}
            list={profileData.institutes}
            achievementList={profileData.achievements}
            otherContentText={profileData.other_achievements}
            onEdit={onEditEducation}
          />
          <InternationalExperience
            isMobile={isMobile}
            list={profileData.international_experiences}
            containerStyle={styles.internationalExperience}
            onEdit={onEditExperience}
            otherContentText={profileData.other_international_experiences}
          />
          <WorkExperience
            isMobile={isMobile}
            list={profileData.work_experiences}
            containerStyle={styles.workExperience}
            onEdit={onEditExperience}
            otherContentText={profileData.other_work_experiences}
          />
          <Languages
            isMobile={isMobile}
            list={profileData.languages}
            containerStyle={styles.languages}
            onEdit={onEditLang}
            otherContentText={profileData.other_languages}
          />
          <Activities
            isMobile={isMobile}
            isBoardMember={profileData.is_board_member}
            list={profileData.activities}
            containerStyle={styles.activities}
            onEdit={onEditActivities}
            otherContentText={profileData.other_activities}
          />
        </div>
      </div>
    </>
  ) : null;
};

export default View;
