import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Title from 'src/components/titles/Title/index.web';
import Link from 'src/components/links/Link/index.web';
import Message from '../Message';
import { IMessage } from '../../../requests/models';
import Icon from 'src/components/icons/Icon/index.web';

const getEntityType = (type: string) => (type === '1' ? 'company' : type === '2' ? 'opportunity' : type === '3' ? 'event' : '');

const Conversation = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, accept, reject, archive, data, containerStyle } = props;

  const { id, logo, name, entity_id: entityId, message_type, is_accepted: isAccepted, is_archived: isArchived, is_automated: isAutomated } = data;

  const entityType = getEntityType(message_type);

  const history = useHistory();

  //@ts-ignore
  const msgList: IMessage[] = [data];

  const { t } = useTranslation();

  useEffect(() => {
    if (isAutomated && isAccepted === null) {
      accept(id);
    }
  }, [isAutomated, isAccepted, id]);

  useEffect(() => {
    if (!isAutomated && isAccepted === null) {
      reject(id);
    }
  }, [isAutomated, isAccepted, id]);

  const textButton = useMemo(() => {
    if (entityType === 'company') {
      return t('Go to company details and subscribe');
    } else if (entityType === 'opportunity') {
      return t('Go to opportunity details and apply');
    } else if (entityType === 'event') {
      return t('Go to event details and apply');
    }
  }, [entityType]);

  return (
    <div className={`${styles.component} ${containerStyle}`}>
      {id ? (
        <>
          <div className={styles.head}>
            <div className={styles.headItem}>
              <div className={`${logo ? styles.headLogo : styles.withoutHeadLogo}`} />
            </div>
            <div className={styles.headItem}>
              <Title size={'h1'} containerStyle={styles.headTitle}>
                {name}
              </Title>
              <Link internal href={`${entityType}/${entityId}`} size={'xs'}>
                {t('View details')}
              </Link>
            </div>
          </div>
          <div className={styles.body}>
            {msgList.map((item) => (
              <Message key={item.id} isMobile={isMobile} data={item} containerStyle={styles.msg} />
            ))}
          </div>
          <div className={styles.footer}>
            {!isAccepted && !isAutomated ? (
              <>
                <ButtonMain
                  type={'primary_1'}
                  size={'xl'}
                  // text={textButton}
                  onPress={() => {
                    accept(id);
                    history.push(`${entityType}/${entityId}`);
                  }}
                  containerStyle={styles.btnAccept}>
                  <div className={styles.btnText}>{textButton}</div>
                  <div className={styles.btnIcon}>
                    <Icon name={'arrow-right'} color="white" size={15} />
                  </div>
                </ButtonMain>
              </>
            ) : isArchived ? (
              <ButtonMain
                type={'secondary_1'}
                size={'xl'}
                outline
                onPress={() => archive(id, false)}
                text={t('Move back to inbox')}
                containerStyle={styles.btnArchived}
              />
            ) : (
              <ButtonMain
                type={'primary_1'}
                size={'xl'}
                outline
                onPress={() => archive(id, true)}
                text={t('Archive conversation')}
                containerStyle={styles.btnArchived}
              />
            )}
          </div>
        </>
      ) : (
        <Title size={'h2'} containerStyle={styles.emptyTitle}>
          {t('No conversation selected')}
        </Title>
      )}
    </div>
  );
};

export default Conversation;
