import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Link from 'src/components/links/Link/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import Avatar from '../../../Avatar';
import Divider from 'src/components/dividers/Divider/index.web';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import ButtonTransparentToggle from 'src/components/buttons/ButtonTransparentToggle/index.web';
import ProgressBar from 'src/components/progressbars/ProgressBar/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';

import { ITheme } from '../../../../../constant/themes';
import { IProps } from './types';
import { useStyles } from './styles';

interface ComponentStyles {
  component?: string;
  title?: string;
}

interface IKeyValue {
  activities: string;
  international_experiences: string;
  work_experiences: string;
  languages: string;
  achievements: string;
  short_bio: string;
  social_media: string;
}

const Completeness = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, data, editFunctions } = props;
  const { t } = useTranslation();
  const progress = data ? data.value : 15;

  const keyValue: any = {
    activities: { value: t('Add activities'), scroll: undefined },
    achievements: { value: t('Add specific skills'), scroll: undefined },
    international_experiences: { value: t('Add international experience'), scroll: undefined },
    work_experiences: { value: t('Add work experience'), scroll: undefined },
    languages: { value: t('Add languages'), scroll: undefined },
    short_bio: { value: t('Add bio'), scroll: 'bio' },
    social_media: { value: t('Add socials'), scroll: 'socials' },
    grade_proof: { value: t('Add proof of degree'), scroll: undefined },
    resume: { value: t('Upload personal CV'), scroll: undefined },
  };

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    title: `${styles.item} ${styles.title}`.trim(),
  };

  return progress !== 100 ? (
    <div className={cn.component}>
      <div className={cn.title}>
        <Title size={'h2'} containerStyle={styles.titleText}>
          {t('Profile completeness')}
        </Title>
        <Title size={'h2'} containerStyle={styles.titlePercent}>
          {progress}%
        </Title>
      </div>
      <ProgressBar value={progress} containerStyle={styles.progress} />
      <div className={styles.needToComplete}>
        {data
          ? //@ts-ignore
            data.fields_to_fill.map((item: { name: keyof IKeyValue; value: string }) => {
              const cItem = keyValue[item.name];
              return cItem ? (
                <ButtonMain
                  key={`${item.name}${item.value}`}
                  type={'primary_1'}
                  size={'xl'}
                  containerStyle={styles.needToCompleteItem}
                  contentStyle={styles.addLinkContent}
                  onPress={() => editFunctions[item.name](true, cItem.scroll)}>
                  <span className={styles.addLinkText}>{cItem.value}</span>
                  <span className={styles.needToCompleteItemNumber}>+&nbsp;{item.value}%</span>
                </ButtonMain>
              ) : null;
            })
          : null}
      </div>
    </div>
  ) : null;
};

export default Completeness;
