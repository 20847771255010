import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { widthD } from 'src/constant/variables';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      display: 'flex',
      maxWidth: `calc(${widthD} - 288px - 30px - 30px)`,
      width: '100%',
      justifyContent: isMobile ? 'center' : 'space-between',
      background: 'transparent',
      margin: '15px auto',
    }),
    side: ({ isMobile }: IProps) => ({
      position: 'relative',
      display: isMobile ? 'none' : 'flex',
      width: 288,
      flexShrink: 0,
      marginTop: `calc(41px + 26px)`,
    }),
    main: ({ isMobile }: IProps) => ({
      width: isMobile ? '100%  ' : 'calc(100% - 288px - 30px)',
      maxWidth: isMobile ? '512px' : 'none',
      flexShrink: 0,
      padding: isMobile ? '0 15px' : 0,
    }),
    tabs: (p: IProps) => ({
      justifyContent: p.isMobile ? 'center' : 'flex-start',
      marginBottom: p.isMobile ? 10 : 26,
      borderBottom: '1px solid #ACB7BF',
    }),
    tab: (p: IProps) => ({
      padding: p.isMobile ? '4px 6px' : '4px 10px',
      borderBottom: 'none',
      margin: p.isMobile ? 0 : '0 5px',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
      '& *': {
        letterSpacing: 0,
      },
    }),
    modal: {},
    modalHeader: ({ isMobile }: IProps) => ({
      display: 'flex',
      alignItems: 'center',
      height: isMobile ? 55 : 70,
      padding: '15px 30px',
      borderRadius: '15px 15px 0 0',
      backgroundColor: '#E8EFF440',
    }),
    modalBody: ({ isMobile }: IProps) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 30,
    }),
    modalFooter: ({ isMobile }: IProps) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30,
      paddingTop: 0,
    }),
    modalFooterBtn: ({ isMobile }: IProps) => ({
      maxWidth: 330,
      marginRight: isMobile ? 0 : 15,
      marginBottom: isMobile ? 15 : 0,
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    }),
    modalHeaderTitle: ({ isMobile }: IProps) => ({
      fontSize: isMobile ? 18 : 24,
    }),
    modalContent: {
      height: 'auto',
      width: '80%',
      minWidth: 320,
      maxWidth: 520,
      borderRadius: 15,
    },
    deactivateContent: {},
    deleteContent: {},
    bodyIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 46,
      height: 46,
      background: 'rgba(40, 40, 40, 0.08)',
      borderRadius: 5,
    },
    bodyTitle: {
      // width: '100%',
      maxWidth: 400,
    },
    bodyText: {
      fontSize: 14,
      lineHeight: '18px',
      maxWidth: 290,
    },
    fieldLabel: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '0.5rem 0',
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      margin: '15px 0',
    },
    listItem: {
      margin: '5px 15px 5px 0',
    },
    confirmText: {
      fontSize: 10,
    },
    confirmBodyText: {
      fontSize: 12,
    },
    confirmBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30,
    },
    languages: (p) => ({
      marginTop: '5px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    }),
    language: {
      margin: '10px 10px 0 0',
      display: 'inline-block',
    },
    langInput: {
      marginTop: 15,
      maxWidth: 210,
    },
    languageTitle: {
      maxWidth: 400,
      margin: '0 0 10px 0',
    },
    languageText: {},
  };
});
