import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import Icon from 'src/components/icons/Icon/index.web';
import Button from '../Button/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';

interface ComponentStyles {
  component?: string;
  icon?: string;
}

const ButtonIconToggle: FC<IProps> = (props) => {
  const { disabled, active, iconSize = 25, activeIconName, inactiveIconName, iconColor, containerStyle, iconStyle, onHover, onPress, onBlur } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
    icon: `${styles.icon} ${iconStyle ? iconStyle : ''}`.trim(),
  };

  const renderIcon = (): React.ReactChild | null => {
    return <Icon size={iconSize} name={active ? activeIconName : inactiveIconName} color={iconColor} />;
  };

  return (
    <Button disabled={disabled} onPress={onPress} onHover={onHover} onBlur={onBlur} containerStyle={cn.component} contentStyle={cn.icon}>
      {renderIcon()}
    </Button>
  );
};

export default ButtonIconToggle;
