import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import Button from 'src/components/buttons/Button/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSkipPress(): void;
  current: any;
}

const SkipPortal = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { onSkipPress, current } = props;

  const { t } = useTranslation();

  return current
    ? createPortal(
        <Button containerStyle={styles.btn} contentStyle={styles.btnContent} onPress={onSkipPress}>
          {t('Skip')}
          <Icon name={'arrow-right'} size={10} color={theme.$primaryColor1} />
        </Button>,
        current,
      )
    : null;
};

export default SkipPortal;
