import { Platform, Dimensions, Image } from 'react-native';
import { useState } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';
import { string } from 'prop-types';

export const initGA = () => {
  if (window !== undefined) {
    //@ts-ignore
    if (!window.ga) {
      ReactGA.initialize(process.env.REACT_APP_GTAG);
    }
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = ({ category, action, label }: { category: string; action: string; label: string[] }) => {
  const eventLabel = label ? label.join(' - ') : '';

  ReactGA.event({
    category,
    action,
    label: eventLabel,
  });
};

interface ICatalog {
  id: string;
  name: string;
  value: string;
}

const DEF_VALUE = {
  id: undefined,
  name: undefined,
  value: undefined,
};

export const isElementExistInArray = (list: ICatalog[], element: ICatalog): boolean => {
  const res = list.find((item) => item.id === element.id);
  return res === undefined ? false : true;
};

export const getFilteredArray = (list: ICatalog[], element: ICatalog, isMultiselect = true): any => {
  if (isMultiselect) {
    const isExist = isElementExistInArray(list, element);

    if (isExist) {
      return list.map((item: ICatalog) => item.id !== element.id);
    } else {
      return [...list, element];
    }
  } else {
    const isExist = isElementExistInArray(list, element);

    if (isExist) {
      return DEF_VALUE;
    } else {
      return element;
    }
  }
};

export const markdownList = (markdownString: string, predefined?: number[]) => {
  const markdowns: any = [];
  let indexItem = 0;
  let markdownItem = { title: '', markdown: '', isOpened: false };
  markdownString.split('\n').map((str: string, index: number, arr: string[]) => {
    if (str.indexOf('# ') === 0) {
      markdowns.push({ ...markdownItem });
      markdownItem = { title: '', markdown: '', isOpened: false };
      markdownItem.title = str;
      if (predefined && predefined.includes(indexItem)) {
        markdownItem.isOpened = true;
      }
      indexItem++;
    } else {
      markdownItem.markdown = markdownItem.markdown + '\n' + str;
    }
    if (arr.length - 1 === index) {
      markdowns.push({ ...markdownItem });
    }
  });
  return markdowns;
};

export const getSlicedText = (text?: string, maxCharacters = Platform.OS !== 'web' ? 20 : 30): string => {
  return text ? (text.length > maxCharacters ? `${text.slice(0, maxCharacters)}...` : text) : 'Text not found';
};
export const modifyTextForMobile = (text) => {
  let newText = text;
  const regexp = /\(https*?(?=:\/\/(app|app-tst|app-stage).launch.career)/g;
  while (newText.match(regexp)) {
    newText = newText.replace(regexp, '(launch');
  }
  return newText;
};

export const momentLocale = {
  fr: {
    relativeTime: {
      future: 'in %s',
      past: 'il y a %s',
      s: `1 ${'s'}`,
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 j',
      dd: '%d j',
      M: '1 m',
      MM: '%d m',
      y: 'a',
      yy: '%d a',
    },
  },
  nl: {
    relativeTime: {
      future: 'in %s',
      past: '%s geleden',
      s: `1 ${'s'}`,
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: '%d d',
      M: '1 mth',
      MM: '%d mths',
      y: 'y',
      yy: '%d a',
    },
  },
  en: {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: `1 ${'s'}`,
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: '%d d',
      M: '1 mth',
      MM: '%d mths',
      y: 'y',
      yy: '%d y',
    },
  },
};

export const momentTimeLocale = (() => {
  moment.updateLocale('fr', {
    relativeTime: {
      future: 'in %s',
      past: 'il y a %s',
      s: `1 ${'s'}`,
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 j',
      dd: '%d j',
      M: '1 m',
      MM: '%d m',
      y: 'a',
      yy: '%d a',
    },
  });
  moment.updateLocale('nl', {
    relativeTime: {
      future: 'in %s',
      past: '%s geleden',
      s: `1 ${'s'}`,
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: '%d d',
      M: '1 mth',
      MM: '%d mths',
      y: 'y',
      yy: '%d a',
    },
  });

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: `1 ${'s'}`,
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: '%d d',
      M: '1 mth',
      MM: '%d mths',
      y: 'y',
      yy: '%d y',
    },
  });

  return moment;
})();

export const getZElement = (array: Array<any>): string => {
  return array !== null && array.length > 0 ? array[0].name : 'Not insert';
};

export const getValidDTime = (date: string, format: string): string => {
  return moment(date).utc(true).format(format);
};

export const getSplittedAddress = (address: string): string[] => {
  const addressText = address.split(',');
  const addressText1 = addressText[0];
  const addressText2 = addressText.length > 1 ? addressText.slice(1).join(',') : '';
  return [addressText1, addressText2];
};

interface ISocialMediaItem {
  url: string;
  type: string;
}

export const checkOnEmptyURL = (data: ISocialMediaItem[]) => {
  const result = data.filter((obj: { url: string }) => obj.url);
  return result.length ? result : null;
};

export const path = (o: any, p: string[]): any => {
  let res = o;
  for (const it of p) {
    if (res === undefined) {
      break;
    }
    res = res[it] ? res[it] : undefined;
  }
  return res;
};

export const getByPath = (root: any, item: string[], defaultItem: any): any => {
  if (root) {
    const result = path(root, item);
    return result ? result : defaultItem;
  } else {
    return defaultItem;
  }
};

export const parseDeepLink = (link: string): any => {
  const parsedLink = link.split('?')[1].split('&');

  let response = {};
  /*   const str = reverseString(link);
  const id = reverseString(str.slice(
    str.indexOf('eercs?') + 6,
    str.indexOf('/'),
  ));
 */
  for (const value of parsedLink) {
    const param = value.split('=');

    response = {
      ...response,
      [param[0]]: param[1],
      // id,
    };
  }

  return response;
};

/**
 * Used when image did not have standard ratio
 * @return fast-image string with cover or center image
 * @param {string} img
 */

export const useRatioImage = (img: string): string => {
  const [ratio, setRatio] = useState<number>();
  const screenHeight = Dimensions.get('window').height;
  const screenWidth = Dimensions.get('window').width;
  const sizeScreen = screenHeight / screenWidth;

  Image.getSize(img, (width, height) => {
    const size = width / height;
    setRatio(size);
  });

  if ((ratio !== undefined && ratio > sizeScreen + 1) || ratio === sizeScreen) {
    return 'center';
  } else {
    return 'cover';
  }
};

export const convertBtoMB = (size: number): number => size / Math.pow(1024, 2);

export const pick = (keys: string[], object: { [keys: string]: string }) => {
  const res = {} as { [keys: string]: string };
  keys.forEach((k: string) => {
    if (object[k] !== undefined) {
      res[k] = object[k];
    }
  });
  return res;
};

export const extension = (type: string) => type?.split('.').reverse()[0];

//export const getCompanyId = (route: any) =>  route?.params?.companyId?.toString().includes('screen') ? parseInt(route.params?.companyId.substr(0, route.params.companyId.indexOf('?screen'))) : route.params.companyId;

/* export const reverseString = (str: string) => {
  let splitString = str.split(""); 
  let reverseArray = splitString.reverse();
  let joinArray = reverseArray.join(""); 
  return joinArray; 
} */
