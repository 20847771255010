import React, { useContext, memo, useEffect, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/titles/Title/index.web';
import Text from '../../../components/texts/Text/index.web';
import img from '../../../assets/images/support.png';
import CombinedInput from '../../../components/combined/InputField/index.web';
import ButtonMain from '../../../components/buttons/ButtonMain/index.web';
import { useMutation } from '@apollo/client';
import { SEND_SUPPORT_MESSAGE } from '../../../requests/mutations';
import { useAuth0 } from '@auth0/auth0-react';
import Select from '../../../components/inputs/Select/index.web';
import Icon from '../../../components/icons/Icon/index.web';
import { validateEmail } from '../../../utilities/validation';
import { Context as ProfileContext } from '../../../web/contexts/ProfileContexts/index.web';
import { IOptionItem } from '../../../requests/models';

const Support = memo((props: IProps) => {
  const { user } = useAuth0();
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { isMobile } = props;
  const { contactEmail, isProfileCreated }: any = useContext(ProfileContext);
  const [subject, setSubject] = useState<IOptionItem>({ id: '', name: '', __typename: '' });
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState(isProfileCreated ? contactEmail : user ? user.email : '');
  const [succeed, setSucceed] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);

  const { t } = useTranslation();

  const [onSendMessage] = useMutation(SEND_SUPPORT_MESSAGE);

  const onPressSubmit = (): void => {
    onSendMessage({ variables: { message, subject: subject.name, email } });
    setSubject({ id: '', name: '', __typename: '' });
    setMessage('');
  };

  const onSubmit = async () => {
    if ((!isMobile && !subject.name.length) || !message.length || !email.length || !validateEmail(email)) {
      setShowToolTip(true);
    } else {
      await onPressSubmit();
      setSucceed(true);
      setTimeout(() => {
        setSucceed(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setShowToolTip(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (showTooltip) {
      setShowToolTip(false);
    }
  }, [subject, message, email]);

  console.log(validateEmail(email));
  const onSelectSubject = (value: IOptionItem) => {
    setSubject(value);
  };
  const subjectData = [
    { id: '1', name: 'I would like to have some career advice', __typename: 'subjectData' },
    { id: '2', name: 'I have a question regarding a company, opportunity or event', __typename: 'subjectData' },
    { id: '3', name: 'I would like to have some free CV counseling', __typename: 'subjectData' },
    { id: '4', name: 'I have a technical issue/bug with the app', __typename: 'subjectData' },
  ];
  return (
    <div className={styles.component}>
      <div className={styles.head}>
        <img className={styles.img} src={img} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Have a chat with us')}
        </Title>
        <Text containerStyle={styles.text} text={t('If you need help, have any questions or feedback')} />
      </div>
      <div className={styles.body}>
        <CombinedInput
          type={'email'}
          value={email}
          label={t('Email')}
          onChange={setEmail}
          containerStyle={styles.input}
          inputStyle={styles.input}
          labelStyle={styles.inputLabel}
          placeholder={t('Email')}
        />

        <div>
          <Text>{t('How can we help you?')}</Text>
          <Select value={subject} containerStyle={styles.input} placeholder={t('Select topic')} list={subjectData} onChoose={onSelectSubject} />
        </div>
        {/* <CombinedInput
          type={'text'}
          value={subject}
          label={t('Subject')}
          onChange={setSubject}
          containerStyle={styles.input}
          inputStyle={styles.input}
          labelStyle={styles.inputLabel}
          placeholder={t('How can we help?')}
        /> */}
        <CombinedInput
          type={'area'}
          value={message}
          onChange={setMessage}
          label={t('Description')}
          containerStyle={styles.input}
          labelStyle={styles.inputLabel}
          placeholder={t('Tell us more details')}
        />
        <div className={styles.submitContainer}>
          {showTooltip ? (
            <div className={styles.tooltip}>
              {!subject.name.length ? <div className={styles.tooltipItem}>{t('Please enter message subject')}</div> : null}
              {!message.length ? <div className={styles.tooltipItem}>{t('Message cannot be empty')}</div> : null}
              {!email.length || !validateEmail(email) ? <div className={styles.tooltipItem}>{t('Please enter a valid email')}</div> : null}
            </div>
          ) : null}
          {succeed ? (
            <ButtonMain type={'primary_1'} size={'xl'} outline containerStyle={styles.button} iconSize={20} iconName={'checkmark'} />
          ) : (
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              disabled={isMobile ? !subject.name.length || !message.length || !email.length || !validateEmail(email) : false}
              containerStyle={styles.button}
              text={t('Send')}
              onPress={onSubmit}
            />
          )}
        </div>
      </div>
      {succeed ? (
        <div className={styles.successMsg}>
          <div className={styles.successMsgHeader}>
            <div className={styles.successMsgImg}>
              <Icon name="logo" color="white" size={35} />
            </div>
            <Text type={'primary'} containerStyle={styles.successMsgText} text={t('Success')} />
          </div>
          <div className={styles.successMsgBody}>
            <Text type={'primary'} containerStyle={styles.successMsgText} text={t('Your message has been sent')} />
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default Support;
