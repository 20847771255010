import React, { useContext, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import Title from 'src/components/titles/Title/index.web';
import CombinedInput from 'src/components/combined/InputField/index.web';
import TitleIcon from 'src/components/titles/TitleIcon/index.web';
import { ITheme } from 'src/constant/themes';
import Devider from 'src/components/dividers/Divider/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { useMutation } from '@apollo/client';
import { DELETE_ACCOUNT, DEACTIVATE_PROFILE, RESET_PASSWORD, CHANGE_EMAIL } from 'src/requests/mutations';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import Text from 'src/components/texts/Text/index.web';
import Modal from '../../../Modal/Modal';
import Icon from 'src/components/icons/Icon/index.web';
import Input from 'src/components/inputs/TextInput/index.web';
import RadioButtonGroup from 'src/components/inputs/RadioButtonGroup/index.web';

const Account = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, title } = props;

  const { t } = useTranslation();

  const [modalDeactivate, setModalDeactivate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const [modalEmailChange, setModalEmailChange] = useState(false);

  const [newEmail, setNewEmail] = useState('');
  const [newEmailConfirm, setNewEmailConfirm] = useState('');
  const [reason, setReason] = useState('');
  const [additional_info, setAdditionalInfo] = useState('');

  const { user } = useAuth0();

  const { profileData }: any = useContext(ProfileContext);
  const { is_deactivated, is_deleted } = profileData;
  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    variables: { input: { reason, additional_info } },

    update(cache, { data: { deleteAccount } }) {
      cache.modify({
        id: cache.identify(profileData),
        fields: {
          is_deleted: (cache) => deleteAccount,
        },
      });
    },
  });
  const [deactivateProfile] = useMutation(DEACTIVATE_PROFILE, {
    variables: { input: { is_deactivated } },
    update(cache, { data: { deactivateAccount } }) {
      cache.modify({
        id: cache.identify(profileData),
        fields: {
          is_deactivated: (cache) => deactivateAccount,
        },
      });
    },
  });
  const [resetPassword] = useMutation(RESET_PASSWORD, {});
  const [changeEmail] = useMutation(CHANGE_EMAIL, {
    variables: { input: { email: newEmailConfirm } },
    update(cache, { data: { changeEmail } }) {
      cache.modify({
        id: cache.identify(profileData),
        fields: {
          email: (cache) => changeEmail,
        },
      });
    },
  });

  const onDeactivate = async () => {
    if (!is_deactivated) {
      deactivateProfile({
        variables: {
          input: { is_deactivated: true },
        },
      });
    }
  };

  const onReactivate = () => {
    if (is_deactivated) {
      deactivateProfile({
        variables: {
          input: { is_deactivated: false },
        },
      });
    }
  };

  const onDeleteAccount = async () => {
    await deleteAccount();
  };

  const onResetPassword = React.useCallback(() => {
    const reset = async () => {
      await resetPassword();
      setModalResetPassword(true);
    };

    reset();
  }, [resetPassword]);

  const onChangeEmail = React.useCallback(() => {
    const change = async () => {
      await changeEmail({
        variables: {
          input: {
            email: newEmailConfirm,
          },
        },
      });
    };

    setModalEmailChange(false);

    change();
  }, [changeEmail, newEmailConfirm]);

  const onBtnClick = (btnName: string) => {
    switch (btnName) {
      case 'reactivate': {
        onReactivate();
        break;
      }
      case 'deactivate': {
        setModalDeactivate(true);
        break;
      }
      case 'delete': {
        setModalDelete(true);
        break;
      }
      case 'deactivateModal': {
        setModalDeactivate(false);
        onDeactivate();
        break;
      }
      case 'deleteModal': {
        setModalDelete(false);
        onDeleteAccount();
        break;
      }
      case 'resetPassword': {
        onResetPassword();
        break;
      }
      case 'emailChange': {
        setModalEmailChange(true);
        break;
      }
      case 'emailChangeModal': {
        onChangeEmail();
        break;
      }
      default: {
        console.error('onBtnClick wrong arguments');
      }
    }
  };

  const onModalClose = () => {
    setModalDeactivate(false);
    setModalDelete(false);
    setModalResetPassword(false);
    setModalEmailChange(false);
    setReason('');
    setAdditionalInfo('');
  };

  const provider = user && user.sub ? user.sub.split('|')[0] : undefined;

  const reasons = [
    { label: t('I found a job (via Launch)'), value: t('I found a job (via Launch)') },
    { label: t('I found a job (via other channels)'), value: t('I found a job (via other channels)') },
    { label: t('I don’t believe the app is useful'), value: t('I don’t believe the app is useful') },
    { label: t('The app is sending me too much emails'), value: t('The app is sending me too much emails') },
    { label: t('Other reason'), value: t('Other reason') },
  ];
  const handleRadioButtonChange = (e) => {
    setReason(e.target.value);
  };
  const onChangeAdditionalInfo = (additionalInfo: string) => {
    setAdditionalInfo(additionalInfo.trim());
  };

  return (
    <>
      <div className={styles.account}>
        <TitleIcon size={'h2'} iconName={'user'} iconColor={theme.$primaryColor1} containerStyle={styles.accountTitle}>
          {t(title)}
        </TitleIcon>
        <Devider size={'xl'} />
        <div className={styles.deactivate}>
          {provider === 'auth0' && (
            <>
              <Title size={'h2'} containerStyle={styles.deactivateTitle}>
                {t('Change email')}
              </Title>
              <CombinedInput
                type={'email'}
                value={newEmail}
                label={'New email'}
                containerStyle={styles.fieldInputContainer}
                inputStyle={styles.fieldInput}
                onChange={setNewEmail}
                placeholder={'example@mail.com'}
              />
              <CombinedInput
                type={'email'}
                value={newEmailConfirm}
                label={t('Confirm new email')}
                containerStyle={styles.fieldInputContainer}
                inputStyle={styles.fieldInput}
                onChange={setNewEmailConfirm}
                placeholder={'example@mail.com'}
              />
              <ButtonMain
                size={'xl'}
                type={'primary_1'}
                text={t('Change email')}
                disabled={!newEmail || !newEmailConfirm || newEmail !== newEmailConfirm || newEmailConfirm === user?.email}
                onPress={() => onBtnClick('emailChange')}
                containerStyle={styles.resetButton}
              />
              <Title size={'h2'} containerStyle={styles.deactivateTitle}>
                {t('Reset password')}
              </Title>
              <ButtonMain
                size={'xl'}
                type={'primary_1'}
                text={t('Reset password')}
                onPress={() => onBtnClick('resetPassword')}
                containerStyle={styles.resetButton}
              />
            </>
          )}
          <Title size={'h2'} containerStyle={styles.deactivateTitle}>
            {t('Deactivate account')}
          </Title>
          <div className={styles.deactivateButtons}>
            {is_deactivated ? (
              <ButtonMain
                size={'xl'}
                iconName={'refresh'}
                type={'primary_1'}
                text={t('Reactivate account')}
                outline
                containerStyle={styles.deactivateButton}
                onPress={() => onBtnClick('reactivate')}
              />
            ) : (
              <ButtonMain
                size={'xl'}
                iconName={'watch'}
                type={'primary_2'}
                text={t('Temporarily deactivate')}
                outline
                containerStyle={styles.deactivateButton}
                onPress={() => onBtnClick('deactivate')}
              />
            )}
            {is_deleted ? (
              <Text
                text={`${t('Your account deletion request has been sent. Your request will be reviewed within 30 days')}.`}
                containerStyle={styles.deletedString}
              />
            ) : (
              <ButtonMain
                size={'xl'}
                iconName={'block'}
                iconColor={'white'}
                type={'primary_2'}
                containerStyle={styles.deactivateButton}
                text={t('Delete account')}
                onPress={() => onBtnClick('delete')}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        isModalOpened={modalResetPassword || modalDeactivate || modalDelete || modalEmailChange}
        wrapperStyles={styles.modal}
        contentStyles={styles.modalContent}
        onClose={onModalClose}>
        {modalDeactivate && (
          <div className={styles.deactivateContent}>
            <div className={styles.modalHeader}>
              <Title size={'h2'} containerStyle={styles.modalHeaderTitle}>
                {t('Temporarily deactivate account')}
              </Title>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.bodyIcon}>
                <Icon size={24} name={'watch'} color={theme.$primaryColor2} />
              </div>
              <Title size={'h2'} containerStyle={styles.bodyTitle}>
                {t('Are you sure you want to temporarily deactivate your account?')}
              </Title>
              <Text type={'default'} containerStyle={styles.bodyText}>
                {t('This will prevent you from receiving direct offers from companies and automated Launch update emails. You can undo this action any time.')}
              </Text>
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain outline size={'lg'} type={'primary_2'} containerStyle={styles.modalFooterBtn} text={t('Cancel')} onPress={onModalClose} />
              <ButtonMain
                size={'lg'}
                type={'primary_1'}
                containerStyle={styles.modalFooterBtn}
                text={t('Deactivate for 6 months')}
                onPress={() => onBtnClick('deactivateModal')}
              />
            </div>
          </div>
        )}
        {modalDelete && (
          <div className={styles.deleteContent}>
            <div className={styles.modalHeader}>
              <Title size={'h2'} containerStyle={`${styles.modalHeaderTitle} ${styles.center}`}>
                {t('Delete account')}
              </Title>
            </div>
            <div className={styles.modalBody}>
              <div className={`${styles.bodyIcon} ${styles.center}`}>
                <Icon size={24} name={'block'} color={theme.$primaryColor2} />
              </div>
              <Title size={'h2'} containerStyle={`${styles.bodyTitle} ${styles.center}`}>
                {t('Are you sure you want to permanently delete your account?')}
              </Title>
              <Text type={'default'} containerStyle={styles.bodyText}>
                {t('This will permanently delete all your data, messages, connections with companies etc. Can not be restored.')}
              </Text>
              <Text type={'default'} containerStyle={styles.bodyText2}>
                {t('Please, select the reason:')}
              </Text>
              <RadioButtonGroup data={reasons} onChange={handleRadioButtonChange} />
              <Input
                placeholder={t('Please indicate the reason')}
                value={additional_info}
                onChange={onChangeAdditionalInfo}
                instantOnChange={true}
                containerStyle={styles.fieldInput}
              />
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain outline size={'lg'} type={'primary_2'} containerStyle={styles.modalFooterBtn} text={t('Cancel')} onPress={onModalClose} />
              <ButtonMain
                disabled={!reason}
                size={'lg'}
                type={'primary_1'}
                containerStyle={styles.modalFooterBtn}
                text={t('Delete')}
                onPress={() => onBtnClick('deleteModal')}
              />
            </div>
          </div>
        )}
        {modalResetPassword && (
          <div className={styles.deleteContent}>
            <div className={styles.modalHeader}>
              <Title size={'h2'} containerStyle={styles.modalHeaderTitle}>
                {t('Reset password')}
              </Title>
            </div>
            <div className={styles.modalBody}>
              <Text type={'default'} containerStyle={styles.bodyText}>
                {t('Reset password link has been sent to your email.')}
              </Text>
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain size={'lg'} type={'primary_1'} containerStyle={styles.modalFooterBtn} text={'Ok'} onPress={onModalClose} />
            </div>
          </div>
        )}
        {modalEmailChange && (
          <div className={styles.deleteContent}>
            <div className={styles.modalHeader}>
              <Title size={'h2'} containerStyle={styles.modalHeaderTitle}>
                {t('Change email')}
              </Title>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.bodyIcon}>
                <Icon size={24} name={'mail'} color={theme.$primaryColor2} />
              </div>
              <Title size={'h2'} containerStyle={styles.bodyTitle}>
                {t('Are you sure you want to change your account email to', { email: newEmailConfirm })}
              </Title>
            </div>
            <div className={styles.modalFooter}>
              <ButtonMain outline size={'lg'} type={'primary_2'} containerStyle={styles.modalFooterBtn} text={t('Cancel')} onPress={onModalClose} />
              <ButtonMain
                size={'lg'}
                type={'primary_1'}
                containerStyle={styles.modalFooterBtn}
                text={t('Change email')}
                onPress={() => onBtnClick('emailChangeModal')}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Account;
