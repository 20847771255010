import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    title: {
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: 0,
    },
  };
});
