import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      maxWidth: isMobile ? 'none' : 405,
      width: '100%',
      height: '100%',
      maxHeight: isMobile ? 'none' : 635,
      background: 'white',
      padding: isMobile ? 15 : 30,
      //marginTop: isMobile ? 15 : 60,
      border: isMobile ? 'none' : `1px solid ${theme.$borderColor}`,
      borderRadius: isMobile ? 0 : 15,
    }),
    head: {
      height: 94,
    },
    body: {
      height: 'calc(100% - 94px)',
    },
    tabs: {
      marginTop: 5,
    },
    tab: {
      flexGrow: 1,
    },
    inboxTab: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    archiveTab: {},
    msgList: {},
    searchField: {
      maxWidth: 'none',
    },
    inboxCount: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 14,
      minHeight: 14,
      background: theme.$primaryColor1,
      color: 'white',
      borderRadius: '50%',
      lineHeight: '10px',
      padding: 3,
      marginLeft: 5,
      fontSize: 9,
    },
  };
});
