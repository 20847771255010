import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

import { widthD, headerHeightD, headerHeightM } from 'src/constant/variables';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: ({ isMobile }: IProps) => ({
      position: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
      height: isMobile ? headerHeightM : headerHeightD,
      backgroundColor: 'white',
      boxShadow: 'inset 0px -1px 0px rgba(145, 165, 182, 0.24)',
      zIndex: 99,
    }),
    content: ({ isMobile }: IProps) => {
      return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: isMobile ? '100%' : widthD,
        margin: '0 auto',
        height: '100%',
        padding: isMobile ? '0 15px' : '0',
      };
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    navList: ({ isMobile }: IProps) => ({
      display: isMobile ? 'none' : 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    }),
    logo: ({ isMobile }: IProps) => ({
      marginRight: isMobile ? 0 : '20px',
      padding: '10px 10px 10px 0',
    }),
    clickable: {
      cursor: 'pointer',
    },
    searchInput: {
      borderRadius: 28,
      height: 44,
    },
    searchInputInput: {
      borderRadius: 28,
    },
    navListItem: {
      fontSize: 16,
      fontWeight: 500,
      transition: '0.25s all ease',
      cursor: 'pointer',
      padding: 15,
      '&:hover': {
        color: theme.$primaryColor1,
      },
    },
    navListItemActive: {
      color: theme.$primaryColor1,
    },
    profile: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    profileNotification: {
      margin: '0 10px',
    },
    profileImgContainer: ({ isMobile }: IProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      margin: isMobile ? '0 6px 0 14px' : '0 16px',
    }),
    profileImgMain: ({ isMobile }: IProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: isMobile ? 50 : 64,
      height: isMobile ? 50 : 64,
    }),
    profileImgBorder: {},
    profileImgBorderFill: {},
    profileTitle: {},
    profileTitleText: {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
      color: theme.$primaryColor2,
    },
    avatar: {
      borderRadius: '50%',
    },
    icon: {
      flexShrink: 0,
    },
    menu: ({ isMobile }: IProps) => ({
      position: 'absolute',
      right: 0,
      bottom: 0,
      transform: 'translate(0, 100%)',
    }),
    title: {},
    desktopLinks: {
      display: 'flex',
    },
    desktopLink: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 15px',
      textTransform: 'capitalize',
      cursor: 'pointer',
      fontWeight: 500,

      '&:hover': {
        color: theme.$primaryColor1,

        '& svg path': {
          fill: theme.$primaryColor1,
        },
      },
      '&:first-child': {
        paddingLeft: 20,
      },
      '&:last-child': {
        paddingRight: 20,
      },
    },
    desktopLinkActive: {
      color: theme.$primaryColor1,
    },
    desktopLinkIcon: {
      display: 'flex',
      marginRight: 15,
    },
  };
});
