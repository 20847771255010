import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  const componentActive = (active: boolean) =>
    active
      ? {
          '&:hover': {
            opacity: 1,
          },
        }
      : null;

  return {
    component: (p: IProps) => {
      const { active } = p;

      return {
        width: 'auto',
        maxWidth: 'none',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        '&:hover': {
          opacity: 0.75,
        },
        ...componentActive(active),
      };
    },
    text: {
      color: theme.$primaryColor1,
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      marginRight: '1rem',
    },
    icon: {
      width: 'auto',
      display: 'inline-flex',
      flexDirection: 'row',
      '& svg': {
        transition: '0.25s all ease',
      },
    },
    iconActive: {
      '& svg': {
        transform: 'scale(1, -1);',
      },
    },
    iconInactive: {
      '& svg': {
        transform: 'scale(-1, 1);',
      },
    },
  };
});
