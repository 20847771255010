import React, { useEffect, useRef } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import Link from 'src/components/links/Link/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

const Menu = React.forwardRef((props: IProps, ref: any) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { isMobile, isOpen, containerStyle, onClose, showFull } = props;
  const { logout, isAuthenticated } = useAuth0();
  const { t } = useTranslation();

  const menuRef = useRef(null);

  const onLogout = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    logout();
  };

  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (menuRef.current !== null && !menuRef.current.contains(event.target) && !ref.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  // useEffect(()=>{
  //   if(isMobile && isOpen){
  //     const page = document.getElementById('page');
  //     if(page !== null){
  //       page.style.overflow = 'hidden'
  //     }
  //   } else {
  //     const page = document.getElementById('page');
  //     if(page !== null){
  //       page.style.overflow = 'auto'
  //     }
  //   }
  //
  //   return () => {
  //     const page = document.getElementById('page');
  //     if(page !== null){
  //       page.style.overflow = 'auto'
  //     }
  //   }
  // }, [isMobile, isOpen]);

  return isOpen ? (
    <div className={`${styles.container} ${containerStyle}`} ref={menuRef}>
      {showFull ? (
        <div className={styles.navs}>
          <Link internal href={'/profile'} size={'sm'} containerStyle={styles.nav}>
            <Icon size={20} color={theme.$primaryColor1} name={'user'} />
            <span className={styles.navText}>{t('My profile')}</span>
          </Link>
          <Link internal href={'/messages'} size={'sm'} containerStyle={styles.nav}>
            <Icon size={20} color={theme.$primaryColor1} name={'mail'} />
            <span className={styles.navText}>{t('Messages')}</span>
          </Link>
          <Link internal href={'/likes'} size={'sm'} containerStyle={styles.nav}>
            <Icon size={20} color={theme.$primaryColor1} name={'thumbs-up'} />
            <span className={styles.navText}>{t('Likes')}</span>
          </Link>
          <Link internal href={'/settings'} size={'sm'} containerStyle={styles.nav}>
            <Icon size={20} color={theme.$primaryColor1} name={'settings'} />
            <span className={styles.navText}>{t('Settings')}</span>
          </Link>
          {/*<Link internal href={'/profile'} size={'sm'} containerStyle={styles.nav}>*/}
          {/*<Icon size={20} color={theme.$primaryColor1} name={'light'}/>*/}
          {/*<span className={styles.navText}>Quizes</span>*/}
          {/*</Link>*/}
          <Link internal href={'/support'} size={'sm'} containerStyle={styles.nav}>
            <Icon size={20} color={theme.$primaryColor1} name={'chat'} />
            <span className={styles.navText}>{t('Have a chat with us')}</span>
          </Link>
        </div>
      ) : null}
      <div className={styles.footer}>
        {isAuthenticated ? (
          <Link internal href={'/'} size={'sm'} containerStyle={styles.nav} onPress={onLogout}>
            <Icon size={20} color={theme.$primaryColor1} name={'logout'} />
            <span className={styles.navText}>{t('Logout')}</span>
          </Link>
        ) : (
          <Link internal href={'/login'} size={'sm'} containerStyle={styles.navLogin}>
            <Icon size={20} color={theme.$primaryColor1} name={'logout'} />
            <span className={styles.navText}>{t('Login')}</span>
          </Link>
        )}
      </div>
    </div>
  ) : null;
});

//@ts-ignore
export default Menu;
