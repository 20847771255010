import React, { FC, useState } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import Label from 'src/components/labels/Label/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';
import { IOptionItem } from '../../../requests/models';

interface ComponentStyles {
  component?: string;
  label?: string;
  labelShow?: string;
}

const LabelList: FC<IProps> = (props) => {
  const { show, visibleCount, list, containerStyle, labelStyle, onHover, onPress, onBlur, onShow } = props;
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    label: `${styles.label} ${labelStyle}`.trim(),
    labelShow: `${styles.label} ${styles.labelShow}`.trim(),
  };
  return (
    <div className={cn.component} onMouseOver={onHover} onClick={onPress} onBlur={onBlur}>
      {list
        .filter((item, i) => show || i < visibleCount)
        .map((label: IOptionItem) => {
          //@ts-ignore
          return <Label key={label.id} item={label} containerStyle={cn.label} />;
        })}
      {/*@ts-ignore*/}
      {!show && visibleCount < list.length ? <Label item={{ id: '0', name: '+' }} containerStyle={cn.labelShow} onPress={onShow} /> : null}
    </div>
  );
};

export default LabelList;
