import React, { memo, useContext, useMemo } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import CompanyItem from 'src/web/components/Company/Item';
import OpportunityItem from 'src/web/components/Opportunity/Item';
import EventItem from 'src/web/components/Event/Item';
import Text from 'src/components/texts/Text/index.web';
import { FlatList } from 'react-native';
import Title from 'src/components/titles/Title/index.web';
import { ApplyModal } from 'src/web/components/ApplyModal';
import { Context as SearchContext } from 'src/contexts/SearchContext';
import { IOpportunity } from 'src/components/cards/Opportunity/types';

interface ISelectOption {
  key: string;
  orderLabel: string;
  orderValue: string;
  orderDirectionLabel: string;
  orderDirectionValue: string;
}

const List = (props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, view, order, orderList, orderDirectionList, setOrder, setOrderDirection, toggleLike, toggleBookmark, toggleDislike, total, result } = props;
  const { t } = useTranslation();

  const toggleCompanyLike = (company: any, is_liked: boolean) => toggleLike(company, is_liked, 'c');

  const toggleCompanyBookmark = (id: string, is_liked: boolean) => toggleBookmark(id, is_liked, 'c');
  const toggleOpportunityBookmark = (id: string, is_liked: boolean) => toggleBookmark(id, is_liked, 'o');
  const toggleEventBookmark = (id: string, is_liked: boolean) => toggleBookmark(id, is_liked, 'e');

  const toggleCompanyDislike = (id: string, is_liked: boolean) => toggleDislike(id, is_liked, 'c');
  const { setApplyModalShown, setEntityForApply, entityForApply, applyModalShown } = useContext(SearchContext);

  const sortItems = useMemo(() => {
    const result = new Array(orderList.length * orderDirectionList.length).fill(null);
    let index = 0;
    orderList.forEach((oI: { key: string; labelWeb: string; labelMob: string }) => {
      orderDirectionList.forEach((oDI: { key: string; labelWeb: string; labelMob: string }) => {
        result[index++] = {
          key: index,
          orderLabel: oI.labelMob,
          orderValue: oI.key,
          orderDirectionLabel: oDI.labelMob,
          orderDirectionValue: oDI.key,
        };
      });
    });
    return result;
  }, [orderList, orderDirectionList]);

  const onSelectOrder = (e: any) => {
    if (e.target) {
      const item = sortItems[e.target.value];
      if (item) {
        setOrder(item.orderValue);
        setOrderDirection(item.orderDirectionValue);
      }
    }
  };

  return (
    <>
      <div className={styles.info}>
        <Text type={'secondary'} containerStyle={styles.results} inline>{`${t('Showing')} ${total} ${t('results')}`}</Text>
        {isMobile && (
          <div className={styles.order}>
            <Text containerStyle={styles.filtersLabel}>{t('Sort by')}:</Text>
            {
              <select className={styles.orderSelect} onChange={onSelectOrder}>
                {sortItems.map((item: ISelectOption, index: number) => (
                  <option key={item.key} value={index} className={`${styles.filtersValue} ${order === item.key ? styles.filtersValueActive : ''}`}>{`${t(
                    item.orderLabel,
                  )} ${item.orderDirectionLabel}`}</option>
                ))}
              </select>
            }
          </div>
        )}
      </div>
      <div className={styles.component}>
        {result.length ? (
          <>
            <FlatList
              key={1}
              data={result}
              numColumns={1}
              extraData={[isMobile, view]}
              removeClippedSubviews={true}
              keyExtractor={(item: any, index: number) => `${item.id}-${item.like_type}-${index}`}
              style={{ width: '100%' }}
              renderItem={({ item, index }: { item: any; index: number }) => {
                if (item.like_type === '1' || item.bookmark_type === '1' || item.dislike_type === '1') {
                  return (
                    <CompanyItem
                      view={view}
                      isMobile={isMobile}
                      data={item}
                      containerStyle={styles.item}
                      toggleLike={toggleCompanyLike}
                      toggleBookmark={toggleCompanyBookmark}
                      toggleDislike={toggleCompanyDislike}
                    />
                  );
                } else if (item.like_type === '2' || item.bookmark_type === '2' || item.dislike_type === '2') {
                  return (
                    <OpportunityItem
                      view={view}
                      isMobile={isMobile}
                      data={item}
                      containerStyle={styles.item}
                      toggleLike={() => {
                        setEntityForApply(item);
                        setApplyModalShown(true);
                      }}
                      toggleBookmark={toggleOpportunityBookmark}
                    />
                  );
                } else if (item.like_type === '3' || item.bookmark_type === '3' || item.dislike_type === '3') {
                  return (
                    <EventItem
                      view={view}
                      isMobile={isMobile}
                      data={item}
                      containerStyle={styles.item}
                      toggleLike={() => {
                        setEntityForApply(item);
                        setApplyModalShown(true);
                      }}
                      toggleBookmark={toggleEventBookmark}
                    />
                  );
                } else {
                  return null;
                }
              }}
            />
            {applyModalShown && (
              <ApplyModal
                entity={entityForApply as IOpportunity}
                cancel={() => {
                  setApplyModalShown(false);
                  setEntityForApply({});
                }}
                confirm={() => {
                  setApplyModalShown(false);
                  setEntityForApply({});
                }}
                isLikesSection={true}
                text={(entityForApply as IOpportunity)?.application?.message ? (entityForApply as IOpportunity)?.application?.message : ''}
              />
            )}
          </>
        ) : (
          <Title size={'h2'} containerStyle={styles.noResults}>
            {t('No data found')}
          </Title>
        )}
      </div>
    </>
  );
};

export default List;
