export interface IError {
  message?: string;
  stack?: string;
  location?: string;
  path?: string;
}

export interface IErrorObject {
  id: string;
  error: IError;
  type: string;
}

export interface ErrorState {
  messages: IErrorObject[];
}

export enum EErrorActionTypes {
  SET_MESSAGE = '@error/SET_MESSAGE',
  CLEAR_MESSAGE = '@error/CLEAR_MESSAGE',
  CLEAR_ALL_MESSAGES = '@error/CLEAR_ALL_MESSAGES',
}

export type TSetMessageAction = {
  type: EErrorActionTypes.SET_MESSAGE;
  value: { error: IError; type: string };
};

export type TClearMessageAction = {
  type: EErrorActionTypes.CLEAR_MESSAGE;
  value: string;
};

export type TClearAllMessageAction = {
  type: EErrorActionTypes.CLEAR_ALL_MESSAGES;
};

export type TErrorActionType = TSetMessageAction | TClearMessageAction | TClearAllMessageAction;
