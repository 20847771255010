import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';

import ButtonMain from 'src/components/buttons/ButtonMain/index.web';

const Footer: FC<IProps> = memo((props) => {
  const { isMobile, isNextDisabled, onBack, onNext, finishOnboarding } = props;
  const { t } = useTranslation();
  const theme: any = useTheme();
  const styles = useStyles({ isMobile, ...theme });

  const match = useRouteMatch();
  const location = useLocation();

  const pathArr = location.pathname.split('/');
  const currentStep = Number(pathArr[pathArr.length - 1]);

  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === 10;

  const onBackPress = () => {
    onBack(`${match.path}/${currentStep - 1}`);
  };

  const onNextPress = () => {
    const nextStep = isLastStep ? 'last' : currentStep + 1;
    onNext(`${match.path}/${nextStep}`);
    isLastStep && finishOnboarding();
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footerButtons}>
        {!isFirstStep && <ButtonMain type={'primary_2'} size={'xl'} text={t('Back')} containerStyle={styles.footerButtonBack} outline onPress={onBackPress} />}
        <ButtonMain
          disabled={isNextDisabled}
          type={'primary_1'}
          size={'xl'}
          text={isLastStep ? t('Finish') : t('Next')}
          containerStyle={isFirstStep ? styles.footerButtonOnly : styles.footerButtonNext}
          onPress={onNextPress}
        />
      </div>
    </div>
  );
});

export default Footer;
