import { createSelector } from 'reselect';

import { State } from '..';
import { TUIState } from './ui.types';

export const getUiState = (state: State) => state.ui;

export const xsDevice = createSelector([getUiState], (uiState: TUIState) => uiState.resolutions.xs);

export const smDevice = createSelector([getUiState], (uiState: TUIState) => uiState.resolutions.sm);

export const mdDevice = createSelector([getUiState], (uiState: TUIState) => uiState.resolutions.md);

export const lgDevice = createSelector([getUiState], (uiState: TUIState) => uiState.resolutions.lg);

export const getChosenTheme = createSelector([getUiState], (uiState: TUIState) => uiState.chosenTheme);

export const selectModalPrivacy = createSelector([getUiState], (uiState: TUIState) => uiState.modalPrivacy);

export const selectModalTerms = createSelector([getUiState], (uiState: TUIState) => uiState.modalTerms);

export const selectSubscriptions = createSelector([getUiState], (uiState: TUIState) => (uiState.subscriptions ? uiState.subscriptions : []));
