import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // alignItems: 'center',
    width: '100%',
    padding: '0',
    //borderTop: `1px solid ${theme.$defaultColor1}`,
    borderBottom: `1px solid ${theme.$defaultColor1}`,
  },
  title: (p: IProps) => ({
    color: p.active ? theme.$primaryColor1 : '',
    transition: '0.25s all ease',
    overflow: 'hidden',
  }),
  content: (p: IProps) => ({
    transition: '0.25s all ease',
    overflow: 'hidden',
    paddingBottom: p.active ? 15 : 0,
  }),
  header: (p: IProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '15px 0',
    color: p.active ? theme.$primaryColor1 : '',
    transition: '0.25s all ease',
    overflow: 'hidden',
    cursor: 'pointer',
    '& h1': {
      color: theme.$primaryColor2,
      fontWeight: '500',
      fontSize: 21,
      lineHeight: '24px',
    },
  }),
  headerIcon: (p: IProps) => ({}),
}));
