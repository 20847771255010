import React from 'react';
import styles from './styles.module.scss';
import { useQuery } from '@apollo/client';
import { PRIVACY_POLICY } from 'src/requests/queries';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PrivacyPage = () => {
  const { i18n } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const qLang = query.get('lang');

  const { data, loading } = useQuery(PRIVACY_POLICY, {
    variables: {
      lang: qLang ? qLang : i18n.language,
    },
  });

  function createMarkup(data: any) {
    return { __html: data };
  }

  return (
    <>
      <Helmet>
        <title>{'Privacy'}</title>
      </Helmet>
      <div className={styles.page}>
        {/*@ts-ignore*/}
        <div className={styles.pageInner} dangerouslySetInnerHTML={loading ? { __html: '' } : createMarkup(data.privatePolicy)} />
      </div>
    </>
  );
};

export default PrivacyPage;
