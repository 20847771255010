import React, { FC, useContext, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import Header from 'src/web/components/Header';
import ConversationList from '../../components/ConversationList';
import Conversation from '../../components/Conversation';
import { IProps } from './types';
import { ITheme } from 'src/constant/themes';
import styles from './styles.module.scss';
import { Context as MessagesContext } from 'src/contexts/MessagesContext/index.web';
import Modal from '../../components/Modal';
import ConversationMobile from '../../components/ConversationMobile';
import { IMessage } from 'src/requests/models';
import { useHistory } from 'react-router';
import { async } from 'q';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';

const getEntityType = (type: string) => (type === '1' ? 'company' : type === '2' ? 'opportunity' : type === '3' ? 'event' : '');

const MessagePage = React.forwardRef((props: IProps, ref) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const history = useHistory();

  const { isMobile } = props;

  const { setSearch, chosenConversation = {}, setChosenConversation, acceptMessage, rejectMessage, archiveMessage }: any = useContext(MessagesContext);

  const {
    id,
    name = '',
    logo = '',
    entity_id: entityId,
    message_type: entityType,
    is_accepted: isAccepted,
    is_archived: isArchived,
  }: IMessage = chosenConversation;

  const onBack = () => {
    history.push('/messages');
  };

  const onArchive = async (id: string, flag: boolean) => {
    await archiveMessage(id, flag);
    setChosenConversation(undefined);
  };

  useEffect(() => {
    setSearch();
  }, []);

  return (
    <>
      <Helmet>
        <title>{'Messages'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.main}>
          <ConversationList isMobile={isMobile} containerStyle={styles.msgList} />
          {!isMobile && (
            <Conversation
              isMobile={false}
              containerStyle={styles.conversation}
              data={chosenConversation}
              accept={acceptMessage}
              reject={rejectMessage}
              archive={onArchive}
            />
          )}
          {chosenConversation.id && (
            <Modal isModalOpened={isMobile && chosenConversation.id} onClose={() => setChosenConversation(undefined)}>
              <ConversationMobile
                containerStyle={styles.conversation}
                data={chosenConversation}
                onClose={() => setChosenConversation(undefined)}
                accept={acceptMessage}
                reject={rejectMessage}
                archive={onArchive}
              />
            </Modal>
          )}
        </div>
      </ErrorBoundaries>
    </>
  );
});

export default MessagePage;
