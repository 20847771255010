export interface IProps {
  isMobile: boolean;
  onChangeTab: (tab: EExploreFeedTypes) => void;
  activeTab: EExploreFeedTypes;
  result: any[];
}

export enum EExploreFeedTypes {
  GENERAL = 'general',
  COMPANY = 'company',
  EVENT = 'event',
  OPPORTUNITY = 'opportunity',
}
