import React, { useContext } from 'react';
import moment from 'moment';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { Context as SearchContext } from 'src/contexts/SearchContext';

import Title from 'src/components/titles/Title/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { ITheme } from '../../../../constant/themes';
import { useHistory } from 'react-router';
import { getSplittedAddress } from 'src/utilities';
import { useTranslation } from 'react-i18next';
import { formatInBrusselsTimeZone } from 'src/utilities/dateTimeFormatter';

interface ComponentStyles {
  component?: string;
  header?: string;
  body?: string;
  footer?: string;
}

const Item = (props: IProps) => {
  const theme: ITheme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles({ ...props, ...theme });
  const { fixed, containerStyle, data, toggleLike, toggleBookmark, view } = props;
  const { setApplyModalShown, setEntityForApply } = useContext(SearchContext);
  const {
    id,
    name,
    company_name,
    main_description,
    types = [],
    address,
    start_date,
    end_date,
    is_liked = view === 'likes',
    is_bookmarked = view === 'bookmarks',
  } = data;

  const onViewEvent = () => {
    history.push('/event/' + id);
  };
  const showApplyModal = () => {
    setApplyModalShown(true);
    setEntityForApply(data);
  };

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
    header: `${styles.block} ${styles.header}`,
    body: `${styles.block} ${styles.body}`,
    footer: `${styles.block} ${styles.footer}`,
  };

  const addressText = getSplittedAddress(address);

  return (
    <div className={cn.component}>
      <div className={cn.header} onClick={onViewEvent}>
        {fixed ? null : (
          <div style={{ display: 'flex' }}>
            <div>
              <div className={styles.logo} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Title size={'h3'} containerStyle={styles.companyName}>
                {company_name}
              </Title>
              {types && <div className={styles.types}>{types && types[0] && types[0].name}</div>}
            </div>
          </div>
        )}
        <Title size={'h2'} containerStyle={styles.eventName}>
          {name}
        </Title>
        <div className={styles.eventInfo}>
          {fixed ? <div className={styles.eventInfoImg} /> : null}
          {fixed ? <div className={styles.eventType}>{types && types[0] && types[0].name}</div> : null}
          {fixed ? (
            <div className={styles.eventShortDate}>
              <div className={styles.eventShortDateDay}>{moment(start_date).format('DD')}</div>
              <div className={styles.eventShortDateMonth}>{moment(start_date).format('MMM')}</div>
            </div>
          ) : null}
          <Title size={'h3'} containerStyle={styles.eventInfoItem}>
            <Icon size={20} color={fixed ? 'white' : theme.$primaryColor1} name={'calendar'} />
            {fixed ? formatInBrusselsTimeZone(start_date) : `${formatInBrusselsTimeZone(start_date)} - ${formatInBrusselsTimeZone(end_date)}`}
          </Title>
          <div className={styles.eventInfoItem}>
            <Icon size={20} color={fixed ? 'white' : theme.$primaryColor1} name={'location'} />
            <span style={{ fontWeight: 500 }}>{addressText[0]}</span>
            <span>{addressText[1]}</span>
          </div>
        </div>
      </div>
      <div className={cn.body} onClick={onViewEvent}>
        {main_description}
      </div>
      <div className={cn.footer}>
        {!view || view !== 'bookmarks' ? (
          <>
            {is_liked ? (
              <ButtonMain
                outline
                size={'lg'}
                type={'primary_1'}
                text={t('Interested')}
                containerStyle={styles.setInterest}
                onPress={() => {
                  showApplyModal();
                }}
              />
            ) : (
              <ButtonMain
                size={'lg'}
                type={'primary_1'}
                text={t('Show interest event')}
                containerStyle={styles.setInterest}
                onPress={() => {
                  showApplyModal();
                }}
              />
            )}
          </>
        ) : null}
        {(!view || view === 'explorer') && !is_liked && (
          <ButtonIconToggle
            size={'lg'}
            activeIconName={'bookmark-fill'}
            inactiveIconName={'bookmark'}
            containerStyle={styles.btnMain}
            iconSize={20}
            iconColor={'#F65532'}
            active={is_bookmarked}
            onPress={() => toggleBookmark(id, !is_bookmarked)}
          />
        )}
        {view === 'bookmarks' ? (
          <ButtonMain
            outline
            size={'lg'}
            type={'primary_1'}
            text={t('Remove bookmark')}
            containerStyle={`${styles.btnMain} ${styles.setInterest}`}
            onPress={() => toggleBookmark(id, false)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Item;
