import React, { memo, useEffect, useRef, useState, useContext } from 'react';
import { useStyles } from './styles';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import University from './University';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import { DEGREE_LIST, GRADE_LIST, UNIVERSITY_LIST } from 'src/requests/queries';
import { useQuery } from '@apollo/client';
import Devider from 'src/components/dividers/Divider/index.web';
import Title from 'src/components/titles/Title/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import Input from 'src/components/inputs/TextInput/index.web';
import Text from 'src/components/texts/Text/index.web';

import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import { IOptionItem } from 'src/requests/models';
import { useTranslation } from 'react-i18next';

const Education = memo((props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { setPage, onClose, achievementList } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;

  const {
    institutes: defaultInstitutes,
    achievements: defaultAchievements,
    otherAchievements: defaultOtherAchievements,
    setData,
  }: any = useContext(ProfileContext);

  const [scrollTop, setScrollTop] = useState(0);
  const [achievements, setAchievements] = useState(defaultAchievements);
  const [otherAchievements, setOtherAchievements] = useState(defaultOtherAchievements);
  const [institutes, setInstitutes] = useState(
    defaultInstitutes.map((item) => ({
      ...item,
      institute: item.manual_input ? undefined : item.institute,
      custom_institute: item.manual_input ? item.institute : undefined,
      subject: item.manual_input ? undefined : item.subject,
      custom_subject: item.manual_input ? item.subject : undefined,
      minor: item?.minor || '',
    })),
  );

  const universityList = useQuery(UNIVERSITY_LIST, { variables: { lang } });
  const gradeList = useQuery(GRADE_LIST);
  const degreeList = useQuery(DEGREE_LIST, { variables: { lang } });

  const [ready, setReady] = useState(false);
  const [minors, setMinors] = useState([]);

  const uniContainerRef = useRef(null);

  const nextDisabled = institutes.some(
    (i: any) =>
      !(((i?.institute?.id && i?.subject) || (i?.custom_institute?.name && i?.custom_subject?.name)) && i?.archetype && i.degree && i.graduation_date),
  );

  const onCancelPress = () => {
    onClose();
  };

  const onSavePress = async () => {
    try {
      setData({ achievements, institutes, otherAchievements });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const onAddClick = () => {
    //@ts-ignore
    setScrollTop(uniContainerRef.current.scrollTop);
    //@ts-ignore
    setInstitutes([
      ...institutes,
      ...[
        {
          key: Math.random(),
          institute: { id: null, name: null },
          subject: null,
          degree: { id: '2', name: 'Master' },
          graduation_date: null,
          average_grades: null,
        },
      ],
    ]);
  };

  const onSelectAchievements = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setAchievements(achievements.concat([item]));
    } else if (action === 'rem') {
      setAchievements(achievements.filter((i: IOptionItem) => i.name !== item.name).slice());
    }
  };

  const setInstituteData = (index: number, data?: any) => {
    const intitute = data ? { ...institutes[index], ...data } : undefined;
    const copyInstitutes = institutes.slice();
    if (intitute) {
      copyInstitutes.splice(index, 1, intitute);
    } else {
      copyInstitutes.splice(index, 1);
    }
    setInstitutes(copyInstitutes);
  };

  useEffect(() => {
    setReady(!(universityList.loading || gradeList.loading));
  }, [universityList.loading, gradeList.loading]);

  useEffect(() => {
    setPage('profileEdit');
  }, []);

  useEffect(() => {
    if (ready) {
      //@ts-ignore
      uniContainerRef.current.scrollTop = scrollTop;
    }
  }, [institutes.length, ready, scrollTop]);

  const { t } = useTranslation();

  const checkForMinors = (minors) => {
    setMinors(minors);
  };

  return ready ? (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Education')}
        </Title>
      </div>
      <div className={styles.container} ref={uniContainerRef}>
        <div className={styles.list}>
          {institutes.map((u: any, index: number) => {
            return (
              <University
                key={'' + u?.institute?.id + index || u.key}
                index={index}
                list={universityList.data ? universityList.data.institutes : []}
                degrees={degreeList.data ? degreeList.data.degrees : []}
                grades={gradeList.data ? gradeList.data.grades : []}
                setData={setInstituteData}
                data={u}
                isMobile
                checkForMinors={checkForMinors}
              />
            );
          })}
        </div>
        <div className={styles.add}>
          <ButtonMain type={'primary_1'} size={'xl'} outline text={`+ ${t('Add education')}`} containerStyle={styles.addBtn} onPress={onAddClick} />
        </div>
        <Devider />
        <Title size={'h1'} containerStyle={`${styles.title} ${styles.titleAchievements}`}>
          {t('Achievements')}
        </Title>
        <div className={styles.achievements}>
          {achievementList.map((achievementItem: IOptionItem) => {
            const active = !!achievements.find((value: IOptionItem) => value.name === achievementItem.name);
            return (
              <ButtonToggle
                key={achievementItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.achievement}
                text={achievementItem.name}
                onPress={() => onSelectAchievements(achievementItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        <div className={styles.textAreaTexts}>
          <Text containerStyle={styles.fieldLabel}>{t('Other achievements')}</Text>
          <Text type={'secondary'} containerStyle={styles.textAreaMax}>
            500 {t('characters max')}
          </Text>
        </div>
        <Input value={otherAchievements} containerStyle={styles.textArea} inputStyle={styles.textAreaInput} type={'textarea'} onChange={setOtherAchievements} />
      </div>
      <div className={styles.footer}>
        <ButtonMain type={'primary_2'} size={'xl'} text={t('Cancel')} containerStyle={styles.footerButtonBack} outline onPress={onCancelPress} />
        <ButtonMain type={'primary_1'} size={'xl'} text={t('Save')} containerStyle={styles.footerButtonNext} onPress={onSavePress} disabled={nextDisabled} />
      </div>
    </div>
  ) : null;
});

export default Education;
