import React from 'react';
import { IErrorObject, clearMessage } from 'src/state/modules/error';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { useStyles } from './styles';
import { IErrorsProps, IErrorProps } from './types';
import { useDispatch } from 'react-redux';

const Error = (props: IErrorProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const dispatch = useDispatch();
  const { itemStyle, errorObject } = props;
  const { error, id, type } = errorObject;

  const onClose = () => {
    dispatch(clearMessage(id));
  };

  return (
    <div className={`${styles.item} ${itemStyle}`}>
      <div className={styles.close} onClick={onClose} />
      <div className={styles.itemType}>{type}</div>
      <textarea className={styles.itemMessage} rows={3} disabled>
        {error.message}
      </textarea>
    </div>
  );
};

const Errors = (props: IErrorsProps) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { containerStyle, itemStyle, errors } = props;

  return errors.length ? (
    <div className={`${styles.list} ${containerStyle}`}>
      {errors.map((errorObject: IErrorObject) => (
        <Error key={errorObject.id} itemStyle={itemStyle} errorObject={errorObject} />
      ))}
    </div>
  ) : null;
};

export default Errors;
