import React, { createContext, useReducer, useState } from 'react';
import { IOptionItem } from '../../requests/models';
import { Provider as CompaniesSearchProvider } from './CompaniesSearchContext';
import { Provider as OpportunitySearchContext } from './OpportunitySearchContext';
import { Provider as EventSearchContext } from './EventSearchContext';
import { Provider as GlobalSearchContext } from './GlobalSearchContext';
import { IGContext, ISearchFilters } from './types';
import { IEvent } from '../../components/cards/Event/types';
import { IOpportunity as IOpportunityType } from '../../components/cards/Opportunity/types';

interface IProps {
  children: any;
}

const entitiesList: IOptionItem[] = [
  { id: '4', name: 'everything' },
  { id: '1', name: 'companies' },
  { id: '2', name: 'events' },
  { id: '3', name: 'opportunities' },
];

const defaultFilters = {
  searchValue: '',
  entity: { id: '4', name: 'everything' },
  hiringCompanies: false,
  industry: [],
  companySize: [],
  tags: [],
  perks: [],
  types: [],
  experience: [],
  degree: [],
  companyId: undefined,
  startDate: undefined,
  endDate: undefined,
  limit: 10,
  page: 1,
};

const reducer = (state: ISearchFilters, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...state, [action.key]: action.value };
    case 'multiset':
      return { ...state, ...action.value };
    case 'clear':
      return defaultFilters;
    default:
      throw new Error();
  }
};

const Context = createContext({} as IGContext);
Context.displayName = '_SEARCH_';

const Provider = (props: IProps) => {
  const { children } = props;
  const [entity, setEntityOperation] = useState<IOptionItem>(entitiesList[0]);
  const [filters, dispatch] = useReducer(reducer, defaultFilters);
  const [applyModalShown, setApplyModalShown] = useState(false);
  const [entityForApply, setEntityForApply] = useState<IEvent | IOpportunityType | {}>({});
  const { searchValue, hiringCompanies, industry, companySize, tags, perks, types, experience, degree, companyId, startDate, endDate, limit, page } = filters;
  const setFilters = (key: string, value: any) => {
    if (key !== undefined) {
      dispatch({ type: 'set', key, value });
    } else {
      dispatch({ type: 'multiset', value });
    }
  };

  const setEntity = (value: IOptionItem) => {
    setEntityOperation(value);
  };

  const setEntityMob = (value: IOptionItem) => {
    setFilters('entity', value);
    setEntityOperation(value);
  };

  const clearSearchFilters = () => {
    dispatch({ type: 'clear' });
  };

  const setEntityByName = (name: string) => {
    const found = entitiesList.find((item: IOptionItem) => item.name === name);
    if (found) {
      setEntity(found);
    }
  };

  return (
    <Context.Provider
      value={{
        filters,
        entity,
        setEntity,
        setEntityByName,
        setFilters,
        clearSearchFilters,
        setEntityMob,
        applyModalShown,
        setApplyModalShown,
        entityForApply,
        setEntityForApply,
      }}>
      <CompaniesSearchProvider
        filters={{
          searchValue,
          hiringCompanies,
          industry,
          companySize,
          tags,
          perks,
          limit,
          page,
        }}
        setFilters={setFilters}
        setEntityByName={setEntityByName}>
        <OpportunitySearchContext
          filters={{
            searchValue,
            industry,
            companySize,
            types,
            experience,
            degree,
            companyId,
            limit,
            page,
          }}
          setFilters={setFilters}
          setEntityByName={setEntityByName}>
          <EventSearchContext
            filters={{
              searchValue,
              startDate,
              endDate,
              types,
              companyId,
              limit,
              page,
            }}
            setFilters={setFilters}
            setEntityByName={setEntityByName}>
            <GlobalSearchContext
              filters={{
                searchValue,
                limit,
                page,
              }}
              setFilters={setFilters}
              setEntityByName={setEntityByName}
              applyModalShown={applyModalShown}
              setApplyModalShown={setApplyModalShown}
              entityForApply={entityForApply}
              setEntityForApply={setEntityForApply}>
              {children}
            </GlobalSearchContext>
          </EventSearchContext>
        </OpportunitySearchContext>
      </CompaniesSearchProvider>
    </Context.Provider>
  );
};

export { Context, Provider };
