import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      width: '100%',
      maxWidth: 705,
      margin: '60px auto',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '6px 6px 0px 0px',
    },
    backgroundImage: ({ banner_img }: IProps) => ({
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
      padding: 30,
      backgroundImage: `url(${banner_img ? banner_img.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: 350,
      borderRadius: '6px 6px 0px 0px',
      zIndex: 1,
    }),
    main: {
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      background: 'white',
      padding: '20px 30px 30px',
      zIndex: 2,
      borderRadius: '0 0 6px 6px',
    },
    mainTitle: {
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      maxWidth: '50%',
    },
    mainItem: {
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    mainItemTop: {
      marginBottom: 30,
    },
    mainItemInfo: {
      padding: '10px 0',
    },
    mainHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
      marginBottom: 20,
    },
    mainInfoLogo: ({ companyData }: IProps) => ({
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 90,
      height: 90,
      border: '2px solid #FFFFFF',
      borderRadius: 5,
      flexShrink: 0,
      backgroundColor: 'white',
      backgroundImage: `url(${companyData ? companyData.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    labels: {
      display: 'flex',
    },
    label: {
      justifyContent: 'flex-start',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      flexGrow: 1,
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    labelType: {
      margin: 0,
    },
    labelIcon: {},
    linkText: {
      color: theme.$primaryColor2,
      fontSize: 12,
      opacity: 0.75,
    },
    labelOther: {
      color: '#282828',
      fontWeight: 500,
      opacity: 1,
      '& svg *': {
        fill: theme.$primaryColor1,
      },
    },
    labelList: {
      width: 'auto',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexShrink: 0,
      flexGrow: 1,
    },
    overall: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      transition: '0.5s all ease',
      overflowY: 'hidden',
      wordBreak: 'break-all',
    },
    overallValue: {
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      resize: 'none',
      overflow: 'hidden',
    },
    overallTitle: {
      width: '100%',
      display: 'none',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 0,
    },
    overallBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0 0',
    },
    perks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      background: '#FFFFFF',
      border: `1px solid ${theme.$borderColor}`,
      boxSizing: 'border-box',
      borderRadius: 10,
      marginBottom: 15,
    },
    btnToggle: {},
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    demoToggleTitle: {
      maxWidth: '37rem',
      width: '100%',
      cursor: 'pointer',
    },
    toggleText: {
      borderTop: 'none',
      padding: 0,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    toggleTextItem: {},
    viewCompany: {
      maxWidth: 'none',
    },
    bio: {
      margin: '10px 0',
      lineHeight: '21px',
    },
    eventInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: -13,
    },
    eventType: {},
    eventDate: {},
    eventDateDay: {
      color: 'white',
      fontSize: 24,
      fontWeight: 500,
    },
    eventDateMonth: {
      color: 'white',
      fontSize: 16,
    },
    setInterest: {
      maxWidth: 215,
    },
    companyInfo: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
    },
    companyLogo: ({ companyData }: IProps) => ({
      width: 25,
      height: 25,
      borderRadius: 2,
      backgroundImage: `url(${companyData ? companyData.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      marginRight: 15,
    }),
    companyTitle: {
      margin: 0,
    },
    eventOrganizer: {
      marginTop: 15,
    },
    eventOverall: {
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      padding: '25px 15px',
      margin: '10px 0 0',
    },
    eventInfoItem: ({ fixed }: any) => ({
      position: 'relative',
      margin: '0 0 10px',
      fontSize: 13,
      color: fixed ? 'white' : 'black',
      wordBreak: 'break-word',

      '&:last-child': {
        margin: 0,
      },

      '& svg': {
        marginRight: 12,
        flexShrink: 0,
        float: 'left',
      },
    }),
    shareButton: {
      background: 'transparent',
      backgroundColor: 'transparent !important',
      border: '1px solid #F65532',
      width: '40px !important',
      height: '40px !important',
      margin: '0 0 0 auto',
    },
  };
});
