import React, { createContext, useState, memo, useEffect } from 'react';
import { IOptionItem } from '../../../requests/models';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { IEvents } from '../types';
import { EVENTS } from '../../../requests/queries';
import { UPDATE_EVENT_BOOKMARK, UPDATE_EVENT_LIKE } from '../../../requests/mutations';
import { useTranslation } from 'react-i18next';
import { IEvent } from '../../../components/cards/Event/types';
import { IOpportunity } from '../../../components/cards/Opportunity/types';

export interface IEventFilter {
  searchValue: string;
  startDate: Date | null;
  endDate: Date | null;
  types: IOptionItem[];
  limit: number;
  page: number;
  companyId: string;
}

interface IProps {
  children: any;
  filters: IEventFilter;
  setFilters: any;
  setEntityByName: any;
}

const customLikeUpdate = (cache: any, { data: { updateEventLike } }: { data: any }) => {
  const { id, is_liked, name } = updateEventLike;
  const item: any = {
    __typename: 'Event',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_liked: (cached: any) => {
        return is_liked;
      },
    },
  });
};

const customBookmarkUpdate = (cache: any, { data: { updateEventBookmark } }: { data: any }) => {
  const { id, is_bookmarked, name } = updateEventBookmark;
  const item: any = {
    __typename: 'Event',
    id,
    name,
  };
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });

  // FOR FEED
  item.__typename = 'FeedElementType';
  cache.modify({
    id: cache.identify(item),
    fields: {
      is_bookmarked: (cached: any) => {
        return is_bookmarked;
      },
    },
  });
};

const Context = createContext({} as IEvents);
Context.displayName = '_EVENT_SEARCH_';

const Provider = memo((props: IProps) => {
  const { children, filters, setFilters, setEntityByName } = props;
  const { i18n } = useTranslation();
  const { language: lang } = i18n;
  const [enableSave, setEnableSave] = useState(false);
  const [enableFilters, setEnableFilters] = useState(false);
  const { searchValue, startDate, endDate, types, companyId, limit, page } = filters;
  const [queryFilters, setQueryFilters] = useState({
    searchValue,
    startDate,
    endDate,
    types,
    companyId,
    limit,
    page,
  });

  const [
    getEvents,
    {
      loading,
      data = {
        events: {
          page: 1,
          total: 1,
          limit: 10,
          result: [],
        },
      },
      fetchMore,
      called,
    },
  ] = useLazyQuery(EVENTS, {
    variables: {
      lang,
      filters: {
        company_id: queryFilters.companyId,
        types: queryFilters.types.length ? queryFilters.types.map((i: IOptionItem) => i.id) : undefined,
        start_date: queryFilters.startDate,
        end_date: queryFilters.endDate,
        name: queryFilters.searchValue ? queryFilters.searchValue : undefined,
      },
      limit: queryFilters.limit,
      page: queryFilters.page,
    },
    fetchPolicy: enableSave ? 'cache-and-network' : 'cache-only',
  });

  const [eventLike, { loading: eventLikeLoading }] = useMutation(UPDATE_EVENT_LIKE);

  const [eventBookmark, { loading: eventBookmarkLoading }] = useMutation(UPDATE_EVENT_BOOKMARK);

  const setData = (data: IEventFilter) => {
    setFilters(undefined, data);
  };

  const setSearch = () => {
    getEvents();
    setEnableFilters(true);
  };

  const setLike = async (id: string | number, is_liked: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customLikeUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };

      await eventLike({ variables: { like: { id: id + '', is_liked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  const setBookmark = async (id: string | number, is_bookmarked: boolean, update?: any) => {
    try {
      const mergeUpdates = (cache: any, result: any) => {
        customBookmarkUpdate(cache, result);
        if (update) {
          update(cache, result);
        }
      };
      await eventBookmark({ variables: { bookmark: { id: id + '', is_bookmarked } }, update: mergeUpdates });
    } catch (e) {
      console.error(e);
    }
  };

  const setClear = () => {
    setFilters(undefined, {
      searchValue: '',
      types: [],
      startDate: undefined,
      endDate: undefined,
      companyId: undefined,
      page: 1,
    });
  };

  const setSearchStart = () => {
    setFilters('page', 1);
    setEntityByName('events');
    setSearch();
  };

  const setSearchNext = async () => {
    if (!loading && data.events && data.events.result.length) {
      // if (page === 1 && data.events.result.length !== data.events.total) setSearch();
      setFilters('page', data.events.result.length / 10 + 1);
      if (called && data.events.result.length !== data.events.total) {
        //@ts-ignore
        await fetchMore({
          variables: {
            page: data.events.result.length / 10 + 1,
          },
          //@ts-ignore
          updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any }) => {
            return Object.assign({}, prev, {
              events: {
                ...fetchMoreResult.events,
                result: [...prev.events.result, ...fetchMoreResult?.events?.result],
              },
            });
          },
        });
      }
    }
  };

  useEffect(() => {
    if (enableFilters) {
      setQueryFilters({
        searchValue,
        companyId,
        startDate,
        endDate,
        types,
        limit,
        page,
      });
      setEnableFilters(false);
      setEnableSave(true);
    }
  }, [enableFilters, companyId, searchValue, startDate, endDate, types, limit, page]);

  useEffect(() => {
    if (enableSave && !loading && data) {
      setEnableSave(false);
    }
  }, [enableSave, loading, data]);

  return (
    <Context.Provider
      //@ts-ignore
      value={{
        searchValue,
        startDate,
        endDate,
        types,
        limit,
        page,
        setData,
        setSearch,
        setSearchStart,
        setSearchNext,
        setClear,
        setLike,
        setBookmark,
        result: data.events ? data.events.result : [],
        companyId,
      }}>
      {children}
    </Context.Provider>
  );
});

export { Context, Provider };
