import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => {
  return {
    buttonSwitcher: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonSwitcherLeft: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      maxWidth: 'none',
    },
    buttonSwitcherRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      maxWidth: 'none',
    },
  };
});
