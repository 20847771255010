import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import WithOnboarding from '../WithOnboarding';

interface IProps extends RouteComponentProps {
  children: any;
  path: string;
  allowed?: boolean;
}

const WithAuth = (props: IProps) => {
  const { children, path } = props;
  return <WithOnboarding path={path}>{children}</WithOnboarding>;
};

export default withRouter<IProps, any>(WithAuth);
