import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSubscription, selectSubscriptions } from '../../../state/modules/ui';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { useStyles } from './styles';
import Icon from '../../../components/icons/Icon';
import { useTranslation } from 'react-i18next';
import { IOptionItem } from '../../../requests/models';

const Subscription = ({ subscription }: { subscription: IOptionItem }) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });
  const [timer, setTimer] = useState<any>(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    setTimer(
      setTimeout(
        () => {
          dispatch(removeSubscription(subscription.id));
        },
        subscription.type === 'c' || subscription.type === 'share' ? 3000 : 6000,
      ),
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const textCompany = () =>
    subscription.type === 'c' ? (
      <>
        {`${t('You now follow')}`}
        <span className={styles.subscriptionName}> {subscription.name}</span>
        {`. ${t('New jobs or events will appear more prominent on your Explore feed')}.`}
      </>
    ) : subscription.type === 'd' ? (
      <>{`${t('The changes were saved!')}`}</>
    ) : subscription.type === 'share' ? (
      <>{`${t('Link copied to your clipboard')}`}</>
    ) : (
      ''
    );

  const textOpportunityAndEvent = () =>
    subscription.type && ['o', 'e'].includes(subscription.type) ? (
      <>
        {`${t('You applied for')}`}
        <span className={styles.subscriptionName}> {subscription.name}</span>
      </>
    ) : (
      ''
    );

  return (
    <div className={styles.subscription}>
      <div className={styles.checkmark}>
        <Icon name={'checkmark'} color={theme.$successColor} size={16} />
      </div>
      <div className={styles.text}>
        {textCompany()}
        {textOpportunityAndEvent()}
      </div>
    </div>
  );
};

const SubscribeBanners = () => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });

  const subscriptions = useSelector((state) => selectSubscriptions(state));

  return (
    <div className={`${styles.component}`}>
      {subscriptions.length > 0 && (
        <div className={styles.subscriptions}>
          {subscriptions.map((sub: IOptionItem) => (
            <Subscription key={sub.id} subscription={sub} />
          ))}
          <div className={styles.grayLine} />
        </div>
      )}
    </div>
  );
};

export default SubscribeBanners;
