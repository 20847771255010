import React, { FC, useContext, useEffect } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Divider from 'src/components/dividers/Divider/index.web';
import { Context as LikesContext } from 'src/contexts/LikesContext/index.web';
import { Context as OnboardingContext } from 'src/contexts/OnboardingContext';

import CompanyList from 'src/web/components/Company/List';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { OnboardingData } from 'src/requests/models';
import { useTranslation } from 'react-i18next';
import { useLoadOnScroll } from '../../../../hooks/useLoadOnScroll';

interface IProps {
  isMobile: boolean;
  children?: any;
  ref: any;
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding8: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { setIsNextDisabled, setPage } = props;
  const { t } = useTranslation();
  const { fetchCompanies, fetchMoreCompanies, companies }: any = useContext(OnboardingContext);
  const { result: likesResult }: any = useContext(LikesContext);

  useLoadOnScroll({ setSearchNext: fetchMoreCompanies, result: companies });

  const companiesToSelect = 3;

  useEffect(() => {
    setIsNextDisabled(likesResult.length < companiesToSelect);
  }, [likesResult]);

  useEffect(() => {
    fetchCompanies();
    setPage('8');
  }, []);

  const getTitle = (selected: number) => {
    const remaining = companiesToSelect - selected;
    let title = '';
    switch (true) {
      case selected >= companiesToSelect:
        title = t('You can follow some more companies if you like');
        break;
      case remaining === companiesToSelect:
        title = t('Follow at least 3 companies');
        break;
      case remaining === 2:
        title = t('Follow at least 2 more companies');
        break;
      default:
        title = t('Follow at least 1 more company');
    }
    return title;
  };

  return (
    <>
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {getTitle(likesResult.length)}
        </Title>
        <CompanyList containerStyle={styles.list} isMobile={true} view={'onboarding'} companies={companies} />
      </div>
    </>
  );
};

export default Onboarding8;
