import React from 'react';
import SearchPage from '../containers/SearchPage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';

const Container = () => (
  <Switch>
    <PrivateRoute path={'/search/companies'}>
      <Page>
        {({ isMobile }: IPassProps) => {
          return <SearchPage isMobile={isMobile} entity="companies" />;
        }}
      </Page>
    </PrivateRoute>
    <PrivateRoute exact path={'/search/events'}>
      <Page>
        {({ isMobile }: IPassProps) => {
          return <SearchPage isMobile={isMobile} entity="events" />;
        }}
      </Page>
    </PrivateRoute>
    <PrivateRoute exact path={'/search/opportunities'}>
      <Page>
        {({ isMobile }: IPassProps) => {
          return <SearchPage isMobile={isMobile} entity="opportunities" />;
        }}
      </Page>
    </PrivateRoute>
    <PrivateRoute path={'/search/all'}>
      <Page>
        {({ isMobile }: IPassProps) => {
          return <SearchPage isMobile={isMobile} entity="everything" />;
        }}
      </Page>
    </PrivateRoute>
    <Redirect to={'/search/all'} />
  </Switch>
);

export default Container;
