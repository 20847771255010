import { createUseStyles } from 'react-jss';
import { IProps } from './types';

export const useStyles = createUseStyles({
    component: ({theme}: any) =>({
      width: '100%',
      maxWidth: 510,
      margin: '0 auto',
      border: `1px solid ${theme.$borderColor}`,
      borderRadius: 15,
    }),
    head: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#F9FBFC',
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      padding: 15,
    },
    img: {
      width: 90,
      height: 80,
    },
    title: {},
    text: {},
    input: {
      maxWidth: ['none', '!important'],
      margin: '15px 0',
    },
    inputLabel: {},
    body: {
      background: 'white',
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
      padding: 15,
    },
    button: {
      maxWidth: '100%',
    },
    successMsg: ({ isMobile }: IProps) => ({
      position: 'fixed',
      top: 100,
      left: isMobile ? '50%' : 50,
      transform: isMobile ? 'translate(-50%, 0)' : '',
      display: 'flex',
      flexDirection: 'column',
      width: 320,
      height: 100,
      borderRadius: 15,
      padding: 15,
      background: 'white',
      boxShadow: 'rgba(145, 165, 182, 0.24) 0px -1px 8px 2px',
    }),
    successMsgHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    successMsgImg: {
      marginRight: 20,
    },
    successMsgText: ({theme}: any)=>({
      fontSize: '1.4rem',
      color: theme.$primaryColor1,
    }),
    successMsgBody: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
    submitContainer: {
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translate(100%, -100%)',
      background: 'white',
      padding: '10px 20px',
      borderRadius: 5,
      boxShadow: '0px 5px 10px rgba(48, 70, 89, 0.08)',
    },
    tooltipItem: {
      margin: 5,
    },
  }
);
