import React, { memo } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Icon from 'src/components/icons/Icon/index.web';
import Title from 'src/components/titles/Title/index.web';
import Devider from 'src/components/dividers/Divider/index.web';
import Text from 'src/components/texts/Text/index.web';
import TextIcon from 'src/components/texts/TextIcon/index.web';
import Link from 'src/components/links/Link/index.web';
import ButtonIconToggle from 'src/components/buttons/ButtonIconToggle/index.web';
import FixedOpportunityList from 'src/web/components/Opportunity/List/Fixed';
import FixedEventList from 'src/web/components/Event/List/Fixed';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import { ITheme } from 'src/constant/themes';
import { useTranslation } from 'react-i18next';
import { getSlicedText } from 'src/utilities';
import { addSubscription } from '../../../../../state/modules/ui';
import { useDispatch } from 'react-redux';
import ButtonIcon from 'src/components/buttons/ButtonIcon/index.web';
import Markdown from 'src/web/components/Markdown';

const Company = memo((props: IProps) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const {
    id,
    name,
    website,
    cities,
    city,
    additional_info,
    bio,
    company_size = { name: '' },
    emoji = [],
    socials = [],
    sectors = [],
    is_liked,
    deleted,
    is_bookmarked = false,
    is_disliked = false,
    setCompanyLike,
    setCompanyBookmark,
    setCompanyDislike,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className={styles.component}>
      <div className={styles.companyBackground} />
      <main className={styles.main}>
        <div className={styles.mainLeft}>
          <div className={styles.mainTop}>
            <div className={styles.mainInfoLogo} />
            <div className={styles.sideLogo}>
              <Title size={'h1'} containerStyle={`${styles.title} ${styles.titleLogo}`}>
                {getSlicedText(name)}
              </Title>
              <Link containerStyle={styles.mainInfoSite} size={'sm'} internal={false} href={website}>
                {t('Website')}
              </Link>
              <TextIcon iconName={'location'} iconSize={14} containerStyle={`${styles.label} ${styles.labelLocation}`} iconStyle={styles.labelIcon}>
                {cities && cities.length ? cities.join(', ') : city || ''}
              </TextIcon>
              <TextIcon iconName={'briefcase'} iconSize={14} containerStyle={`${styles.label} ${styles.labelSection}`} iconStyle={styles.labelIcon}>
                {sectors && sectors[0] && sectors[0].name}
              </TextIcon>
              <TextIcon iconName={'user'} iconSize={14} containerStyle={`${styles.label} ${styles.labelUser}`} iconStyle={styles.labelIcon}>
                {company_size && company_size.name}
              </TextIcon>
            </div>
          </div>
          <div className={styles.perks}>
            {emoji.map((emojiItem) => (
              <div key={emojiItem.id} className={styles.perkItem}>
                <ButtonToggle
                  key={emojiItem.id}
                  size={'lg'}
                  hoverable={false}
                  active={false}
                  containerStyle={`${styles.btnToggle} ${styles.perkItemBtn}`}
                  textStyle={styles.perkItemValue}
                  text={emojiItem.name}
                />
                <div className={styles.perkItemName}>{emojiItem.value}</div>
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            {!is_disliked ? (
              <>
                {is_liked ? (
                  <ButtonMain
                    outline
                    disabled={!!deleted}
                    size={'xl'}
                    type={'primary_1'}
                    text={t('Subscribed')}
                    containerStyle={styles.setInterest}
                    onPress={setCompanyLike}
                  />
                ) : (
                  <ButtonMain
                    size={'xl'}
                    disabled={!!deleted}
                    type={'primary_1'}
                    text={t('Follow company')}
                    containerStyle={styles.setInterest}
                    onPress={() => {
                      dispatch(addSubscription({ id: '' + id, name, type: 'c' }));
                      setCompanyLike();
                    }}
                  />
                )}
                <ButtonIconToggle
                  size={'xl'}
                  disabled={!!deleted}
                  activeIconName={'bookmark-fill'}
                  inactiveIconName={'bookmark'}
                  containerStyle={styles.btnMain}
                  iconSize={20}
                  iconColor={'#F65532'}
                  active={is_bookmarked}
                  onPress={setCompanyBookmark}
                />
              </>
            ) : (
              <ButtonMain
                size={'xl'}
                type={'primary_1'}
                text={t('Ignored')}
                disabled={!!deleted}
                outline
                containerStyle={styles.setInterest}
                onPress={setCompanyDislike}
              />
            )}
          </div>
          <div className={styles.linksContainer}>
            <Devider size={'xl'} />
            <div className={styles.links}>
              {socials &&
                socials.map((item) => (
                  <Link
                    key={item.type}
                    containerStyle={styles.link}
                    internal={false}
                    //@ts-ignore
                    href={item.value}
                    size={'xs'}>
                    {item.type ? <Icon key={item.name} name={`${item.type.toLowerCase()}-outline`} color="rgba(172, 183, 191, 1)" size={30} /> : item.name}
                  </Link>
                ))}
              <ButtonIcon
                size="xs"
                type="default"
                active
                activeIconName={'share'}
                iconColor="red"
                iconSize={20}
                containerStyle={styles.shareButton}
                onPress={() => {
                  dispatch(addSubscription({ id: '' + id, name, type: 'share' }));
                  navigator.clipboard.writeText(window.location.href);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.mainRight}>
          <div className={styles.mainRightItem}>
            <Title size={'h1'} containerStyle={styles.title}>
              <Icon name={'text'} size={17} color={theme.$primaryColor1} />
              {t('Interesting to know')}
            </Title>
            <Text type={'primary'} containerStyle={styles.bio}>
              {bio}
            </Text>
            <Markdown isMobile={false} markdown={additional_info} />
          </div>
          <FixedOpportunityList isMobile={false} containerStyle={styles.sideEntitiesItemsList} company={id} />
          <FixedEventList isMobile={false} containerStyle={styles.sideEntitiesItemsList} company={id} />
        </div>
      </main>
    </div>
  );
});

export default Company;
