import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      padding: '20px 0',
    }),
    picture: {
      width: 46,
      flexShrink: 0,
      marginRight: 25,
    },
    info: (p: IProps) => ({
      display: p.isMobile ? 'block' : 'flex',
      padding: '0 10px',
      width: '100%',
    }),
    infoInner: {
      width: '100%',
    },
    title: {
      margin: 0,
    },
    date: {},
    grades: (p: IProps) => ({
      display: 'flex',
      flexDirection: p.isMobile ? 'row' : 'column',
      alignItems: p.isMobile ? 'center' : 'flex-end',
      margin: p.isMobile ? '10px 0 0 0' : '0 0 0 20px',
      width: p.isMobile ? 'auto' : 300,
    }),
    gradesTitle: (p: IProps) => ({
      margin: p.isMobile ? '0' : '0 0 10px 0',
    }),
    gradesValue: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px 15px',
      borderRadius: 5,
      backgroundColor: 'rgba(232, 239, 244, 0.5)',
    },
    editLink: (p: IProps) => ({
      height: 'auto',
      top: p.isMobile ? 15 : 30,
      right: p.isMobile ? 15 : 30,
      borderColor: 'transparent',
      width: 'auto',
      background: 'transparent',
    }),
    editLinkText: {
      color: theme.$primaryColor1,
    },
    gradesContainer: {
      display: 'flex',
    },
    gradeProofInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: '0 0 0 10px',
    },
    gradeProofUpload: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: '0 0 0 10px',
    },
    modal: (p: IProps) => ({
      cursor: 'pointer',
      height: p.isMobile ? '100%' : 400,
    }),
    modalContent: {
      height: '100%',
    },
    modalHeader: (p: IProps) => ({
      width: '100%',
      height: 60,
      display: 'flex',
      background: 'rgba(232, 239, 244, 0.25)',
      flexShrink: 0,
      padding: '10px 30px',
      borderTopLeftRadius: p.isMobile ? 0 : 15,
      borderTopRightRadius: p.isMobile ? 0 : 15,
    }),
    modalMain: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100% - 100px)',
    },
    modalButtons: {
      display: 'flex',
      padding: '0 15px',
      height: 100,
    },
    modalBtn: {
      flexGrow: 1,
      maxWidth: 'none',
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    modalGrade: (p: IProps) => ({
      maxWidth: p.isMobile ? 350 : 450,
    }),
  };
});
