import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(20%, -50%)',
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    display: 'block',
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontSize: 'inherit',
  },
}));
