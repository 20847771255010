import React, { FC, useEffect } from 'react';
import Text from 'src/components/texts/Text/index.web';
import TextInput from 'src/components/inputs/TextInput/index.web';
import EmailInput from 'src/components/inputs/EmailInput/index.web';
import Select from 'src/components/inputs/Select/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  label?: string;
  area?: string;
  input?: string;
  content?: string;
}

const CombinedInput: FC<IProps> = (props) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ theme });
  const {
    type,
    containerStyle,
    labelStyle,
    inputStyle,
    value,
    defaultValue,
    label,
    placeholder,
    onChange = () => {},
    onChoose = () => {},
    onPress = () => {},
    list = [],
  } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle}`.trim(),
    label: `${styles.label} ${labelStyle}`.trim(),
    area: `${styles.area} ${inputStyle}`.trim(),
    input: `${styles.input} ${inputStyle}`.trim(),
  };

  const onAreaChange = (e: any) => {
    const value = e.target.value;
    onChange(value);
  };

  const contentRender = () => {
    if (type === 'text') {
      return <TextInput value={value} containerStyle={cn.input} onPress={onPress} onChange={onChange} placeholder={placeholder} />;
    } else if (type === 'email') {
      return <EmailInput defaultValue={defaultValue} value={value} containerStyle={cn.input} onPress={onPress} onChange={onChange} placeholder={placeholder} />;
    } else if (type === 'area') {
      return <textarea value={value} className={cn.area} onClick={onPress} onChange={onAreaChange} placeholder={placeholder} />;
    }
  };

  return (
    <div className={cn.component}>
      <Text containerStyle={cn.label}>{label}</Text>
      <div className={cn.content}>{contentRender()}</div>
    </div>
  );
};

export default CombinedInput;
