import React, { useContext, useEffect, useRef, useState } from 'react';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import Title from 'src/components/titles/Title/index.web';
import { useTranslation } from 'react-i18next';
import Text from 'src/components/texts/Text/index.web';
import img from '../../../assets/images/empty_search.svg';

const List = (props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { t } = useTranslation();
  const { isMobile, containerStyle, value = '' } = props;

  return (
    <div className={`${styles.container} ${containerStyle}`.trim()}>
      <Title containerStyle={styles.title} size={'h2'}>
        {value}
      </Title>
      {isMobile ? <img className={styles.img} src={img} /> : ''}
      <div className={styles.subtitle}>{t('Nothing was found')}</div>
      <Text containerStyle={styles.text} text={t('Please check the spelling or try searching for something different')} />
    </div>
  );
};

export default List;
