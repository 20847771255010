import React, { FC, useEffect } from 'react';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import CheckBox from 'src/components/checkboxes/CheckBox/index.web';
import Divider from 'src/components/dividers/Divider/index.web';
import { useStyles } from './styles.web';
import { useTheme } from 'react-jss';
import { OnboardingData } from '../../../../requests/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrivacyTermsAgree, selectWhoAreYou } from 'src/state/modules/onboarding';

import { IOptionItem } from 'src/requests/models';
import { setModal } from 'src/state/modules/ui';
import { useTranslation } from 'react-i18next';

interface IProps {
  isMobile: boolean;
  children?: any;
  list: IOptionItem[];
  setData(data: OnboardingData): void;
  setPage(page: string): void;
  setIsNextDisabled(state: boolean): void;
}

const Onboarding1: FC<IProps> = (props) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { list, setData, setPage, setIsNextDisabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const whoAreYou = useSelector((state) => selectWhoAreYou(state));
  const privacyTermsAgree = useSelector((state) => selectPrivacyTermsAgree(state));

  const onCheck = (e: any) => {
    setData({ privacyTermsAgree: !privacyTermsAgree });
  };

  const onPrivacy = () => {
    dispatch(setModal({ key: 'privacy', value: true }));
  };

  const onTerms = () => {
    dispatch(setModal({ key: 'terms', value: true }));
  };

  const onChooseHandler = (whoAreYou: IOptionItem) => {
    setData({ whoAreYou });
  };

  useEffect(() => {
    // @ts-ignore
    const dataLayerExists = window?.dataLayer?.find((o) => o.event === 'onboarding_started');
    if (!dataLayerExists) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'onboarding_started'
      });
    }
    setPage('1');
  }, []);

  useEffect(() => {
    setIsNextDisabled(!(!!whoAreYou && privacyTermsAgree));
  }, [whoAreYou, privacyTermsAgree]);

  return (
    <>
      <div className={styles.body}>
        <Divider containerStyle={styles.divider} />
        <Title size={'h2'} containerStyle={styles.title}>
          {t('Who are you?')}
        </Title>
        <Text type={'default'} text={`${t('Please let us know which of the following personas fits you best, so we can tailor your Launch experience')}.`} />
        <div className={styles.list}>
          {list.map((item: IOptionItem) => (
            <ButtonToggle
              key={item.name}
              containerStyle={styles.listItem}
              textStyle={styles.listItemText}
              size={'md'}
              text={item.name}
              active={whoAreYou ? item.id === whoAreYou.id : false}
              onPress={() => onChooseHandler(item)}
            />
          ))}
        </div>
      </div>
      <Divider containerStyle={styles.divider} />
      <div className={styles.footer}>
        <div className={styles.agreement}>
          <CheckBox active={privacyTermsAgree} onPress={onCheck} />
          <Text containerStyle={styles.agreementText} inline>
            {t('I agree with')}&nbsp;
            <span className={styles.link} onClick={onPrivacy}>
              {t('Privacy statement')}
            </span>{' '}
            {t('and')}&nbsp;
            <span className={styles.link} onClick={onTerms}>
              {t('Terms')}
            </span>
          </Text>
        </div>
      </div>
    </>
  );
};

export default Onboarding1;
