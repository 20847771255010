import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types.web';

export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    display: 'flex',
    'align-items': 'center',
  },
  box: (props: IProps) => ({
    height: '2.5rem',
    width: '2.5rem',
    border: `1px solid ${theme.$borderColor}`,
    'border-radius': '5px',
    'margin-right': '1rem',
    transition: '0.25s all ease',
    backgroundColor: props.active ? theme.$primaryColor1 : 'transparent',
    cursor: 'pointer',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
  }),

  check: {
    position: 'relative',
    height: '3rem',

    '&:before': {
      content: '',
      position: 'absolute',
      display: 'flex',
      width: '0.2rem',
      height: '25%',
      top: '50%',
      left: '50%',
      transform: 'translate(-0.6rem, -30%) rotate(-40deg)',
      background: '#30455a',
      'border-radius': '1px',
    },
    '&:after': {
      content: '',
      position: 'absolute',
      display: 'flex',
      width: '0.2rem',
      height: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(0.2rem, -50%) rotate(45deg)',
      background: '#30455a',
      'border-radius': '1px',
    },
  },
}));
