import React, { memo, useEffect, useState, useContext } from 'react';
import { useStyles } from './styles';
import { IProps } from './types';
import { useTheme } from 'react-jss';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import ButtonToggle from 'src/components/buttons/ButtonRoundedToggle/index.web';
import Title from 'src/components/titles/Title/index.web';
import { IOptionItem } from 'src/requests/models';
import Devider from 'src/components/dividers/Divider/index.web';
import CheckBox from 'src/components/checkboxes/CheckBox/index.web';
import Text from 'src/components/texts/Text/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { Context as ProfileContext } from 'src/web/contexts/ProfileContexts/index.web';
import { useTranslation } from 'react-i18next';
import Input from 'src/components/inputs/TextInput/index.web';

const Activities = memo((props: IProps) => {
  const theme: any = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { setPage, list, onClose } = props;
  const {
    activities: defaultActivities,
    otherActivities: defaultOtherActivities,
    isBoardMember: defaultIsActivityAbroad,
    setData,
  }: any = useContext(ProfileContext);

  const [activities, setActivities] = useState(defaultActivities);
  const [isBoardMember, setIsActivityAbroad] = useState(defaultIsActivityAbroad);
  const [otherActivities, setOtherActivities] = useState(defaultOtherActivities);

  const onSelectExperience = (item: IOptionItem, action: 'add' | 'rem') => {
    if (action === 'add') {
      setActivities(activities.concat([item]));
    } else if (action === 'rem') {
      const result = activities.filter((i: IOptionItem) => i.name !== item.name).slice();
      setActivities(result);
      setIsActivityAbroad(result.length ? isBoardMember : false);
    }
  };

  const onCheck = (e: any) => {
    setIsActivityAbroad(!isBoardMember);
  };

  const onCancelPress = () => {
    onClose();
  };

  const onSavePress = async () => {
    try {
      setData({ activities, otherActivities, isBoardMember });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPage('profileEdit');
  }, []);

  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title size={'h1'} containerStyle={styles.title} onPress={onClose}>
          <Icon size={24} name={'arrow-left2'} color={'#222222'} />
          {` ${t('Activities')}`}
        </Title>
      </div>
      <div className={styles.container}>
        <div className={styles.activities}>
          {list.map((activityItem: IOptionItem) => {
            const active = !!activities.find((value: IOptionItem) => value.name === activityItem.name);
            return (
              <ButtonToggle
                key={activityItem.id}
                size={'lg'}
                icon
                active={active}
                containerStyle={styles.activity}
                text={activityItem.name}
                onPress={() => onSelectExperience(activityItem, active ? 'rem' : 'add')}
              />
            );
          })}
        </div>
        <Devider size={'xl'} />
        <div className={`${styles.checkSection} ${!activities.length ? styles.checkSectionDisabled : ''}`}>
          <CheckBox active={isBoardMember} onPress={onCheck} />
          <Text containerStyle={styles.checkSectionText} inline>
            {t('I’ve been in a board of one above')}
          </Text>
        </div>
        <div className={styles.textAreaTexts}>
          <Text containerStyle={styles.fieldLabel}>{t('Other activities')}</Text>
          <Text type={'secondary'} containerStyle={styles.textAreaMax}>
            500 {t('characters max')}
          </Text>
        </div>
        <Input value={otherActivities} containerStyle={styles.textArea} inputStyle={styles.textAreaInput} type={'textarea'} onChange={setOtherActivities} />
      </div>
      <div className={styles.footer}>
        <ButtonMain type={'primary_2'} size={'xl'} text={t('Cancel')} containerStyle={styles.footerButtonBack} outline onPress={onCancelPress} />
        <ButtonMain type={'primary_1'} size={'xl'} text={t('Save')} containerStyle={styles.footerButtonNext} onPress={onSavePress} />
      </div>
    </div>
  );
});

export default Activities;
