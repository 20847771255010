import { TSessionState, ESessionActionTypes, TSessionAction } from './session.types';

const initialState: TSessionState = {
  isAuthenticated: false,
  ready: false,
  token: undefined,
  tries: 3,
};

export const sessionReducer = (state: TSessionState = initialState, action: TSessionAction): TSessionState => {
  switch (action.type) {
    case ESessionActionTypes.SIGN_IN:
      return { ...state, isAuthenticated: true };

    case ESessionActionTypes.SIGN_OUT:
      return { ...state, isAuthenticated: false };

    case ESessionActionTypes.READY:
      return { ...state, ready: action.value };

    case ESessionActionTypes.TOKEN:
      return { ...state, token: action.value };

    case ESessionActionTypes.TRIES:
      return { ...state, tries: action.value !== undefined ? action.value : initialState.tries };

    default:
      return state;
  }
};
