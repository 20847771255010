import React, { FC, useRef, useState, useEffect, memo } from 'react';
import { useTheme } from 'react-jss';
import Icon from 'src/components/icons/Icon/index.web';
import { IProps } from './types.web';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
  iconClose?: string;
  input?: string;
}

const TextInput: FC<IProps> = memo((props) => {
  const {
    value,
    placeholder = '',
    type = 'text',
    maxLength = 500,
    containerStyle,
    inputStyle,
    onBlur,
    onHover,
    onPress = () => undefined,
    onChange = () => undefined,
    validate,
    instantOnChange,
    toUpper = false,
  } = props;
  const theme: ITheme = useTheme();
  const inputRef = useRef(null);
  const [active, setActive] = useState(false);
  const [stateValue, setStateValue] = useState(value);
  const styles = useStyles({ ...props, theme });

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle} ${active ? styles.componentActive : ''}`.trim(),
    iconClose: `${styles.icon} ${styles.iconClose}`.trim(),
    input: `${styles.input} ${inputStyle ? inputStyle : ''}`.trim(),
  };

  const setFocusOnInput = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (inputRef.current) {
      // @ts-ignore */
      inputRef.current.focus();
      setActive(true);
    }
  };

  const onInputChange = (e: any) => {
    const value = e.target.value;
    if (validate) {
      if (value ? validate(value) : true) {
        setStateValue(value);

        if (instantOnChange) {
          onChange(value);
        }
      }
    } else {
      setStateValue(value);

      if (instantOnChange) {
        onChange(value);
      }
    }
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setFocusOnInput(event);
    setActive(true);
    onPress(event);
  };

  const onTextAreaClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setActive(true);
    onPress(event);
  };

  const onClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onChange('');
  };

  const onInputBlur = (e: any) => {
    if (stateValue?.trim().length !== stateValue?.length) {
      onChange(stateValue.trim());
    } else {
      onChange(stateValue);
    }
    setActive(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  return (
    <div className={cn.component} onClick={setFocusOnInput}>
      {type === 'text' ? (
        <>
          <input
            ref={inputRef}
            className={cn.input}
            defaultValue={value}
            value={toUpper ? stateValue.toUpperCase() : stateValue}
            placeholder={placeholder}
            type="text"
            onBlur={onInputBlur}
            onClick={onInputClick}
            onMouseOver={onHover}
            onChange={onInputChange}
          />
          {value && !active ? (
            <div className={cn.iconClose} onClick={onClear}>
              <Icon name={'close'} color={theme.$secondaryColor2} size={16} />
            </div>
          ) : null}
        </>
      ) : null}
      {type === 'textarea' ? (
        <textarea
          ref={inputRef}
          className={cn.input}
          defaultValue={value}
          value={stateValue}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onInputBlur}
          onClick={onTextAreaClick}
          onMouseOver={onHover}
          onChange={onInputChange}
        />
      ) : null}
    </div>
  );
});

export default TextInput;
