export interface ITheme {
  $primaryColor1: string;
  $primaryColor2: string;
  $primaryColor1a03: string;
  $primaryColor1a05: string;
  $primaryColor1a10: string;
  $primaryColor2a05: string;
  $secondaryColor1: string;
  $secondaryColor2: string;
  $secondaryColor2a05: string;
  $secondaryColor1a05: string;
  $defaultColor1: string;
  $defaultColor2: string;
  $defaultColor1a05: string;
  $textColor: string;
  $borderColor: string;
  $successBgColor: string;
  $successColor: string;
  $errorColor;
}

export interface IThemes {
  main: ITheme;
}

export const themes = {
  main: {
    $primaryColor1: '#F65532',
    $primaryColor2: '#282828',
    $primaryColor1a03: 'rgba(246,85,50,0.03)',
    $primaryColor1a05: 'rgba(246,85,50,0.05)',
    $primaryColor1a10: 'rgba(246,85,50,0.10)',
    $primaryColor1a30: 'rgba(246,85,50,0.30)',
    $primaryColor1a85: 'rgba(246,85,50,0.90)',
    $primaryColor2a05: 'rgba(40,40,40,0.05)',
    $secondaryColor1: '#97A3AC',
    $secondaryColor1a05: 'rgba(151,163,172,0.05)',
    $secondaryColor2: '#ACB7BF',
    $secondaryColor2a05: 'rgba(172, 183, 191, 0.05)',
    $defaultColor1: '#F3F5F8',
    $defaultColor1a05: 'rgba(246,247,249,0.05)',
    $textColor: '#222222',
    $outlineBtnBackground: '#FFFFFF',
    $notActiveBtnBackground: '#F3F4F5',
    $borderColor: '#E3EAEF',
    $regularDark: '#282828',
    $successBgColor: 'rgba(34, 197, 118, 0.1)',
    $successColor: '#22C576',
    $errorColor: '#FF0000',
  },
};

type Themes = typeof themes;

export function getTheme(themeName: keyof Themes = 'main'): object {
  return themes[themeName];
}
