import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: (p: IProps) => ({
      position: 'fixed',
      height: '100vh',
      zIndex: 1001,
    }),
    errors: {
      position: 'absolute',
      maxWidth: '100vh',
      zIndex: 1000,
    },
  };
});
