import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles({
    component: ({ isMobile, theme }: any) => {
      return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'white',
        padding: 15,
        borderTop: `1px solid ${theme.$borderColor}`,
        borderBottom: `1px solid ${theme.$borderColor}`,
        borderLeft: isMobile ? `1px solid ${theme.$borderColor}` : 'none',
        borderRight: isMobile ? `1px solid ${theme.$borderColor}` : 'none',
        borderRadius: isMobile ? 15 : 0,
        width: isMobile ? '100%' : 'auto',
        boxShadow: isMobile ? `0px 2px 0px ${theme.$borderColor}` : 'none',
      };
    },
    title: {
      margin: 0,
    },
    button: {
      maxWidth: ['9.4rem', '!important'],
    },
});
