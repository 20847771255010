import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { Props } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: ({ active }: Props) => {
    return {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flexStart',
      alignItems: 'center',
      flexShrink: '0',
      width: '6rem',
      height: '3rem',
      borderRadius: '3.6rem',
      padding: '0 0.33rem',
      backgroundColor: active ? theme.$primaryColor1 : theme.$secondaryColor1,
      cursor: 'pointer',
    };
  },
  toggle: {
    position: 'relative',
    left: 0,
    width: '2.33rem',
    height: '2.33rem',
    borderRadius: '1.15rem',
    backgroundColor: 'white',
    transition: '0.25s all ease',
  },
  toggleActive: {
    left: '3rem',
  },
}));
