import { createSelector } from 'reselect';

import { State } from '..';
import { TOnboardingState } from './onboarding.types';
import { OnboardingData, OnboardingDataToProfile } from '../../../requests/models';

export const selectOnboardingState = (state: State) => state.onboarding;

export const selectloading = createSelector([selectOnboardingState], (onboardingState: TOnboardingState) => onboardingState.loading);

export const selectSaveProfileFlag = createSelector([selectOnboardingState], (onboardingState: TOnboardingState) => onboardingState.saveProfileFlag || false);

export const selectCurrentPage = createSelector([selectOnboardingState], (onboardingState: TOnboardingState) => onboardingState.currentPage);

export const selectData = createSelector([selectOnboardingState], (onboardingState: TOnboardingState) => onboardingState.data);

export const selectLastPage = createSelector([selectData], (data: OnboardingData) => {
  return data.lastPage || '';
});

export const selectPrivacyTermsAgree = createSelector([selectData], (data: OnboardingData) => data.privacyTermsAgree || false);

export const selectWhoAreYou = createSelector([selectData], (data: OnboardingData) => data.whoAreYou || undefined);

export const selectInstitute = createSelector([selectData], (data: OnboardingData) => data.institute || undefined);

export const selectSubject = createSelector([selectData], (data: OnboardingData) => data.subject || undefined);

export const selectMinor = createSelector([selectData], (data: OnboardingData) => data.minor || '');

export const selectCustomInstitute = createSelector([selectData], (data: OnboardingData) => data.custom_institute || undefined);

export const selectCustomSubject = createSelector([selectData], (data: OnboardingData) => data.custom_subject || undefined);

export const selectArchetype = createSelector([selectData], (data: OnboardingData) => data.archetype || undefined);

export const selectCountryOfEducation = createSelector([selectData], (data: OnboardingData) => data.country_of_education || undefined);

export const selectGraduationDate = createSelector([selectData], (data: OnboardingData) => data.graduationDate || undefined);

export const selectDegree = createSelector([selectData], (data: OnboardingData) => data.degree || undefined);

export const selectAverageGrade = createSelector([selectData], (data: OnboardingData) => data.averageGrades || undefined);

export const selectAchievements = createSelector([selectData], (data: OnboardingData) => data.achievements || []);

export const selectIExperience = createSelector([selectData], (data: OnboardingData) => data.internationalExperience || []);

export const selectActivities = createSelector([selectData], (data: OnboardingData) => data.activities || []);

export const selectLanguages = createSelector([selectData], (data: OnboardingData) => data.languages || []);

export const selectOtherLanguages = createSelector([selectData], (data: OnboardingData) => data.otherLanguages || []);

export const selectWorkExperience = createSelector([selectData], (data: OnboardingData) => data.workExperience || []);

export const selectFirstName = createSelector([selectData], (data: OnboardingData) => data.firstName || '');

export const selectLastName = createSelector([selectData], (data: OnboardingData) => data.lastName || '');

export const selectReferrer = createSelector([selectData], (data: OnboardingData) => data.referrer || '');

export const selectSex = createSelector([selectData], (data: OnboardingData) => data.sex || undefined);

export const selectBirthDate = createSelector([selectData], (data: OnboardingData) => data.birthDate || undefined);

export const selectCountry = createSelector([selectData], (data: OnboardingData) => data.country || undefined);

export const selectCity = createSelector([selectData], (data: OnboardingData) => data.city || undefined);

export const selectContactEmail = createSelector([selectData], (data: OnboardingData) => data.contact_email || '');

export const selectIsActivityAbroad = createSelector([selectData], (data: OnboardingData) => data.isBoardMember || false);

export const selectInterest = createSelector([selectData], (data: OnboardingData) => data.interest || []);

export const selectPhoneNumber = createSelector([selectData], (data: OnboardingData) => data.phone_number || '');

export const selectProfileDataForSave = createSelector([selectData], (data: any) => {
  const result: any = {
    social_media: null,
    institute: [
      {
        id: '',
        subject: '',
        graduation_date: '',
        degree: '',
        average_grades: '',
      },
    ],
  };
  Object.keys(data).forEach((key: any) => {
    if (Array.isArray(data[key])) {
      if (key === 'institute') {
        result.institute[0].id = data[key].map((obj: any) => obj.id);
      } else {
        result[key] = data[key].map((obj: any) => obj.id);
      }
    } else if (typeof data[key] === 'object') {
      if (key === 'institute') {
        result.institute[0].id = data[key].id;
      } else if (key === 'subject' || key === 'degree') {
        result.institute[0][key] = data[key].id;
      } else if (key === 'averageGrades') {
        result.institute[0].average_grades = data[key].name;
      } else if (key === 'city' || key === 'country') {
        result[key] = data[key].name;
      } else if (data[key] === null) {
        result[key] = null;
      } else if (!data[key]?.id) {
        result[key] = data[key];
      } else {
        result[key] = data[key].id;
      }
    } else if (typeof data[key] === 'string') {
      if (key === 'graduationDate') {
        result.institute[0].graduation_date = data[key];
      } else if (key === 'country_of_education') {
        result.institute[0].country_of_education = data[key];
      } else if (key === 'archetype') {
        result.institute[0].archetype = data[key];
      } else if (key === 'minor') {
        result.institute[0].minor = data[key];
      } else {
        result[key] = data[key];
      }
    } else if (typeof data[key] === 'boolean') {
      if (key === 'archetype_override') {
        result.institute[0].archetype_override = data[key];
      }
    } else if (data[key] === undefined) {
      result[key] = '';
    } else {
      result[key] = data[key];
    }
  });
  const filteredResult = (<any>Object).fromEntries(Object.entries(result).filter(([_, v]) => !(Array.isArray(v) && v.length === 0)));
  return OnboardingDataToProfile(filteredResult);
});
