import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IProps } from './types';
import { useStyles } from './styles';
import { useTheme } from 'react-jss';
import { useDispatch } from 'react-redux';
import { ITheme } from 'src/constant/themes';
import Title from 'src/components/titles/Title/index.web';
import Text from 'src/components/texts/Text/index.web';
import ButtonMain from 'src/components/buttons/ButtonMain/index.web';
import Icon from 'src/components/icons/Icon/index.web';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import GradeProof from '../../../../GradeProof';
import { changeGradeProof } from 'src/state/modules/user';
import { selectGradeProofChange } from 'src/state/modules/user';
import { useSelector } from 'react-redux';

interface ComponentStyles {
  component?: string;
  title?: string;
}

const Education = (props: IProps) => {
  // @ts-ignore
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { isMobile, containerStyle, data, onEdit, index } = props;
  const { name, average_grades, graduation_date, subject_id, subject_name, degree_name, grade_proof, id, degree_id } = data;
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const gradeProofChange = useSelector((state) => selectGradeProofChange(state));
  const dispatch = useDispatch();

  const modalOpen = () => {
    setModal(true);
  };

  const modalClose = () => {
    dispatch(changeGradeProof());
    setModal(false);
  };

  useEffect(() => {
    if (!grade_proof && index === gradeProofChange) {
      setModal(true);
    }
    return () => {
      setModal(false);
    };
  }, [gradeProofChange]);

  const cn: ComponentStyles = {
    component: `${styles.container} ${containerStyle ? containerStyle : ''}`.trim(),
  };

  const gradDate = moment(graduation_date).diff(Date.now());

  return (
    <div className={cn.component}>
      <div className={styles.picture}>
        <Icon name={'institute'} size={46} color={theme.$primaryColor1} />
      </div>
      <div className={styles.info}>
        <div className={styles.infoInner}>
          <Title size={'h3'} containerStyle={styles.title}>
            {name}
          </Title>
          <Text type={'secondary'} containerStyle={styles.date}>
            {gradDate > 0 ? t('Ongoing') : `${t('Graduated')} ${moment(graduation_date).format('MMM YYYY')}`}
          </Text>
          <Text type={'primary'}>{`${degree_name} ${t('Degree')}, ${subject_name}`}</Text>
        </div>
        <div className={styles.grades}>
          {average_grades ? (
            <>
              <Title size={'h3'} containerStyle={styles.gradesTitle}>
                {t('Average grades')}
              </Title>
              <div className={styles.gradesContainer}>
                <div className={styles.gradesValue}>{average_grades}</div>
                <div className={styles.gradeProofInfo} onClick={modalOpen}>
                  {!grade_proof ? (
                    <Icon size={20} name={'upload'} color={theme.$primaryColor1} />
                  ) : (
                    <Icon size={20} name={'file'} color={theme.$primaryColor1} />
                  )}
                </div>
              </div>
            </>
          ) : (
            <ButtonMain
              type={'primary_1'}
              size={'xl'}
              containerStyle={styles.editLink}
              textStyle={styles.editLinkText}
              onPress={() => onEdit(true)}
              text={`+ ${t('Add average grades')}`}
            />
          )}
        </div>
      </div>
      <Modal wrapperStyles={styles.modal} isModalOpened={modal} onClose={modalClose}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <Title size={'h1'} containerStyle={styles.title} onPress={modalClose}>
              <Icon size={24} name={'arrow-left2'} color={'#222222'} /> {t('Average grades')}
            </Title>
          </div>
          <div className={styles.modalMain}>
            <GradeProof
              containerStyle={styles.modalGrade}
              institute_id={id}
              subject_id={subject_id}
              grade_proof={grade_proof}
              degree_id={degree_id}
              subject_name={subject_name}
              institute_name={name}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Education;
