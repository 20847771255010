import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    root: (p: IProps) => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: 'white',
      width: '100%',
      height: '100%',
      borderRadius: p.isMobile ? 0 : 15,
    }),
    header: (p: IProps) => ({
      width: '100%',
      height: 60,
      display: 'flex',
      background: 'rgba(232, 239, 244, 0.25)',
      flexShrink: 0,
      padding: '10px 30px',
      borderTopLeftRadius: p.isMobile ? 0 : 15,
      borderTopRightRadius: p.isMobile ? 0 : 15,
    }),
    title: {
      margin: 0,

      '& svg': {
        cursor: 'pointer',
        marginRight: 10,
        marginLeft: -10,
      },
    },
    container: (p: IProps) => ({
      width: '100%',
      maxWidth: p.isMobile ? 510 : 'none',
      padding: '0 15px 30px',
      overflow: 'auto',
    }),
    item: (p: IProps) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 15px',
    }),
    picture: (p: IProps) => ({}),
    details: (p: IProps) => ({
      alignItems: 'flex-start',
    }),
    more: (p: IProps) => ({
      alignItems: 'flex-start',
      marginBottom: 30,
    }),
    avatar: (p: IProps) => ({
      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.$primaryColor1,
      margin: '20px 0',

      '& svg': {
        width: 60,
        height: 60,
      },
    }),
    fields: {
      margin: '15px 0 0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    field: {
      marginBottom: '15px',
      width: '100%',
    },
    fieldHalf: (p) => ({
      width: p.isMobile ? '100%' : 'calc(50% - 7.5px)',
      marginBottom: '15px',
    }),
    fieldInput: {
      maxWidth: 'none',
    },
    fieldLabel: {
      color: theme.$primaryColor2,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
      margin: '0.5rem 0',
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      margin: '15px 0',
    },
    listItem: {
      margin: '5px 15px 5px 0',
    },
    textAreaTexts: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textArea: {
      padding: 15,
      maxWidth: 'none',
      height: 162,
    },
    textAreaInput: {
      width: '100%',
      height: '100%',
      resize: 'none',
      borderRadius: 0,
    },
    textAreaMax: {
      marginRight: 10,
    },
    socials: {
      marginTop: 30,
      padding: '0 15px',

      '& $fieldInput': {
        paddingLeft: 40,
      },
    },
    socialInput: {
      position: 'relative',
    },
    socialIcon: {
      position: 'absolute',
      display: 'flex',
      top: '50%',
      left: 20,
      transform: 'translate(-50%, -50%)',
    },
    footer: (p) => ({
      padding: p.isMobile ? '30px 15px' : '30px 30px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
    }),
    footerButtonBack: {
      width: '25%',
      maxWidth: 'none',
      marginRight: 15,
    },
    footerButtonNext: {
      width: 'calc(75% - 15px)',
      maxWidth: 'none',
    },
    invalidInput: {
      borderColor: 'red',
      maxWidth: 'none',
    },
  };
});
