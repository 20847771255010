import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';
import { IProps } from './types';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
export const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    width: '100%',
    maxWidth: '34.5rem',
  },
  content: {},
  label: {
    fontFamily: 'AvenirNextCyr, sans-serif',
    fontWeight: 500,
    margin: '0.5rem',
  },
  area: {
    width: '100%',
    height: 200,
    fontFamily: 'Inter',
    color: '#222222',
    fontSize: 14,
    borderRadius: 10,
    lineHeight: '16px',
    outline: 'none',
    border: `1px solid ${theme.$borderColor}`,
    resize: 'none',
    padding: 15,

    '&::placeholder': {
      opacity: 0.3,
      color: '#222222',
    },
    '&:focus, &:active': {
      color: theme.$primaryColor2,
      opacity: 1,
    },
  },
  input: {},
}));
