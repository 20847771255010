import React, { useEffect } from 'react';
import LikesPage from '../containers/LikesPage';
import Page from '../containers/Page';
import { IPassProps } from '../containers/Page/types';
import { initGA } from 'src/utilities';

const Container = () => (
  <Page>
    {({ isMobile, pageRef }: IPassProps) => {
      return <LikesPage isMobile={isMobile} ref={pageRef} />;
    }}
  </Page>
);

export default Container;
