import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    container: ({ isMobile, view }: IProps) => {
      return {
        display: 'flex',
        flexDirection: (view === 'likes' || view === 'bookmarks') && !isMobile ? 'row' : 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: view ? '100%' : isMobile ? 345 : 383,
        width: '100%',
        //height: isMobile ? 204 : 233,
        padding: isMobile ? 15 : 30,
        backgroundColor: 'white',
        border: `1px solid ${theme.$borderColor}`,
        borderRadius: 10,
        boxShadow: `0px 2px 0px ${theme.$borderColor}`,
      };
    },
    block: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
    },
    companyName: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      margin: '0 10px 0 0',
    },
    types: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 12px',
      background: 'rgba(232, 239, 244, 0.5)',
      borderRadius: 3,
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 0,
      paddingBottom: 13,
      cursor: 'pointer',
    },
    eventName: ({ fixed }) => ({
      wordBreak: 'break-word',
      marginTop: fixed ? 0 : 15,
    }),
    eventInfo: ({ fixed, data }) => ({
      position: 'relative',
      background: fixed ? 'linear-gradient(0, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)' : '#FFFFFF',
      border: fixed ? 'none' : `1px solid ${theme.$borderColor}`,
      borderRadius: 10,
      padding: 15,
      height: fixed ? 140 : 'auto',
    }),
    setInterest: {
      flexGrow: 1,
      maxWidth: 'none',
    },
    eventShortDate: {
      position: 'relative',
      width: 34,
      height: 45,
      marginBottom: 15,
      color: 'white',
    },
    eventShortDateDay: {
      fontSize: 24,
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 700,
      color: 'white',
    },
    eventShortDateMonth: {
      fontSize: 16,
      fontFamily: 'AvenirNextCyr, sans-serif',
      fontWeight: 500,
    },
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    eventInfoImg: ({ fixed, data }) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0.5,
      backgroundImage: `url(${data && data.banner_img ? data.banner_img.replace(/ /g, '%20') : ''})`,
      borderRadius: 3,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100%',
      zIndex: 0,
    }),
    eventInfoItem: ({ fixed, data }) => ({
      position: 'relative',
      margin: '0 0 10px',
      fontSize: 13,
      color: fixed ? 'white' : 'black',
      wordBreak: 'break-word',

      '&:last-child': {
        margin: 0,
      },

      '& svg': {
        marginRight: 12,
        flexShrink: 0,
        float: 'left',
      },
    }),
    eventType: {
      position: 'absolute',
      top: 15,
      right: 15,
      background: '#F3F6F9',
      borderRadius: 3,
      padding: '3px 12px',
    },
    body: ({ view, isMobile }: IProps) => ({
      maxWidth: (view === 'likes' || view === 'bookmarks') && !isMobile ? '60%' : '100%',
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      cursor: 'pointer',
      wordBreak: 'break-word',
    }),
    footer: ({ view, isMobile }: IProps) => ({
      maxWidth: (view === 'likes' || view === 'bookmarks') && !isMobile ? '40%' : '100%',
      paddingLeft: (view === 'likes' || view === 'bookmarks') && !isMobile ? 30 : 0,
      paddingTop: 10,
      alignItems: 'flex-end',
    }),
    title: {
      marginTop: 2,
      marginBottom: 8,
      lineHeight: '21px',
    },
    logo: ({ data }: IProps) => ({
      width: 30,
      height: 30,
      borderRadius: 3,
      marginRight: 15,
      flexShrink: 0,
      backgroundImage: `url(${data && data.logo ? data.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    info: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    viewItem: {
      flexGrow: 1,
      maxWidth: 'none',
    },
    labels: {
      display: 'flex',
    },
    label: {
      padding: '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
  };
});
