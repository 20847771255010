import { createUseStyles } from 'react-jss';
import { ITheme } from '../../../constant/themes';

export const useStyles = createUseStyles((theme: ITheme) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '0.3rem 1.2rem',
      height: '2.2rem',
      alignItems: 'center',
      color: theme.$primaryColor2,
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      backgroundColor: theme.$defaultColor1,
      border: `1px solid ${theme.$defaultColor1}`,
      borderRadius: 3,
      margin: '1rem',
    },
  };
});
