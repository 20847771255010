import React, { memo, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useStyles } from './styles';
import ContentToggle from 'src/components/combined/ContentToggle/index.web';
import emoji from 'emoji-dictionary';
import { ITheme } from 'src/constant/themes';
import { useTheme } from 'react-jss';
import { markdownList } from 'src/utilities';
import { IProps } from './types';

const emojiSupport = (text: any) => text.value.replace(/:\w+:/gi, (name: any) => emoji.getUnicode(name));

const MarkdownItem = ({ title, styles, content, isOpened = false }: { title: any; styles: any; content: any; isOpened?: boolean }) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  return (
    <ContentToggle
      noIcon
      active={isOpen}
      containerStyle={styles.toggleText}
      contentStyle={styles.toggleTextItem}
      title={<ReactMarkdown source={title} renderers={{ text: emojiSupport }} />}
      onPress={() => setIsOpen(!isOpen)}>
      <ReactMarkdown source={content} escapeHtml={false} />
    </ContentToggle>
  );
};

const Markdown = memo((props: IProps) => {
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, theme });
  const { markdown, isApplication } = props;
  const list = markdownList(markdown, [0]);
  return (
    <div className={styles.component}>
      {list.map(({ title, markdown, isOpened }: { title: string; markdown: string; isOpened?: boolean }, index: number) => {
        if (isApplication) {
          return (
            <>
              <ReactMarkdown source={title} escapeHtml={false} />
              <ReactMarkdown source={markdown} escapeHtml={false} />
            </>
          );
        } else {
          if (title) {
            return <MarkdownItem key={index} title={title} styles={styles} content={markdown} isOpened={isOpened} />;
          } else {
            return <ReactMarkdown key={index} source={markdown} escapeHtml={false} />;
          }
        }
      })}
    </div>
  );
});

export default Markdown;
