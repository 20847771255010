import React from 'react';
import Header from 'src/web/components/Header';
import Settings from 'src/web/components/Settings';
import styles from './styles.module.scss';
import { IProps } from './types';
import ErrorBoundaries from 'src/web/components/ErrorBoundaries';
import { Helmet } from 'react-helmet';

const SettingsPage = (props: IProps) => {
  const { isMobile } = props;

  return (
    <>
      <Helmet>
        <title>{'Settings'}</title>
      </Helmet>
      <Header isMobile={isMobile} containerStyle={styles.header} />
      <ErrorBoundaries>
        <div className={styles.contain}>
          <Settings isMobile={isMobile} />
        </div>
      </ErrorBoundaries>
    </>
  );
};

export default SettingsPage;
