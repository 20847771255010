import { createUseStyles } from 'react-jss';
import { ITheme } from 'src/constant/themes';
import { IProps } from './types';

export const useStyles = createUseStyles({
    container: ({ isMobile, view, theme }: any) => {
      return {
        display: 'flex',
        flexDirection: (view === 'likes' || view === 'bookmarks' || view === 'dislikes') && !isMobile ? 'row' : 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: view ? '100%' : isMobile ? 345 : 383,
        width: '100%',
        //height: isMobile ? 204 : 233,
        padding: isMobile ? 15 : 30,
        backgroundColor: 'white',
        border: `1px solid ${theme.$borderColor}`,
        borderRadius: 10,
        boxShadow: `0px 2px 0px ${theme.$borderColor}`,
      };
    },
    block: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: 10,
      cursor: 'pointer',
    },
    body: ({ view, isMobile }: IProps) => ({
      maxWidth: (view === 'likes' || view === 'bookmarks' || view === 'dislikes') && !isMobile ? '60%' : '100%',
      height: view === 'explorer' ? 'auto' : 56,
      overflow: 'hidden',
      lineHeight: '19px',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      cursor: 'pointer',
    }),
    footer: ({ view, isMobile }: IProps) => ({
      maxWidth: (view === 'likes' || view === 'bookmarks' || view === 'dislikes') && !isMobile ? '40%' : '100%',
      paddingLeft: (view === 'likes' || view === 'bookmarks' || view === 'dislikes') && !isMobile ? 30 : 0,
      paddingTop: 10,
      alignItems: 'flex-end',
    }),
    title: {
      display: 'block',
      marginTop: 2,
      marginBottom: 0,
      lineHeight: '21px',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    logo: ({ data, view }: IProps) => ({
      width: view ? 44 : 64,
      height: view ? 44 : 64,
      borderRadius: 3,
      marginRight: 15,
      flexShrink: 0,
      backgroundImage: `url(${data && data.logo ? data.logo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }),
    info: ({ view }: IProps) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: view && view !== 'explorer' ? 'none' : 'calc(100% - 64px - 15px)',
    }),
    bookmark: {
      marginLeft: 15,
    },
    setInterest: {
      flexGrow: 1,
      maxWidth: 'none',
    },
    labels: ({ view }: IProps) => ({
      display: !view ? 'block' : 'flex',
    }),
    label: ({ view }: IProps) => ({
      overflow: 'hidden',
      justifyContent: 'flex-start',
      padding: !view ? 0 : '0 5px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    }),
    labelIcon: {},
    details: {},
    detailsImg: ({ data }: IProps) => ({
      width: '100%',
      height: 214,
      backgroundImage: `url(${data && data.banner_photo ? data.banner_photo.replace(/ /g, '%20') : ''})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }),
    detailsList: {
      marginTop: 5,
      padding: [[0], '!important'],
    },
    btnMain: {
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
    setIgnore: {},
    setIgnoreText: {
      color: '#222222',
    },
});
