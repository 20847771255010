import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import Button from 'src/components/buttons/ButtonIconToggle/index.web';
import { IProps } from './types';
import { useStyles } from './styles.web';
import { ITheme } from '../../../constant/themes';

interface ComponentStyles {
  component?: string;
}

const ButtonFilter: FC<IProps> = (props) => {
  /* @ts-ignore */
  const theme: ITheme = useTheme();
  const styles = useStyles({ ...props, ...theme });
  const { disabled, active, size, containerStyle, onHover, onPress, onBlur } = props;

  const cn: ComponentStyles = {
    component: `${styles.component} ${containerStyle ? containerStyle : ''}`.trim(),
  };

  return (
    <Button
      disabled={disabled}
      size={size}
      active={active}
      activeIconName={'filter'}
      inactiveIconName={'filter'}
      iconColor={active ? theme.$primaryColor1 : theme.$primaryColor2}
      containerStyle={cn.component}
      onPress={onPress}
      onHover={onHover}
      onBlur={onBlur}
    />
  );
};

export default ButtonFilter;
